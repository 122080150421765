
import { Avatar, Button, Chip, TextField } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getDB_Data, getDB_Data_lazy, getDB_Data_lazy_notID, getUSERS } from '../../../actions/PageActions';
import { closePopUp, openPopUp } from '../../../actions/PopUpActions';
import { GlobalFunctions as GF } from '../../../GlobalFunctions';
import Table from '../../components/Table';
import withToast from '../../components/Toasts';
import POP_NewPartner from './POP_NewPartner';
import POP_NewPartner_Accept from './POP_NewPartner_Accept';
import POP_NewPartner_Forbidden from './POP_NewPartner_Forbidden';





const Rows = [
  {
    title: 'ФИО',
    key: 'FIO'
  },

  {
    title: 'Компания',
    key: 'COMPANY_TITLE',
  },
  {
    title: 'ИНН',
    key: 'INN',
  },
  {
    title: 'Дата запроса',
    key: 'DATE_CREATE',
  },
  {
    title: 'Статус',
    key: 'ID_STATUS',
  },
]

class NewPartner extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      errorMSG: null,
      succes: false,
      filter: null,
      Data: [],
      selectedRows: []
    }
    this.input = {
      dataWindow: {},
      main: {}
    }
    this.checkBoxs = {}
    this.updateTimer = null
    this.can = true
  }



  componentDidMount() {
    this.props.getDB_Data('getNewPartners', 'NEW_PARTNERS')
    this.updateTimer = setInterval(() => this.props.getDB_Data('getNewPartners', 'NEW_PARTNERS'), 3000)
  }
  componentWillUnmount() {
    clearInterval(this.updateTimer)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.NEW_PARTNERS.length != this.props.NEW_PARTNERS.length) {
      this.checkSelected()
    }
  }

  checkSelected() {
    const { USERS, USER_ROLES } = this.props
    const { filter, Data, selectedTabFilter, selectedRows } = this.state
    const filtered = this.props.NEW_PARTNERS.filter(u => filter == null ||
      u.FIO.indexOf(filter) != -1 ||
      u.PHONE.indexOf(filter) != -1 ||
      u.EMAIL.indexOf(filter) != -1
    )
    let correctRows = []
    selectedRows.forEach((sr, i) => {
      if (filtered.findIndex(u => u.ID == sr) != -1) {
        correctRows.push(sr)
      }
    })
    if (correctRows.length != selectedRows) {
      this.setState({ selectedRows: correctRows })
    }
  }

  setStatus(NEW_STATUS) {

    const { filter, Data, selectedRows } = this.state

    const func = (ADDITIONAL) => {
      this.props.closePopUp()
      if (!this.can) return
      this.can = false

      let promises = []

      selectedRows.forEach(sr => {
        promises.push(GF.API_task({
          operation: 'setNewPartnerStatus',
          ID: sr,
          NEW_STATUS,
          ADDITIONAL
        }))
      })



      Promise.all(promises)
        .then(res => {
          this.props.getDB_Data('getNewPartners', 'NEW_PARTNERS')
          this.props.getDB_Data_lazy_notID('HUMANS', 'IMAGES_URL')
          this.props.getDB_Data_lazy('MANAGERS_ROOLES')
          setTimeout(() => this.props.getUSERS(), 500)

        }).finally(() => {
          this.can = true
          this.setState({ selectedRows: [] })
        })


    }



    if (NEW_STATUS == 16)
      this.props.openPopUp(<POP_NewPartner_Accept callBack={(args) => func(args)} />)
    else
      this.props.openPopUp(<POP_NewPartner_Forbidden callBack={(args) => func(args)} />)

  }



  render() {
    const { NEW_PARTNERS } = this.props
    const { filter, Data, selectedRows } = this.state

    const filtered = this.props.NEW_PARTNERS.filter(u => filter == null ||
      u.FIO.toUpperCase().indexOf(filter) != -1 ||
      u.PHONE.toUpperCase().indexOf(filter) != -1 ||
      u.INN?.indexOf(filter) > -1 ||
      u.EMAIL.toUpperCase().indexOf(filter) != -1
    )

    return (
      <View>
        <div className='mt-4'>
          <TextField
            className='me-2'
            label='Поиск'
            variant='filled'
            size="small"
            onKeyUp={(e) => this.setState({ filter: e.target.value.length > 0 ? e.target.value.toUpperCase() : null }, this.checkSelected)}
          />

          <Button color='success' className='me-2 align-bottom' variant='text' onClick={() => {
            this.setState({ selectedRows: filtered.map(u => u.ID) })
          }}>Выбрать все</Button>

          <Button disabled={selectedRows.length == 0} color='success' className='me-2 align-bottom' variant='text' onClick={() => {
            this.setState({ selectedRows: [] })
          }}>Cнять выделение</Button>

          <Button disabled={selectedRows.length == 0} color='error' className='me-2 align-bottom' variant='text' onClick={() => {
            this.setStatus(15)
          }}>Отказать выбранным</Button>
          <Button disabled={selectedRows.length == 0} color='primary' className='me-2 align-bottom' variant='text' onClick={() => {
            this.setStatus(16)
          }}>Подтвердить выбранным</Button>


        </div>


        <Table
          className='mt-1'
          rows={Rows}
          keyExtraktor={item => item.ID}
          canCheck={true}
          selectedRows={selectedRows}
          setSelectedRows={(rows) => this.setState({ selectedRows: rows })}
          data={filtered}
          itemOnClick={({ data, index }) => {
            this.props.openPopUp(<POP_NewPartner ID_OBJECT={data.ID} />)
          }}
          rowOnClick={({ data, index }) => {
            debugger
          }}
          renderItem={({ data, index }) =>
            Rows.map(r => {
              switch (r.key) {
                case 'ID_STATUS':
                  return (<td>
                    <Chip size='small' label={data.STATUS_TITLE} style={{
                      background: data.STATUS_COLOR,
                      color: 'white'
                    }} />
                  </td>)
                case 'DATE_CREATE':
                  return (<td>{moment(data.DATE_CREATE).format('D MMMM YYYY')}</td>)
                case 'PHONE':
                  return (<td>{GF.phoneFormatter(data.PHONE)}</td>)
                case 'INN':
                  return (<td>{data.INN}</td>)
                case 'EMAIL':
                  return (<td><a href={'mailto:' + data[r.key]} target='black'>{data[r.key]}</a></td>)
                case 'FIO':
                  return (<td>
                    <Chip size='small' label={GF.FIO(data.FIO, 2)} avatar={<Avatar />} /></td>)
                default: return (<td>{data[r.key]}</td>)
              }

            })
          } />


      </View>
    )
  }
}
const View = styled.div`
height:100%;
`

const mapDispatchToProps = (dispatch) => {
  return {
    openPopUp: (arg) => dispatch(openPopUp(arg)),
    closePopUp: (arg) => dispatch(closePopUp(arg)),
    getUSERS: (arg) => dispatch(getUSERS(arg)),
    getDB_Data: (arg, grfm) => dispatch(getDB_Data(arg, grfm)),
    getDB_Data_lazy_notID: (arg, grfm) => dispatch(getDB_Data_lazy_notID(arg, grfm)),
    getDB_Data_lazy: (tableName, JSONparseKey) => dispatch(getDB_Data_lazy(tableName, JSONparseKey)),
  };
};


const mapStateToProps = (store) => {

  return {
    NEW_PARTNERS: store.page.NEW_PARTNERS
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withToast(NewPartner));

