import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Inst_schedule from '../Inst_schedule'
import AllMyInstallers from './AllMyInstallers'

export default function ManagerInstallers(){
    return(<Switch>
        <Route path='*/all'>
            <AllMyInstallers/>
        </Route>

        <Route path='*/schedule'>
            <Inst_schedule/>
        </Route>

        <Redirect to='*/schedule' path='*/'/>
    </Switch>)
}