import React, { Component } from 'react';

import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Button, LinearProgress } from '@mui/material';
import ruLocale from 'date-fns/locale/ru';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastProvider } from 'react-toast-notifications';
import { GlobalFunctions as GF } from "../GlobalFunctions";
import { getInstallSchedule, getManagerInstallersRooles } from '../actions/InstallSchedule';
import { getACCESSORIES, getACCESSORIES_BASKET, getACCESSORIES_CATEGORIS, getACCESSORIES_ORDERS, getDB_Data, getDB_Data_lazy, getDB_Data_lazy_notID, getFileSustem, getMANAGERS_ROOLES, getMyPartners, getORDERS, getTexts, getUSERS, getUsersPermissions, getVARS } from '../actions/PageActions';
import { openPopImgs } from '../actions/PopUpActions';
import { loadUserData_FromStorage, loginIn } from '../actions/UserAct';
import logo from '../design/logo.svg';
import "../global";
import Login from "./Login";
import Main from './Main';
import { Bg } from './PopUp';
import PopUpImgs from './PopUp/PopUpImgs';
import Guid from './pages/Guid';
import CalkPage from './pages/MainPage/CalkPage';
import Saved from './pages/MainPage/Saved';
import NewPartner from './pages/NewPartner';
import SaveFiles from './pages/SaveFiles';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stratAnim: false,
      appLoaded: false,
      nowLoading: 0,
      maxLoading: 100,
      nowWaiting: 'Авторизация',
      showLoadingScreen: true,
      haveReactError: false
    }
    this.updateTimer = null
    this.DataShedul = []
    const { cookies } = props;

    global.afterSustemLoad = () => {
      //тут описывается то, что будет запущено при старте системы
      this.props.loginIn()
    }
    this.props.getTexts()

    global.afterLoginned = async () => {
      const DataTables = this.props?.user?.data?.ROLE ? ['HUMANS', 'USER_ROLES', 'USER_STATUS', 'VARS', 'STATUSES', 'USERS', 'PARTNER_COMPANYS', 'TEXTS', 'USERS_PERMISSIONS'] : []

      this.DataShedul = [
        () => this.props.getMANAGERS_ROOLES(),
        () => this.props.getDB_Data_lazy_notID('HUMANS', 'IMAGES_URL'),
        () => this.props.getDB_Data_lazy_notID('USER_ROLES'),
        () => this.props.getDB_Data_lazy('USER_STATUS'),

        () => this.props.getDB_Data_lazy_notID('VARS'),
        () => this.props.getDB_Data_lazy('NEWS'),
        () => this.props.getFileSustem(),
        () => this.props.getUSERS(),
        () => this.props.getUsersPermissions(),
        () => this.props.getDB_Data_lazy('STATUSES'),
        () => this.props.getTexts(),
        () => this.props.getDB_Data_lazy('PARTNER_COMPANYS', 'IMAGES_URL'),

      ]

      const Basket = [
        () => this.props.dispatch(getACCESSORIES_CATEGORIS()),
        () => this.props.dispatch(getACCESSORIES()),
        () => this.props.dispatch(getACCESSORIES_BASKET()),
        () => this.props.dispatch(getACCESSORIES_ORDERS()),
      ]
      const Orders = [
        () => this.props.getORDERS(),
      ]

      const InstSchedule = [
        () => this.props.dispatch(getInstallSchedule()),
        () => this.props.dispatch(getManagerInstallersRooles()),
      ]

      //тут описывается то, что будет запущено после авторизации
      try {


        // this.props.getVARS()
        console.error('user ROLE', this.props.user.data.ROLE)
        switch (this.props.user.data.ROLE) {
          case 'ADMN':
            DataTables.push('NEW_PARTNERS', 'ACCESSORIES', 'ACCESSORIES_CATEGORIS', 'MANAGERS_ROOLES', 'InstallSchedule')
            this.DataShedul = this.DataShedul.concat([
              () => this.props.getDB_Data('getNewPartners', 'NEW_PARTNERS'),
              () => this.props.getDB_Data_lazy('MANAGERS_ROOLES'),
              ...Orders,
              ...Basket,
              ...InstSchedule,
            ])
            break;

          case 'MNGR':
            DataTables.push('MyPartners', 'ACCESSORIES', 'ACCESSORIES_CATEGORIS', 'MANAGERS_ROOLES', 'InstallSchedule')
            this.DataShedul = this.DataShedul.concat([
              () => this.props.getMyPartners(),
              ...Orders,
              ...Basket,
              ...InstSchedule,
            ])
            break;

          case 'PRTN': case 'CPRT':
            DataTables.push('ACCESSORIES', 'ACCESSORIES_CATEGORIS', 'MANAGERS_ROOLES')
            this.DataShedul = this.DataShedul.concat([

              () => this.props.getDB_Data('getMyManager', 'MY_MANAGER'),
              ...Orders,
              ...Basket,
            ])
            break;

          case 'INST':
            DataTables.push('InstallSchedule')
            this.DataShedul = this.DataShedul.concat([
              ...Orders,
              ...InstSchedule,
            ])
            break;
        }


      } catch (e) { }

      this.DataShedul.forEach((ds, i) => {
        setTimeout(() => ds(), 10 * i)
      })


      let i = 0;
      while (i < DataTables.length) {
        let d = DataTables[i]
        this.setState({ nowWaiting: d, nowLoading: Math.floor(i / this.DataShedul.length * 100) })
        while (this.props.page[d] == null) {
          await GF.WaitingTimer(100)
        }
        await GF.WaitingTimer(100)

        i++
      }

      this.setState({ nowWaiting: null, nowLoading: 100 })

      setTimeout(() => {
        this.setState({ appLoaded: true }, () => {
          setTimeout(() => {
            this.setState({ showLoadingScreen: false })
          }, 600)
        })
      }, 500)



    }

    global.startShedul = () => {
      //тут описываются все события, которые должны быть запущены после успешной авторизации
    }

    props.loadUserData_FromStorage()




  }

  componentDidCatch(error, info) {
    if (window.location.href.indexOf('localhost') == -1) {
      GF.API_task({
        operation: 'logError',
        block: 'PublicFunctions',
        ERROR: error.toString(),
        INFO: info.componentStack,
        PLATFORM: 'web ' + GF.browserDetection(),
        LAST_ROUTE: window.location.href.replace('https://', ''),
        AppVersion: null
      })
    }

    this.setState({ haveReactError: true })
  }


  componentDidMount() {

    setTimeout(() => {
      this.setState({ stratAnim: true })
    }, 1000)
    this.updateTimer = setInterval(() => {
      this.DataShedul.forEach((ds, i) => {
        setTimeout(() => ds(), 1000 * i)
      })
    }, this.DataShedul.length * 1000 + 6000000000)
  }

  componentWillUnmount() {


    clearInterval(this.updateTimer)
  }



  render() {
    const { user, PopUps } = this.props
    const { appLoaded, haveReactError } = this.state
    let content = []

    if (haveReactError) {
      return (<div style={{
        padding: 30
      }}>
        <h1>Что-то пошло не так</h1>
        <p className='w-75'>Платформа находится на стадии разработки, и такие ситуации, к сожалению, могут происходить. Мы уже получили отчет об этой ошибке и обязательно ее исправим. Приносим свои извинения за неудобства</p>
        <Button color='success' onClick={() => {
          window.location.replace('../../../../../../')
        }}>Вернуться на главный экран</Button>
      </div>)
    }

    /*
     <LinearProgress style={{
              height:5
            }} color='success'/>
    */

    const loading =
      window.innerWidth < 900 ?
        (<LinearProgress style={{
          height: 5,
          width: '100%',
        }} color='success' variant="determinate" value={this.state.nowLoading} />)
        :
        (
          <div key='rootView' style={{
            opacity: this.state.appLoaded ? 0 : 1,
            transition: '.5s',
            zIndex: 999999,
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            overflow: 'hidden'
          }}>

            <div style={{
              width: 300,
              position: 'fixed',
              left: '50vw',
              top: '50vh',
              transform: 'translate(-50%,-50%)',
              zIndex: 999
            }}>
              <img src={logo} style={{
                width: '100%',
              }} />
              <LinearProgress style={{
                height: 5,
                width: '100%',
                marginTop: 20
              }} color='success' variant="determinate" value={this.state.nowLoading} />


              <small style={{
                textAlign: 'center',
                width: '100%',
                display: 'block',
                marginTop: 20
              }}>{this.state.nowWaiting || 'Приложение загружается'}</small>
            </div>
            <div style={{
              opacity: this.state.stratAnim ? 0.5 : 0,
              transition: '5s',
              position: 'fixed',
              width: '100vw',
              height: '100vw',
              left: this.state.stratAnim ? '-65vw' : '-90vw',
              bottom: this.state.stratAnim ? '-40vw' : '-95vw',
              borderRadius: 500,
              zIndex: -1,
              background: 'rgb(46,125,50)',
              background: 'radial-gradient(circle, rgba(46,125,50,1) 2%, rgba(175,205,177,1) 40%, rgba(255,255,255,1) 68%)',
            }} />
          </div>
        )

    if (user.isLogined == null || (
      user.isLogined == true
    ) || (
        user.isLogined == false && this.props.TEXTS == null || this.props.TEXTS.length == 0 || Object.keys(this.props.TEXTS).length == 0
      )) {
      if (this.state.showLoadingScreen)
        content.push(loading)
    }

    if (user.isLogined == null) {

    } else if (user.isLogined) {
      if (appLoaded)
        content.push(
          <div key="mainRouter" style={{
            position: "relative",
            zIndex: 0
          }}>
            <Router key="mainRouter" >
              <Switch>
                <Route path='/save_files'>
                  <SaveFiles />
                </Route>
                <Route path='/registration'>
                  <NewPartner CAN_BACK={true} />
                </Route>
                <Route path='/new_partner'>
                  <NewPartner />
                </Route>

                <Route path='/guid/:GUID'>
                  <Guid />
                </Route>
                <Route path='/'>

                  <Main />
                </Route>

              </Switch>

            </Router>
          </div>
        )
    } else {

      if (this.props.TEXTS == null || this.props.TEXTS.length == 0 || Object.keys(this.props.TEXTS).length == 0) {

      } else content.push(
        <div style={{
          position: "relative",
          zIndex: 0
        }}>
          <Router key="mainRouter" >

            <Switch>
              <Route path='/calk/saved'>
                <Saved />
              </Route>


              <Route path='/calk'>
                <CalkPage />
              </Route>

              <Route path='/save_files'>
                <SaveFiles />
              </Route>
              <Route path='/registration'>
                <NewPartner CAN_BACK={true} />
              </Route>

              <Route path='/new_partner'>
                <NewPartner />
              </Route>

              <Route path='/guid/:GUID'>
                <Guid />
              </Route>
              <Route path='/'>
                <Login />
              </Route>
            </Switch>

          </Router>
        </div>
      )
    }

    return (
      <ToastProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>

          {PopUps.length == 0 ? null : <Bg />}
          {PopUps.map(popUp => {
            return popUp
          })}

          {content}
          <PopUpImgs />
        </LocalizationProvider>
      </ToastProvider>

    )

  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (arg) => dispatch(arg),
    loadUserData_FromStorage: (arg) => dispatch(loadUserData_FromStorage(arg)),
    loginIn: (arg) => dispatch(loginIn(arg)),
    getVARS: (arg) => dispatch(getVARS(arg)),
    getDB_Data: (operation, tableName, JSONparseKey) => dispatch(getDB_Data(operation, tableName, JSONparseKey)),
    getDB_Data_lazy: (tableName, JSONparseKey) => dispatch(getDB_Data_lazy(tableName, JSONparseKey)),
    getDB_Data_lazy_notID: (tableName, JSONparseKey) => dispatch(getDB_Data_lazy_notID(tableName, JSONparseKey)),

    getUSERS: (arg) => dispatch(getUSERS(arg)),
    getTexts: (arg) => dispatch(getTexts(arg)),
    getORDERS: (arg) => dispatch(getORDERS(arg)),
    getFileSustem: (arg) => dispatch(getFileSustem(arg)),
    openPopImgs: (arg) => dispatch(openPopImgs(arg)),
    getMyPartners: (arg) => dispatch(getMyPartners(arg)),
    getMANAGERS_ROOLES: (arg) => dispatch(getMANAGERS_ROOLES(arg)),
    getUsersPermissions: (arg) => dispatch(getUsersPermissions(arg)),
  };
};


const mapStateToProps = (store) => {
  return {
    user: store.user,
    page: store.page,
    PopUps: store.popups.windows,
    TEXTS: store.page.TEXTS,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(App));

