
import { Check, Delete } from '@mui/icons-material';
import { Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Select, TextField, Tooltip, Typography,Grid, ListItem, IconButton, ListItemButton,Menu } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getDB_Data,getDB_Data_lazy,getUSERS,getCHATS } from '../../../actions/PageActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'

import InputMask from 'react-input-mask';
import Question from '../../PopUp/Views/Question'
import { Label } from '../../components/moduls/Input';
import { POPbottom, POPscroll } from '../../PopUp';




  


class POP_NewPartner_Accept extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            ROLE:'PRTN',
            MANAGER:null,
            managerFIO:' ',
            selectedUserManagerID:null
        }

        this.Managers = props.USERS.filter(u=>u.ROLE=='MNGR')
    }



    

    render (){
       const {props,state} = this
        return (
            <POP>
                <POPscroll bottom>
                <h4 className='mb-0'>Подтвердить сотрудничество</h4>
                <small className='mb-4 d-block'>И создать аккаунт в системе</small>
                
                <FormControl fullWidth className='mb-2' color='success'>
                    <InputLabel className='mt-3'>Роль</InputLabel>
                    <Select label="Роль"
                    variant='filled'
                    color='success'
                    value={this.state.ROLE}
                    onChange={e=>this.setState({ROLE:e.target.value})}>
                        <MenuItem value={'PRTN'}>Партнер</MenuItem>
                        <MenuItem value={'СPRT'}>Компания - партнер</MenuItem>
                    </Select>
                </FormControl>
                

                <TextField
                color='success'
                className='w-100 mb-2'
                variant='filled'
                label="Назначен менеджеру"
                value={state.managerFIO}
                onChange={e=>this.setState({managerFIO:e.target.value})}
                />

                <div style={{
                    height:200,
                    overflowY:"auto"
                }}>
                    {this.Managers.map(user=>{
                        if((state.managerFIO=='' || state.managerFIO==' ' || (
                            user.FIO?.toUpperCase().includes(state.managerFIO.toUpperCase())
                            )))
                            return(<Button key={user.ID+'user'} size='small' color= {
                                state.selectedUserManagerID==user.ID?
                                'success':'inherit'
                            } 
                            onClick={()=>{
                                this.setState({selectedUserManagerID:user.ID,managerFIO:user.FIO})
                            }} style={{
                            display:"block",
                            marginBottom:5
                            }}>{user.FIO}</Button>)
                        else return null
                    })}
                </div>
            
                </POPscroll>
                <POPbottom>
                    <Button  color='success'  onClick={()=>this.props.callBack({
                        ROLE:state.ROLE,
                        ID_USER_MANAGER:state.selectedUserManagerID
                    })}>Подтвердить</Button>
                    <Button  color='primary'  onClick={()=>this.props.closePopUp()}>Отмена</Button>
              

                </POPbottom>
                
                
            </POP>
        )

            
      
    }
}

const POP = styled.div`
width:380px;
`


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 

        getDB_Data_lazy:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy(tableName,JSONparseKey)), 

      
        getUSERS: (arg) => dispatch(getUSERS(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        NEW_PARTNERS:store.page.NEW_PARTNERS,
        USERS:store.page.USERS
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(POP_NewPartner_Accept)




