
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { closePopUp, openPopUp } from '../../../actions/PopUpActions';
import { getDB_Data, getDB_Data_lazy, getDB_Data_lazy_notID, getUSERS } from '../../../actions/PageActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import Table from '../../components/Table';
import withToast from '../../components/Toasts'
import { Avatar, Button, ButtonGroup, Chip, TextField, Tooltip } from '@mui/material';
import POP_User from '../DataBase/pages/POP_User';
import { withRouter } from 'react-router-dom';




const Rows = [
  {
    title:'ФИО',
    key:'FIO'
  },
  {
    title:'E-mail',
    key:'EMAIL'
  },

  {
    title:'Телефон',
    key:'PHONE'
  },
  {
    title:'Юр лицо',
    key:'TYPE',
  },
  {
    title:'Тип',
    key:'ROLE_TITLE',
  },
  {
    title:'Аккаунт',
    key:'STATUS_TITLE',
  },
  {
    title:'Замещение',
    key:'REPLACEMENT',
  }
  
]

class NewPartner extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
          errorMSG:null,
          succes:false,
          filter:null,
          Data:[],
          selectedRows:[]
        }
        this.input={
          dataWindow:{},
          main:{}
        }
        this.checkBoxs={}
        this.can=true
    }


 

   

    setStatus(NEW_STATUS){

      const {filter,Data,selectedRows} = this.state

      const func = (ADDITIONAL)=>{
        this.props.closePopUp()
        if(!this.can) return
        this.can=false

        let promises = []

        selectedRows.forEach(sr=>{
          promises.push(GF.API_task({
            operation:'setNewPartnerStatus',
            ID:sr,
            NEW_STATUS,
            ADDITIONAL
          }))
        })

      

      Promise.all(promises)
        .then(res=>{
            this.props.getDB_Data('getNewPartners','NEW_PARTNERS')
            this.props.getDB_Data_lazy_notID('HUMANS','IMAGES_URL')
            setTimeout(()=>this.props.getUSERS(),500)
           
        }).finally(()=>{
            this.can=true
            this.setState({selectedRows:[]})
        })
        
        
    }

    

  

    }



    render (){
      const {state,props} = this
        const {filter,Data,selectedRows} = this.state

        let filtered  = props.MyPartners.filter(u=>filter==null ||
          u.FIO.toUpperCase().indexOf(filter)!=-1 ||
          u.EMAIL.toUpperCase().indexOf(filter)!=-1 ||
          u.PHONE.toUpperCase().indexOf(filter)!=-1
        )

        filtered=filtered.sort((a,b)=>{
          let textA = a.FIO.toUpperCase();
          let textB = b.FIO.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;  
        })

      

        

        return(
          <View>
            <div>
              <TextField
                className='me-2'
                label='Поиск'
                variant='filled'
                size="small"
                onKeyUp={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}
              />

            
            

            </div>
              
              
          
              <Table
                    className='mt-1'
                    rows={Rows}
                    keyExtraktor={item=>item.ID}
                    canCheck={false}
                    selectedRows={selectedRows}
                    setSelectedRows={(rows)=>this.setState({selectedRows:rows})}
                    data={filtered}
                    itemOnClick={({data,index})=>{
                      this.props.openPopUp(<POP_User ID_OBJECT={data.ID}/>)
                    }}
                    rowOnClick={({data,index})=>{
                        debugger
                    }}
                    renderItem={({data,index})=>
                      Rows.map(r=>{
                        switch(r.key){
                          
                          case 'TYPE':
                            return(<td>{data?.PARTNER?.TYPE}</td>)

                          case 'PHONE':
                            return(<td>{GF.phoneFormatter(data.PHONE)}</td>)

                          case 'REPLACEMENT':
                            return(<td>
                              { data.ID_REPLACEMENT==null?null:
                              <Chip label={
                                data.ID_REPLACEMENT == props.user.ID?
                                <><small style={{color:'white'}}>От </small>{data.MANAGER_FIO}</>:
                                data.MANAGER_FIO_REPLACE
                              } size='small' color={
                                data.ID_REPLACEMENT == props.user.ID?'warning':'default'
                              }/>
                            }</td>
                          )

             
                            
                          default: return(<td>{data[r.key]}</td>)
                        }
                        
                      })
                    }/>

           
          </View>
        )
    }
}
const View = styled.div`
height:100%;
`

const mapDispatchToProps = (dispatch) => {
    return {
      openPopUp:(arg)=>dispatch(openPopUp(arg)),
      closePopUp:(arg)=>dispatch(closePopUp(arg)),
      getUSERS:(arg)=>dispatch(getUSERS(arg)),
      getDB_Data:(arg,grfm)=>dispatch(getDB_Data(arg,grfm)),
      getDB_Data_lazy_notID:(arg,grfm)=>dispatch(getDB_Data_lazy_notID(arg,grfm)),
      getDB_Data_lazy:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy(tableName,JSONparseKey)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
      MyPartners:store.page.MyPartners,
      user:store.user.data,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(withToast(withRouter(NewPartner)));

