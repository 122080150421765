import { useLayoutEffect, useState } from "react"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import UserSearch from "../../../Class/UserSearch"

const selectUsersINST = createSelector(
    (state) => state.page.USERS,
    (USERS) => USERS.filter((user) => user.ROLE == 'INST')
)
export default function useUsersINST_byRooles(id_manager) {
    const managerInstallersRooles = useSelector(state => state.page.ManagerInstallersRooles)
    const [users, setUsers] = useState([])
    const UsersINST = useSelector(selectUsersINST)

    useLayoutEffect(() => {
        if (id_manager != null) {
            let arr = []
            managerInstallersRooles.forEach(rool => {
                //if (rool.ID_MNGR == id_manager) {
                let user = UserSearch.find(rool.ID_INST)
                if (user != null) {
                    arr.push(user)
                }
                //}
            })
            setUsers(arr)
        }
    }, [UsersINST, managerInstallersRooles])



    return users
}