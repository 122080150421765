import { useSelector } from "react-redux";


export default function useIsClient(){
    const reduxUser = useSelector(store=>store.user.data)
    if(reduxUser?.ROLE==null){
        throw 'Не задана роль пользователя'
    }


    if(reduxUser.ROLE=='PRTN' || reduxUser.ROLE=='CPRT')
        return true
    else return false
}