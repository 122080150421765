import { Map,List,fromJS } from 'immutable';
import { sincBasketDB } from '../actions/PageActions';

  const initialState = {
    POPUPS:[],
    
    VARS:null,//статические переменные
    USERS:null,
    USER_STATUS:null,
    USER_ROLES:null,
    HUMANS:null,
    MANAGERS_ROOLES:[],
    TEXTS:{},
    ACCESSORIES:null,
    NEW_PARTNERS:[],
    STATUSES:null,
    NEWS:[],
    MY_MANAGER:null,
    USERS_PERMISSIONS:null,
    MANAGERS_ROOLES:null,
    ACCESSORIES_BASKET:[],
    MyPartners:[],
    ORDERS:[],
    ORDERS_timeUpdate:0,
    FileSustem:[],
    PARTNER_COMPANYS:null,
    ACCESSORIES_ORDERS:[],
    ACCESSORIES_CATEGORIS:null,
    ACCESSORIES:[],
    ACCESSORIES_BASKET:[],
    InstallSchedule:null,
    ManagerInstallersRooles:[]
  }//null - это пометка того, что это обязательные данные для старта системы
  

  export function pageReducer(state = initialState, action) {
    switch (action.type) {

     

      case "GET_TEXTS":
        let TEXTS = {}
        action.payload.map(txt=>{
          TEXTS[txt.NAME]={}
         
          TEXTS[txt.NAME]=txt
          if(txt.TITLE==null )
            delete(txt.TITLE)
          TEXTS[txt.NAME].TEXT=JSON.parse(TEXTS[txt.NAME].TEXT)
          delete(TEXTS[txt.NAME].NAME)
        })
      return {
          ...state,
          TEXTS
      };

      case 'SET_ORDERS':
        let temp_state2={}
        Object.assign(temp_state2,state)
        temp_state2['ORDERS'] = action.payload
        try{
          temp_state2['ORDERS_timeUpdate'] ++
        }catch{
          temp_state2['ORDERS_timeUpdate'] = 0
        }
        
      return temp_state2

      case 'sincBasket':
        sincBasketDB()
        return{...state,ACCESSORIES_BASKET:action.payload}

      case 'JUST_SET_VALUE':
        let temp_state={}
        Object.assign(temp_state,state)
        temp_state[action.key] = action.payload
        
      return temp_state

      case 'GET_YC_SERVICES_AND_CATEGORIES':
        
        return {
          ...state,
          YC:{
            ...state.YC,
            ...action.payload
          }
        }
      
      
      default:
        return state;
    }
  }