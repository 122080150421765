import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

export default class SelectView extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            value:props.value
        }
        this.previosValue = props.value
    }
    
    render (){
        return (
            <FormControl variant='filled' fullWidth error={this.props.error} className={this.props.className} disabled={this.props.disabled}>
                <InputLabel variant='filled'>{this.props.label}</InputLabel>
                <Select value={this.state.value}
                size='small'
                variant='filled'
                    label={this.props.label}
                    onChange={e=>{
                        this.setState({value:e.target.value})
                        try{
                            this.props.onChange(e.target.value)
                        }catch{}
                    }}>
                        {this.props.items.map(i=>
                            <MenuItem value={i.value}>{i.title==null?i.value:i.title}</MenuItem>
                        )}
                    
                </Select>
                {this.props.errorText==null?null:
                <FormHelperText>{this.props.errorText}</FormHelperText>}
            </FormControl>
        )
    }
}

