
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../../actions/PopUpActions';
import {GlobalFunctions as GF} from '../../../../GlobalFunctions'
import { Label } from '../../../components/moduls/Input';
import Table from '../../../components/Table';
import { Button, ButtonGroup, Chip, TextField } from '@mui/material';

import POP_Statuses from './POP_Statuses';
import { getTexts } from '../../../../actions/PageActions';
import { TreeItem, TreeView } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';






class Costs extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
          errorMSG:null,
          succes:false,
          filter:null,
          Data:[],
          TEXTS_tmp:{}
        }


        this.input={
          dataWindow:{},
          main:{}
        }
        this.checkBoxs={}
    }


    componentDidMount(){
      let tmp = {}
      Object.keys(this.props.TEXTS).forEach(key=>{
          
        if(this.props.TEXTS[key].TITLE==this.props.category){
          tmp[key] = this.props.TEXTS[key]
        }
      })
      
      this.setState({TEXTS_tmp:tmp})
    }

    componentWillReceiveProps(nextProps){
      if(nextProps.category!=this.props.category){
        setTimeout(()=>{
          this.componentDidMount()
        },1)
      }
    }



    PreDelete(){
      let promises = []
      let somethingDoing = false

        Object.keys(this.checkBoxs).forEach((key,index)=>{
          if(this.checkBoxs[key].checked){
            somethingDoing=true
            this.checkBoxs[key].checked=false
            promises.push(this.Delete(key))
            delete(this.checkBoxs[key])
          }
        })

        if(!somethingDoing) return

        Promise.allSettled(promises)
        .then(()=>{
          this.props.getUsers()
          this.props.addToast('Удалено', { appearance: 'success',autoDismiss:true });
        })
      
    }

    Delete(ID){
      return new Promise((resolve,reject)=>{
        let data = {
          operation:'deleteSome',
          Table:'USERS',
          ID
        }
        
        GF.API_task(data).then(res=>{
         
         
          setTimeout(()=>resolve(),1)
          
        }).catch(()=>reject()) 
      })
      
    }

    changeBlock(ID){
      return new Promise((resolve,reject)=>{
        let data = {
          operation:'changeBlockUser',
          ID
        }
        
        GF.API_task(data).then(res=>{
          
          setTimeout(()=>resolve(),1)
        }).catch(()=>reject()) 
      })
    }

    Block(){
      let somethingDoing = false
      let promises = []
      Object.keys(this.checkBoxs).forEach((key,index)=>{
          if(this.checkBoxs[key].checked){
            somethingDoing = true
            this.checkBoxs[key].checked=false
            promises.push(this.changeBlock(key))
          }
      })
      
      if(!somethingDoing) return

      Promise.allSettled(promises)
        .then(()=>{
          this.props.getUsers()
          this.props.addToast('Значение обновлено', { appearance: 'success',autoDismiss:true });
        })
    }

    


    render (){
        const {props,state} = this
        const {filter} = state


        if(Object.keys(state.TEXTS_tmp).length==0) return <div/>

        if(props.category=='sustem-coast-tkan'){
          const Tkan = state.TEXTS_tmp['Цены на ткани']?.TEXT

          if(Tkan==null) return <div/>

          return(<div>
            <Button color='success' size='small' onClick={()=>{
              debugger
              
                GF.API_task({
                  operation:'updateTEXTS',
                  TEXTS:JSON.stringify(state.TEXTS_tmp)
                }).then(res=>{
                  alert('Сохранено')
                    this.props.getTexts()
                })
            }}>Сохранить</Button>
            <hr/>
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              {Object.keys(Tkan).map(tkanCategory=>
                <TreeItem nodeId={tkanCategory} label={ <b>{tkanCategory}</b>}>
                  {Object.keys(Tkan[tkanCategory]).map(size=>
                    <TreeItem nodeId={size} label={size}>
                      <div className='row row-cols-4'>
                        {Object.keys(Tkan[tkanCategory][size]).map(curSize=>
                          <div className='col'>
                            <TextField
                            variant='filled'
                            className='d-block mb-1'
                            label={curSize}
                            color='success'
                            value={Tkan[tkanCategory][size][curSize]}
                            onChange={(e)=>{
                              let ferst = e.target.value[0]
                              e.target.value = e.target.value.replace(/[,]/gm,'.')
                              e.target.value = e.target.value.replace(/[^0-9,^.,^,]/,'')
                              if(ferst=='-') e.target.value=ferst+e.target.value
            
                              if(e.target.value.indexOf('.')!=e.target.value.lastIndexOf('.')){
                                //несколько точек
                                e.target.value = e.target.value.replace(/[.]/gm,'')
                              }
                              
            
                              if(e.target.value=='')
                                e.target.value=0
            
                              let tmp = {...{...state.TEXTS_tmp}}

                              tmp['Цены на ткани'].TEXT[tkanCategory][size][curSize] = e.target.value=='-'?e.target.value:e.target.value*1

                              

                            
                              this.setState({TEXTS_tmp:{...tmp}})
                            }}
                            size='small'
                            />
                          </div>
                        )}
                      </div>
                      </TreeItem>
                    )}
                  
                </TreeItem>
              )}
            </TreeView>
          </div>)

        }else

        return(
          <View>
            <div className='mt-4'>
              <div style={{
                display:'flex',
                alignItems:'center',
                justifyContent:'flex-start',
                gap:20
              }}>
              <TextField
                className='me-2'
                label='Поиск'
                color='success'
                variant='filled'
                size="small"
                onKeyUp={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}
              />
              <Button color='success' size='small' onClick={()=>{
                GF.API_task({
                  operation:'updateTEXTS',
                  TEXTS:JSON.stringify(state.TEXTS_tmp)
                }).then(res=>{
                  alert('Сохранено')
                    this.props.getTexts()
                })
              }}>Сохранить</Button>
            </div>

            </div>
              
            <hr/>

            <div className='row row-cols-lg-3 row-cols-md-2 row-cols-sm-1 row-cols-xs-1'>
          
            {Object.keys(state.TEXTS_tmp).map(key=>
              filter!=null && key.toUpperCase().indexOf(filter)==-1
                
              ?null:
            <div className='col mb-3'>
            <small className='d-block'>{key.replace('PULT ','')}</small>
              <TextField
                variant='filled'
                className='d-block'
                color='success'
                value={state.TEXTS_tmp[key].TEXT}
                onChange={(e)=>{
                  let ferst = e.target.value[0]
                  e.target.value = e.target.value.replace(/[,]/gm,'.')
                  e.target.value = e.target.value.replace(/[^0-9,^.,^,]/,'')
                  if(ferst=='-') e.target.value=ferst+e.target.value

                  if(e.target.value.indexOf('.')!=e.target.value.lastIndexOf('.')){
                    //несколько точек
                    e.target.value = e.target.value.replace(/[.]/gm,'')
                  }
                  

                  if(e.target.value=='')
                    e.target.value=0

                  let tmp = {...{...state.TEXTS_tmp}}
                  tmp = GF.changeParametr(tmp,key,{
                    TEXT:e.target.value=='-'?e.target.value:e.target.value*1
                  })
                  this.setState({TEXTS_tmp:{...tmp}})
                
                }}
                size='small'
                />
              </div>
            )}
            </div>

           
          </View>
        )
    }
}
const View = styled.div`
height:100%;
`

const mapDispatchToProps = (dispatch) => {
    return {
      openPopUp:(arg)=>dispatch(openPopUp(arg)),
      getTexts:(arg)=>dispatch(getTexts(arg))
    }
}


const mapStateToProps = (store) => {
   
    return {
        STATUSES: store.page.STATUSES,
        TEXTS: store.page.TEXTS,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Costs)

