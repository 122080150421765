
import { Check, Delete } from '@mui/icons-material';
import { Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Select, TextField, Tooltip, Typography,Grid, ListItem, IconButton, ListItemButton,Menu, Switch, FormGroup, FormControlLabel } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getDB_Data,getDB_Data_lazy,getUSERS,getCHATS } from '../../../actions/PageActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'

import InputMask from 'react-input-mask';
import Question from '../../PopUp/Views/Question'
import { Label } from '../../components/moduls/Input';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SwitchCheck from '../../components/SwitchCheck';
import ComboBox from '../../components/moduls/ComboBox';
import { POPbottom, POPscroll } from '../../PopUp';
import Input_image from '../../components/Input_image';




const form_fields = [
    {
      label:'1С идентификатор',
      name:'ID_1C'
    },
    {
        label:'Название',
        name:'TITLE',
    },
    {
        label:'Вес (грамм)',
        name:'WEIGHT',
    },
    {
        label:'Цена',
        name:'PRICE',
    },
  ]

  


class POP_Accessuar extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
            Self:{
                ID_CATEGORY:props.selectedCategory || 1
            },
            can:true
        }
        this.input={}
        this.can=true
        this.filesToUpload=[]
        this.filesToDelete=[]
    }

    

    componentDidMount() {
        const {state,props} = this
        if(props.ID_OBJECT!=null){
            var Self = props.ACCESSORIES.find(p=>p.ID==props.ID_OBJECT)
            if(Self==null) return
            Self = GF.ObjectClone(Self)
            this.setState({Self})
        }
    }

 
    Delete(){
        const {state,props} = this
        const {COMPANYS,DEPTS,ID_OBJECT} = this.props

        new Promise((resolve,reject)=>{
            this.props.openPopUp(<Question text={'Увенеры, что хотите удалить товар?'}
            buttons={[
                <Button className='m-2' color='error' onClick={()=>resolve()}>Удалить</Button>,
                <Button className='m-2'  onClick={()=>reject()}>Отмена</Button>
            ]}/>)
        }).then(()=>{

            GF.API_task({
                operation:'deleteSome',
                Table:'ACCESSORIES',
                ID:ID_OBJECT
            }).then(res=>{
                this.props.getDB_Data_lazy('ACCESSORIES','IMAGES_URL')
                this.props.closePopUp()
            })
            
        }).finally(()=>{
            this.props.closePopUp()
        })

        
    }


    Save(){
        const {state,props} = this
        if(!this.can) return
        this.can=false

        let Self = GF.ObjectCopy(state.Self)
        delete(Self.DATE_CREATE)
        delete(Self.IMAGES_URL)
        delete(Self.ID)
        delete(Self.CATEGORY_TITLE)

        let data = Self
        
        GF.API_task({
            operation:'createUpdateSome',
            Table:'ACCESSORIES',
            data,
            ID:props.ID_OBJECT
        }).then(res=>{
            this.props.getDB_Data_lazy('ACCESSORIES','IMAGES_URL')
            this.props.closePopUp()

            if(this.filesToUpload.length>0)
                GF.API_files({
                    operation:'setIMAGE',
                    Table:'ACCESSORIES',
                    ID:res.ID
                },this.filesToUpload).then(()=>{
                   this.props.getDB_Data_lazy('ACCESSORIES','IMAGES_URL')
                })

            if(this.filesToDelete.length>0)
                GF.API_task({
                    operation:'deleteIMAGE',
                    ID:res.ID,
                    Table:'ACCESSORIES',
                    filesToDelete:JSON.stringify(this.filesToDelete)
                }).then(()=>{
                    this.props.getDB_Data_lazy('ACCESSORIES','IMAGES_URL')
                })
                
            
        }).finally(()=>{
            this.can=true
        })
    }

    

    render (){
        const {state,props} = this
        const {NEW_PARTNERS,ID_OBJECT,ACCESSORIES_CATEGORIS} = props
        const {Self,anchorEl} = state
        const open = Boolean(anchorEl)

        
       
        if(ID_OBJECT!=null && Self.ID==null) return <div/>
     
        return (
            <POP>
                <POPscroll bottom>
                <h4>Товар</h4>

                <FormControl variant="filled" sx={{width: 250}} color='success' size='small'>
                    <InputLabel>Категория</InputLabel>
                    <Select
                    size='small'
                    label='Категория'
                    value={Self.ID_CATEGORY}
                    onChange={e=>
                        this.setState({
                            Self:{
                                ...Self,
                                ID_CATEGORY:e.target.value*1
                            }
                        })
                    }>
                        {ACCESSORIES_CATEGORIS.map(cat=>
                            <MenuItem value={cat.ID}>{cat.TITLE}</MenuItem> 
                        )}

                    </Select>
                </FormControl>
               
               {form_fields.map(input => 
                        <TextField
                            className='w-100 mt-3'
                            margin='dense'
                            variant="filled"
                            size='small'
                            color='success'
                            {...input}
                            value={state.Self[input.name]}
                            onChange={(e)=>
                                this.setState({
                                    Self:GF.changeParametr(state.Self,input.name,e.target.value)
                                })
                            }
                            inputRef={ref=>this.input[input.name]=ref}
                        />
                )}

                

                <div className='mb-2' style={{height:140}}>
                            <Input_image multiple={false} value={Self.IMAGES_URL} onChange={(filesToUpload,filesToDelete)=>{
                                
                                this.filesToUpload=[...this.filesToUpload,...filesToUpload]
                                this.filesToDelete=[...this.filesToDelete,...filesToDelete]
                                
                            }}/>
                </div>

                
                </POPscroll>
                <POPbottom>

                    {this.props.ID_OBJECT==null?null:
                        <Button className='float me-2' color='error' onClick={()=>this.Delete()}>удалить</Button>
                    }
                    <Button  color='success' className='float-end' onClick={()=>this.Save()}>Сохранить</Button>
                </POPbottom>
                
                
            </POP>
        )

            
      
    }
}

const POP = styled.div`
width:500px;
`


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 

        getDB_Data_lazy:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy(tableName,JSONparseKey)), 

      
        getUSERS: (arg) => dispatch(getUSERS(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        ACCESSORIES:store.page.ACCESSORIES,
        ACCESSORIES_CATEGORIS:store.page.ACCESSORIES_CATEGORIS,
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(POP_Accessuar)




