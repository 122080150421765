

import { useTab } from '@mui/base';
import { Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Select, TextField, Tooltip, Typography,Grid, ListItem, IconButton, ListItemButton,Menu, Switch, FormGroup, FormControlLabel, Alert } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { closePopUp } from '../../../actions/PopUpActions';
import Debounce from '../../../Class/Debounce';
import Question from '../../PopUp/Views/Question';

export default function POP_SetComplitedInstall({onAccept,onCansel}){
    const dispatch = useDispatch()
   
    return(<Question
            text='Вы подтверждаете выполнение установки?'
            buttons={[
                <Button color='success' onClick={()=>{
                    Debounce.start(()=>{
                        dispatch(closePopUp())
                        !!onAccept && onAccept()
                    })
                }}>Да, подтверждаю</Button>,
                <Button onClick={()=>{
                    dispatch(closePopUp())
                    !!onCansel && onCansel()
                }}>Назад</Button>
            ]}
        />)
   
      
    
}








