import { useState } from "react"

export default  function useDebounce(){
    let timer = null
    const [can,setCan] = useState(true)
    return({
        start:(func=()=>{},delay=500)=>{
            if(can===true){
                setCan(false)
                timer=setTimeout(()=>{
                    setCan(true)
                },delay)
                func() 
            }else{
                console.error('Debounce','need wait some time')
            }
        },
        canStart:can
    })
}
