
  const initialState = {
    windows:[],
    popUppedImgs:{
      selectedImg:0,
      aImgs:[]
    }
  }
  
  export function popupReducer(state = initialState, action) {
    let arr=[]

    switch (action.type) {
      case "OPEN_POP_UP":
        
      arr = state.windows.slice()
      arr.push(action.payload)
        
          return {
            ...state,
            windows:arr
          };

      case "CLOSE_IMGS":
        return {
          ...state,
          popUppedImgs:{
            selectedImg:0,
            aImgs:[]
          }
        };

      case "OPEN_IMGS":
        return {
          ...state,
          popUppedImgs:action.payload
        };

      case "CLOSE_POP_UP":
        arr = state.windows.slice()
        arr.pop()
        
          return {
            ...state,
            windows:arr
          };
          
      default:
        return state;
    }
  }