
import { AccessTime, AccessTimeFilled, LibraryAddCheck, PeopleAlt, TaskAlt, Work } from '@mui/icons-material';
import { Button, Card, CardActions, CardHeader, IconButton ,CardContent, Avatar, Chip} from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import { Label } from '../../components/moduls/Input';
import {down, up} from 'styled-breakpoints'
import {withRouter} from 'react-router-dom'
import POP_PasswodChange from './POP_PasswodChange';




class Index extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
           errorMSG:null,
        }
        this.input=[]
    }

  
    render (){
        const {user} = this.props
        return(
        <React.Fragment>
            <div style={{
                display:"flex",
                gap:20
            }}>
            {user.IMAGES_URL==null || user.IMAGES_URL[0]==null?
                <Avatar
                sx={{ width: 100, height: 100 }}>{user.LOGIN.substr(0,4).toUpperCase()}</Avatar>
                :
                
                <Avatar src={user.IMAGES_URL[0]}
                label={user.FIO}
                sx={{ width: 100, height: 100 }}/>
            }
            
                <div>
                    <small>{user.ROLE_TITLE}</small>
                    <h3>{user.FIO}</h3>
                    
                    <p>{user.EMAIL}</p>
                </div>
            </div>

            {user.PERMISSIONS.length==0?null:<>
                <small className='mt-2 mb-1 d-block'>Дополнительные права в системе</small>
                <div style={{
                    flexWrap:'wrap',
                    display:'flex',
                    gap:20,
                }}>
                    {user.PERMISSIONS.map(p=>
                        <div style={{
                            background:"white",
                            borderRadius:5,
                            border:'1px solid rgb(232 232 232)',
                            padding:10,
                            maxWidth:'20vw'
                        }}>
                            <p className='d-block m-0'>{p}</p>
                            <small className='d-block m-0'>{this.props.USERS_PERMISSIONS[p]?.DESCR}</small>
                        </div>
                    )}
                </div>
            </>}


            <Button color='success' className='mt-3' onClick={()=>this.props.openPopUp(<POP_PasswodChange/>)}>Изменить пароль</Button>
           
            
        </React.Fragment>)
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
        USERS_PERMISSIONS:store.page.USERS_PERMISSIONS,
        user: store.user.data,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Index))

