
import { AccessTime, AccessTimeFilled, LibraryAddCheck, PeopleAlt, PlayLesson, TaskAlt, Work } from '@mui/icons-material';
import { Button, Card, CardActions, CardHeader, IconButton ,CardContent, List, ListItem, ListItemIcon, ListItemText, ListItemButton, Breadcrumbs, Typography, TextField, Menu, MenuItem, Chip} from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { closePopUp, openPopUp } from '../../../actions/PopUpActions';
import { Label } from '../../components/moduls/Input';
import {down, up} from 'styled-breakpoints'
import {Link, withRouter} from 'react-router-dom'
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Constants, GlobalFunctions as GF } from '../../../GlobalFunctions';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { Draggable, Droppable } from 'react-drag-and-drop'
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import POP_Folder from './POP_Folder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getFileSustem } from '../../../actions/PageActions';
import POP_FolderReplase from './POP_FolderReplase';
import POP_Upload from './POP_Upload';
import POP_AddURL from './POP_AddURL';
import LinkIcon from '@mui/icons-material/Link';
import POP_Alert from '../../PopUp/Views/POP_Alert';

class Index extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
           errorMSG:null,
           anchorEl:null,
           hoverFolder:null
        }
        this.input=[]
    }

    

    componentDidMount() {
    
    }

    changeParent(Type,ID,NewParent_id){
        this.replasing=true
        this.setState({nowDrag:ID})
        GF.API_task({
            operation:'changeParent',
            block:'FileSustem',
            Type,
            ID,
            NewParent_id
        }).then(res=>{
            
                this.props.getFileSustem(()=>{
                    setTimeout(()=>{
                        this.setState({nowDrag:null})
                        this.replasing=false
                    },1000)
                })
            
        })
    }

    deleteFileOrFolder(ID,Type){
        let obj = null
        
        if(Type=='folder')
            obj = 'папку?'
        else
            obj = 'файл?'

        new Promise((resolve,reject)=>{
            this.props.openPopUp(<POP_Alert text={'Вы уверены, что хотите удалить '+obj} buttons={[
                <Button color='error' onClick={()=>
                    resolve()
                }>Да</Button>,
                <Button onClick={()=>
                    reject()
                }>Отмена</Button>,
            ]}/>)
        }).then(()=>{
            GF.API_task({
                operation:'deleteFileSustemObject',
                block:'FileSustem',
                Type,
                ID
            }).then(res=>{
                this.props.getFileSustem(()=>{
                    this.setState({nowDrag:null})
                    this.replasing=false
                })
            })
        }).finally(()=>{
            this.props.closePopUp()
        })
    }

    render (){
        const {state,props} = this
        if(props.FileSustem.length==0) return <div/>

        let CurrentFolder = props.FileSustem.find(f=> f.ID==this.props.match.params.ID_FOLDER )
        if(CurrentFolder == null)
            CurrentFolder = props.FileSustem.find(f=> f.ID==1)

       
        return (
            <MainView>
                <div style={{
                    display:'flex',
                    flexDirection:'row'
                }}>
                    <TextField
                        className='me-2'
                        label='Поиск'
                        variant='filled'
                        color='success'
                        size="small"
                        onKeyUp={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null},this.checkSelected)}
                    />
                    {props.user.ROLE!='ADMN'?null:<>

                        <Button color='success'
                        className='me-2 align-bottom'
                        startIcon={<CreateNewFolderIcon/>}
                        onClick={()=>
                        this.props.openPopUp(<POP_Folder PARENT={CurrentFolder}/>)
                        }>Создать папку</Button>  

                        <Button color='success' className='me-2 align-bottom'
                        startIcon={<NoteAddIcon/>}
                        onClick={()=>{
                            this.inputFiles.click()
                        }}>Загрузить файл</Button>    

                        <Button color='success' className='me-2 align-bottom'
                        startIcon={<LinkIcon/>}
                        onClick={()=>{
                            this.props.openPopUp(<POP_AddURL PARENT={CurrentFolder} 
                                callBack={({name,url})=>{
                                    this.props.openPopUp(<POP_Upload PARENT={CurrentFolder} files={[{name,url,type:'url'}]}/>)
                                }}
                            />)
                        }}>Добавить сслыку</Button>    
                        

                        <input type='file' multiple hidden ref={ref=>this.inputFiles=ref} onChange={e=>{
                            var files = []
                            for(let i = 0; i< e.target.files.length;i++){
                                files.push(e.target.files.item(i))
                            }
                            //files = GF.ObjectCopy(files)
                            
                            this.props.openPopUp(<POP_Upload PARENT={CurrentFolder} files={files}/>)
                            this.inputFiles.value=''
                            this.inputFiles.type=''
                            this.inputFiles.type='file'
                        }}/>

                    </>}
                </div>

                <Breadcrumbs aria-label="breadcrumb" className='mt-1'>
                    {CurrentFolder.ID_PARENT==1 || CurrentFolder.ID==1 ? null:
                    <Droppable
                    types={['folder','file']} 
                    onDrop={(e)=>{
                        let type = e.folder!=null && e.folder>0?'folder':'file'
                            let id = e[type]
                            
                                this.changeParent(type,id,1)
                        
                    }}>
                        <Link underline="hover" color="inherit" to="/file_manager">
                            Корневой каталог
                        </Link>
                    </Droppable>
                    }

                    {CurrentFolder.PARENT_ID_PARENT != 1 &&  CurrentFolder.ID_PARENT!=1 && CurrentFolder.ID!=1?
                        <Typography color="text.primary">{'...'}</Typography>
                        :null
                    }


                    {!!CurrentFolder.PARENT_TITLE &&
                        <Droppable
                        types={['folder','file']} 
                        onDrop={(e)=>{
                            let type = e.folder!=null && e.folder>0?'folder':'file'
                            let id = e[type]
                            
                                this.changeParent(type,id,CurrentFolder.ID_PARENT)
                        }}>
                            <Link
                                underline="hover"
                                color="inherit"
                                to={"/file_manager/"+CurrentFolder.ID_PARENT}
                            >
                                {CurrentFolder.PARENT_TITLE}
                            </Link>
                        </Droppable>
                    }
                    <Typography color="text.primary">{CurrentFolder.TITLE}</Typography>
                </Breadcrumbs>
                <List component="nav">
                
                    {CurrentFolder.CONTENT.FOLDERS.map(folder=>
                        !(state.filter!=null && folder.TITLE.toUpperCase().indexOf(state.filter)!=-1 ||
                        state.filter==null)?null:
                        <Droppable
                        
                        onMouseEnter={e=>{
                            this.setState({hoverFolder:folder.ID})
                        }}
                        onMouseLeave={e=>{
                            setTimeout(()=>{
                                if(this.state.hoverFolder==folder.ID){
                                    this.setState({hoverFolder:null})
                                }
                            },100)
                        }}
                        style={{
                            maxWidth:550,
                        }}
                        types={['folder','file']} 
                        onDrop={(e)=>{
                            let type = e.folder!=null && e.folder>0?'folder':'file'
                            let id = e[type]
                            if(type=='folder' && id!=folder.ID || type=='file')
                                this.changeParent(type,id,folder.ID)
                        }}>
                                <ListItem key={'folder_'+folder.ID}
                                style={{
                                    maxWidth:550,
                                    transition:'.3s',
                                    
                                    background:state.hoverFolder==folder.ID?'rgba(25, 118, 210, 0.1)':null
                                }}
                                secondaryAction={
                                    props.user.ROLE!='ADMN'?null:
                                        <IconButton edge="end" onClick={e=>{
                                            this.setState({anchorEl:e.currentTarget})
                                            this.ClickedOBJ=folder
                                            this.ClickedOBJ.type='folder'
                                        }}>
                                        <MoreVertIcon />
                                        </IconButton>
                                } disablePadding>
                                    <ListItemButton onClick={()=>{
                                        setTimeout(()=>{
                                            props.history.push('/file_manager/'+folder.ID)
                                        },300)
                                    }}>
                                        {props.user.ROLE!='ADMN'?(
                                            <div style={{
                                                display:'flex',
                                                flexDirection:'row',
                                                alignItems:'center',
                                                alignContent:'center'
                                            }}>
                                                <ListItemIcon className='m-0'>
                                                        <FolderIcon color='success'/>
                                                </ListItemIcon>
                                                <ListItemText  className='m-0' primary={folder.TITLE}/>
                                            </div>
                                        ):
                                        <Draggable
                                         onDrag={(e)=>{
                                            this.setState({nowDrag:folder.ID})
                                        }} onDragEnd={e=>{
                                            if(!this.replasing){
                                                this.setState({nowDrag:null}) 
                                            }
                                        }}
                                        type="folder" data={folder.ID} key={'folder_drag_'+folder.ID}>
                                            <div style={{
                                                display:'flex',
                                                flexDirection:'row',
                                                alignItems:'center',
                                                alignContent:'center'
                                            }}>
                                                <ListItemIcon className='m-0'>
                                                        <FolderIcon color='success'/>
                                                </ListItemIcon>
                                                <ListItemText  className='m-0' primary={folder.TITLE}/>
                                            </div>
                                        </Draggable>}
                                    </ListItemButton> 
                                </ListItem>
                            </Droppable>
                    )}
                    {CurrentFolder.CONTENT.FILES.length>0 && CurrentFolder.CONTENT.FOLDERS.length>0?
                    <>
                        <hr/>
                        <small className='mb-3 d-block'>Файлы</small>
                    </>
                    :null}

                    {CurrentFolder.CONTENT.FILES.map(file=>
                        <ListItem  style={{
                            maxWidth:550,
                        }} secondaryAction={
                            props.user.ROLE!='ADMN'?null:
                            <IconButton edge="end" onClick={e=>{
                                this.setState({anchorEl:e.currentTarget})
                                this.ClickedOBJ=file
                                this.ClickedOBJ.type='file'
                            }}>
                            <MoreVertIcon />
                            </IconButton>
                        } disablePadding>
                            <ListItemButton disablePadding onClick={()=>{
                                if(file.EXT=='url')
                                window.open(file.URL, '_blank').focus()
                                else
                                window.open(
                                Constants.serverMain+'server/files/'+file.URL
                                , '_blank').focus()
                            }}>
                                {props.user.ROLE!='ADMN'?(
                                    <div style={{
                                                display:'flex',
                                                flexDirection:'row',
                                                alignItems:'center',
                                                alignContent:'center'
                                            }}>
                                            <ListItemIcon style={{
                                                justifyContent:'center',
                                                marginLeft:-15
                                            }}>
                                            {file.EXT!='url' ? <InsertDriveFileIcon/> : <Chip size='small' color='success' label='URL'/>}
                                            </ListItemIcon>
                                            <ListItemText
                                                        primary={<p style={{
                                                            margin:0,
                                                            fontSize:12,
                                                            width:'100%',
                                                            paddingRight:40
                                                        }}>{file.TITLE}</p>}
                                                        secondary={ file.EXT}
                                                    />
                                    </div>
                                ):
                                <Draggable  type="file"
                                data={file.ID} key={'file_drag_'+file.ID}>
                                            <div style={{
                                                display:'flex',
                                                flexDirection:'row',
                                                alignItems:'center',
                                                alignContent:'center'
                                            }}>
                                            <ListItemIcon style={{
                                                justifyContent:'center',
                                                marginLeft:-15
                                            }}>
                                            {file.EXT!='url' ? <InsertDriveFileIcon/> : <Chip size='small' color='success' label='URL'/>}
                                            </ListItemIcon>
                                            <ListItemText
                                                        primary={<p style={{
                                                            margin:0,
                                                            fontSize:12,
                                                            width:'100%',
                                                            paddingRight:40
                                                        }}>{file.TITLE}</p>}
                                                        secondary={ file.EXT}
                                                    />
                                    </div>
                                </Draggable>}
                            </ListItemButton> 
                        </ListItem>
                    )}
                        
                </List>

                <Menu
                open={Boolean(this.state.anchorEl)}
                anchorEl={this.state.anchorEl}
                onClose={()=>this.setState({anchorEl:null})}>

                    <MenuItem onClick={()=>{
                        this.props.openPopUp(<POP_Folder PARENT={CurrentFolder} ID_OBJECT={this.ClickedOBJ.ID} type={this.ClickedOBJ.type}/>)
                        this.setState({anchorEl:null})
                    }}>Переименовать</MenuItem>
                        <hr/>
                    <MenuItem onClick={()=>{
                        this.props.openPopUp(<POP_FolderReplase PARENT={CurrentFolder} SelectedFolder={this.ClickedOBJ} type={this.ClickedOBJ.type}/>)
                        this.setState({anchorEl:null})
                    }}>Переместить</MenuItem>
                    <MenuItem onClick={()=>{
                        this.setState({anchorEl:null})
                        this.deleteFileOrFolder(this.ClickedOBJ.ID,this.ClickedOBJ.type)
                    }}>Удалить</MenuItem>
                </Menu>
           </MainView>
        )

      
    }
}

const MainView = styled.div`
& .InfoBlock{
    display: flex;
    justify-content: center;
}
`




const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getFileSustem:(arg)=>dispatch(getFileSustem(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
    }
}


const mapStateToProps = (store) => {
   
    return {
        
        user: store.user.data,
        FileSustem:store.page.FileSustem,
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Index))

