import { useSelector } from "react-redux"
import { createSelector } from "reselect"

const selectUsersClients = createSelector(
    (state) => state.page.USERS,
    (USERS) => USERS.filter((user) => user.ROLE=='CPRT' || user.ROLE=='PRTN')
)
export default function useUsersClients(){
    const UsersClients = useSelector(selectUsersClients)
    return UsersClients
}