
import { Stack } from '@mui/material';
import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import StackHorizontal from '../../components/StackHorizontal';
import BasketOrders from './BasketOrders';
import CalckOrders from './CalckOrders';

export default function Index() {
  const user = useSelector(store => store.user.data)

  const ORDERS = useSelector(store => store.page.ORDERS)
  const ACCESSORIES_ORDERS = useSelector(store => store.page.ACCESSORIES_ORDERS)

  const [newOrders, setNewOrders] = useState({ orders: 0, accessories: 0 })

  useLayoutEffect(() => {
    setNewOrders({
      orders: ORDERS.filter(p => p.ID_STATUS == 13 && p.MANAGER_READED == 0).length,
      accessories: ACCESSORIES_ORDERS.filter(p => p.MANAGER_READED == 0 && p.ID_STATUS == 17).length
    })
  }, [ORDERS, ACCESSORIES_ORDERS])


  return (<div>
    <StackHorizontal style={{
      width: 202,
      position: 'absolute',
      top: 50
    }}>
      <Stack flexDirection={'row'}>
        <Link to='calk' style={{ display: 'flex' }}>
          <small>Расчеты</small>
        </Link>
        <MenuInd count={newOrders.orders} >{newOrders.orders}</MenuInd>
      </Stack>

      <Stack flexDirection={'row'}>
        <Link to='basket' style={{ display: 'flex' }}>
          <small>Комплектующие</small>
        </Link>
        <MenuInd count={newOrders.accessories}>{newOrders.accessories}</MenuInd>
      </Stack>
    </StackHorizontal>

    <Switch>
      <Route path='*/calk'>
        <CalckOrders />
      </Route>
      {(user.ROLE == 'PRNT' || user.ROLE == 'CPRT') && user.PERMISSIONS?.includes("COMPONENTS") !== true ? null :
        <Route path='*/basket'>
          <BasketOrders />
        </Route>
      }
      <Redirect to='orders/calk' />
    </Switch>

  </div>)
}


const MenuInd = styled.div`

border-radius:50px;
height:18px;
width:18px;

text-align:center;
border: 2px solid white;
font-size: 12px;
line-height: 12.5px;
color: white;
font-weight: bold;

${props => props.count > 0 ? `
background: rgb(52, 145, 255); 
`: `
background:#f0f0f0;
color:#1f1f1f;
`}
`



