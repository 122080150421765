import moment from "moment"

const hours = []
const minutes = []

for(let h=0;h<24;h++){
    hours.push(h)
}

for(let m=0;m<60;m+=15){
    minutes.push(m)
}

export const hours24 = hours
export const minutes15 = minutes


export const moscowUTC = 60*3 - moment().utcOffset()
export const makeMoscowUTC = (momentDate)=>(momentDate.add(moscowUTC,'minutes'))