
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DateRangePicker } from '@mui/lab';
import { Avatar, Chip, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Immutable from 'immutable';
import moment from 'moment';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { getACCESSORIES_ORDERS } from '../../../actions/PageActions';
import { openPopUp } from '../../../actions/PopUpActions';
import { GlobalFunctions as GF } from '../../../GlobalFunctions';
import useIsClient from '../../../Hooks/useIsClient';
import POP_User from '../../pages/DataBase/pages/POP_User';
import POP_BasketOrder from './POP_BasketOrder';

export default function BasketOrders() {
  const history = useHistory()
  const dispatch = useDispatch()
  const isClient = useIsClient()

  const ACCESSORIES_ORDERS = useSelector(store => store.page.ACCESSORIES_ORDERS)
  const USERS = useSelector(store => store.page.USERS)
  const MyPartners = useSelector(store => store.page.MyPartners)

  const selfUser = useSelector(store => store.user.data)

  console.log('ACCESSORIES_ORDERS', ACCESSORIES_ORDERS)

  const [filter, setFilter] = useState(null)
  const [filterFIO, setFilterFIO] = useState(null)
  const [openedUsers, setOpenedUsers] = useState([])
  const [dateFilter, setDateFilter] = useState([null, null])
  const [accessoriesOrders, setAccessoriesOrders] = useState([])
  const [filteredUserOrders, setFilteredUserOrders] = useState([])
  let updateInterval = null

  useLayoutEffect(() => {
    if (isClient) {
      setOpenedUsers([selfUser.ID])
    }
    updateInterval = setInterval(() => dispatch(getACCESSORIES_ORDERS()), 15000)
    return (() => {
      clearInterval(updateInterval)
    })
  }, [])

  useEffect(() => {
    if (filter == '') {
      setFilter(null)
    }
  }, [filter])

  useEffect(() => {
    if (filterFIO == '') {
      setFilterFIO(null)
    }
  }, [filterFIO])

  useLayoutEffect(() => {
    debugger
    let ClientUsers = isClient ?
      USERS.filter(user => user.ID == selfUser.ID) :
      Immutable.fromJS(
        (selfUser.ROLE == 'ADMN' ?
          USERS.filter(user => user.ROLE == 'PRTN' || user.ROLE == 'CPRT') : MyPartners)
      ).toJS()

    ClientUsers.forEach((user, index) => {
      ClientUsers[index].NoReadedCount = 0
      ClientUsers[index].BasketOrdersByMonth = {}


      ACCESSORIES_ORDERS.forEach(order => {

        if (order.ID_USER == user.ID) {
          if (order.ID_STATUS == 17 && order.MANAGER_READED == 0)
            ClientUsers[index].NoReadedCount++

          let dateCreate = moment(order.DATE_CREATE)

          let orderYear = dateCreate.format('YYYY') * 1
          let orderMonth = dateCreate.format('MM') * 1


          if (ClientUsers[index].BasketOrdersByMonth[orderYear] == null)//нет года -> создать
            ClientUsers[index].BasketOrdersByMonth[orderYear] = {}

          if (ClientUsers[index].BasketOrdersByMonth[orderYear][orderMonth] == null)//нет месяца в году -> создать
            ClientUsers[index].BasketOrdersByMonth[orderYear][orderMonth] = []

          ClientUsers[index].BasketOrdersByMonth[orderYear][orderMonth].push({
            ...order,
            DATE_CREATE: dateCreate
          })

        }
      })

    })

    setAccessoriesOrders(ClientUsers.filter(user => Object.keys(user.BasketOrdersByMonth).length != 0))
  }, [ACCESSORIES_ORDERS, USERS])


  useLayoutEffect(() => {
    let filterd = []

    accessoriesOrders.forEach(user => {

      let index = -1

      let ITEMS_COUNT = 0
      let ALL_COUNT = 0
      let ALL_WEIGHT = 0
      let ALL_PRICE = 0

      Object.keys(user.BasketOrdersByMonth).forEach(year => {
        Object.keys(user.BasketOrdersByMonth[year]).forEach(month => {
          let calkMonth = {
            ITEMS_COUNT: 0,
            ALL_COUNT: 0,
            ALL_WEIGHT: 0,
            ALL_PRICE: 0,
            ITEMS_COUNT: 0
          }

          user.BasketOrdersByMonth[year][month].forEach(order => {
            let dateCreate = order.DATE_CREATE
            if (
              (filter == null || (
                order.GUID.includes(filter)
              )) &&
              ((dateFilter[0] == null || dateFilter[1] == null) || (
                dateCreate.isSameOrAfter(dateFilter[0]) &&
                dateCreate.isSameOrBefore(dateFilter[1])
              )) && (
                filterFIO == null ||
                order.FIO.toUpperCase().includes(filterFIO)
              )
            ) {
              index = filterd.findIndex(userF => userF.ID == user.ID)
              if (index == -1) {
                let tmpUser = Immutable.fromJS(user).toJS()
                tmpUser.BasketOrdersByMonth = {}
                filterd.push(tmpUser)
                index = filterd.length - 1
              }

              if (filterd[index].BasketOrdersByMonth[year] == null) filterd[index].BasketOrdersByMonth[year] = {}
              if (filterd[index].BasketOrdersByMonth[year][month] == null) filterd[index].BasketOrdersByMonth[year][month] = []
              filterd[index].BasketOrdersByMonth[year][month].push(order)

              if (order.ID_STATUS == 17) {
                ALL_COUNT += order.ALL_COUNT
                ALL_PRICE += order.ALL_PRICE
                ALL_WEIGHT += order.ALL_WEIGHT
                ITEMS_COUNT++

                calkMonth.ALL_COUNT += order.ALL_COUNT
                calkMonth.ALL_PRICE += order.ALL_PRICE
                calkMonth.ALL_WEIGHT += order.ALL_WEIGHT
                calkMonth.ITEMS_COUNT++
              }
            }
          })

          if (filterd[index]?.BasketOrdersByMonth?.[year]?.[month] != null) {
            filterd[index].BasketOrdersByMonth[year][month] = {
              ITEMS: [...filterd[index].BasketOrdersByMonth[year][month]],
              ...calkMonth
            }
          }
        })
      })

      if (index != -1) {
        filterd[index] = {
          ...filterd[index],
          ITEMS_COUNT, ALL_COUNT, ALL_WEIGHT, ALL_PRICE,
          isOpenIndex: openedUsers.findIndex(ou => ou == filterd[index].ID)
        }
      }

    })


    console.error('filteredUserOrders', filterd)
    console.error('accessoriesOrders', accessoriesOrders)
    console.error('filter', filter)
    console.error('dateFilter', dateFilter)

    setFilteredUserOrders(filterd)
  }, [accessoriesOrders, filter, dateFilter, openedUsers, filterFIO])





  return (
    <View className='noselect'>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: 10
      }}>
        <FormControl key='urlFormer' variant="filled" sx={{ minWidth: 200 }} color='success' size='small'>
          <InputLabel>Раздел заказов</InputLabel>
          <Select
            size='small'
            label='Раздел заказов'
            value='/orders/basket'
            onChange={e => {
              history.push(e.target.value)
            }}>
            <MenuItem value={'/orders/calk'}>Расчеты</MenuItem>
            <MenuItem value={'/orders/basket'}>Комплектующие</MenuItem>
          </Select>
        </FormControl>

        <TextField
          style={{ width: 250 }}
          className='m-0'
          label='Поиск по номеру заказа'
          variant='filled'
          size="small"
          color='success'
          value={filter}
          onChange={(e) => setFilter(e.target.value.length > 0 ? e.target.value.toUpperCase() : null)}
        />

        {selfUser.ROLE == 'PRTN' ? null :
          <TextField
            style={{ width: 250 }}
            className='m-0'
            label={selfUser.ROLE == 'СPRT' ? 'ФИО менеджера' : 'ФИО партнера'}
            variant='filled'
            size="small"
            value={filterFIO}
            color='success'
            onChange={(e) => setFilterFIO(e.target.value.length > 0 ? e.target.value.toUpperCase() : null)}
          />
        }

        <DateRangePicker
          value={dateFilter}
          onChange={(newValue) => setDateFilter([
            newValue[0] == null ? null : moment(newValue[0]).set({ hours: 0, minutes: 0 }),
            newValue[1] == null ? null : moment(newValue[1]).set({ hours: 23, minutes: 59 })
          ])}
          renderInput={(startProps, endProps) => (
            <div style={{
              display: 'flex',
              gap: 10,
              position: 'relative'
            }}>
              <small style={{
                position: 'absolute',
                display: 'block',
                top: -17,
                left: 0
              }}>Дата оформления заказа</small>
              <TextField style={{ width: 140 }} size='small' variant='filled' {...startProps} label='Дата начала' />
              <TextField style={{ width: 150 }} size='small' variant='filled' {...endProps} label='Дата окончания' />
            </div>
          )}
        />



        <div>
          <IconButton onClick={() => {
            setDateFilter([null, null])
            setFilter('')
            setFilterFIO('')
          }}><CancelIcon /></IconButton>
        </div>
      </div>


      <table className='w-100 mt-3 mb-3 ' >
        <thead>
          <td width='30px' />
          <td />
          <td>Кол-во заказов</td>
          <td width='130px'>Дата оформления</td>
          <td>Кол-во товаров</td>
          <td>Общий вес</td>
          <td>Стоимость</td>
          <td width='100px'>Статус</td>
        </thead>
        <tbody>
          {filteredUserOrders.map(user => {

            let isOpenIndex = user.isOpenIndex
            let isOpen = isOpenIndex != -1
            let NoReadedCount = user.NoReadedCount


            return (<>
              <tr key={'us' + user.ID}>
                <td width='30px'><IconButton key={'button_order_' + user.ID}
                  onClick={() => {
                    if (isOpen) {
                      let tmp = Immutable.List(openedUsers).delete(isOpenIndex).toJS()
                      setOpenedUsers(tmp)
                    } else {
                      setOpenedUsers([
                        ...openedUsers,
                        user.ID
                      ])
                    }

                  }}>
                  <ExpandMoreIcon style={{
                    transform: !isOpen ? 'scale(-1)' : null,
                    transition: '.3s'
                  }} /></IconButton>
                </td>
                <td>
                  <Chip className='float-start' avatar={<Avatar />}
                    label={
                      <div style={{
                        display: "flex",
                        alignItems: 'center',
                      }} onClick={(e) => {
                        e.stopPropagation()
                        dispatch(openPopUp(<POP_User ID_OBJECT={user.ID} />))
                      }}>
                        <p className='m-0'>
                          {user.ID == selfUser.ID ? ' Мои заказы комплектующих' : user.FIO}
                        </p>
                        {(selfUser.ROLE != 'MNGR' && selfUser.ROLE != 'ADMN') || NoReadedCount == 0 ? null :
                          <div style={{
                            minWidth: 20,
                            background: 'rgb(52, 145, 255)',
                            paddingLeft: 5,
                            paddingRight: 5,
                            marginRight: -5,
                            marginLeft: 10,
                            borderRadius: 100,
                            transition: '.5s',
                            color: 'white',
                            fontSize: 10,
                            fontWeight: '700',
                            paddingBottom: 2,
                            paddingTop: 2,
                            textAlign: 'center'
                          }}>
                            {NoReadedCount}
                          </div>
                        }
                      </div>} /></td>
                <td><Chip label={<p className='m-0'>{GF.makeCoast(user.ITEMS_COUNT)} <small>шт</small></p>} /></td>
                <td />
                <td><Chip label={<p className='m-0'>{GF.makeCoast(user.ALL_COUNT)} <small>шт</small></p>} /></td>
                <td><Chip label={<p className='m-0'>{GF.makeCoast(user.ALL_WEIGHT / 1000)} <small>кг</small></p>} /></td>
                <td><Chip label={<p className='m-0'>{GF.makeCoast(user.ALL_PRICE)} <small>руб</small></p>} /></td>
              </tr>
              {isOpen &&
                (() => {//не выводятся юзеры
                  let result = []
                  Object.keys(user.BasketOrdersByMonth)?.forEach(year => {

                    const monthsKeys = Object.keys(user.BasketOrdersByMonth[year]) || []

                    for (let i = monthsKeys.length - 1; i >= 0; i--) {
                      const month = Number(monthsKeys[i])

                      const curentMonth = user.BasketOrdersByMonth[year][month]
                      result.push(<tr>

                        <td colSpan={2} style={{ //вывод мясаца группировки
                          paddingLeft: 10,
                          textAlign: 'left',
                          alignSelf: 'flex-start',
                          padding: 20
                        }}><b className='m-0'>{moment(month, 'M').format('MMMM')} <small>{year}</small></b></td>
                        <td><b>{GF.makeCoast(curentMonth.ITEMS_COUNT)} <small>шт</small></b></td>
                        <td />
                        <td><b>{GF.makeCoast(curentMonth.ALL_COUNT)} <small>шт</small></b></td>
                        <td><b>{GF.makeCoast(curentMonth.ALL_WEIGHT / 1000)} <small>кг</small></b></td>
                        <td><b>{GF.makeCoast(curentMonth.ALL_PRICE)} <small>шт</small></b></td>
                      </tr>)
                      curentMonth.ITEMS.forEach(order => {

                        let dateCreate = moment(order.DATE_CREATE)

                        result.push(
                          <tr className='order' onClick={() => dispatch(openPopUp(<POP_BasketOrder ID_OBJECT={order.ID} />))}
                            style={{
                              opacity: order.ID_STATUS != 17 ? 0.4 : 1
                            }}>
                            <td>
                              {order.MANAGER_READED == 0 && order.ID_STATUS == 17 ?
                                <div style={{
                                  alignSelf: 'flex-start',
                                  marginRight: 'auto',
                                  background: 'rgb(52, 145, 255)',
                                  height: 8,
                                  width: 8,
                                  borderRadius: 100,
                                  marginLeft: 20,
                                  transition: '.5s'
                                }} /> : null}
                            </td>
                            <td><Chip style={{ marginTop: 2, marginBottom: 2 }} className='float-start' color='success' size='small' label={order.GUID} /></td>
                            <td />
                            <td>{dateCreate.format('D MMM')} <small>{dateCreate.format('HH:mm')}</small></td>
                            <td><Chip label={<>{GF.makeCoast(order.ALL_COUNT)} <small>шт</small></>} size='small' /></td>
                            <td><Chip label={<>{GF.makeCoast(order.ALL_WEIGHT / 1000)} <small>кг</small></>} size='small' /></td>
                            <td><Chip label={<>{GF.makeCoast(order.ALL_PRICE)} <small>руб</small></>} size='small' /></td>
                            <td><Chip label={order.STATUS_TITLE} size='small' style={{
                              color: 'white', background: order.STATUS_COLOR,
                              marginLeft: 'auto'
                            }} /></td>
                          </tr>
                        )
                      })
                    }
                  })
                  return result
                })()}

            </>)
          })
          }

        </tbody>
      </table>
    </View>
  )
}

const View = styled.div`
height:max-content;
padding-bottom:150px;
& table td{
  padding-top:0px;
  padding-bottom:0px;
  font-size:13px;
  text-align:center;
}
& table tbody tr.order{
    border-bottom:1px solid rgb(240,240,240);
  transition:.3s;
  transform:scale(1);
  cursor:pointer;
}

& .order:hover{

  background:rgb(241, 241, 241);

}

& table thead td{
  color:rgb(120,120,120);
  font-size:11px !important;
  padding-bottom:5px;
}
`



