import React from 'react';

import { Lock, Person } from '@mui/icons-material';
import { Alert, AlertTitle, Button, InputAdornment, TextField } from '@mui/material';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { down, up } from 'styled-breakpoints';
import styled from 'styled-components';
import { GlobalFunctions } from '../../../GlobalFunctions';
import { openPopUp } from "../../../actions/PopUpActions";
import { loadUserData_FromStorage, logOut } from '../../../actions/UserAct';
import BackImg from '../../../design/bg.jpg';
import Logo_png from '../../../design/logo.svg';
import Alert_pop from '../../PopUp/Views/POP_Alert';

class Index extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
            errorMSG: null,
            isLoaded: false,
            isLoadedALL: false,
            isError: false
        }
        this.input = []
    }



    componentDidMount() {
        try {
            const urlParams = new URLSearchParams(window.location.search);
            const myParamEmail = urlParams.get('email');

            this.input.LOGIN.value = myParamEmail
        } catch { }
        debugger
        GlobalFunctions.deleteCookie('login')
        GlobalFunctions.deleteCookie('password')

        localStorage.removeItem('login')
        localStorage.removeItem('password')

        this.props.logOut()
        this.props.loadUserData_FromStorage()

        GlobalFunctions.API_task({
            operation: "checkKey",
            KEY_TYPE: 'CUREG',
            GUID: this.props.match.params.GUID
        }).then(res => {

        }).catch(e => {
            this.setState({ isError: true })
        })

        setTimeout(() => {
            this.setState({ isLoaded: true })
        }, 500)
    }

    passwordSendToMail() {
        if (!GlobalFunctions.checkReqvireds(this.input, ["PASSWORD"])) return


        GlobalFunctions.API_task({
            operation: "passwordSendToMail",
            LOGIN: (this.input.LOGIN.value)
        }).then(res => {
            if (res == "ok") {
                this.props.openPopUp(<Alert_pop text="Восстановленный пароль выслан на почту пользователя" />)
            }
        })
    }

    signIN() {

        if (!!this.input.PASSWORD.value.match(/[^0-9,a-z,A-Z,а-я,А-Я]/gi)) {
            alert('Пароль может содержать только цифры и буквы')
            return
        }
        this.setState({ errorMSG: null })

        if (!GlobalFunctions.checkReqvireds(this.input)) return

        GlobalFunctions.API_task({
            operation: "confimEmailCustomer",
            GUID: this.props.match.params.GUID,
            PASSWORD: (this.input.PASSWORD.value),
            LOGIN: (this.input.LOGIN.value)
        }).then(res => {
            if (res == null) {

                this.setState({ errorMSG: "ОШИБКА" })
                return
            }
            this.setState({ isLoaded: false, isLoadedALL: true })
            const { cookies } = this.props
            cookies.set("login", res.LOGIN, { path: '/' })
            cookies.set("password", res.PASSWORD, { path: '/' })
            cookies.set("id", res.ID, { path: '/' })

            window.location.replace('../../')
        }).catch((error) => {

            this.setState({ errorMSG: error })
        })
    }

    render() {
        const { } = this.props.page
        const { isLoaded, isLoadedALL, isError } = this.state

        if (isError) return (<h1>Неверный ключ регистрации</h1>)
        return (
            <BodyBlack isLoadedALL={isLoadedALL}>
                <Page className="Page Login bg-light">
                    <BGimg isLoaded={isLoaded}>
                        <DarkBg />
                    </BGimg>



                    <LoginView isLoaded={isLoaded} isLoadedALL={isLoadedALL} className="row p-4">
                        <div className="col-lg-4 col-md-3" />
                        <div className="col-lg-4 col-md-6">

                            <View>
                                <Img isLoaded={isLoaded} src={Logo_png} />

                                <h5>Регистрация в приложении</h5>

                                <p>Система ведения проектов</p>
                                <div className='row'>
                                    <div className='col-7'>
                                        <TextField
                                            color='success'
                                            label='Логин (E-mail)'
                                            variant="filled"
                                            size='small'
                                            className='bg-light'
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Person />
                                                    </InputAdornment>
                                                ),
                                            }} inputRef={ref => this.input.LOGIN = ref}
                                        />

                                        <TextField
                                            color='success'
                                            label='Пароль'
                                            type='password'
                                            variant="filled"
                                            size='small'
                                            className='bg-light mt-3 '
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Lock />
                                                    </InputAdornment>
                                                ),
                                            }} inputRef={ref => this.input.PASSWORD = ref}
                                        />
                                    </div>
                                    <div className='col-5'>
                                        <Button variant='text' color='success' style={{
                                            position: 'absolute',
                                            bottom: 20,
                                            right: 20
                                        }} size='small' onClick={() => this.signIN()}>регистрация</Button>
                                    </div>
                                </div>
                            </View>
                            {this.state.errorMSG != null ?
                                <Alert severity="error" className='mt-3'>
                                    <AlertTitle>Ошибка</AlertTitle>
                                    {this.state.errorMSG}
                                </Alert>
                                : null}


                        </div>
                        <div className="col-lg-4 col-md-3" />
                    </LoginView>
                </Page>
            </BodyBlack>
        )
    }
}

const Iframe = styled.iframe`

height:100vh;
    width:100vw;
    transition:1s;

position: fixed;
    top:0;
    left:0;


${down('md')}{
    ${props => props.isLoaded ? `
    transform:scale(1);
    opacity:1;
    `: `
    transform:scale(1.5);
    opacity:0;
    `}
}

${up('md')}{
    ${props => props.isLoaded ? `
    transform:scale(1);
    opacity:1;
    `: `
    transform:scale(1.1);
    opacity:0;
    `}
}
`

const LoginView = styled.div`

${props => props.isLoadedALL ? `transition:1s;` : `transition:1s ease 1s;`}

${props => props.isLoaded ? `
transform:translateY(0);
opacity:1;
`: `
transform:translateY(50px);
opacity:0;
`}
`

const View = styled.div`
background:white;
box-shadow:10px 10px 40px rgba(0,0,0,0.1);
padding:20px !important;
border-radius:5px;
margin-top:50px;
position:relative;
z-index:10;
`

const BodyBlack = styled.div`
height:100vh;
width:100vw;
${props => props.isLoadedALL ? 'background:rgba(0,0,0,0);' : 'background:rgba(0,0,0,1);'}
`


const Img = styled.img`
display:block;
margin:auto;
width:150px;
margin-top:30px;
z-index:1;
position:relative;

transition:1s;

position: absolute;
width: 80px;
right: 25px;
top: 3px;

`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp: (content) => dispatch(openPopUp(content)),
        loadUserData_FromStorage: (content) => dispatch(loadUserData_FromStorage(content)),

        logOut: (arg) => dispatch(logOut(arg)),
    };
};


const mapStateToProps = (store) => {

    return {
        page: store.page
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(withRouter(Index)));

const BGimg = styled.div`
background:url('${BackImg}');
height:100vh;
    width:100vw;
    transition:1s;
    object-fit: cover;
    background-position: center top;
background-size: cover;
position: fixed;
    top:0;
    left:0;


${down('md')}{
    ${props => props.isLoaded ? `
    transform:scale(1);
    opacity:1;
    `: `
    transform:scale(1.5);
    opacity:0;
    `}
}

${up('md')}{
    ${props => props.isLoaded ? `
    transform:scale(1);
    opacity:1;
    `: `
    transform:scale(1.1);
    opacity:0;
    `}
}
`

const Page = styled.div`
    
   
    overflow: hidden;
    height:100vh;
    width:100vw;
    



`

const DarkBg = styled.div`
position:fixed;
background:rgba(0,0,0,0.);
top:0;
left:0px;
width:100vw;
height:100vh;
`
