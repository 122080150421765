
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../../actions/PopUpActions';
import {GlobalFunctions as GF} from '../../../../GlobalFunctions'
import { Label } from '../../../components/moduls/Input';
import Table from '../../../components/Table';
import { Button, ButtonGroup, Chip, TextField } from '@mui/material';

import POP_Statuses from './POP_Statuses';




const Rows = [

  {
      title:'Название',
      key:'TITLE'
  },
  {
      title:'Сущность',
      key:'ENTITY',
  },
  {
    title:'Системный',
    key:'IS_SUSTEM',
    width:'100px'
  },
  {
    title:'Цвет',
    key:'COLOR',
    width:'100px'
  },
  
]

class Statuses extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
          errorMSG:null,
          succes:false,
          filter:null,
          Data:[]
        }
        this.input={
          dataWindow:{},
          main:{}
        }
        this.checkBoxs={}
    }


    PreDelete(){
      let promises = []
      let somethingDoing = false

        Object.keys(this.checkBoxs).forEach((key,index)=>{
          if(this.checkBoxs[key].checked){
            somethingDoing=true
            this.checkBoxs[key].checked=false
            promises.push(this.Delete(key))
            delete(this.checkBoxs[key])
          }
        })

        if(!somethingDoing) return

        Promise.allSettled(promises)
        .then(()=>{
          this.props.getUsers()
          this.props.addToast('Удалено', { appearance: 'success',autoDismiss:true });
        })
      
    }

    Delete(ID){
      return new Promise((resolve,reject)=>{
        let data = {
          operation:'deleteSome',
          Table:'USERS',
          ID
        }
        
        GF.API_task(data).then(res=>{
         
         
          setTimeout(()=>resolve(),1)
          
        }).catch(()=>reject()) 
      })
      
    }

    changeBlock(ID){
      return new Promise((resolve,reject)=>{
        let data = {
          operation:'changeBlockUser',
          ID
        }
        
        GF.API_task(data).then(res=>{
          
          setTimeout(()=>resolve(),1)
        }).catch(()=>reject()) 
      })
    }

    Block(){
      let somethingDoing = false
      let promises = []
      Object.keys(this.checkBoxs).forEach((key,index)=>{
          if(this.checkBoxs[key].checked){
            somethingDoing = true
            this.checkBoxs[key].checked=false
            promises.push(this.changeBlock(key))
          }
      })
      
      if(!somethingDoing) return

      Promise.allSettled(promises)
        .then(()=>{
          this.props.getUsers()
          this.props.addToast('Значение обновлено', { appearance: 'success',autoDismiss:true });
        })
    }

    


    render (){
        const {STATUSES} = this.props
        const {filter,Data} = this.state
        return(
          <View>
            <div className='mt-4'>
              <TextField
                className='me-2'
                color='success'
                label='Поиск'
                variant='filled'
                size="small"
                onKeyUp={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}
              />

             
              
            

           
           

            </div>
              
          
              <Table
                    className='mt-1'
                    rows={Rows}
                    canCheck={false}
                    data={STATUSES.sort((a,b)=>a.ENTIT-b.ENTITY).filter(a=>filter==null || a.TITLE.toUpperCase().indexOf(filter)!=-1  )}
                    itemOnClick={({data,index})=>{
                        this.props.openPopUp(<POP_Statuses ID_OBJECT={data.ID}/>)
                    }}
                    rowOnClick={({data,index})=>{
                        debugger
                    }}
                    renderItem={({data,index})=>
                      Rows.map(r=>{
                        switch(r.key){
                          case 'COLOR':
                            return(<td><Chip style={{
                              backgroundColor:data.COLOR,
                              color:'white'
                            }} className='me-1 mb-1' label={data.COLOR} size="small" /></td>)
                          case 'IS_SUSTEM':
                            return(<td>{data[r.key]=='1'?'Да':'Нет'}</td>)
                          default: return(<td>{data[r.key]}</td>)
                        }
                        
                      })  
                    }/>

           
          </View>
        )
    }
}
const View = styled.div`
height:100%;
`

const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
        STATUSES: store.page.STATUSES
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Statuses);

