import React from 'react';

export default function FlatCard({style,children,className,onClick}){
    return(<div className={className} onClick={onClick} style={{
        background:"white",
        borderRadius:5,
        border:'1px solid rgb(232 232 232)',
        padding:10,
        maxWidth:'20vw',
        ...style
    }}>
        {children}
    </div>)
}