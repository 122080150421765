import Immutable from "immutable"
import _ from "lodash"
import moment from "moment"
import UserSearch from "../Class/UserSearch"
import { GlobalFunctions as GF } from "../GlobalFunctions"
import { store } from '../store/configureStore'



export function sincInstSchedulDB(isPRIORITY, callBack = () => { }) {
  clearTimeout(global.sincInstSchedulDB_timer)
  global.sincInstSchedulDB_timer = setTimeout(() => {
    sincInstSchedulDB_reqvest().then(data => {

      callBack(data)
    })
  }, isPRIORITY ? 1 : 3000)
}


export function sincInstSchedulDB_reqvest() {
  const propertys = ['ID', 'DATE_INSTALL', 'TIME_START', 'TIME_END', 'ID_USER_INSTALL', 'ADDRESS', 'ADDRESS_DESCR', 'ID_ORDER', 'ID_STATUS', 'CLIENT_TITLE', 'CLIENT_FIO', 'CLIENT_PHONE']

  const getCurrentPropertys = (obj) => {
    let result = {}
    propertys.forEach(key => {
      result[key] = obj[key]
    })
    return Immutable.fromJS(result).toJS()
  }

  return GF.API_task({
    operation: 'sincInstSchedul',
    block: 'InstallSchedule',
    SCHEDULE: store.getState().page.InstallSchedule.map(a => (getCurrentPropertys(a)))
  })
}

export function createInstallScheduleItem(new_item = {}, callBack = () => { }) {
  return (dispatch) => {
    GF.API_task({
      operation: 'createInstallScheduleItem',
      block: 'InstallSchedule',
      data: new_item
    }).then((data) => {
      callBack({
        ...data,
        ...InstallSchedule_getDates(data)
      })
      getInstallSchedule()(dispatch)
    })


  }
}

export function assingUserToScheduleItemByHisId(scheduleItem = { ID: 0 }) {

}

export function updateInstallScheduleItems(new_schedule_items = [{ ID: 0 }], callBack = () => { }) {
  return (dispatch) => {
    if (new_schedule_items?.length > 0) {

      const newArryOfSchedule = store.getState().page.InstallSchedule.map(a => {
        let index = new_schedule_items.findIndex(new_sch => new_sch.ID == a.ID)
        if (index != -1) {
          if (new_schedule_items[index].ID_USER_INSTALL != a.USER_INSTALL_FIO) {
            //Если изменился Монтажник
            new_schedule_items[index].USER_INSTALL_FIO = UserSearch.find(new_schedule_items[index].ID_USER_INSTALL)?.FIO
          }
          return (new_schedule_items[index])
        } else
          return (a)
      })

      setInstallSchedule(newArryOfSchedule, PRIORITY, callBack)(dispatch)

    }
  }
}

export function deleteFromInstallScheduleItems(deleted_schedule_items_ids = [0], callBack = () => { }) {
  return (dispatch) => {
    if (deleted_schedule_items_ids?.length > 0) {
      const newArryOfSchedule = store.getState().page.InstallSchedule.filter(a =>
        deleted_schedule_items_ids.findIndex(del => del == a.ID) == -1
      )

      GF.API_task({
        operation: 'deleteInstSchedul',
        block: 'InstallSchedule',
        ID_arr: deleted_schedule_items_ids
      }).then(() => {
        callBack()
      })

      dispatch({
        type: 'JUST_SET_VALUE',
        payload: newArryOfSchedule,
        key: 'InstallSchedule'
      })
    }
  }
}


const PRIORITY = true
export function setInstallSchedule(newSchedule, isPRIORITY = false, callBack = () => { }) {
  return (dispatch) => {
    sincInstSchedulDB(isPRIORITY, callBack)

    newSchedule.forEach((d, i) => {
      newSchedule[i] = {
        ...newSchedule[i],
        ...InstallSchedule_getDates(d)
      }
    })


    dispatch({
      type: 'JUST_SET_VALUE',
      payload: newSchedule,
      key: 'InstallSchedule'
    })
  }
}

export function getManagerInstallersRooles() {

  return (dispatch) => {
    GF.API_task({
      operation: 'getManagerInstallersRooles',
      block: 'InstallSchedule'
    }).then(data => {
      dispatch({
        type: 'JUST_SET_VALUE',
        payload: data,
        key: 'ManagerInstallersRooles'
      })
    })
  }
}



export function getInstallSchedule(callBack = () => { }) {



  return (dispatch) => {


    GF.API_task({
      operation: 'getInstallSchedule',
    }).then(data => {

      data.forEach((d, i) => {
        data[i] = {
          ...data[i],
          ...InstallSchedule_getDates(d)
        }

      })

      try {
        setTimeout(callBack)
      } catch { }

      dispatch({
        type: 'JUST_SET_VALUE',
        payload: data,
        key: 'InstallSchedule'
      })
    })
  }
}


export function InstallSchedule_getDates(schedule) {
  let dateData = {}
  dateData.dateTimeStart = moment(schedule.DATE_INSTALL + ' ' + schedule.TIME_START, 'YYYY-MM-DD HH:mm:ss')
  dateData.dateTimeEnd = moment(schedule.DATE_INSTALL + ' ' + schedule.TIME_END, 'YYYY-MM-DD HH:mm:ss')
  dateData.dateInstall = moment(schedule.DATE_INSTALL, 'YYYY-MM-DD')

  dateData.timeStart = {
    h: dateData.dateTimeStart.format('HH') * 1,
    m: dateData.dateTimeStart.format('mm') * 1
  }

  dateData.timeEnd = {
    h: dateData.dateTimeEnd.format('HH') * 1,
    m: dateData.dateTimeEnd.format('mm') * 1
  }

  return dateData
}

