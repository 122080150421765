
import { AccessTime, AccessTimeFilled, LibraryAddCheck, PeopleAlt, TaskAlt, Work } from '@mui/icons-material';
import { Button, Card, CardActions, CardHeader, IconButton ,CardContent} from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import {down, up} from 'styled-breakpoints'
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'
import Files from './Files';




class Index extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
           errorMSG:null,
        }
        this.input=[]
    }

    

    componentDidMount() {
    
    }


    render (){
        const {user,COMPANYS,STATUSES} = this.props
       
        

       
        return (
           <MainView>
                <h1>Файловая система</h1>
                
                <Switch>
                    <Route path='/file_manager/:ID_FOLDER'>
                        <Files/>
                    </Route>
                    <Route exact path="/file_manager">
                        <Redirect to="/file_manager/1" />
                    </Route>
                </Switch>
           </MainView>
        )

      
    }
}

const MainView = styled.div`

`




const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
        
        user: store.user.data,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Index))

