import { Alert, AlertTitle, Avatar, Button, Chip, CircularProgress, IconButton, TextField } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { closePopUp, openPopUp } from '../../../actions/PopUpActions';
import { useRef } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { POPbottom, POPscroll, POPtop } from '../../PopUp';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import { GlobalFunctions as GF } from '../../../GlobalFunctions';

import { addToBasket, cancelBasketOrder, cleanBasket, removeFromBasket, setBasket, setCountInBasket, sincBasketDB_reqvest,getACCESSORIES_ORDERS } from '../../../actions/PageActions';
import { LoadingButton } from '@mui/lab';
import POP_User from '../../pages/DataBase/pages/POP_User'

import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import moment from 'moment';
import useIsClient from '../../../Hooks/useIsClient';
import POP_Alert from '../../PopUp/Views/POP_Alert';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import POP_Basket from '../Accessories/POP_Basket';
import { useToasts } from 'react-toast-notifications';
import { useLayoutEffect } from 'react';
import FileOpenIcon from '@mui/icons-material/FileOpen';

function POP_BasketOrder(props){

    const toastFuncs = useToasts()
    const isClient = useIsClient()
    const dispatch = useDispatch()
    
    const [Self,setSelf] = useState({
        ITEMS:[]
    })
    const [isCancelingLoading,setCancelingLoading] = useState(false)
    let readingStatusIsMaked = false
   

    useLayoutEffect(()=>{
        let Self = props.ACCESSORIES_ORDERS.find(order=>order.ID==props.ID_OBJECT)

        

        setSelf(Self)

        if(Self.ID_STATUS==17 && Self.MANAGER_READED==0 && props.user.ROLE=='MNGR' && !readingStatusIsMaked){
            readingStatusIsMaked=true
            GF.API_task({
                operation:'readBasketOrder',
                block:'Basket',
                ID:Self.ID
            }).then(data => {
                dispatch(getACCESSORIES_ORDERS())
            }).catch(()=>{
                readingStatusIsMaked=false
            })
        }

    },[props.ACCESSORIES_ORDERS])
    
    
    if(Self?.ID ==null && props.ID_OBJECT==null)    
        return <div/>
    
    
    return(<POP>
        <POPscroll  top bottom>
            <POPtop>
                
            <div style={{
                display:"flex",
                gap:20,
                alignItems:'center'
            }}>
                <Chip className='m-0' color='success' size='small' label={Self.GUID}/>
                {Self.ID_STATUS==17 && Self.MANAGER_READED==0 && props.user.ROLE!='MNGR'?
                    <Chip className='m-0' color='info' size='small' label='Не прочитан менеджером'/>
                :Self.ID_STATUS==18?
                    <Chip className='m-0' color='error' size='small' label='Отменен'/>
                :null}
            </div>
            <div style={{
                display:"flex",
                gap:20,
                alignItems:'center'
            }}>
                <div>
                    <small className='d-block text-center m-0'>{moment(Self.DATE_CREATE).format('D MMM YYYY')}</small>
                    <b className='d-block text-center m-0'>Оформлен</b>
                </div>
                <div style={{
                    display:"flex",
                    gap:20,
                    paddingRight:10,
                    alignItems:'center'
                }}>
                    <b>{GF.makeCoast(Self.ALL_PRICE)} <small>руб</small></b>
                    <b>{GF.makeCoast(Self.ALL_WEIGHT / 1000)} <small>кг</small></b>
                </div>
            </div>
            </POPtop>
            
            
            
           
            {Self.ITEMS.map(item=>{
                return(<div style={{
                    display:"flex",
                    alignContent:"center",
                    alignItems:'center',
                    gap:10,
                    marginBottom:20,
                }}>
                    {item?.IMAGES_URL?.at(0)==null?
                        <HomeRepairServiceIcon style={{
                            width: 50,
                            height: 50,
                            objectFit: 'contain'
                        }}/>:
                        <img style={{
                            width: 50,
                            height: 50,
                            objectFit: 'contain'
                        }} src={global.URL_host+'img/'+item.IMAGES_URL.at(0)} onClick={(e)=>{
                            e.stopPropagation()
                            props.openPopUp(<img style={{
                                margin: -30,
                                maxHeight:'70vh',
                                maxWidth:'80vw',
                                minHeight:300,
                                minWidth:300,
                                padding:20,
                                objectFit:'contain'
                            }}  src={global.URL_host+'img/'+item.IMAGES_URL.at(0)}/>)
                    }}/>}
                    <div>
                        <small style={{fontSize:10}}>{item.ID_1C}</small>
                        <p className='m-0' style={{
                            lineHeight: '15px',
                            fontSize: 14
                        }}>{item.TITLE}</p>
                        <small className='m-0 d-block'><b>{GF.makeCoast(item.PRICE)}</b> руб {!!item.WEIGHT && <>/ <b>{GF.makeCoast(item.WEIGHT)}</b> г</>}</small>
                    </div>

                    <div className='ms-auto'>
                        <h4 className='m-0 text-end w-100 d-flex' style={{
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end'
                        }}>{GF.makeCoast(item.COUNT)} <small style={{width:30, display:'block',
                            textAlign: 'left',
                            paddingLeft: 5,
                            paddingBottom:2
                        }}>шт</small></h4>
                        <p  className='m-0 text-end w-100 d-flex'>{GF.makeCoast(item.PRICE * item.COUNT)} <small style={{width:30, display:'block',
                            textAlign: 'left',
                            paddingLeft: 5,
                            paddingTop:1
                        }}>руб</small></p>
                    </div>
                   

                </div>)
            })}
            
        </POPscroll>

        <POPbottom>
            {isClient?<>
                <Button color='success' onClick={()=>{
                    if(props.ACCESSORIES_BASKET.length>0){
                        props.openPopUp(<POP_Alert text='У вас в корзине уже есть товары. Очистить корзину для повторения выбранного заказа?' buttons={[
                            <Button color='warning' onClick={()=>{
                                dispatch(closePopUp())
                                dispatch(setBasket(Self.ITEMS))
                                toastFuncs.addToast('Корзина очищина', { appearance: 'warning',autoDismiss:true })
                                
                                setTimeout(()=>{
                                    toastFuncs.addToast('Товары добавлены в корзину', { appearance: 'success',autoDismiss:true })
                                    dispatch(openPopUp(<POP_Basket/>))
                                },400)
                            }}>Да, очистить</Button>,
                            <Button color='inherit' onClick={()=>{
                                props.closePopUp()
                            }}>Отмена</Button>
                        ]}/>)
                    }else{
                        dispatch(setBasket(Self.ITEMS))
                        toastFuncs.addToast('Товары добавлены в корзину', { appearance: 'success',autoDismiss:true })
                        setTimeout(()=>dispatch(openPopUp(<POP_Basket/>)),100)
                    }
                }}>Повторить заказ</Button>
                    
                
            </>:<>
                <Chip avatar={<Avatar/>}  label={props.USERS?.find(user=>user.ID==Self.ID_USER)?.FIO} onClick={()=>dispatch(openPopUp(<POP_User ID_OBJECT={Self.ID_USER}/>))}/>
            </>}

            <div style={{display:'flex',gap:10}}>
                <Button endIcon={<FileOpenIcon/>} size='small' color='success' onClick={()=>{
                    GF.API_task({
                        operation:'createDoc',
                        block:'Basket',
                        ID_ORDER:Self.ID
                    }).then(data => {
                        window.open( global.URL_host+'server/'+data, '_blank').focus()
                    })
                }}>Excel</Button>

                <LoadingButton disabled={Self.ID_STATUS!=17} 
                size='small'
                    endIcon={<DoNotDisturbIcon />}
                    loading={isCancelingLoading}
                    loadingPosition="end" color='error' onClick={()=>{
                        props.openPopUp(<POP_Alert text='Отменить оформление заказа?' buttons={[
                            <Button color='warning' onClick={()=>{
                                setCancelingLoading(true)
                                props.closePopUp()
                                dispatch(cancelBasketOrder(Self.ID,Self.ID_USER,()=>{
                                    setTimeout(()=>setCancelingLoading(false),1000)
                                }))
                            }}>Да, отменить</Button>,
                            <Button color='inherit' onClick={()=>{
                                props.closePopUp()
                            }}>Нет</Button>
                        ]}/>)
                    }}>Отменить оформение</LoadingButton>
            </div>
        </POPbottom>
       
    </POP>)
}


const POP = styled.div`
width:600px;
`

const mapDispatchToProps = dispatch => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        addToBasket:(arg)=>dispatch(addToBasket(arg)),
        removeFromBasket:(arg)=>dispatch(removeFromBasket(arg)),
        setCountInBasket:(arg)=>dispatch(setCountInBasket(arg)),
        cleanBasket:(arg)=>dispatch(cleanBasket(arg)),
    }
}
const mapStateToProps = (store) => {
    return {
        ACCESSORIES_ORDERS:store.page.ACCESSORIES_ORDERS,
        ACCESSORIES_BASKET:store.page.ACCESSORIES_BASKET,
        ACCESSORIES:store.page.ACCESSORIES,
        USERS:store.page.USERS,
        user: store.user.data,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POP_BasketOrder)