
import { Check, Delete } from '@mui/icons-material';
import { Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Select, TextField, Tooltip, Typography,Grid, ListItem, IconButton, ListItemButton,Menu, Switch, FormGroup, FormControlLabel, Alert } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getDB_Data,getDB_Data_lazy,getUSERS,getCHATS } from '../../../actions/PageActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'

import InputMask from 'react-input-mask';
import Question from '../../PopUp/Views/Question'
import { Label } from '../../components/moduls/Input';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SwitchCheck from '../../components/SwitchCheck';
import ComboBox from '../../components/moduls/ComboBox';


  


class POP_News extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
            Self:{
                TYPE:'N'
            },
            anchorEl:null
        }
        this.input=[]
        this.can=true
    }

    

    componentDidMount() {
        const {state,props} = this
        const {ID_OBJECT,NEW_PARTNERS} = props

        if(ID_OBJECT!=null){
            var Self = props.NEWS.find(p=>p.ID==ID_OBJECT)
            if(Self==null) return
            Self = GF.ObjectClone(Self)
            this.setState({Self})
        }
    }

 

    render (){
        const {state,props} = this
        const {NEW_PARTNERS,ID_OBJECT} = props
        const {Self,anchorEl} = state
        const open = Boolean(anchorEl)

        
       
        if(ID_OBJECT!=null && Self.ID==null) return <div/>
     
        return (
            <POP>
               <div style={{
                            display:'flex',
                            justifyContent:'space-between',
                            paddingRight:40
                        }}>
                            <h3 className='m-0'>{Self.TITLE}</h3>
                            <Alert  icon={false}  color={Self.TYPE=='N'?'primary':'warning'}>{Self.TYPE=='N'?'Новость':'Акция'}</Alert>
                </div>
                <p className='m-0 mb-3'>{Self.SUB_TITLE}</p>

                        <hr/>
                <div dangerouslySetInnerHTML={{__html:Self.TEXT}}/>



                
                
            </POP>
        )

            
      
    }
}

const POP = styled.div`
width:700px;
`


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 

        getDB_Data_lazy:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy(tableName,JSONparseKey)), 

      
        getUSERS: (arg) => dispatch(getUSERS(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        NEWS:store.page.NEWS
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(POP_News)




