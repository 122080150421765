import React from 'react'
import { Button, TextField } from "@mui/material"
import { useState } from 'react'
import { POPbottom, POPscroll, POPtop } from '../../PopUp'
import { useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalFunctions as GF} from '../../../GlobalFunctions'
import POP_Alert from '../../PopUp/Views/POP_Alert'
import { getACCESSORIES, getACCESSORIES_CATEGORIS } from '../../../actions/PageActions'
import { closePopUp, openPopUp } from '../../../actions/PopUpActions'
import { useToasts } from 'react-toast-notifications'
import Immutable from 'immutable'



const form_fields = [
    {
        label:'Название',
        name:'TITLE',
    }
  ]

export default function POP_AccessoryCaterory({ID_OBJECT,callBack}){
    const [Self,setSelf] = useState({})
    const ACCESSORIES_CATEGORIS = useSelector(state=>state.page.ACCESSORIES_CATEGORIS)
    const dispatch = useDispatch()
    const toast = useToasts()

    useLayoutEffect(()=>{
        if(ID_OBJECT==null)
            setSelf({})
        else
            setSelf(ACCESSORIES_CATEGORIS.find(c=>c.ID==ID_OBJECT)||{})
    },[ID_OBJECT])



    function beforeDelete(){
        new Promise((resolve,reject)=>{
            const ferst = ACCESSORIES_CATEGORIS.find(c=>c.ID==1)

            dispatch(openPopUp(<POP_Alert text={'Вы уверены, что хотите удалить категорию "'+Self.TITLE+'"? Все товары этой категории будут перемещены в "'+ferst.TITLE+'"'} buttons={[
                <Button color='error' onClick={()=>
                    resolve()
                }>Да</Button>,
                <Button onClick={()=>
                    reject()
                }>Отмена</Button>,
            ]}/>))
        }).then(()=>{
            GF.API_task({
                operation:'deleteCategoryBasket',
                ID:Self.ID,
                block:'Basket'
            }).then(()=>{
                dispatch(getACCESSORIES_CATEGORIS())
                dispatch(getACCESSORIES())
            })
        }).finally(()=>{
            dispatch(closePopUp())
            dispatch(closePopUp())
        })
    }

    function Save(){
        if(Self.TITLE.length==0){
            toast.addToast('Введите название категории', { appearance: 'error',autoDismiss:true })
            return
        }

        let data = Immutable.fromJS(Self).toJS()

        GF.API_task({
            operation:'createUpdateSome',
            Table:'ACCESSORIES_CATEGORIS',
            data,
            ID:ID_OBJECT
        }).then((data)=>{
            dispatch(getACCESSORIES_CATEGORIS())
            if(ID_OBJECT!=null)
                dispatch(getACCESSORIES())
            dispatch(closePopUp())
            try{
                callBack(data)
            }catch{}
            toast.addToast('Категория создана', { appearance: 'success',autoDismiss:true })
        }).catch((e)=>{
            toast.addToast('Ошибка', { appearance: 'error',autoDismiss:true })
        })

    }

   
    return(
        <div style={{
            width:300
        }}>
            <POPscroll  bottom>
            <h5 className='m-0'>Категория</h5>

                {form_fields.map(input => 
                        <TextField
                            className='w-100 mt-3'
                            margin='dense'
                            variant="filled"
                            size='small'
                            color='success'
                            {...input}
                            value={Self[input.name]}
                            onChange={(e)=>
                                setSelf(GF.changeParametr(Self,input.name,e.target.value))
                            }
                        />
                )}

            </POPscroll>
            <POPbottom>
                <div>
                    {ID_OBJECT==null || ID_OBJECT==1?null:
                        <Button size='small' color='error' onClick={beforeDelete}>Удалить</Button>
                    }
                </div>

                <Button size='small' color='success' onClick={Save}>Сохранить</Button>
            </POPbottom>
        </div>
    )
}