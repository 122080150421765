function _updateInterval(){
    let _interval = null
    let _func = ()=>{}
    let _delay = ()=>{}
    return({
        start:(func=()=>{},delay=10000)=>{
            _func = func
            _delay= delay
            console.warn('UpdateInterval','Timer started '+_delay)
            _interval = setInterval(_func,_delay)
        },
        resset:()=>{
            console.warn('UpdateInterval','Timer resseted and started '+_delay)
            clearInterval(_interval)
            _interval = setInterval(_func,_delay)
        },
        stop:()=>{
            console.warn('UpdateInterval','Timer stopped')
            clearInterval(_interval)
        }
    })
}
const UpdateInterval = _updateInterval()
export default UpdateInterval