import { Button, TextField } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import POP_Alert from '../../PopUp/Views/POP_Alert'
import { connect } from 'react-redux';
import { closePopUp, openPopUp } from '../../../actions/PopUpActions';
import { useRef } from 'react';
import { useEffect } from 'react';


function POP_AddBasketCount(props){

    const [count, setCount] = useState(props.value)
    const input = useRef(null)

    return(<POP_Alert text='Какое количество товара должно лежать в корзине?' content={
        <TextField className='w-100' color='success' value={count} variant='filled' label='Количество'
        ref={input}
        onFocus={(e)=>e.target.select()}
        autoFocus
        onChange={e=>{
            e.target.value= e.target.value.replace('-','')
            if(e.target.value=='' || e.target.value==null || e.target.value*1<0 ){
                e.target.value=0
            }
            e.target.value = e.target.value*1
            setCount(e.target.value)
        }}
        onKeyUp={e=>{
            var charCode = (typeof e.which === "number") ? e.which : e.keyCode
            if(charCode==13){
                props.closePopUp()
                props.callBack(count*1)
            }
        }}
        type='number'
        step={1}/>
    } buttons={[
        <Button color='success' onClick={()=>{
            props.closePopUp()
            props.callBack(count*1)
        }}>Ок</Button>
    ]}/>)
}

const mapDispatchToProps = dispatch => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
    }
}

export default connect(null, mapDispatchToProps)(POP_AddBasketCount)