
import { AccessTime, AccessTimeFilled, LibraryAddCheck, PeopleAlt, TaskAlt, Work } from '@mui/icons-material';
import { Button, Card, CardActions, CardHeader, IconButton ,CardContent, Hidden, Chip, Skeleton, TextField, Avatar} from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { closePopUp, openPopUp } from '../../../actions/PopUpActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import { Label } from '../../components/moduls/Input';
import {down, up} from 'styled-breakpoints'
import { Draggable, Droppable } from 'react-drag-and-drop'
import {withRouter} from 'react-router-dom'
import { getDB_Data_lazy, getDB_Data_lazy_notID, getUSERS } from '../../../actions/PageActions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import POP_manager from './POP_manager';
import POP_User from '../DataBase/pages/POP_User';
import withToast from '../../components/Toasts';
import POP_UserSearch from '../DataBase/pages/POP_UserSearch';
import moment from 'moment';
import ReactHighcharts from 'react-highcharts';


import { DatePicker, DateRangePicker } from '@mui/lab';


class Index extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            Managers:null,
            Clients:null,
            drawSceleton:null,
            replacingClientID:null,
            draggingClient:null,
            year:moment().format('YYYY')*1,
            filter:null
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.USERS != this.props.USERS)
            this.componentDidMount()
    }

    componentDidMount(){
        const {state,props} = this
       
        let Clients = GF.ObjectCopy(props.USERS.filter(u=>u.ROLE=='PRTN' || u.ROLE=='СPRT'))
        let Managers = GF.ObjectCopy(props.USERS.filter(u=>u.ROLE=='MNGR'))
        let ClientsIDs_haveManagers = []

        Managers.forEach((m,i)=>{
            Managers[i].CLIENTS=props.MANAGERS_ROOLES.filter(mr=>mr.ID_USER == m.ID || mr.ID_REPLACEMENT == m.ID)
            
            Managers[i].ItemsCount=0
            Managers[i].AllOrdersPrice=0
            Managers[i].DraftsCount=0
            Managers[i].DraftsPrice=0
            Managers[i].SendedCount=0
            Managers[i].SendedPrice=0
            Managers[i].NewCount=0
            Managers[i].NewPrice=0
            Managers[i].ORDERS=[]

            

            Managers[i].CLIENTS = Managers[i].CLIENTS.map(c=>{
                let clen = Clients.find(u=>u.ID==c.ID_RESPONS)
                if(clen!=null){
                    ClientsIDs_haveManagers.push(clen.ID)
                    const ORDERS = []

                    let ItemsCount = 0
                    
                    let AllOrdersPrice = 0

                    let DraftsCount = 0
                    let DraftsPrice = 0

                    let SendedCount = 0
                    let SendedPrice = 0

                    let NewCount = 0
                    let NewPrice = 0

                    

                    props.ORDERS.forEach(order=>{
                        if(moment(order.DATE_CREATE).format('YYYY')*1 == state.year || (order.DATE_SENDED!=null && moment(order.DATE_SENDED).format('YYYY')*1 == state.year ))
                        if(order.ID_USER==clen.ID){
                            ORDERS.push(order)
                            AllOrdersPrice+=order.ALL_COAST-order.TAX_COAST
                            if(order.ID_STATUS==13){
                                if(order.MANAGER_READED==1){
                                    SendedCount++
                                    SendedPrice+=order.ALL_COAST-order.TAX_COAST
                                    order.ITEMS.forEach(item=>{
                                        ItemsCount+=item.ORDERS_COUNT
                                    })
                                }else{
                                    NewCount++
                                    NewPrice+=order.ALL_COAST-order.TAX_COAST
                                }
                                
                            }else{
                                DraftsCount++
                                DraftsPrice+=order.ALL_COAST-order.TAX_COAST
                            }
                                
                            
                            
                        }
                    })


                    if(c.ID_REPLACEMENT == m.ID || (c.ID_REPLACEMENT==null && m.ID==c.ID_USER)){
                        Managers[i].ItemsCount+=ItemsCount
                        Managers[i].AllOrdersPrice+=AllOrdersPrice
                        Managers[i].DraftsCount+=DraftsCount
                        Managers[i].DraftsPrice+=DraftsPrice
                        Managers[i].SendedCount+=SendedCount
                        Managers[i].SendedPrice+=SendedPrice
                        Managers[i].NewCount+=NewCount
                        Managers[i].NewPrice+=NewPrice
                    }
                    
                    Managers[i].ORDERS = [...Managers[i].ORDERS,...ORDERS]
                    
                    return {
                        ...clen,
                       
                        ORDERS,
                        ItemsCount,
                        AllOrdersPrice,
                        DraftsCount,
                        DraftsPrice,
                        SendedCount,
                        SendedPrice,
                        NewCount,
                        NewPrice,
                        is_replacement: c.ID_REPLACEMENT == m.ID,
                        ID_REPLACEMENT: c.ID_REPLACEMENT,
                        from_ID_MANAGER:m.ID,
                        manager_id:c.ID_USER,
                        
                    }
                } 
            })

            Managers[i].ordersByMonths = this.ordersByMonths(Managers[i].ORDERS.filter(o=>o.ID_STATUS==13 && o.MANAGER_READED==1))
            
        })


        Clients = Clients.filter(c=>ClientsIDs_haveManagers.findIndex(cihm => cihm==c.ID) == -1)
        
        this.setState({Managers,Clients})
    }

    remakeManagerRool(e,m,type='simple'){
        const {state,props} = this
        const {Managers} = state

        if(state.drawSceleton!=null) return

        const client = JSON.parse(e.client)

        let data = {}

        if(type=='remove'){
            if(client.manager_id==null) return
            data = {
                operation:'removeManagerRool',
                ID_CLIENT:client.ID,
            }
            this.setState({drawSceleton:-1,replacingClient:client})
        }else{
            if(client.from_ID_MANAGER==m.ID || (client.ID_REPLACEMENT == m.ID && client.is_replacement)) return
           
            this.setState({drawSceleton:m.ID,replacingClient:client})
            data = {
                operation:'remakeManagerRool',
                ID_CLIENT:client.ID,
            }
            
    
            if(client.is_replacement || type=='replacement')
                data.ID_MANAGER_REPLACEMENT = m.ID
            else
            data.ID_MANAGER = m.ID
    
    
            if(
                m.ID == client.manager_id && type =='simple' && client.is_replacement ||
                m.ID == client.ID_REPLACEMENT && type =='simple' && !client.is_replacement 
            ){
                data.ID_MANAGER_REPLACEMENT = -1
                data.ID_MANAGER = m.ID
            }
    
            
        }

        GF.API_task(data).then(res=>{
            this.props.getDB_Data_lazy('MANAGERS_ROOLES')
            setTimeout(()=>{
                this.componentDidMount()
                this.setState({drawSceleton:null,replacingClient:null})
            },1500)
        })
        
    }

    ordersByMonths(orders_){
        const {state,props} = this
        let orders = [...orders_]
        let months = []
        for(let month = 1;month<=12;month++){
            let countKarniz = 0
            let countOrders = 0
            let sumOrders = 0
            let otherOrders = []
            let date_1 = moment(`01.${month}.${state.year}`,'DD.M.YYYY')
            let date_2 = moment(`01.${month}.${state.year}`,'DD.M.YYYY')
            date_2.add(1,'M')
            
            
            orders.forEach(order=>{
                
                
                if(order.DATE_SENDED !=null && moment(order.DATE_SENDED).isBetween(
                    date_1,
                    date_2
                )){
                    order.ITEMS.forEach(item=>{
                        countKarniz+=item.ORDERS_COUNT
                    })
                    countOrders++
                    sumOrders+=order.ALL_COAST-order.TAX_COAST
                }else{
                    if(order.DATE_SENDED !=null)
                        otherOrders.push(order)
                }
            })

            orders = [...otherOrders]


            months.push({
                countKarniz,countOrders,sumOrders
            })
        }
        return months
    }

    

    render (){
        const {state,props} = this
        const {Managers,filter} = state
        if(Managers==null || Managers.length==0) return <div>
            <h3>Нет менеджеров</h3>
            <p>Нужно добавить их базе данных</p>
        </div>

        let filtered = Managers.filter(user=>filter==null || 
            user.FIO.toUpperCase().indexOf(filter)!=-1 ||
            user.EMAIL.toUpperCase().indexOf(filter)!=-1 ||
            user.PHONE.indexOf(filter)!=-1
        )

        const aUsersHaveNotManagers = props.USERS.filter(user=>
            (user.ROLE=='PRTN' || user.ROLE=='CPRT') && user.ID_MANAGER==null
        )

 

        return(<View className='noselect'>
                <div  style={{
                    display:'flex',
                    alignItems:'center',
                    gap:10
                }}>
                    <TextField
                        style={{width:250}}
                        className='m-0'
                        label='Поиск менеджера'
                        variant='filled'
                        size="small"
                        color='success'
                        
                        onKeyUp={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}
                    />

                <DatePicker
                    value={state.year+'-01-01'}
                    views={['year']}
                    renderInput={(params) => <TextField {...params} helperText={null} variant='filled'
                    size='small'
                    label='Год статистики' color='success'/>}
                    onChange={(value) => {
                        this.setState({year:moment(value).format('YYYY')*1},()=>this.componentDidMount())
                    }}
                />

                    <Button color='success' onClick={()=>this.props.openPopUp(<POP_User callBack={()=>this.componentDidMount()}  ROLE='MNGR'/>)}>Добавить менеджера</Button>



                    {aUsersHaveNotManagers.length==0?null:
                    <Chip color='error' label={`Есть ${aUsersHaveNotManagers.length} ${GF.okonchanie(aUsersHaveNotManagers.length,'клиент','клиента','клиентов')} без менеджера!`} onClick={()=>
                        this.props.openPopUp(<POP_UserSearch title='Выберите клиентов' callBack={selected_clen=>{
                            
                            this.props.openPopUp(<POP_UserSearch title='Выберите менеджера' callBack={selected_mng=>{
                                let promises = []
                                selected_clen.forEach(user=>{
                                    promises.push(GF.API_task({
                                        operation:'createManagerRoole',
                                        ID_USER:user.ID,
                                        ID_MANAGER:selected_mng[0].ID
                                    }))
                                })
                                Promise.allSettled(promises).finally(()=>{
                                    this.props.closePopUp()
                                    this.props.closePopUp()
                                    this.props.getUSERS(()=>this.componentDidMount())
                                })
                            }} isHiddenRule={user=>{
                                return user.ROLE!='MNGR' 
                            }} placeholder='Нет менеджеров'/>)

                        }} isHiddenRule={user=>{
                            
                            return props.MANAGERS_ROOLES.findIndex(rule=>rule.ID_RESPONS==user.ID)!=-1 || (user.ROLE!='PRTN' && user.ROLE!='СPRT')
                        }} multiple placeholder='Всем клиентам назначены менеджеры'/>)
                    }/>
                    }
                </div>


                <div style={{borderRadius:5,overflow:'hidden',marginTop:10,minHeight:400}}>
                    <ReactHighcharts config = {{
                        chart: {
                            type: 'spline',
                            scrollablePlotArea: {
                                minWidth: 600,
                                scrollPositionX: 1
                            }
                        },
                        title: {
                            text: 'Статистика за '+state.year+' год',
                            align: 'left'
                        },
                        subtitle: {
                            text: 'Группировка по менеджерам и согласованым ими заказам',
                            align: 'left'
                        },
                        xAxis: {
                            type: 'datetime',
                            categories: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
                                'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                            labels: {
                                overflow: 'justify'
                            }
                        },
                        yAxis: {
                            title: {
                                text: 'Количество карнизов согласовано'
                            },
                            minorGridLineWidth: 0,
                            gridLineWidth: 0,
                            alternateGridColor: null,
                        
                        },
                        tooltip: {
                            valueSuffix: 'шт',
                            crosshairs: true,
                            shared: true,
                        },
                        plotOptions: {
                            series: {
                                animation: false
                            },
                            line: {
                                animation: false
                            },
                            spline: {
                                
                                lineWidth: 4,
                                states: {
                                    hover: {
                                        lineWidth: 5
                                    }
                                },
                                marker: {
                                    enabled: false
                                },
                            
                            
                            }
                        },
                        series: [
                            ...filtered.map(user=>{
                                return({
                                    name: GF.FIO(user.FIO),
                                    data: user.ordersByMonths?.map(data=>data.countKarniz) || []
                                })
                            })
                        ],
                        navigation: {
                            menuItemStyle: {
                                fontSize: '10px'
                            }
                        }
                    }} domProps = {{id: 'chartId'}}/>
                </div>

                <table className='w-100 mt-3 mb-3 ' >
                    <thead>
                        <td style={{
                            textAlign:'left'
                        }}>Менеджер</td>
                        <td width='120px'>E-mail</td>
                        <td width='130px'>Телефон</td>
                        <td>Клиентов</td>
                        <td>Заменяет</td>
                        <td>Оформленных заказов</td>
                        <td>Карнизов</td>
                        <td>Новых</td>
                        <td>Сумма в оформлении</td>
                    </thead>
                    <tbody>
                    {filtered.map(user=>{

                        return(<tr key={'manager_'+user.ID_USER} className='partner' style={{
                            cursor:'pointer',
                            background:'white',
                            borderTop:'1px solid rgb(240,240,240)',
                        
                    }} onClick={()=>{
                        
                    }}>
                        <td style={{
                        textAlign:'left',
                        }} onClick={()=>this.props.openPopUp(<POP_User callBack={()=>this.componentDidMount()} ID_OBJECT={user.ID}/>)}>
                            <Chip style={{
                                margin:5,
                                cursor:'pointer',
                            }} label={GF.FIO(user.FIO)} avatar={<Avatar/>}/>
                        </td>
                        <td style={{textAlign:'right'}}><Button size='small' color='inherit' onClick={e=>{
                            e.stopPropagation()
                            navigator.clipboard.writeText(user.EMAIL)
                            this.props.addToast('Скопировано', { appearance: 'success',autoDismiss:true });
                        }}>{user.EMAIL}</Button></td>
                        <td><Button size='small' color='inherit' onClick={e=>{
                            e.stopPropagation()
                            navigator.clipboard.writeText('+'+user.PHONE)
                            this.props.addToast('Скопировано', { appearance: 'success',autoDismiss:true });
                        }}>{GF.phoneFormatter(user.PHONE)}</Button></td>
                        <td><Chip size='small' label={<>{
                            GF.makeCoast(
                                (user.CLIENTS.filter(c=>
                                    c.manager_id==user.ID
                                )
                            ).length)
                        }<small>чел</small></>}/></td>

                        <td><Chip size='small' label={<>{
                            GF.makeCoast(
                                (user.CLIENTS.filter(c=>
                                    c.ID_REPLACEMENT==user.ID
                                )
                            ).length)
                        }<small>чел</small></>}/></td>
                       
                        <td><Chip size='small' label={<>{GF.makeCoast(user.SendedCount)}<small>шт</small></>}/></td>
                        <td><Chip size='small' label={<>{GF.makeCoast(user.ItemsCount)}<small>шт</small></>}/></td>
                        <td><Chip size='small' label={<>{GF.makeCoast(user.NewCount)}<small>шт</small></>}/></td>
                        <td><Chip size='small' label={<>{GF.makeCoast(user.SendedPrice)}<small>руб</small></>}/></td>
                        
                    </tr>)
                    })}
                    </tbody>
                </table>



        </View>)
    }
}


const View = styled.div`
height:max-content;
padding-bottom:150px;
& table td{
  padding-top:0px;
  padding-bottom:0px;
  font-size:13px;
  text-align:center;
}
& table tbody tr{
  transition:.3s;
  transform:scale(1);
  cursor:pointer;
}
& table tbody tr.partner:hover{
  transform:scale(1.01);
}
& table tbody tr:hover{

  background:rgb(241, 241, 241);

}
& table .MuiButton-root.MuiButton-text {
    font-size: 11px;
}

& table thead td{
  color:rgb(120,120,120);
  font-size:11px !important;
  padding-bottom:5px;
}
`



const mapDispatchToProps = (dispatch) => {
    return {
        getUSERS:(arg)=>dispatch(getUSERS(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data_lazy:(arg,a,b)=>dispatch(getDB_Data_lazy(arg,a,b)),
    }
}


const mapStateToProps = (store) => {
   
    return {
        MANAGERS_ROOLES:store.page.MANAGERS_ROOLES,
        user: store.user.data,
        POPUPS:store.page.POPUPS,
        STATUSES:store.page.STATUSES,
        ORDERS:store.page.ORDERS,
        USERS:store.page.USERS,
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withToast(Index)))

