import { Avatar, Button, Chip, Stack, TextField } from '@mui/material'
import Immutable from 'immutable'
import React, { memo } from 'react'
import { useState } from 'react'
import { useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getManagerInstallersRooles } from '../../../../../actions/InstallSchedule'
import { closePopUp, openPopUp } from '../../../../../actions/PopUpActions'
import { GF } from '../../../../../GlobalFunctions'
import useUsersMNGR from '../../../../../Hooks/Data/USERS/useUsersMNGR'
import POP_User, { UserDataContext } from '../POP_User'
import POP_Alert from '../../../../PopUp/Views/POP_Alert'
import POP_UserSearch from '../POP_UserSearch'
import StackHorizontal from '../../../../components/StackHorizontal'
import { useContext } from 'react'
import useUsersINST_byRooles from '../../../../../Hooks/Data/USERS/useUsersINST_byRooles'
import { Label } from '../../../../components/moduls/Input'
import Debounce from '../../../../../Class/Debounce'



export default function PopUser_MNGR_installers() {
    const [filter, setFilter] = useState(null)
    const { Self } = useContext(UserDataContext)
    const INST_rooles = useUsersINST_byRooles(Self.ID)
    const [toDeleteManagersID, setToDeleteManagersID] = useState([])
    const [thisUserManagers_filtered, setThisUserManagers_filtered] = useState([])
    const dispatch = useDispatch()


    useLayoutEffect(() => {
        if (filter == null) {
            setThisUserManagers_filtered(INST_rooles)
        } else {
            console.error('filter', filter)
            setThisUserManagers_filtered(
                INST_rooles.filter(user =>
                    user.FIO?.toUpperCase()?.indexOf(filter) >= 0
                )
            )
        }
    }, [INST_rooles, filter])


    function removeINST(user) {
        Debounce.start(() => {
            setToDeleteManagersID(prev => ([...prev, user.ID]))
            GF.API_task({
                operation: 'deleteManagerInstRoole',
                ID_MNGR: Self.ID,
                ID_INST: user.ID,
                block: 'InstallSchedule'
            }).then(() => {
                dispatch(getManagerInstallersRooles())
            })
        })
    }

    return (<View>
        <StackHorizontal>
            <TextField
                style={{ width: '100%', flex: 1 }}
                className='m-0 w-100'
                label='Поиск монтажника'
                variant='filled'
                size="small"
                color='success'
                onChange={(e) => setFilter(e.target.value.length > 0 ? e.target.value.toUpperCase() : null)}
            />

            <Button key='ActionButton' color='success'
                style={{ width: 100 }}
                onClick={e => {
                    setToDeleteManagersID([])
                    dispatch(openPopUp(<POP_UserSearch title='Выберите монтажников'
                        callBack={selected => {
                            dispatch(closePopUp())
                            GF.API_task({
                                operation: 'createManagerInstRooles',
                                ID_MNGR: Self.ID,
                                ID_INST_arr: selected.map(usr => usr.ID),
                                block: 'InstallSchedule'
                            }).then(() => {
                                dispatch(getManagerInstallersRooles())
                            })
                        }} isHiddenRule={user => {
                            return !(user.ROLE == 'INST' && INST_rooles.findIndex(usr => usr.ID == user.ID) == -1)
                        }} multiple placeholder='Нет доступных монтажников' />))
                }}
                disabled={Self.ID == null}>
                Добавить
            </Button>
        </StackHorizontal>
        <Label>Работает со следующими монтажниками</Label>
        <Stack direction='row' flexWrap='wrap' sx={{ mb: 2 }}>
            {INST_rooles.length > 0 ? null : <small>Монтажники не назначены</small>}
            {thisUserManagers_filtered.map(user => {

                return (<Chip key={`mngr_${user.ID}`}
                    avatar={<Avatar />}
                    label={user.FIO}
                    style={{ marginBottom: 2, width: '100%', justifyContent: "space-between" }}
                    color={toDeleteManagersID.includes(user.ID) ? 'warning' : 'default'}
                    onDelete={() => removeINST(user)}
                    onClick={() => dispatch(openPopUp(<POP_User ID_OBJECT={user.ID} />))} />)
            })}
        </Stack>
    </View >)
}

const View = styled.div`
& .MuiChip-label.MuiChip-labelMedium{
flex:1;
}
`