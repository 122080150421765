import { DatePicker } from '@mui/lab'
import { Avatar, Box, Button, Chip, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import Immutable from 'immutable'
import moment from 'moment'
import React, { useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { createSelector } from "reselect"
import { GF } from '../../../GlobalFunctions'
import useIsManagerOrAdmin from '../../../Hooks/useIsManagerOrAdmin'
import Pallet from '../../../Pallet'
import { InstallSchedule_getDates, createInstallScheduleItem, deleteFromInstallScheduleItems, getInstallSchedule, updateInstallScheduleItems } from '../../../actions/InstallSchedule'
import { closePopUp, openPopUp } from '../../../actions/PopUpActions'
import { hours24, minutes15 } from '../../../constants'
import { POPbottom, POPscroll, POPtop } from '../../PopUp'
import FlatCard from '../../components/FlatCard'
import StackHorizontal from '../../components/StackHorizontal'
import POP_User from '../DataBase/pages/POP_User'
import POP_Order from '../Orders/POP_Order'

import { isEqual } from 'lodash'
import Debounce from '../../../Class/Debounce'
import useDebounce from '../../../Hooks/useDebounce'
import POP_Alert from '../../PopUp/Views/POP_Alert'
import POP_UserSearch from '../DataBase/pages/POP_UserSearch'
import POP_OrdersSearch from '../Orders/POP_OrdersSearch'
import POP_CanselReson from './POP_CanselReson'

import makeUrls from 'add-event-to-calendar'
import useUsersClients from '../../../Hooks/Data/USERS/useUsersClients'
import useUsersINST from '../../../Hooks/Data/USERS/useUsersINST'
import POP_SetComplitedInstall from './POP_SetComplitedInstall'


const form_fields = [
    {
        label: 'Название клиента',
        name: 'CLIENT_TITLE'
    },
    {
        label: 'Имя клиента',
        name: 'CLIENT_FIO'
    },
    {
        label: 'Номер телефона клиента',
        name: 'CLIENT_PHONE',
        type: 'phonenumber',
        placeholder: '+7',
    },

    { hLine: true },

    {
        label: 'Адрес',
        name: 'ADDRESS'
    },
    {
        label: 'Комментарий к адресу',
        placeholder: 'Подъезд, этаж, квартира, схема проезда',
        name: 'ADDRESS_DESCR'
    },
    {
        label: 'Комметарий',
        name: 'COMMENT',
        multiline: true,
        rows: 4
    },
]





const selectStatusDraft = createSelector(
    (state) => state.page.STATUSES,
    (STATUSES) => STATUSES.find((status) => status.ID == 23)
)



/*
SelfData - это начальные параметры для Self
ID_OBJECT - ID объекта
callBack - устаревшая но нужная функция для обновления данных в class components или дргуих моментах. вызывается при изменении данных этого элмента расписания (удаление/сохранение/создание/...)
*/
export default function POP_InstallSchedule({ ID_OBJECT, SelfData, callBack }) {

    const InstallSchedule = useSelector(state => state.page.InstallSchedule)
    const selfUser = useSelector(state => state.user.data)
    const managerInstallersRooles = useSelector(state => state.page.ManagerInstallersRooles)
    const [isUserInstallSaved, setIsUserInstallSaved] = useState(false)

    const dispatch = useDispatch()

    const UsersINST = useUsersINST()
    const UsersClients = useUsersClients()
    const StatusDraft = useSelector(selectStatusDraft)

    const ORDERS = useSelector(state => state.page.ORDERS)

    const [userInstaller, setUserInstaller] = useState(null)
    const [orderToInstall, setOrderToInstall] = useState(null)



    const [Self, setSelf] = useState({
        timeStart: {
            h: 0,
            m: 0
        },
        timeEnd: {
            h: 23,
            m: 0
        },
        DATE_INSTALL: moment().format('YYYY-MM-DD'),
        TIME_END: '23:00:00',
        TIME_START: '00:00:00',

        ...SelfData, //не передедавать сам объект, только настройки, например ID_ORDER или ID_USER_INSTALL


        ID_STATUS: StatusDraft.ID,
        STATUS_TITLE: StatusDraft.TITLE,
        STATUS_COLOR: StatusDraft.COLOR,
    })
    const [SelfDefault, setSelfDefault] = useState({})

    const [disabled, setDisabled] = useState(true)

    const [fieldErrors, setFieldErrors] = useState({})

    const isManagerOrAdmin = useIsManagerOrAdmin()
    const Debounce_Save = useDebounce()


    const toasts = useToasts()



    useLayoutEffect(() => {
        setDisabled(!isManagerOrAdmin || Self.ID_STATUS == 21 || Self.ID_STATUS == 22 || Self.ID_STATUS == null)
    }, [Self, isManagerOrAdmin])

    useLayoutEffect(() => {
        if (ID_OBJECT != null && InstallSchedule != null) {
            let newSelf = InstallSchedule.find(s => s.ID == ID_OBJECT) || {}

            newSelf = Immutable.fromJS(newSelf)?.toJS()

            if (Self?.ID != null) {
                //Если кно уже было открыто и произошло обновление из вне
                if (isEqual(SelfDefault, newSelf)) { //если объекты идентичны по значениям а не по ссылке, но обновлять ничего не нужно
                    return
                }

            }

            if (newSelf.ID_USER_INSTALL != null)
                setIsUserInstallSaved(true)
            setSelf(newSelf)
            setSelfDefault(Immutable.fromJS(newSelf).toJS())
        }
    }, [InstallSchedule, ID_OBJECT])

    useLayoutEffect(() => {
        if (Self.ID_USER_INSTALL == null && userInstaller != null) {
            setUserInstaller(null)
        } else {
            if (Self.ID_USER_INSTALL != null) {
                const installer = UsersINST.find(user => user.ID == Self.ID_USER_INSTALL)
                if (userInstaller == null || installer?.ID != userInstaller?.ID) {
                    setUserInstaller(installer)
                }
            }
        }

    }, [Self, UsersINST])

    useLayoutEffect(() => {

        if (Self.ID_ORDER == null && orderToInstall != null)
            setOrderToInstall(null)
        else {

            if (Self.ID_ORDER != null) {
                let order = ORDERS.find(order => order.ID == Self.ID_ORDER)
                if (order == null)
                    setOrderToInstall(null)
                else if (orderToInstall == null || order?.ID != orderToInstall?.ID) {
                    order = Immutable.fromJS(order).toJS()

                    let orderUser = UsersClients.find(user => user.ID == order.ID_USER)
                    orderUser = Immutable.fromJS(order).toJS()
                    order.USER = orderUser
                    setOrderToInstall(order)
                }
            }
        }

    }, [Self, ORDERS, UsersClients])

    function HaveFieldErrors() {
        let errors = {}

        if (!(Self.ADDRESS?.length > 3)) errors.ADDRESS = true
        if (!(Self.ADDRESS_DESCR?.length > 2)) errors.ADDRESS_DESCR = true

        if (!(Self.DATE_INSTALL != null)) errors.DATE_INSTALL = true

        if (!(Self.timeStart?.h != null)) errors.timeStartH = true
        if (!(Self.timeStart?.m != null)) errors.timeStartM = true

        if (!(Self.timeEnd?.h != null)) errors.timeEndH = true
        if (!(Self.timeEnd?.m != null)) errors.timeEndM = true


        console.error('errors', errors)

        setFieldErrors(errors)
        if (Object.keys(errors).length > 0) return true
        else return false
    }

    function Save() {
        Debounce_Save.start(() => {
            if (HaveFieldErrors()) {
                toasts.addToast('Заполните поля корректно', { appearance: 'error', autoDismiss: true })
                return
            }
            setSelfDefault(Self)

            console.warn('Saveing', Self)

            if (Self.ID != null) { //update
                dispatch(updateInstallScheduleItems([Self], () => {
                    toasts.addToast('Изменения сохранены', { appearance: 'success', autoDismiss: true })
                    !!callBack && callBack()
                }))
            } else { //create
                dispatch(createInstallScheduleItem(Self, (created_item) => {
                    toasts.addToast('Задача сохраненена', { appearance: 'success', autoDismiss: true })
                    setSelfDefault(created_item)
                    setSelf(created_item)
                    !!callBack && callBack()
                }))
            }

            if (!isUserInstallSaved)
                setIsUserInstallSaved(true)

        }, 3000)
    }

    function Delete() {
        if (Self.ID == null) {
            dispatch(closePopUp())
        } else {
            new Promise((resolve, reject) => {
                dispatch(openPopUp(<POP_Alert text={'Вы уверены, что хотите удалить эту запись?'} buttons={[
                    <Button color='error' onClick={() =>
                        resolve()
                    }>Да, удалить</Button>,
                    <Button onClick={() =>
                        reject()
                    }>Отмена</Button>,
                ]} />))
            }).then(() => {
                dispatch(closePopUp())
                dispatch(deleteFromInstallScheduleItems([Self.ID], () => {
                    toasts.addToast('Удалено', { appearance: 'error', autoDismiss: true })
                    callBack()
                }))
            }).finally(() => {
                dispatch(closePopUp())
            })
        }
    }


    function makeTwoZeros(time) {
        if (Number(time) < 10) return '0' + time
        else return time + ''
    }

    function changeTime(timeKey, timeVariant, value) {
        setSelf((prev) => {

            let newSelf = Immutable.fromJS(prev).toJS()
            value = value * 1

            newSelf[timeKey][timeVariant] = value

            let timeStart = newSelf['timeStart'].h * 100 + newSelf['timeStart'].m
            let timeEnd = newSelf['timeEnd'].h * 100 + newSelf['timeEnd'].m

            if (timeStart > timeEnd) {
                let otherTime = timeKey == 'timeStart' ? 'timeEnd' : 'timeStart'
                newSelf[timeKey].h = newSelf[otherTime].h
                newSelf[timeKey].m = newSelf[otherTime].m
            }

            if (newSelf.DATE_INSTALL == null)
                newSelf.DATE_INSTALL = moment().format('YYYY-MM-DD')
            newSelf.timeStart.h = newSelf.timeStart.h || 0
            newSelf.timeStart.m = newSelf.timeStart.m || 0
            newSelf.timeEnd.h = newSelf.timeEnd.h || 23
            newSelf.timeEnd.m = newSelf.timeEnd.m || 0


            newSelf['TIME_START'] = `${makeTwoZeros(newSelf.timeStart.h)}:${makeTwoZeros(newSelf.timeStart.m)}:00`
            newSelf['TIME_END'] = `${makeTwoZeros(newSelf.timeEnd.h)}:${makeTwoZeros(newSelf.timeEnd.m)}:00`


            newSelf = {
                ...newSelf,
                ...InstallSchedule_getDates(newSelf)
            }

            return newSelf
        })
    }

    function SelectUserInst() {
        dispatch(openPopUp(<POP_UserSearch
            title='Выберите монтажника'
            selected={[{ ID: Self.ID_USER_INSTALL }]}
            callBack={selected => {
                dispatch(closePopUp())
                setSelf(prev => ({
                    ...prev,
                    ID_USER_INSTALL: selected[0].ID
                }))
                setIsUserInstallSaved(false)
            }} isHiddenRule={user => {
                return !(
                    user.ROLE == 'INST'
                )
            }} placeholder='Нет монтажников' />
        )
        )
    }

    function SelectOrder() {
        dispatch(openPopUp(<POP_OrdersSearch
            multyple={false}
            isHiddenRule={(order) =>
                Self.ID_ORDER != order.ID && SelfDefault.ID_ORDER != order.ID &&
                InstallSchedule.findIndex(schedule => schedule.ID_ORDER == order.ID) != -1
            }
            selected={[{ ID: Self.ID_ORDER }]}
            callBack={selected => {
                dispatch(closePopUp())
                setSelf(prev => ({
                    ...prev,
                    ID_ORDER: selected[0].ID
                }))
            }} />
        )
        )
    }

    function SetToInstaller() {
        Debounce.start(() => {
            if (Self.ID == null) {
                toasts.addToast('Перед назначением, сохраните задачу', { appearance: 'error', autoDismiss: true })
                return
            }

            GF.API_task({
                operation: 'setToInstaller',
                block: 'InstallSchedule',
                ID_arr: [Self.ID]
            }).then(data => {
                toasts.addToast('Добавлено в расписание монтажника', { appearance: 'success', autoDismiss: true })
                dispatch(getInstallSchedule(callBack))

            })
        })
    }

    function BackToDraft() {
        Debounce.start(() => {
            GF.API_task({
                operation: 'backToDraft',
                block: 'InstallSchedule',
                ID_arr: [Self.ID]
            }).then(data => {
                toasts.addToast('Удалено из расписания монтажника', { appearance: 'success', autoDismiss: true })
                dispatch(getInstallSchedule(callBack))
            })
        })
    }

    function SetCansel_inst() {
        return new Promise((resolve, reject) => {
            dispatch(openPopUp(<POP_CanselReson
                onAccept={resolve}
                onCansel={reject}
            />))
        })
    }

    function SetCansel() {
        Debounce.start(() => {

            if (selfUser.ROLE == 'INST')
                SetCansel_inst().then((PROBLEM_DESCR) => {
                    GF.API_task({
                        operation: 'setCansel_INST',
                        block: 'InstallSchedule',
                        PROBLEM_DESCR,
                        ID_arr: [Self.ID]
                    }).then(data => {
                        toasts.addToast('Вы отменили установку', { appearance: 'error', autoDismiss: true })
                        dispatch(getInstallSchedule(callBack))
                    })
                })
            else {
                GF.API_task({
                    operation: 'setCansel',
                    block: 'InstallSchedule',
                    ID_arr: [Self.ID]
                }).then(data => {
                    toasts.addToast('Установка отменена', { appearance: 'error', autoDismiss: true })
                    dispatch(getInstallSchedule(callBack))
                })
            }

        })
    }

    function SetAccepted() {
        Debounce.start(() => {
            GF.API_task({
                operation: 'setApprove_INST',
                block: 'InstallSchedule',
                ID_arr: [Self.ID]
            }).then(data => {
                toasts.addToast('Вы подтвердили установку на ' + Self.dateTimeStart.format('DD MMMM'),
                    { appearance: 'success', autoDismiss: true })
                dispatch(getInstallSchedule(callBack))
            })
        })
    }

    function SetComplited() {
        if (Self.PRICE === '' || Self.PRICE === null) {

            setFieldErrors(prev => ({ ...prev, PRICE: true }))
            return
        } else setFieldErrors(prev => ({ ...prev, PRICE: false }))

        dispatch(openPopUp(<POP_SetComplitedInstall
            onAccept={() => {
                GF.API_task({
                    operation: 'setComplited_INST',
                    block: 'InstallSchedule',
                    ID_arr: [Self.ID],
                    PRICE: Self.PRICE * 1
                }).then(data => {
                    toasts.addToast('Установка помечена выполненной',
                        { appearance: 'success', autoDismiss: true })
                    dispatch(getInstallSchedule(callBack))
                })
            }}
        />))
    }

    function AddToCalendar() {
        Debounce.start(() => {
            const getCalendarEvent = () => ({
                name: `ПрофКарниз - монтаж заказа ${orderToInstall.GUID || ''}`,
                location: Self.ADDRESS,
                details: `${Self.ADDRESS_DESCR}\n${Self.COMMENT}`,
                startsAt: Self.dateTimeStart.format('YYYY-MM-DDTHH:mm:ss') + '+03:00',
                endsAt: Self.dateTimeEnd.format('YYYY-MM-DDTHH:mm:ss') + '+03:00',
            });

            const eventUrls = makeUrls(getCalendarEvent())
            const openTab = (url) => {
                window.open(url, '_blank').focus()
            }
            dispatch(openPopUp(<POP_Alert text='Добавить событие в ваш личный календарь'
                content={<StackHorizontal spacing={1}>
                    <Button size='small' onClick={() => openTab(eventUrls.google)}>Google</Button>
                    <Button size='small' onClick={() => openTab(eventUrls.outlook)}>Outlook</Button>
                    <Button size='small' onClick={() => openTab(eventUrls.yahoo)}>Yahoo</Button>
                    <Button size='small' onClick={() => openTab(eventUrls.ics)}>Apple</Button>
                </StackHorizontal>}
                buttons={<Button color='success' onClick={() => dispatch(closePopUp())}>Закрыть</Button>} />
            ))
        })
    }

    if (Self.ID == null && ID_OBJECT != null)
        return (<div />)

    return (<div style={{
        width: 480
    }}>
        <POPtop>
            <h5 className='m-0'>Запрос на установку {Self.ID == null ? '' : `#${Self.ID}`}</h5>
            <Chip size='small' className='me-3 ' style={{ background: Self.STATUS_COLOR, color: Pallet.White }} label={Self.STATUS_TITLE} />
        </POPtop>
        <POPscroll bottom top>
            {(Self.ID_STATUS == 20 || Self.ID_STATUS == 22) && <>
                <TextField
                    disabled={selfUser.ROLE != 'INST' || Self.ID_STATUS == 22}
                    className='w-100 mt-1'
                    margin='dense'
                    variant="filled"
                    size='small'
                    color='success'
                    error={fieldErrors.PRICE === true}
                    label='Стоимость работ (руб)'
                    value={Self.PRICE}
                    onChange={(e) => {
                        let text = e.target.value
                        if (text.length == 0)
                            setFieldErrors(prev => ({
                                ...prev,
                                PRICE: true
                            }))
                        else
                            setFieldErrors(prev => ({
                                ...prev,
                                PRICE: false
                            }))

                        setSelf((prev) => {
                            if (prev == null) return
                            let newSelf = Immutable.fromJS(prev).toJS()
                            newSelf.PRICE = text == '' ? '' : text * 1
                            return newSelf
                        })

                    }}
                />

                <hr />
            </>}



            {form_fields.map(input =>
                input.hLine === true ?
                    <hr /> :
                    <TextField
                        disabled={disabled}
                        className='w-100 mt-1'
                        margin='dense'
                        variant="filled"
                        size='small'
                        color='success'
                        error={fieldErrors[input.name] === true}
                        {...input}
                        value={Self[input.name]}
                        onChange={(e) => {
                            if (fieldErrors[input.name] === true)
                                setFieldErrors(prev => {
                                    let newErrors = Immutable.fromJS(prev).toJS()
                                    newErrors[input.name] = false
                                    return newErrors
                                })

                            setSelf((prev) => {
                                let newSelf = Immutable.fromJS(prev).toJS()
                                newSelf[input.name] = e.target.value
                                return newSelf
                            })
                        }}
                    />
            )}

            <hr className='mb-1' />

            <small className='mb-1 d-block mt-0'>Дата и время установки по UTC+3 MSC</small>
            <DatePicker

                mask="__.__.____"
                label="Дата установки"
                disabled={disabled}
                value={Self.DATE_INSTALL}
                error={fieldErrors['DATE_INSTALL'] === true}
                onChange={(newValue) => {
                    if (fieldErrors['DATE_INSTALL'] === true)
                        setFieldErrors(prev => {
                            let newErrors = Immutable.fromJS(prev).toJS()
                            newErrors['DATE_INSTALL'] = false
                            return newErrors
                        })

                    setSelf((prev) => {
                        let newSelf = Immutable.fromJS(prev).toJS()
                        newSelf['DATE_INSTALL'] = moment(newValue).format('YYYY-MM-DD')
                        return {
                            ...newSelf,
                            ...InstallSchedule_getDates(newSelf)
                        }
                    })
                }}
                renderInput={(params) => <TextField {...params} size='small' color='success'
                    className='w-100' variant='filled' />}
            />


            <StackHorizontal spacing={2}>
                <Box sx={{ flex: 1 }}>
                    <small className='mb-1 d-block'>Начало</small>
                    <Stack direction="row" spacing={2}>
                        <FormControl fullWidth size='small' color='success' variant='filled'>
                            <InputLabel>Час</InputLabel>
                            <Select
                                error={fieldErrors['timeStartH'] === true}
                                disabled={disabled}
                                value={Self.timeStart?.h || 0}
                                label="Час"
                                onChange={(e) => {
                                    if (fieldErrors['timeStartH'] === true)
                                        setFieldErrors(prev => {
                                            let newErrors = Immutable.fromJS(prev).toJS()
                                            newErrors['timeStartH'] = false
                                            return newErrors
                                        })
                                    changeTime('timeStart', 'h', e.target.value)
                                }}
                            >
                                {hours24.map(h =>
                                    <MenuItem value={h} disabled={Self.timeEnd > 0 && Self.timeEnd?.h < h}>{h}</MenuItem>
                                )}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth size='small' color='success' variant='filled'>
                            <InputLabel>Минут</InputLabel>
                            <Select
                                error={fieldErrors['timeStartM'] === true}
                                value={Self.timeStart?.m || 0}
                                disabled={disabled}
                                label="Минут"
                                onChange={(e) => {
                                    if (fieldErrors['timeStartM'] === true)
                                        setFieldErrors(prev => {
                                            let newErrors = Immutable.fromJS(prev).toJS()
                                            newErrors['timeStartM'] = false
                                            return newErrors
                                        })
                                    changeTime('timeStart', 'm', e.target.value)
                                }}
                            >
                                {minutes15.map(m =>
                                    <MenuItem value={m} disabled={
                                        Self.timeEnd?.h > 0 &&
                                        Self.timeStart?.h * 100 + m >
                                        Self.timeEnd?.h * 100 + Self.timeEnd?.m
                                    }>{m}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Stack>
                </Box>

                <Box sx={{ flex: 1 }}>
                    <small className='mb-1 d-block'>Завершение</small>
                    <Stack direction="row" spacing={2}>
                        <FormControl fullWidth size='small' color='success' variant='filled'>
                            <InputLabel>Час</InputLabel>
                            <Select
                                error={fieldErrors['timeEndH'] === true}
                                disabled={disabled}
                                value={Self.timeEnd?.h || 23}
                                label="Час"
                                onChange={(e) => {
                                    if (fieldErrors['timeEndH'] === true)
                                        setFieldErrors(prev => {
                                            let newErrors = Immutable.fromJS(prev).toJS()
                                            newErrors['timeEndH'] = false
                                            return newErrors
                                        })
                                    changeTime('timeEnd', 'h', e.target.value)
                                }}
                            >
                                {hours24.map(h =>
                                    <MenuItem value={h} disabled={Self.timeStart?.h > 0 && Self.timeStart?.h > h}>{h}</MenuItem>
                                )}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth size='small' color='success' variant='filled'>
                            <InputLabel>Минут</InputLabel>
                            <Select
                                error={fieldErrors['timeEndM'] === true}
                                disabled={disabled}
                                size='small'
                                value={Self.timeEnd?.m || 0}
                                label="Минут"
                                onChange={(e) => {
                                    if (fieldErrors['timeEndM'] === true)
                                        setFieldErrors(prev => {
                                            let newErrors = Immutable.fromJS(prev).toJS()
                                            newErrors['timeEndM'] = false
                                            return newErrors
                                        })
                                    changeTime('timeEnd', 'm', e.target.value)
                                }}
                            >
                                {minutes15.map(m =>
                                    <MenuItem value={m} disabled={
                                        Self.timeStart?.h > 0 &&
                                        Self.timeStart?.h * 100 + Self.timeStart?.m >
                                        Self.timeEnd?.h * 100 + m
                                    }>{m}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Stack>
                </Box>
            </StackHorizontal>

            <hr className='' />

            <StackHorizontal spacing={2}>

                {Self.ID_STATUS != 23 && Self.ID_ORDER == null ? null :
                    <Stack style={{ flex: 1 }}>
                        {!isManagerOrAdmin || Self.ID_STATUS != 23 ?
                            <small>Заказ на установку</small>
                            :
                            <Button sx={{ mb: 1 }} style={{ maxHeight: 35 }} color='success' onClick={SelectOrder} >Прикрепить заказ</Button>
                        }


                        {orderToInstall == null && Self.ID_ORDER == null ? null :
                            Self.ID_ORDER != null && orderToInstall == null ?
                                <small>Заказ не найден</small>
                                :
                                <FlatCard style={{ maxWidth: '100%' }}>
                                    <StackHorizontal >
                                        <Chip size='small' color='success' label={orderToInstall.GUID}
                                            onClick={() => dispatch(openPopUp(<POP_Order ID_OBJECT={orderToInstall.ID} />))} />
                                        <small className='d-block m-0'>от {moment(orderToInstall.DATE_SENDED).format('D MMM YYYY')}</small>
                                    </StackHorizontal>
                                    {!!orderToInstall.USER && <>
                                        <hr />
                                        <Chip size='small' avatar={<Avatar />} label={
                                            selfUser.ROLE == 'INST' ?
                                                orderToInstall.USER.FIO :
                                                orderToInstall.USER.FIO.length > 20 ?
                                                    GF.FIO(orderToInstall.USER.FIO, 2) :
                                                    GF.FIO(orderToInstall.USER.FIO, 3)
                                        } onClick={() => dispatch(openPopUp(<POP_User ID_OBJECT={orderToInstall.ID_USER} />))} />
                                    </>}
                                </FlatCard>
                        }
                    </Stack>
                }

                {!isManagerOrAdmin ? null :
                    <Stack style={{ flex: 1 }}>
                        {Self.ID_STATUS != 23 ?
                            <small>Ответственный монтажник</small>
                            :
                            <Button sx={{ mb: 1 }} style={{ maxHeight: 35 }} color='success' onClick={SelectUserInst} >Выбрать монтажника</Button>}

                        {userInstaller == null && Self.ID_USER_INSTALL == null ? null :
                            Self.ID_USER_INSTALL != null && userInstaller == null ?
                                <small>Монтажник не найден</small>
                                :
                                <FlatCard style={{ maxWidth: '100%' }}>
                                    <StackHorizontal >
                                        <Chip avatar={<Avatar />} size='small' label={
                                            userInstaller?.FIO?.length > 22 ?
                                                GF.FIO(userInstaller.FIO, 2) :
                                                GF.FIO(userInstaller.FIO, 3)
                                        }
                                            onClick={() => dispatch(openPopUp(<POP_User ID_OBJECT={userInstaller.ID} />))}
                                            onDelete={
                                                Self.ID_STATUS == 23 ? () => {
                                                    setSelf(prev => ({
                                                        ...prev,
                                                        ID_USER_INSTALL: null
                                                    }))
                                                    setSelfDefault(prev => ({
                                                        ...prev,
                                                        ID_USER_INSTALL: null
                                                    }))
                                                } : null
                                            }
                                        />
                                    </StackHorizontal>
                                </FlatCard>
                        }
                    </Stack>
                }

            </StackHorizontal>

            {Self.PROBLEM_DESCR == null || Self.ID_STATUS != 21 ? null :
                <FlatCard style={{ maxWidth: '100%' }} className="mt-3">
                    <Chip size='small' color='error' label='Причина отмены' />
                    <p className='d-block m-0' style={{ fontSize: 14 }}>{Self.PROBLEM_DESCR}</p>
                </FlatCard>
            }




        </POPscroll>
        <POPbottom>
            {selfUser.ROLE == 'INST' ? <>
                {Self.ID_STATUS == 19 || Self.ID_STATUS == 20 ?
                    <Button color='error' onClick={SetCansel}>Отменить</Button>
                    : null}

                {Self.ID_STATUS == 19 ?
                    <Button color='success' onClick={SetAccepted}>Принять</Button> :
                    Self.ID_STATUS == 20 ?
                        <Button color='success' onClick={SetComplited}>Выполнен</Button> :
                        null
                }
            </> : isManagerOrAdmin ? <>
                <Stack direction="row" spacing={2}>
                    {Self.ID_STATUS == 19 || Self.ID_STATUS == 20 || Self.ID_STATUS == 23 || Self.ID_STATUS == 21 ?//Черновик/принят/назначен
                        Self.ID_STATUS == 23 || Self.ID_STATUS == 21 ? //черноыик/отменена
                            <Button color='error' onClick={Delete}>Удалить</Button> :
                            <Button color='error' onClick={SetCansel}>Отменить</Button>
                        : null}
                    {Self.ID_STATUS == 19 ?//Назначен
                        <Button color='error' onClick={BackToDraft}>Отозвать</Button> : null
                    }
                </Stack>

                <Stack direction="row" spacing={2}>
                    {Self.ID_STATUS == 23 ?//Черновик
                        <Button color='success' disabled={Self.ID_USER_INSTALL == null || !isUserInstallSaved} onClick={SetToInstaller}>Назначить</Button> : null
                    }
                    {Self.ID_STATUS == 23 || Self.ID_STATUS == 19 ?//Черновик/назначен
                        <Button color='success' onClick={Save} disabled={!Debounce_Save.canStart}>Сохранить</Button> : null
                    }
                    {Self.ID_STATUS == 21 ?//отменена
                        <Button color='warning' onClick={BackToDraft}>Вернуть к черновику</Button> : null
                    }
                </Stack>
            </> : null}
        </POPbottom>

    </div>)
}