
import { Check, Delete } from '@mui/icons-material';
import { Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Select, TextField, Tooltip, Typography,Grid, ListItem, IconButton, ListItemButton,Menu, Switch, FormGroup, FormControlLabel, Alert } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getDB_Data,getDB_Data_lazy,getUSERS,getCHATS } from '../../../actions/PageActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'

import InputMask from 'react-input-mask';
import Question from '../../PopUp/Views/Question'
import { Label } from '../../components/moduls/Input';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SwitchCheck from '../../components/SwitchCheck';
import ComboBox from '../../components/moduls/ComboBox';
import Table from '../../components/Table';
import POP_selectReplacement from './POP_selectReplacement';


const Rows = [

    {
        title:'Клиент',
        key:'CLIENT'
    },
    {
        title:'Сейчас замещает',
        key:'REPLACEMENT',
    },
    
  ]


class POP_manager extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
            Self:{
                CLIENTS:[]
            },
            filter:null,
            selectedRows:[],
            anchorEl:null
        }
        this.input=[]
        this.can=true
    }

    

    componentDidMount() {
        const {state,props} = this
        const {ID_OBJECT,NEW_PARTNERS} = props

        var Self = props.USERS.find(p=>p.ID==ID_OBJECT)
        if(Self==null) return
        Self = {...{...Self}}


        let Clients = GF.ObjectCopy(props.USERS.filter(u=>u.ROLE=='PRTN' || u.ROLE=='СPRT'))
        
        let ClientsIDs_haveManagers = []

        
            Self.CLIENTS=props.MANAGERS_ROOLES.filter(mr=>mr.ID_USER == Self.ID || mr.ID_REPLACEMENT == Self.ID)
            Self.CLIENTS.forEach((c,i)=>{
                let clen = Clients.find(u=>u.ID==c.ID_RESPONS)
                if(clen!=null){
                    ClientsIDs_haveManagers.push(clen.ID)
                    
                    Self.CLIENTS[i]={
                        ...clen,
                        is_replacement: c.ID_REPLACEMENT == Self.ID,
                        REPLACEMENT: c.ID_REPLACEMENT == null ? null : props.USERS.find(u=>u.ID==c.ID_REPLACEMENT),
                        from_ID_MANAGER:Self.ID,
                        manager_id:c.ID_USER,
                        
                    }
                } else {
                    delete(Self.CLIENTS[i])
                }  
            })

           
            
        


       
            
            this.setState({Self})
        
    }

    makeOperation(operation){
        let func = (remakeManagerID)=>{
            GF.API_task({
                operation,
                ID_REPLACEMENT:remakeManagerID,
                ID_MANAGER:this.props.ID_OBJECT,
                CLIENTS_ID:'('+this.state.selectedRows.join(',')+')'
            }).then(data=>{
                this.setState({selectedRows:[]})
                this.props.getDB_Data_lazy('MANAGERS_ROOLES')
                setTimeout(()=>{
                    this.props.callBack()
                    this.componentDidMount()
                },500)
                
            })
        }

        if(operation=='replaceAllClients'){
            this.props.openPopUp(<POP_selectReplacement CurrentManager={this.state.Self} callBack={(remakeManager)=>func(remakeManager.ID)}/>)
        }else{
            func()
        }

        
    }
 

    render (){
        const {state,props} = this
        const {NEW_PARTNERS,ID_OBJECT} = props
        const {Self,anchorEl,selectedRows,filter} = state
        const open = Boolean(anchorEl)

        
        
       
        if(ID_OBJECT!=null && Self.ID==null) return <div/>
     
        let Users_filter = Self.CLIENTS.filter(c=>
            filter==null || c.FIO.toUpperCase().indexOf(filter)!=-1
        )
        debugger

        return (
            <POP>
                <small>Менеджер  <Chip size='small' label={Self.LOGIN}/></small>
                <p className='m-0 '>{Self.FIO} </p>
               
                <div style={{
                    display:'flex',
                    marginBottom:10,
                    justifyContent:'space-between',
                    alignItems: 'flex-end'
                }}>
                    <div>
                        <TextField
                            className='me-2'
                            color='success'

                            label='Поиск'
                            variant='filled'
                            size="small"
                            onKeyUp={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null},this.checkSelected)}
                        />

                        <Button size='small' color='success' className='me-2 align-bottom' variant='text' onClick={()=>{
                            this.setState({selectedRows:Users_filter.map(u=>u.ID)})
                        }}>Выбрать все</Button>

                        <Button  size='small' disabled={selectedRows.length==0} color='success' className='me-2 align-bottom' variant='text' onClick={()=>{
                            this.setState({selectedRows:[]})
                        }}>Cнять выделение</Button>
                    </div>

                    <div>
                        <Button  size='small' disabled={selectedRows.length==0} color='success' className='me-2 align-bottom' variant='text' onClick={()=>{
                            this.makeOperation('backAllClients')
                        }}>Вернуть</Button>
                        
                        <Button  size='small' disabled={selectedRows.length==0} color='warning' className='me-2 align-bottom' variant='text' onClick={()=>{
                            this.makeOperation('replaceAllClients')
                        }}>На замещение</Button>
    

                        <Button  size='small' disabled={selectedRows.length==0} color='error' className='me-2 align-bottom' variant='text' onClick={()=>{
                           this.makeOperation('clearAllClients')
                        }}>Отвязать</Button>
                    </div>

                </div>

                <ScrollView>
                    {Users_filter==null || Users_filter.length==0 || Users_filter[0]?.ID==null?null:
                    <Table
                        className='mt-2'
                        rows={Rows}
                        selectedRows={selectedRows}
                        setSelectedRows={(rows)=>this.setState({selectedRows:rows})}
                        canCheck={true}
                        data={Users_filter}
                        itemOnClick={({data,index})=>{
                            //this.props.openPopUp(<POP_Statuses ID_OBJECT={data.ID}/>)
                        }}
                        rowOnClick={({data,index})=>{
                            debugger
                        }}
                        renderItem={({data,index})=>
                        Rows.map(r=>{
                            switch(r.key){
                            case 'CLIENT':
                                return(<td>
                                <small className='m-0 d-block'>{data.ROLE=='PRTN'?'Партнер':'Компания партнер'}</small>
                                <p className='m-0'>{data.FIO}</p>
                                </td>)
                            case 'REPLACEMENT':
                                return(<td>{data.REPLACEMENT==null?null:
                                    <Chip size='small' color={!data.is_replacement?'default':'warning'} label={data.REPLACEMENT.FIO}/>
                                }</td>)
                            default: return(<td>{data[r.key]}</td>)
                            }
                            
                        })  
                        }/>
                    }
                </ScrollView>


                
                
            </POP>
        )

            
      
    }
}

const POP = styled.div`
width:850px;
min-height:40vh;
`

const ScrollView = styled.div`
max-height:40vh;
overflow-y:auto;
overflow-x:hidden;
`


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 

        getDB_Data_lazy:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy(tableName,JSONparseKey)), 

      
        getUSERS: (arg) => dispatch(getUSERS(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        USERS:store.page.USERS,
        MANAGERS_ROOLES:store.page.MANAGERS_ROOLES,
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(POP_manager)




