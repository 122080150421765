
import { Check } from '@mui/icons-material';
import { Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Select, TextField, Tooltip, Typography,Grid } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../../actions/PopUpActions';
import { getDB_Data,getDB_Data_lazy,getUSERS } from '../../../../actions/PageActions';
import {GlobalFunctions as GF} from '../../../../GlobalFunctions'
import {HexColorPicker} from 'react-colorful'





const form_fields = [
    {
        label:'Название',
        name:'TITLE',
    },
    {
        label:'Сущность',
        name:'ENTITY',
    },
    
]

class POP_Statuses extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
            Self:{}
        }
        this.input=[]
    }

    

    componentDidMount() {
        const {} = this.state
        const {COMPANYS,STATUSES,ID_OBJECT} = this.props

        if(ID_OBJECT!=null){
            var Self_tmp = STATUSES.find(p=>p.ID==ID_OBJECT)
            if(Self_tmp==null) return
            var Self = {}
            Object.assign(Self,Self_tmp)
            Object.keys(this.input).forEach(key=>{
                this.input[key].value = Self[key]
                
            })
            this.setState({Self})
        }
    }

    Delete(){
        const {COMPANYS,DEPTS,ID_OBJECT} = this.props

        GF.API_task({
            operation:'deleteSome',
            Table:'DEPTS',
            ID:ID_OBJECT
        }).then(res=>{
            this.props.getDB_Data_lazy('DEPTS')
            setTimeout(()=>this.props.getUSERS(),400)
            
            this.props.closePopUp()
        })
    }


    Save(){
        const {Self} = this.state

        
        let reqv = {
            operation:'createUpdateSome',
            Table:'STATUSES',
            data:{
                COLOR:Self.COLOR
            }
        }
        if(this.props.ID_OBJECT !=null)
            reqv.ID = this.props.ID_OBJECT

        GF.API_task(reqv).then(res=>{
            this.props.getDB_Data_lazy('STATUSES')
            this.props.closePopUp()
        })
    }

    render (){
        const {COMPANYS,user} = this.props
        const {Self} = this.state
       
    
        return (
            <POP>
                <h4>Отдел</h4>

                {form_fields.map(input => 
                        <TextField
                            className='w-100 mt-3'
                            margin='dense'
                            disabled={user.ROLE!='ADMN' || Self.IS_SUSTEM==1}
                            size='small'
                            {...input}
                            inputRef={ref=>this.input[input.name]=ref}
                        />
                )}

                <HexColorPicker className='w-100 mt-3' color={Self.COLOR} onChange={color=>this.setState({Self:{
                    ...Self,
                    COLOR:color
                }})
                } />
                <TextField
                    className='w-100 mt-3'
                    margin='dense'
                    label='Цвет (HEX)'
                    value={Self.COLOR}
                    disabled={user.ROLE!='ADMN'}
                    size='small'
                    onChange={e=>this.setState({Self:{
                        ...Self,
                        COLOR:e.target.value
                    }})
                    }
                />

                



                <div className='mt-3'/>

                {this.props.ID_OBJECT==null || user.ROLE!='ADMN' || Self.IS_SUSTEM==1?null:
                    <Button className='float me-2' color='error' onClick={()=>this.Delete()}>удалить</Button>
                }
                <Button className='float-end' onClick={()=>this.Save()}>Сохранить</Button>

            </POP>
        )

            
      
    }
}

const POP = styled.div`
width:400px;
`





const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
       
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 

        getDB_Data_lazy:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy(tableName,JSONparseKey)), 

        getUSERS: (arg) => dispatch(getUSERS(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        PROJECTS:store.page.PROJECTS,
        STATUSES:store.page.STATUSES,
        user:store.user.data,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(POP_Statuses);

