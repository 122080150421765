
import { Check, Delete } from '@mui/icons-material';
import { Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Select, TextField, Tooltip, Typography,Grid, ListItem, IconButton, ListItemButton,Menu } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getDB_Data,getDB_Data_lazy,getUSERS,getCHATS } from '../../../actions/PageActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'

import InputMask from 'react-input-mask';
import Question from '../../PopUp/Views/Question'
import { Label } from '../../components/moduls/Input';




  


class POP_NewPartner_Forbidden extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            REASON_FORBIDDEN:null
        }
        this.input={}
    }



    

    render (){
       
        return (
            <POP>

                <h4>Отказать в сотрудничестве</h4>
                
                <TextField
                className='w-100 mb-3'
                label='Причина отказа (будет отправлено на Email)'
                variant='filled'
                multiline
                rows={6}
                size="small"
                onChange={e=>{
                    this.setState({REASON_FORBIDDEN:e.target.value})
                }}
                inputRef={ref=>this.input['REASON_FORBIDDEN']=ref}
                value={this.state.REASON_FORBIDDEN}
                />


                <div style={{
                    display:'flex',
                    justifyContent:'space-between',
                    flexDirection:'row'
                }}>
                    <Button  color='error'  onClick={()=>{
                        if(!GF.checkReqvireds(this.input)) return

                        this.props.callBack(this.state)
                    }}>Подтвердить</Button>
                    <Button  color='primary'  onClick={()=>this.props.closePopUp()}>Отмена</Button>
                </div>

                
                
            </POP>
        )

            
      
    }
}

const POP = styled.div`
width:500px;
`


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 

        getDB_Data_lazy:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy(tableName,JSONparseKey)), 

      
        getUSERS: (arg) => dispatch(getUSERS(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        NEW_PARTNERS:store.page.NEW_PARTNERS
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(POP_NewPartner_Forbidden)




