import { useSelector } from "react-redux"
import { createSelector } from "reselect"

const selectUsersINST = createSelector(
    (state) => state.page.USERS,
    (USERS) => USERS.filter((user) => user.ROLE=='INST')
)
export default function useUsersINST(){
    const UsersINST = useSelector(selectUsersINST)
    return UsersINST
}