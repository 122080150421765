import Immutable from "immutable"
import { store } from "../store/configureStore"



//singleton
class classUserSearch {
    static instance
    #UsersHashMap = {}

    constructor() {
        if (classUserSearch.instance == null) {
            classUserSearch.instance = this
        }
        return classUserSearch.instance
    }

    find(id_user) {
        const USERS = store.getState().page.USERS

        if (this.#UsersHashMap.id_user != null) {
            //мы уже знаем, что за юзер
            return Immutable.fromJS(this.#UsersHashMap[id_user]).toJS()
        } else {
            let user = USERS.find(us => us.ID == id_user)
            if (user != null) {
                this.#UsersHashMap[id_user] = user
                return Immutable.fromJS(this.#UsersHashMap[id_user]).toJS()
            } else {
                return null
            }
        }
    }

    getUsersHashMap() {
        return this.#UsersHashMap
    }


}

const UserSearch = new classUserSearch()

export default UserSearch