import React from 'react';

import styled from 'styled-components';
import { closePopUp, openPopUp } from "../../../actions/PopUpActions";


import { connect } from 'react-redux';
import { GlobalFunctions as GF } from '../../../GlobalFunctions';

import { Button, TextField } from '@mui/material';
import { withCookies } from 'react-cookie';
import { loadUserData_FromStorage } from '../../../actions/UserAct';
import { POPbottom, POPscroll } from '../../PopUp';
import POP_Alert from '../../PopUp/Views/POP_Alert';

class POP_PasswodChange extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
            old_pass: null,
            new_pass: null
        }
        this.input = []
    }

    render() {
        const { state, props } = this
        return (<POP>
            <POPscroll bottom>
                <p className='mb-2'>Для смены пароля введите текущий пароль и задайте новый</p>
                <small style={{ lineHeight: '15px', display: 'block' }}>Если ваш аккаунт авторизирован в мобильном приложении, то потребуется ввести пароль на мобильном устройстве вручную после его изменения в web-интерфейсе</small>
                <TextField
                    color='success'
                    autocomplete="off"
                    label='Текущий пароль'
                    className='w-100 mt-3'
                    margin='dense'
                    variant="filled"
                    size='small'
                    value={state.old_pass}
                    onChange={(e) =>
                        this.setState({ old_pass: e.target.value })
                    }
                />
                <TextField
                    color='success'
                    autocomplete="off"
                    label='Новый пароль'
                    className='w-100 mt-1'
                    margin='dense'
                    variant="filled"
                    size='small'
                    value={state.new_pass}
                    onChange={(e) =>
                        this.setState({ new_pass: e.target.value })
                    }
                />
            </POPscroll>
            <POPbottom>
                <Button size='small' color='success' disabled={state.old_pass == null || state.old_pass?.length <= 3 || state.new_pass == null || state.new_pass?.length < 6} onClick={() => {
                    if (!!state.new_pass.match(/[^0-9,a-z,A-Z,а-я,А-Я]/gi)) {
                        alert('Пароль может содержать только цифры и буквы')
                        return
                    }
                    GF.API_task({
                        operation: 'changePass',
                        block: 'Auth',
                        OLD_PASS: state.old_pass,
                        NEW_PASS: state.new_pass
                    }).then(data => {
                        this.props.closePopUp()

                        const { cookies } = this.props
                        cookies.set("password", state.new_pass, { path: '/' })
                        this.props.loadUserData_FromStorage()

                        this.props.openPopUp(<POP_Alert text={`Пароль успешно изменен`} buttons={[
                            <Button size='small' color='success' onClick={() => this.props.closePopUp()}>Отлично</Button>
                        ]} />
                        )
                    }).catch((e) => {

                        this.props.openPopUp(<POP_Alert text={e.message || `Ошибка при смене пароля`} buttons={[
                            <Button size='small' color='success' onClick={() => this.props.closePopUp()}>Исправить</Button>
                        ]} />)
                    })
                }}>Изменить пароль</Button>
            </POPbottom>
        </POP>)
    }
}


const POP = styled.div`
width:400px;
`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp: (content) => dispatch(openPopUp(content)),
        closePopUp: (content) => dispatch(closePopUp(content)),
        loadUserData_FromStorage: (content) => dispatch(loadUserData_FromStorage(content)),
    };
};


const mapStateToProps = (store) => {

    return {
        page: store.page
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(POP_PasswodChange))


