
import { Avatar, Button, ButtonGroup, Chip, TextField, Tooltip } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { GlobalFunctions as GF } from '../../../../GlobalFunctions';
import { getDB_Data_lazy, getDB_Data_lazy_notID, getUSERS } from '../../../../actions/PageActions';
import { closePopUp, openPopUp } from '../../../../actions/PopUpActions';
import Table from '../../../components/Table';
import withToast from '../../../components/Toasts';
import POP_User from './POP_User';

import BlockIcon from '@mui/icons-material/Block';
import DoneIcon from '@mui/icons-material/Done';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Question from '../../../PopUp/Views/Question';



const Rows = [
  {
    title: 'ФИО',
    key: 'FIO'
  },
  {
    title: 'Компания',
    key: 'COMPANY',
  },


  {
    title: 'ИНН',
    key: 'INN',
  },

  {
    title: 'Роль',
    key: 'ROLE_TITLE',
  },
  {
    title: 'Статус',
    key: 'ID_STATUS',
  },
]

class Users extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      errorMSG: null,
      succes: false,
      filter: null,
      Data: [],
      selectedTabFilter: ['PRTN', 'СPRT', 'MNGR'],
      selectedRows: []
    }
    this.input = {
      dataWindow: {},
      main: {}
    }
    this.checkBoxs = {}
  }


  PreDelete() {
    let promises = []
    let somethingDoing = false
    const { selectedRows } = this.state

    new Promise((resolve, reject) => {
      this.props.openPopUp(<Question text={'Удаление пользователей так же сотрет всю информацию о их действиях в системе'}
        buttons={[
          <Button className='m-2' color='error' onClick={() => resolve()}>Продолжить</Button>,
          <Button className='m-2' onClick={() => reject()}>Отмена</Button>
        ]} />)
    }).then(() => {
      selectedRows.forEach(s => {
        if (s != 1)//админа удалить нельзя
          promises.push(this.Delete(s))
      })


      if (promises.length > 0)
        Promise.allSettled(promises)
          .then(() => {
            this.setState({ selectedRows: [] })
            this.props.getDB_Data_lazy_notID('HUMANS', 'IMAGES_URL')
            setTimeout(() => this.props.getUSERS(), 500)

            this.props.addToast('Удалено', { appearance: 'success', autoDismiss: true });
          })
    }).finally(() => {
      this.props.closePopUp()
    })



  }

  Delete(ID) {
    return new Promise((resolve, reject) => {
      let data = {
        operation: 'deleteSome',
        Table: 'USERS',
        ID
      }

      GF.API_task(data).then(res => {
        setTimeout(() => resolve(), 1)
      }).catch(() => reject())
    })

  }

  changeBlock(ID) {
    return new Promise((resolve, reject) => {
      let data = {
        operation: 'changeBlockUser',
        ID
      }

      GF.API_task(data).then(res => {

        setTimeout(() => resolve(), 1)
      }).catch(() => reject())
    })
  }

  Block() {
    let somethingDoing = false
    let promises = []
    Object.keys(this.checkBoxs).forEach((key, index) => {
      if (this.checkBoxs[key].checked) {
        somethingDoing = true
        this.checkBoxs[key].checked = false
        promises.push(this.changeBlock(key))
      }
    })

    if (!somethingDoing) return

    Promise.allSettled(promises)
      .then(() => {
        this.props.getUSERS()
        this.props.addToast('Значение обновлено', { appearance: 'success', autoDismiss: true });
      })
  }



  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.USERS.length != this.props.USERS.length) {
      this.checkSelected()
    }
  }

  checkSelected() {
    const { USERS, USER_ROLES } = this.props
    const { filter, Data, selectedTabFilter, selectedRows } = this.state
    const filterUsers = this.props.USERS.filter(u =>
      selectedTabFilter.findIndex(stf => stf == u.ROLE) != -1 && (
        filter == null ||
        u.FIO?.toUpperCase()?.indexOf(filter) != -1 ||
        u.PHONE?.indexOf(filter) != -1 ||
        u.EMAIL?.toUpperCase()?.indexOf(filter) != -1
      )
    )
    let correctRows = []
    selectedRows.forEach((sr, i) => {
      if (filterUsers.findIndex(u => u.ID == sr) != -1) {
        correctRows.push(sr)
      }
    })
    if (correctRows.length != selectedRows) {
      this.setState({ selectedRows: correctRows })
    }
  }


  render() {
    const { USERS, USER_ROLES } = this.props
    const { filter, Data, selectedTabFilter, selectedRows } = this.state
    const filterUsers = this.props.USERS.filter(u =>
      selectedTabFilter.findIndex(stf => stf == u.ROLE) != -1 && (
        filter == null ||
        u.FIO?.toUpperCase()?.indexOf(filter) != -1 ||
        u.PHONE?.indexOf(filter) != -1 ||
        u.PARTNER?.INN?.indexOf(filter) > -1 ||
        u.EMAIL?.toUpperCase()?.indexOf(filter) != -1 ||
        u.LOGIN?.toUpperCase()?.indexOf(filter) != -1
      )
    )

    return (
      <View>
        <ButtonGroup variant="text" className='mt-3'>
          {USER_ROLES.map(p =>
            p.ROLE == 'TMPU' ? null :
              <Button onClick={() => {
                let tmp = JSON.parse(JSON.stringify(selectedTabFilter))
                let ind = selectedTabFilter.findIndex(f => f == p.ROLE)
                if (ind == -1)
                  tmp.push(p.ROLE)
                else
                  tmp.splice(ind, 1)

                this.setState({ selectedTabFilter: tmp }, () => this.checkSelected())
              }} variant={selectedTabFilter.findIndex(f => f == p.ROLE) != -1 ? "contained" : "text"}>{p.DESCR}</Button>
          )}
        </ButtonGroup>

        <div className='mt-4'>
          <TextField
            className='me-2'
            color='success'
            label='Поиск'
            variant='filled'
            size="small"
            onKeyUp={(e) => this.setState({ filter: e.target.value.length > 0 ? e.target.value.toUpperCase() : null }, this.checkSelected)}
          />

          <Button color='success' className='me-2 align-bottom' variant='text' onClick={() => {
            this.setState({ selectedRows: filterUsers.map(u => u.ID) })
          }}>Выбрать все</Button>

          <Button disabled={selectedRows.length == 0} color='success' className='me-2 align-bottom' variant='text' onClick={() => {
            this.setState({ selectedRows: [] })
          }}>Cнять выделение</Button>

          <Button disabled={selectedRows.length == 0} color='success' className='me-2 align-bottom' variant='text' onClick={() => {
            this.Block()
          }}>Изменить статус</Button>

          <Button color='success' className='me-2 align-bottom' variant='text' onClick={() => {
            this.props.openPopUp(<POP_User ROLE={this.props.role} />)
          }}>Добавить</Button>

          <Button disabled={selectedRows.length == 0} color='error' className='me-2 align-bottom' variant='text' onClick={() => {
            this.PreDelete()
          }}>Удалить</Button>

        </div>


        <Table
          className='mt-1'
          rows={Rows}
          canCheck={true}
          selectedRows={selectedRows}
          setSelectedRows={(rows) => this.setState({ selectedRows: rows })}
          data={filterUsers}
          itemOnClick={({ data, index }) => {
            this.props.openPopUp(<POP_User ID_OBJECT={data.ID} />)
          }}
          rowOnClick={({ data, index }) => {
            debugger
          }}
          renderItem={({ data, index }) =>
            Rows.map(r => {
              switch (r.key) {
                case 'ID_STATUS':
                  return (<td>
                    {(() => {
                      let color = null
                      let icon = null
                      switch (data.ID_STATUS) {
                        case 1:
                          color = 'warning'
                          icon = <WarningAmberIcon fontSize='small' />
                          break
                        case 2:
                          color = 'success'
                          icon = <DoneIcon fontSize='small' />
                          break
                        case 3:
                          color = 'error'
                          icon = <BlockIcon fontSize='small' />
                          break
                      }

                      return (
                        <Tooltip placement='left' title={data.STATUS_TITLE}>
                          <Chip size='small' color={color} label={icon} />
                        </Tooltip>
                      )
                    })()}
                  </td>)
                case 'PHONE':
                  return (<td>{GF.phoneFormatter(data.PHONE)}</td>)
                case 'COMPANY':
                  return (<td>{data.COMPANY?.TITLE}</td>)
                case 'INN':
                  return (<td>{data.PARTNER?.INN}</td>)
                case 'EMAIL':
                  return (<td><a href={'mailto:' + data[r.key]} target='black'>{data[r.key]}</a></td>)
                case 'FIO':
                  return (<td><Chip avatar={
                    data.IMAGES_URL == null ? <Avatar>{data.FIO[0]}</Avatar> :
                      <Avatar src={data.IMAGES_URL[0]} alt={data.FIO[0]} />
                  } size='small' label={GF.FIO(data.FIO, 2)} /></td>)
                default: return (<td>{data[r.key]}</td>)
              }

            })
          } />


      </View>
    )
  }
}
const View = styled.div`
height:100%;
`

const mapDispatchToProps = (dispatch) => {
  return {
    openPopUp: (arg) => dispatch(openPopUp(arg)),
    closePopUp: (arg) => dispatch(closePopUp(arg)),
    getUSERS: (arg) => dispatch(getUSERS(arg)),
    getDB_Data_lazy_notID: (arg, a) => dispatch(getDB_Data_lazy_notID(arg, a)),
    getDB_Data_lazy: (arg, q) => dispatch(getDB_Data_lazy(arg, q)),
  };
};


const mapStateToProps = (store) => {

  return {
    USERS: store.page.USERS,
    USER_ROLES: store.page.USER_ROLES,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withToast(Users));

