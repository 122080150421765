const Pallet={
    Accent:'#2e7d32',
    Black:'#212021',
    White:'#fff',
    Gray:'#F2F5F7',
    Light:{
        Green:'rgba(46, 125, 50, 0.1)',
        Yellow:'#FFF5E5',
        
        Blue:'#D2E7FF',
        White:'rgba(255, 255, 255, 0.5)',
        Red:'#FFE5E5'
    },
    Primary:{
        Gray:'rgba(153, 157, 157, 1)',
        Green:'#E5F9EE',
        Yellow:'#D89920',
        Blue:'#316285',
        Red:'#D82020'
    }
}

export default Pallet