
import { Autocomplete, Button, Checkbox, Chip, FormControl, Menu, MenuItem, TextField } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { GlobalFunctions as GF } from '../../../../GlobalFunctions';
import { getDB_Data, getDB_Data_lazy, getDB_Data_lazy_notID, getMANAGERS_ROOLES, getUSERS } from '../../../../actions/PageActions';
import { closePopUp, openPopUp } from '../../../../actions/PopUpActions';

import { DatePicker } from '@mui/lab';
import moment from 'moment';
import ReactHighcharts from 'react-highcharts';
import InputMask from 'react-input-mask';
import Debounce from '../../../../Class/Debounce';
import UserSearch from '../../../../Class/UserSearch';
import { getManagerInstallersRooles } from '../../../../actions/InstallSchedule';
import { POPbottom, POPscroll } from '../../../PopUp';
import Question from '../../../PopUp/Views/Question';
import StackHorizontal from '../../../components/StackHorizontal';
import { Label } from '../../../components/moduls/Input';
import SelectView from '../../../components/moduls/SelectView';
import POP_BasketOrder from '../../Orders/POP_BasketOrder';
import POP_Order from '../../Orders/POP_Order';
import NewOrderDot from './NewOrderDot';
import POP_UserSearch from './POP_UserSearch';
import PopUser_INST from './popUserViews/PopUser_INST';
import PopUser_MNGR from './popUserViews/PopUser_MNGR';


const sinonim = {
    MNGR: 'MANAGER',
    PRTN: 'PARTNER',
    СPRT: 'PARTNER'
}

const form_fields = {
    HUMANS: [
        {
            label: 'ФИО',
            name: 'FIO',
        },
        {
            label: 'E-mail',
            name: 'EMAIL',
        },
        {
            label: 'Номер телефона',
            name: 'PHONE',
        },
        {
            label: 'Заметка',
            name: 'DESCR',
            multiline: true,
            rows: 5
        }
    ],
    MNGR: [],
    INST: [],
    PRTN: [
        {
            label: 'Коэффициент цен',
            name: 'FACTOR',
            defaultValue: 1,
            type: 'number',
            min: '0',
        },
        {
            label: 'Адрес доставки',
            name: 'ADDRESS',
        },
        {
            label: 'Юр. Лицо',
            name: 'TYPE',
        },
        {
            label: 'ИНН',
            name: 'INN',
        },
        {
            label: 'КПП',
            name: 'KPP',
        },
    ],
    СPRT: [
        {
            label: 'Коэффициент цен',
            name: 'FACTOR',
            defaultValue: 1,
            type: 'number',
            min: '0'
        },
        {
            label: 'Адрес доставки',
            name: 'ADDRESS',
        },
        {
            label: 'Юр. Лицо',
            name: 'TYPE',
        },
        {
            label: 'ИНН',
            name: 'INN',
        },
        {
            label: 'КПП',
            name: 'KPP',
        },
    ]
}

export const UserDataContext = React.createContext({ Self: null })

class POPView extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            anchorEl1: null,
            isLoaded: false,
            year: moment().format('YYYY') * 1,
            selected: [],
            IhaveAccess: false,
            showDrafts: false,
            showAllData: false,
            filter: null,
            Self: {
                PERMISSIONS: [],
                ROLE: props.ROLE,
                ID_STATUS: 2,
                PARTNER: {},
                COMPANY: {},
                MANAGER: {
                    RESPONSE: []
                }
            },
            anchorEl: null
        }
        this.input = []
        this.can = true


        this.updateTimer = null
    }
    lastDate = null
    lastDateView = null



    componentDidMount() {
        const { state } = this
        const { COMPANYS, DEPTS, ID_OBJECT, USERS } = this.props

        setTimeout(() => this.setState({ isLoaded: true }), 500)



        if (ID_OBJECT != null) {
            var Self = UserSearch.find(ID_OBJECT)
            if (Self == null) return
            Self = { ...{ ...Self } }
            Object.keys(this.input).forEach(key => {
                try {
                    this.input[key].value = Self[key]
                } catch { }

            })

            if (Self.ROLE == 'INST' && this.props.user.ROLE == 'MNGR' && Self.ID_USER_CREATOR == this.props.user.ID) {

                this.setState({ IhaveAccess: true })


            }

            this.setState({ Self: {} }, () => {
                this.setState({ Self })
            })
        } else {
            if (state.Self.ROLE == 'INST' && this.props.user.ROLE == 'MNGR') {
                if (state.Self.ID == null)
                    this.setState({ IhaveAccess: true })
            }
        }
    }



    ressetPass() {
        const { COMPANYS, DEPTS, ID_OBJECT, user } = this.props

        new Promise((resolve, reject) => {
            this.props.openPopUp(<Question text={'Пользователь не сможет пользоваться системой до тех пор, пока не активирует заного свой аккаунт. Ему заного придет письмо активации'}
                buttons={[
                    <Button className='m-2' color='warning' onClick={() => resolve()}>Продолжить</Button>,
                    <Button className='m-2' onClick={() => reject()}>Отмена</Button>
                ]} />)
        }).then(() => {
            GF.API_task({
                operation: 'resendGUID',
                ID_USER: ID_OBJECT
            }).then(res => {
                this.props.getUSERS(() => this.componentDidMount())
            })
        }).finally(() => {
            this.props.closePopUp()
        })
    }

    Delete() {
        const { COMPANYS, DEPTS, ID_OBJECT, user } = this.props

        new Promise((resolve, reject) => {
            this.props.openPopUp(<Question text={'Удаление пользователя так же сотрет всю информацию о его действиях в системе'}
                buttons={[
                    <Button className='m-2' color='error' onClick={() => resolve()}>Продолжить</Button>,
                    <Button className='m-2' onClick={() => reject()}>Отмена</Button>
                ]} />)
        }).then(() => {

            GF.API_task({
                operation: 'deleteUser',
                ID_USER: ID_OBJECT
            }).then(res => {
                let tasks = [
                    () => this.props.getDB_Data_lazy_notID('HUMANS', 'IMAGES_URL'),

                    () => this.props.getDB_Data_lazy('USER_ROLES'),

                    () => this.props.getUSERS(() => this.componentDidMount())


                ]
                for (let i = 0; i < tasks.length; i++) {
                    setTimeout(() => tasks[i](), i * 300)
                }

                this.props.closePopUp()
            })

        }).finally(() => {
            this.props.closePopUp()
        })


    }


    Save() {
        Debounce.start(async () => {

            const { Self } = this.state
            const { COMPANYS, user } = this.props
            if (!GF.checkReqvireds([this.input.FIO, this.input.EMAIL])) return

            if (!this.can) return
            this.can = false





            let reqv = {
                operation: 'createUpdateUSER',
                data: GF.ObjectCopy(Self)
            }
            delete (reqv.data.ID)

            if (reqv.data.MANAGER != null && reqv.data.MANAGER.RESPONSE != null) {
                reqv.data.MANAGER.RESPONSE = reqv.data.MANAGER.RESPONSE.map(r => {
                    return {
                        ID_RESPONS: r.ID,
                        isReplacement: r.isReplacement ? 1 : 0
                    }
                })
            }


            reqv.data.PHONE = reqv.data.PHONE == null || reqv.data.PHONE.length == 0 ? null : reqv.data.PHONE.replace(/[ \t(\t,)\t,+\t,_,-]/g, '')

            if (this.props.ID_OBJECT != null)
                reqv.ID = this.props.ID_OBJECT

            if (reqv.data.COMPANY == null || reqv.data.COMPANY.ID == null)
                reqv.data.ID_COMPANY = null
            else
                reqv.data.ID_COMPANY = reqv.data.COMPANY.ID

            reqv.data.PERMISSIONS = JSON.stringify(reqv.data.PERMISSIONS || [])


            if (Self.ID == null) {


                await new Promise((resolve, reject) => {
                    GF.API_task(reqv).then(res => {
                        this.setState({
                            Self: {
                                ...Self,
                                ID: res.ID_USER,
                                ID_EMPLOY: res.ID_EMPLOYE,
                                ID_CLIENT: res.ID_CLIENT
                            }
                        })

                        this.props.dispatch(getManagerInstallersRooles())
                        this.props.getMANAGERS_ROOLES()
                        this.props.getDB_Data_lazy_notID('HUMANS', 'IMAGES_URL')
                        setTimeout(() => {
                            this.props.getUSERS(() => this.componentDidMount())
                            if (this.props.callBack != null) {
                                setTimeout(() => {
                                    this.props.callBack()
                                }, 500)
                            }
                        }, 500)


                        this.props.closePopUp()



                        setTimeout(() => resolve(), 2)
                    }).catch((e) => reject(e))
                }).catch((e) => {
                    alert(e.error)
                    console.error(reqv.operation, e)
                })

            } else {
                await GF.API_task(reqv).then(res => {
                    this.props.getUSERS(() => this.componentDidMount())
                    this.props.closePopUp()

                    if (this.props.callBack != null) {
                        setTimeout(() => {
                            this.props.callBack()
                        }, 500)
                    }
                })
            }

            this.can = true

            if (Self.ROLE == 'CLEN' && Self.COMPANY != null && Self.COMPANY.ID != null) {
                GF.API_task({
                    operation: 'updateCLEN',
                    ID_USER: this.state.Self.ID,
                    ID_COMPANY: this.state.Self.COMPANY.ID,
                    ID_CLIENT: this.state.Self.ID_CLIENT,
                    PROJECTS: this.state.Self.PROJECTS.map(p => p.ID)
                }).then(res => {
                    this.props.getUSERS(() => this.componentDidMount())
                    if (this.props.callBack != null) {
                        setTimeout(() => {
                            this.props.callBack()
                        }, 500)
                    }
                })
            }
        })
    }

    ordersByMonths(orders_) {
        const { state, props } = this
        let orders = [...orders_]
        let months = []
        for (let month = 1; month <= 12; month++) {
            let countKarniz = 0
            let countOrders = 0
            let sumOrders = 0
            let otherOrders = []
            let date_1 = moment(`01.${month}.${state.year}`, 'DD.M.YYYY')
            let date_2 = moment(`01.${month}.${state.year}`, 'DD.M.YYYY')
            date_2.add(1, 'M')


            orders.forEach(order => {


                if (order.DATE_SENDED != null && moment(order.DATE_SENDED).isBetween(
                    date_1,
                    date_2
                )) {
                    order.ITEMS.forEach(item => {
                        countKarniz += item.ORDERS_COUNT
                    })
                    countOrders++
                    sumOrders += order.ALL_COAST - order.TAX_COAST
                } else {
                    if (order.DATE_SENDED != null)
                        otherOrders.push(order)
                }
            })

            orders = [...otherOrders]


            months.push(countKarniz)
        }
        return months
    }

    DeleteGrade(dept) {
        const { Self } = this.state

        GF.API_task({
            operation: 'deleteEmplDeptGrade',
            ID_DEPT: dept.ID,
            ID_EMPLOY: Self.ID_EMPLOY,
        }).then(res => {
            this.props.getUSERS(() => this.componentDidMount())
            setTimeout(() => this.props.getDB_Data('getPROJECTS', 'PROJECTS'), 500)
        })
    }

    OpenChatWith() {
        const { COMPANYS, user, ID_OBJECT, USER_STATUS, PROJECTS } = this.props
        GF.API_task({
            operation: 'getOrCreatePrivateChat',
            ID_USER: ID_OBJECT
        }).then(data => {
            window.location.replace('/chats/' + data.ID_CHAT)
        })
    }

    renderView() {
        const { Self, anchorEl, filter, anchorEl1 } = this.state
        const { state, props } = this
        const open1 = Boolean(anchorEl1)

        const updateUsersData = () => {
            clearTimeout(this.updateTimer)
            this.updateTimer = setTimeout(() => {
                try {
                    this.props.getUSERS(() => this.componentDidMount())

                } catch { }
            }, 1000)
        }

        const removeClient = (user) => {
            clearTimeout(this.updateTimer)


            let responses = [...[...Self.MANAGER.RESPONSE]]
            let index = responses.findIndex(resp => resp.ID == user.ID)
            responses.splice(index, 1)
            this.setState({
                Self: {
                    ...state.Self,
                    MANAGER: {
                        ...Self.MANAGER,
                        RESPONSE: [...responses]
                    }
                }
            })

            if (user.ID_REPLACEMENT != null) {//это замена
                if (user.ID_REPLACEMENT == Self.ID) {//замена на этого менеджера
                    GF.API_task({
                        operation: 'remove_replacement',
                        CLIENTS_ID: `(${user.ID})`
                    }).then(res => { updateUsersData() })
                } else {
                    GF.API_task({ //это замена 
                        operation: 'remove_replacement_fromThisManager',
                        CLIENTS_ID: `(${user.ID})`
                    }).then(res => { updateUsersData() })
                }
            } else {
                GF.API_task({ //это не замена 
                    operation: 'remove_clientAtall',
                    CLIENTS_ID: `(${user.ID})`
                }).then(res => { updateUsersData() })
            }

        }

        return {
            'INST': () => <PopUser_INST Self={state.Self} />,
            'MNGR': () => <PopUser_MNGR partnersTab={() => {
                let clients = []

                Self.MANAGER.RESPONSE.forEach(user => {
                    if (filter == null ||
                        user.FIO.toUpperCase().indexOf(filter) != -1 ||
                        user.EMAIL.toUpperCase().indexOf(filter) != -1 ||
                        user.PHONE.indexOf(filter) != -1
                    ) {
                        if (clients.length > 7 && !state.showAllData) {

                        } else {

                            const index = state.selected.findIndex(selID => selID == user.ID)
                            const isSelected = index != -1
                            const userOrders = []
                            const userOrdersNew = []

                            let karnizCount = 0
                            let CoastTotal = 0

                            props.ORDERS.forEach(order => {
                                if (order.ID_USER == user.ID && order.ID_STATUS == 13) {
                                    if (order.MANAGER_READED == 1) {
                                        userOrders.push(order)
                                        CoastTotal += order.ALL_COAST
                                        order.ITEMS.forEach(item => {
                                            karnizCount += item.ORDERS_COUNT
                                        })
                                    } else {
                                        userOrdersNew.push(order)
                                    }

                                }
                            })

                            props.ACCESSORIES_ORDERS.forEach(order => {
                                if (order.ID_USER == user.ID && order.ID_STATUS == 17) {
                                    if (order.MANAGER_READED == 1) {
                                        userOrders.push(order)

                                        let allPrice = 0

                                        order.ITEMS.forEach(item => {
                                            karnizCount += item.COUNT
                                            allPrice += item.PRICE * item.COUNT
                                        })

                                        CoastTotal += allPrice
                                    } else {
                                        userOrdersNew.push(order)
                                    }

                                }
                            })

                            const comboBox = <Checkbox size="small" color='success' onClick={e => {
                                e.stopPropagation()

                            }} onChange={e => {

                                let tmp = [...[...this.state.selected]]
                                if (isSelected) {
                                    tmp.splice(index, 1)
                                } else {
                                    tmp.push(user.ID)
                                }
                                this.setState({ selected: tmp })
                            }} checked={isSelected} />

                            if (user.ID_REPLACEMENT != null) {//это замена
                                if (user.ID_REPLACEMENT == Self.ID) {//замена на этого менеджера
                                    clients.push(<Chip key={'par_' + user.ID} className='partners' style={{ height: 40, marginBottom: -8 }} color='warning' label={<div>
                                        {userOrdersNew.length == 0 ? null :
                                            <NewOrderDot>{userOrdersNew.length}</NewOrderDot>}
                                        <p>{user.FIO}</p>
                                        <small style={{ color: 'rgba(255,255,255,0.6)' }}>Замена от <b style={{ color: 'white' }}>{GF.FIO(user.MANAGER_FIO)}</b></small>
                                    </div>} onDelete={() => removeClient(user)} onClick={() => this.props.openPopUp(<POP_User ID_OBJECT={user.ID} />)} icon={comboBox} />)
                                } else {
                                    clients.push(<Chip key={'par_' + user.ID} className='partners' style={{ height: 40, opacity: 0.6, marginBottom: -8 }} label={<div>
                                        {userOrdersNew.length == 0 ? null :
                                            <NewOrderDot>{userOrdersNew.length}</NewOrderDot>}
                                        <p>{user.FIO}</p>
                                        <small>Назначен <b>{GF.FIO(user.MANAGER_FIO_REPLACE)}</b></small>
                                    </div>} onDelete={() => removeClient(user)} onClick={() => this.props.openPopUp(<POP_User ID_OBJECT={user.ID} />)}
                                        icon={comboBox} />)
                                }
                            } else {
                                clients.push(<Chip style={{
                                    marginBottom: -8
                                }} key={'par_' + user.ID} className='partners' label={<div>
                                    {userOrdersNew.length == 0 ? null :
                                        <NewOrderDot>{userOrdersNew.length}</NewOrderDot>}
                                    <p>{user.FIO}</p>
                                </div>}
                                    icon={comboBox}
                                    onDelete={() => removeClient(user)} onClick={() => this.props.openPopUp(<POP_User ID_OBJECT={user.ID} />)} />)
                            }
                        }
                    }
                })

                if (clients.length > 7 && !state.showAllData) {
                    clients.push(<Button color='success' size='small' onClick={() => this.setState({ showAllData: true })}>Показать все записи</Button>)
                }


                return (<ManagerView>


                    <StackHorizontal>
                        <TextField
                            style={{ width: '100%', flex: 1 }}
                            className='m-0 w-100'
                            label='Поиск партнера'
                            variant='filled'
                            size="small"
                            color='success'

                            onChange={(e) => this.setState({ filter: e.target.value.length > 0 ? e.target.value.toUpperCase() : null })}
                        />



                        <Button key='ActionButton' color='success'
                            style={{ width: 100 }}
                            onClick={e =>
                                this.setState({ anchorEl1: e.currentTarget })
                            }
                            disabled={Self.ID == null}>
                            Действия
                        </Button>
                        <Menu
                            anchorEl={state.anchorEl1}
                            open={open1}
                            onClose={() => this.setState({ anchorEl1: null })}
                        >

                            <MenuItem onClick={() => {
                                this.props.openPopUp(<POP_UserSearch title='Выберите клиентов' callBack={selected => {
                                    let promises = []
                                    selected.forEach(user => {
                                        promises.push(GF.API_task({
                                            operation: 'createManagerRoole',
                                            ID_USER: user.ID,
                                            ID_MANAGER: Self.ID
                                        }))
                                    })
                                    Promise.allSettled(promises).finally(() => {
                                        this.props.closePopUp()
                                        this.props.getUSERS(() => this.componentDidMount())
                                    })
                                }} isHiddenRule={user => {

                                    return props.MANAGERS_ROOLES.findIndex(rule => rule.ID_RESPONS == user.ID) != -1 || (user.ROLE != 'PRTN' && user.ROLE != 'СPRT')
                                }} multiple placeholder='Всем клиентам назначены менеджеры' />)

                                this.setState({ anchorEl1: null })
                            }}>Добавить партнера менеджеру</MenuItem>

                            <hr />

                            <MenuItem onClick={() => {
                                this.setState({
                                    selected: [...Self.MANAGER.RESPONSE.map(user => user.ID)]
                                })

                            }}>Выбрать всех</MenuItem>

                            <MenuItem onClick={() => {
                                this.setState({ anchorEl1: null, selected: [] })
                            }}>Отменить выделение</MenuItem>

                            <hr />

                            <MenuItem onClick={() => {
                                if (state.selected.length == 0) return
                                this.setState({ anchorEl1: null })

                                this.props.openPopUp(<POP_UserSearch title='Выберите менеджера' callBack={selected => {
                                    GF.API_task({
                                        operation: 'replaceAllClients',
                                        ID_REPLACEMENT: selected[0].ID,
                                        CLIENTS_ID: `(${state.selected.join(',')})`
                                    }).then(res => {
                                        this.setState({ selected: [] })
                                        this.props.getUSERS(() => this.componentDidMount())

                                        this.props.closePopUp()
                                    })
                                }} isHiddenRule={user => {
                                    return user.ROLE != 'MNGR' || user.ID == Self.ID
                                }} placeholder='Нет менеджеров в системе' />)



                            }}>Отправить на замену выбранных</MenuItem>

                            <MenuItem onClick={() => {
                                if (state.selected.length == 0) return
                                this.setState({ anchorEl1: null, selected: [] })

                                GF.API_task({
                                    operation: 'backAllClients',
                                    CLIENTS_ID: `(${state.selected.join(',')})`
                                }).then(res => {

                                    this.props.getUSERS(() => this.componentDidMount())
                                })
                            }}>Вернуть с замены выбранных</MenuItem>

                            <MenuItem onClick={() => {
                                this.setState({ anchorEl1: null, selected: [] })
                                let remove_replacement = []
                                let remove_replacement_fromThisManager = []
                                let remove_clientAtall = []

                                state.selected.forEach(user => {
                                    if (user.ID_REPLACEMENT != null) {//это замена
                                        if (user.ID_REPLACEMENT == Self.ID) {//замена на этого менеджера
                                            remove_replacement.push(user)
                                        } else {
                                            remove_replacement_fromThisManager.push(user)
                                        }
                                    } else {
                                        remove_clientAtall.push(user)
                                    }
                                })

                                let promises = []

                                if (remove_replacement.length > 0)
                                    promises.push(GF.API_task({
                                        operation: 'remove_replacement',
                                        CLIENTS_ID: `(${remove_replacement.join(',')})`
                                    }))

                                if (remove_replacement_fromThisManager.length > 0)
                                    promises.push(GF.API_task({
                                        operation: 'remove_replacement_fromThisManager',
                                        CLIENTS_ID: `(${remove_replacement_fromThisManager.join(',')})`
                                    }))

                                if (remove_clientAtall.length > 0)
                                    promises.push(GF.API_task({
                                        operation: 'remove_clientAtall',
                                        CLIENTS_ID: `(${remove_clientAtall.join(',')})`
                                    }))

                                Promise.allSettled(promises).finally(() => {
                                    this.props.getUSERS(() => this.componentDidMount())
                                })

                            }}>Убрать из этого менеджера выбранных</MenuItem>
                        </Menu>
                    </StackHorizontal>


                    <Label>Работает со следующими клиентами</Label>
                    <div style={{
                        display: 'flex',
                        flexDirection: "column",
                        gap: 10
                    }}>
                        {clients}
                    </div>
                </ManagerView>)
            }
            } />,
            'СPRT': () => {
                return (<>
                    {this.props.ID_OBJECT == null ?
                        <TextField
                            disabled={this.props.user.ROLE != 'ADMN'}
                            variant='filled'
                            className='w-100 mb-1 mt-0'
                            margin='dense'
                            size='small'
                            label='Название компании'
                            onChange={e => {
                                let tmp = Object.assign({}, Self)
                                tmp['COMPANY_TITLE'] = e.target.value
                                this.setState({ Self: tmp })
                            }}
                            inputRef={ref => this.input['COMPANY_TITLE'] = ref}
                            value={Self['COMPANY_TITLE']}
                        /> :
                        <>
                            <SelectView
                                label='Компания'
                                disabled={true}
                                className='mt-0 mb-1'
                                value={Self.COMPANY.ID}
                                items={[
                                    {
                                        value: null,
                                        title: 'Не выбрана'
                                    },
                                    ...this.props.PARTNER_COMPANYS.map(p => {
                                        return {
                                            value: p.ID,
                                            title: p.TITLE
                                        }
                                    })
                                ]
                                } />
                            <Autocomplete
                                disabled={this.props.user.ROLE != 'ADMN'}

                                color='success'
                                value={
                                    this.props.USERS.find(u => u.ID == this.state.Self.ID_MANAGER)
                                }
                                options={this.props.USERS.filter(u => u.ROLE == 'MNGR')}
                                getOptionLabel={(option) => option.FIO}
                                onChange={(e, value) => {
                                    this.setState({
                                        Self: {
                                            ...this.state.Self,
                                            ID_MANAGER: value == null ? null : value.ID,
                                        }
                                    })

                                }} renderInput={(params) => (
                                    <TextField
                                        className='mt-0 mb-1'
                                        {...params}
                                        color='success'
                                        variant="filled"
                                        label="Менеджер"
                                        placeholder="ФИО менеджера"
                                    />
                                )}
                            />


                            <Autocomplete

                                color='success'
                                disabled={this.props.user.ROLE != 'ADMN'}
                                value={
                                    this.props.USERS.find(u => u.ID == this.state.Self.ID_REPLACEMENT)
                                }
                                options={this.props.USERS.filter(u => u.ROLE == 'MNGR' && u.ID != state.Self.ID_MANAGER)}
                                getOptionLabel={(option) => option.FIO}
                                onChange={(e, value) => {
                                    this.setState({
                                        Self: {
                                            ...this.state.Self,
                                            ID_REPLACEMENT: value == null ? null : value.ID
                                        }
                                    })
                                }} renderInput={(params) => (
                                    <TextField
                                        className='mt-0 mb-1'
                                        {...params}
                                        color='success'
                                        variant="filled"
                                        label="Менеджер (замещающий)"
                                        placeholder="ФИО менеджера"
                                    />
                                )}
                            />
                        </>
                    }
                </>)
            },
            'PRTN': () => {
                return (<>

                    <SelectView
                        label='Компания'
                        className='mt-0 mb-1'
                        disabled={this.props.user.ROLE != 'ADMN'}
                        value={Self.COMPANY.ID}
                        onChange={value => this.setState({
                            Self: {
                                ...Self, COMPANY:
                                    value == null ? {} :
                                        this.props.PARTNER_COMPANYS.find(c => c.ID == value)
                            }
                        })}
                        items={[
                            {
                                value: null,
                                title: 'Не выбрана'
                            },
                            ...this.props.PARTNER_COMPANYS.map(p => {
                                return {
                                    value: p.ID,
                                    title: p.TITLE
                                }
                            })
                        ]
                        } />


                    <Autocomplete
                        className='mb-1'
                        color='success'
                        disabled={this.props.user.ROLE != 'ADMN'}
                        value={
                            this.props.USERS.find(u => u.ID == this.state.Self.ID_MANAGER)
                        }
                        options={this.props.USERS.filter(u => u.ROLE == 'MNGR')}
                        getOptionLabel={(option) => option.FIO}
                        onChange={(e, value) => {
                            this.setState({
                                Self: {
                                    ...this.state.Self,
                                    ID_MANAGER: value == null ? null : value.ID,
                                }
                            })

                        }} renderInput={(params) => (
                            <TextField
                                {...params}
                                color='success'
                                variant="filled"
                                label="Менеджер"
                                placeholder="ФИО менеджера"
                            />
                        )}
                    />


                    <Autocomplete
                        className='mb-1'
                        color='success'
                        disabled={this.props.user.ROLE != 'ADMN'}
                        value={
                            this.props.USERS.find(u => u.ID == this.state.Self.ID_REPLACEMENT)
                        }
                        options={this.props.USERS.filter(u => u.ROLE == 'MNGR' && u.ID != state.Self.ID_MANAGER)}
                        getOptionLabel={(option) => option.FIO}
                        onChange={(e, value) => {
                            this.setState({
                                Self: {
                                    ...this.state.Self,
                                    ID_REPLACEMENT: value == null ? null : value.ID
                                }
                            })
                        }} renderInput={(params) => (
                            <TextField
                                {...params}
                                color='success'
                                variant="filled"
                                label="Менеджер (замещающий)"
                                placeholder="ФИО менеджера"
                            />
                        )}
                    />
                </>)
            },
        }
    }

    render() {
        const { COMPANYS, user, ID_OBJECT, USER_STATUS, PROJECTS } = this.props
        const { Self, anchorEl, anchorEl1 } = this.state

        const { state, props } = this

        const isAdminOrManager = props.user.ROLE == 'ADMN' || props.user.ROLE == 'MNGR'

        if (ID_OBJECT != null && Self?.ID == null) return <div />


        const open = Boolean(anchorEl)
        const open1 = Boolean(anchorEl1)
        let isBigPopUp = isAdminOrManager && (Self.ROLE == 'PRTN' || Self.ROLE == 'СPRT' || Self.ROLE == 'MNGR' || Self.ROLE == 'INST')

        if (props.user.ROLE == 'MNGR' && Self.ROLE == 'INST') {
            isBigPopUp = false
        }

        let userORDERS = []
        if (Self.ROLE == 'PRTN' || Self.ROLE == 'CPRT') {
            userORDERS = [
                ...props.ORDERS.filter(order => order.ID_USER == Self.ID && order.ID_STATUS == 13),
                ...props.ACCESSORIES_ORDERS.filter(order => order.ID_USER == Self.ID && order.ID_STATUS == 17).map(order => {
                    return {
                        ...order,
                        TYPE: 'Basket',
                        DATE_SENDED: order.DATE_CREATE,
                        TAX_COAST: 0,
                        ALL_COAST: order.ITEMS.reduce((a, b) => a + b.COUNT * b.PRICE, 0),
                        ITEMS: order.ITEMS.map(item => {
                            return {
                                ...item,
                                ORDERS_COUNT: item.COUNT
                            }
                        })
                    }
                }),
            ].sort((a, b) => {
                let aD = moment(a.DATE_CREATE)
                let bD = moment(b.DATE_CREATE)

                if (aD.isAfter(bD))
                    return -1

                if (aD.isBefore(bD))
                    return 1

                if (aD.isSame(bD))
                    return 0

            })
        }




        return (
            <POP isBigPopUp={isBigPopUp}>
                <POPscroll bottom={user.ROLE == 'ADMN' || (user.ROLE == 'MNGR' && state.IhaveAccess && Self.ROLE == 'INST')}>
                    <StackHorizontal className='mb-2'>
                        <h4 className='m-0'>{Self.ROLE_TITLE == null ? 'Пользователь' : Self.ROLE_TITLE}</h4>
                        {(props.user.ROLE != 'ADMN' && props.user.ROLE != 'MNGR') || Self.ID_USER_CREATOR == 1 || Self.ID_USER_CREATOR == null ? null :
                            <div style={{ marginTop: -17 }}>
                                <small className='d-block'>Создал</small>
                                <Chip size='small' label={UserSearch.find(Self.ID_USER_CREATOR)?.FIO} onClick={() => props.openPopUp(<POP_User ID_OBJECT={Self.ID_USER_CREATOR} />)} />
                            </div>}
                    </StackHorizontal>
                    <div className={'row ' + (isBigPopUp ? 'row-cols-2' : 'row-cols-1')}>
                        <div className='col'>
                            {!!Self.LOGIN &&
                                <TextField
                                    variant='filled'
                                    className='w-100 mt-0 mb-1'
                                    margin='dense'
                                    color='success'
                                    disabled
                                    size='small'
                                    label='Логин'
                                    value={Self.LOGIN}
                                />
                            }

                            {!isAdminOrManager ? null :
                                <SelectView
                                    label='Роль'
                                    className='mb-1'
                                    disabled={user.ROLE != 'ADMN' || ID_OBJECT != null || this.props.ROLE != null}
                                    value={Self.ROLE}
                                    onChange={value => this.setState({ Self: { ...Self, ROLE: value } })}
                                    items={this.props.USER_ROLES.filter(u => u.ROLE != 'ADMN' && u.ROLE != 'TMPU').map(u => {
                                        return {
                                            value: u.ROLE,
                                            title: u.DESCR
                                        }
                                    })} />}

                            <FormControl fullWidth>
                                {form_fields['HUMANS'].map(input =>
                                    input.name == 'DESCR' && props.user.ROLE != 'ADMN' ? null :
                                        input.name == 'PHONE' ?
                                            <InputMask mask="+7 (999) 999-99-99" value={Self[input.name]} onChange={e => {
                                                let tmp = Object.assign({}, Self)
                                                tmp[input.name] = e.target.value.replace(/[ \t(\t,)\t,+\t,_,-]/g, '')
                                                this.setState({ Self: tmp })
                                            }}
                                                disabled={user.ROLE != 'ADMN' && !state.IhaveAccess}
                                                {...input}>
                                                {(inputProps) =>
                                                    <TextField
                                                        disabled={user.ROLE != 'ADMN' && !state.IhaveAccess}
                                                        variant='filled'
                                                        color='success'
                                                        {...inputProps}
                                                        className='w-100 mt-0 mb-1'
                                                        margin='dense'
                                                        inputRef={ref => this.input[input.name] = ref}
                                                        size='small'


                                                    />}
                                            </InputMask> :
                                            <TextField
                                                variant='filled'
                                                className='w-100 mt-0 mb-1'
                                                margin='dense'
                                                color='success'
                                                disabled={user.ROLE != 'ADMN' && !state.IhaveAccess}
                                                size='small'
                                                onChange={e => {
                                                    let tmp = Object.assign({}, Self)
                                                    tmp[input.name] = e.target.value
                                                    this.setState({ Self: tmp })
                                                }}
                                                inputRef={ref => this.input[input.name] = ref}
                                                value={Self[input.name]}
                                                {...input}
                                            />
                                )}
                            </FormControl>

                            {props.user.ROLE != "ADMN" ? null :
                                <Autocomplete
                                    className='mt-0 mb-1'
                                    multiple
                                    disabled={user.ROLE != 'ADMN'}
                                    color='success'
                                    value={Self.PERMISSIONS}
                                    options={Object.keys(props.USERS_PERMISSIONS)}
                                    onChange={(e, value) => {
                                        this.setState({
                                            Self: {
                                                ...this.state.Self,
                                                PERMISSIONS: value == null ? [] : value
                                            }
                                        })
                                    }} renderInput={(params) => (
                                        <TextField
                                            {...params}

                                            color='success'
                                            variant="filled"
                                            label="Права в системе"
                                            placeholder="Перечень прав доступа"
                                        />
                                    )}
                                />
                            }

                            {isAdminOrManager && (Self.ROLE == 'PRTN' || Self.ROLE == 'CPRT') ?
                                <DatePicker
                                    value={state.year + '-01-01'}
                                    views={['year']}
                                    renderInput={(params) => <TextField {...params} helperText={null} variant='filled'
                                        size='small'
                                        label='Год статистики' color='success' />}
                                    onChange={(value) => {
                                        this.setState({ year: moment(value).format('YYYY') * 1 })
                                    }}
                                />
                                : null}

                        </div>

                        {!isBigPopUp ? null :
                            <div className='col'>
                                {form_fields[Self.ROLE].map(input =>
                                    <TextField
                                        variant='filled'
                                        className='w-100 mb-1 mt-0'
                                        margin='dense'
                                        color='success'
                                        disabled={user.ROLE != 'ADMN'}
                                        size='small'
                                        onChange={e => {
                                            let tmp = Object.assign({}, Self)
                                            tmp[sinonim[Self.ROLE]][input.name] = e.target.value
                                            this.setState({ Self: tmp })
                                        }}
                                        inputRef={ref => this.input[input.name] = ref}
                                        value={Self[sinonim[Self.ROLE]][input.name]}
                                        {...input}
                                    />
                                )}
                                <UserDataContext.Provider value={{ Self: state.Self }}>
                                    {this.renderView()[Self.ROLE]()}
                                </UserDataContext.Provider>
                            </div>
                        }

                    </div>
                    {this.state.isLoaded && isAdminOrManager && (Self.ROLE == 'PRTN' || Self.ROLE == 'CPRT') ? <>
                        <div style={{ minHeight: 400 }}>
                            <ReactHighcharts config={{
                                chart: {
                                    type: 'spline',
                                    scrollablePlotArea: {
                                        minWidth: 100,
                                        scrollPositionX: 1
                                    }
                                },
                                title: {
                                    text: 'Статистика заказов за ' + state.year + ' год',
                                    align: 'left'
                                },
                                subtitle: {
                                    text: 'Группировка по месяцам',
                                    align: 'left'
                                },
                                xAxis: {
                                    type: 'datetime',
                                    categories: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
                                        'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                                    labels: {
                                        overflow: 'justify'
                                    }
                                },
                                yAxis: {
                                    title: {
                                        text: 'Количество карнизов согласовано'
                                    },
                                    minorGridLineWidth: 0,
                                    gridLineWidth: 0,
                                    alternateGridColor: null,

                                },
                                tooltip: {
                                    valueSuffix: 'шт',
                                    crosshairs: true,
                                    shared: true,
                                },
                                plotOptions: {
                                    series: {
                                        animation: false
                                    },
                                    line: {
                                        animation: false
                                    },
                                    spline: {

                                        lineWidth: 4,
                                        states: {
                                            hover: {
                                                lineWidth: 5
                                            }
                                        },
                                        marker: {
                                            enabled: false
                                        },


                                    }
                                },
                                series: [
                                    {
                                        name: GF.FIO(Self.FIO),
                                        data: this.ordersByMonths(
                                            props.ORDERS.filter(order =>
                                                order.ID_USER == Self.ID &&
                                                order.ID_STATUS == 13 &&
                                                order.MANAGER_READED == 1
                                            )
                                        )
                                    }
                                ],
                                navigation: {
                                    menuItemStyle: {
                                        fontSize: '10px'
                                    }
                                }
                            }} domProps={{ id: 'chartId' }} />
                        </div>

                        <table className='w-100 mt-3 mb-3 ' >
                            <thead>
                                <td> </td>
                                <td width='240px'></td>
                                <td width='140px' style={{
                                    textAlign: 'right'
                                }}>Номер</td>
                                <td width='140px'>Дата оформления</td>
                                <td width='120px'>Кол-во карнизов</td>
                                <td>Стоимость</td>
                                {!state.showDrafts ? null : <td width='120px'>Статус</td>}
                            </thead>
                            <tbody>
                                {userORDERS.map(order => {







                                    let Date_create = null
                                    let Year_create = null



                                    this.lastDateView = null




                                    var date = null
                                    var dateYear = null

                                    if (order.DATE_SENDED != null) {
                                        date = moment(order.DATE_SENDED).locale('ru').format('DD MMMM YYYY')
                                        dateYear = moment(order.DATE_SENDED).format('YYYY')


                                        if (date == moment().locale('ru').format('DD MMMM YYYY')) {
                                            date = 'сегодня'
                                        } else {
                                            date = date.replace(' ' + dateYear, '')
                                        }
                                    }




                                    let isToday = false
                                    date = moment(order.DATE_SENDED || order.DATE_CREATE).locale('ru').format('M YYYY')

                                    dateYear = moment(order.DATE_SENDED || order.DATE_CREATE).format('YYYY')


                                    date = date.replace(' ' + dateYear, '')

                                    Date_create = date
                                    Year_create = dateYear

                                    if (this.lastDate != date) {

                                        let orderMonthDate_0 = moment(`01.${Date_create}.${Year_create}`, 'DD.M.YYYY')
                                        let orderMonthDate_1 = moment(`01.${Date_create}.${Year_create}`, 'DD.M.YYYY')
                                        orderMonthDate_1.add(1, 'M')
                                        let textMonth = <p className='m-0'>{orderMonthDate_0.format('MMMM')} <small>{orderMonthDate_0.format('YYYY')}</small></p>

                                        this.lastDate = date

                                        let OrdersPriceMonth = 0
                                        let karnizCount = 0
                                        console.warn(orderMonthDate_0, orderMonthDate_1)
                                        userORDERS.forEach(order_ => {


                                            if (
                                                moment(order_.DATE_SENDED || order_.DATE_CREATE).isSameOrAfter(orderMonthDate_0) &&
                                                moment(order_.DATE_SENDED || order_.DATE_CREATE).isBefore(orderMonthDate_1)
                                            ) {
                                                //console.error(order_.GUID,order_.ALL_COAST  - order_.TAX_COAST)
                                                OrdersPriceMonth += order_.ALL_COAST - order_.TAX_COAST
                                                order_.ITEMS.forEach(item => {
                                                    karnizCount += item.ORDERS_COUNT
                                                })
                                            }
                                        })


                                        this.lastDateView = <tr style={{
                                            cursor: 'default',
                                            borderTop: '1px solid rgb(225,225,225)',
                                            background: 'rgba(198, 218, 232, 0.43)'
                                        }}><td colSpan={4} style={{
                                            textAlign: 'left',
                                            paddingLeft: 10,
                                            fontWeight: 'bold'
                                        }}>{textMonth}</td>
                                            <td style={{
                                                paddingBottom: 10,
                                                paddingTop: 10,
                                                fontSize: 15
                                            }}><b>{GF.makeCoast(karnizCount)}</b><small>шт</small></td>
                                            <td style={{
                                                paddingBottom: 10,
                                                paddingTop: 10,
                                                fontSize: 15
                                            }}><b>{GF.makeCoast(OrdersPriceMonth)}</b><small>руб</small></td>
                                            {!state.showDrafts ? null : <td></td>}
                                        </tr>
                                    }



                                    let itemsCount = 0

                                    order?.ITEMS?.forEach(item => {
                                        itemsCount += item.ORDERS_COUNT
                                    })

                                    return (<>
                                        {this.lastDateView}
                                        <tr key={'order_' + order.ID} style={{
                                            borderTop: '1px solid rgb(225,225,225)',
                                            paddingBottom: 2,
                                            cursor: 'pointer',
                                        }} onClick={() => {
                                            if (order.TYPE == 'Basket')
                                                this.props.openPopUp(<POP_BasketOrder ID_OBJECT={order.ID} />)
                                            else
                                                this.props.openPopUp(<POP_Order ID_OBJECT={order.ID} />)
                                        }}>
                                            <td style={{
                                                borderBottom: '1px solid color(srgb 0.9112 0.9258 0.9344)'

                                            }}>
                                                {(props.user.ROLE != 'MNGR' && props.user.ROLE != 'ADMN') || order.MANAGER_READED == 1 || order.ID_STATUS != 13 ? null :
                                                    <div style={{

                                                        background: 'rgb(52, 145, 255)',
                                                        height: 8,
                                                        width: 8,
                                                        borderRadius: 100,
                                                        margin: 'auto',
                                                        transition: '.5s'
                                                    }} />
                                                }
                                            </td>
                                            <td>
                                                {order.TYPE == 'Basket' ?
                                                    <b style={{
                                                        display: 'block',
                                                        textAlign: 'left',
                                                        fontSize: 12,
                                                        marginBottom: -6,
                                                        fontWeight: 400,
                                                    }}>Комплектующие</b>
                                                    : null}
                                                {order.ADDRESS != null && order.ADDRESS != '' && order.ADDRESS != ' ' ?
                                                    <b style={{
                                                        display: 'block',
                                                        textAlign: 'left',
                                                        fontSize: 12,
                                                        marginBottom: -6,
                                                        fontWeight: 400,
                                                    }}>{order.ADDRESS}</b> : null}
                                                <small style={{
                                                    lineHeight: '11px',
                                                    display: 'block',
                                                    textAlign: "left",
                                                    fontSize: 11,
                                                    marginTop: 5,
                                                    marginBottom: 5
                                                }}>

                                                    {(order.COMMENT || '').substr(0, 150)}{order.COMMENT?.length >= 150 ? '...' : ''}</small></td>
                                            <td style={{
                                                textAlign: 'right'
                                            }}><Chip color='success' label={order.GUID} size='small' /></td>
                                            <td>
                                                {order.DATE_SENDED == null ? ' - ' :
                                                    !isToday ? moment(order.DATE_SENDED).format('D MMM') :
                                                        moment(order.DATE_SENDED).format('HH:mm')}</td>

                                            <td>{itemsCount}<small>шт</small></td>

                                            <td>{GF.makeCoast(order.ALL_COAST - order.TAX_COAST)}<small>руб</small></td>

                                            {!state.showDrafts ? null :
                                                <td><Chip size='small' style={{
                                                    background: order.STATUS_COLOR,
                                                    color: 'white'
                                                }} label={order.STATUS_TITLE} /></td>}
                                        </tr>
                                    </>)



                                }
                                )}
                            </tbody>
                        </table>
                    </> : null}
                </POPscroll>

                {user.ROLE != 'ADMN' ?
                    user.ROLE == 'MNGR' && state.IhaveAccess && Self.ROLE == 'INST' ?
                        <POPbottom>
                            <StackHorizontal>
                                <Button color='success' onClick={() => this.Save()}>Сохранить</Button>
                            </StackHorizontal>
                        </POPbottom>
                        : null

                    : <POPbottom>

                        {this.props.ID_OBJECT == null || user.ROLE != 'ADMN' || Self.ID == 1 ? null :
                            <Button className='float me-2' color='error' onClick={() => this.Delete()}>удалить</Button>
                        }
                        <div style={{ display: 'flex', gap: 20 }}>
                            <Button color='success' className='float-end' onClick={() => this.Save()}>Сохранить</Button>

                            {this.props.ID_OBJECT == null || user.ROLE != 'ADMN' || Self.ID == 1 ? null :
                                <>
                                    <Button
                                        className='float-end me-2'
                                        color='secondary'
                                        onClick={e =>
                                            this.setState({ anchorEl: e.currentTarget })
                                        }
                                    >
                                        другие действия
                                    </Button>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={() => this.setState({ anchorEl: null })}
                                    >

                                        <MenuItem onClick={() => {
                                            this.ressetPass()
                                            this.setState({ anchorEl: null })
                                        }}>Сбросить пароль</MenuItem>
                                    </Menu>
                                </>
                            }
                        </div>
                    </POPbottom>
                }

            </POP>
        )



    }
}

const POP = styled.div`
width:${props => props.isBigPopUp ? '800px' : '400px'};

& .tagw .MuiAutocomplete-tag{
    width:100%;
    justify-content: space-between;
}
& .partners div, & .partners .MuiChip-label{
flex:1;
}

& table .MuiCheckbox-root{
margin-left:0px !important;
}
& table .MuiChip-deleteIcon{
    transition:.3s;
    opacity:0;
}
& table .MuiButtonBase-root:hover .MuiChip-deleteIcon{
    opacity:1;
}


& table td{
    padding-top:0px;
    padding-bottom:0px;
    font-size:13px;
    text-align:center;
  }
  & table tbody tr{
    transition:.3s;
    transform:scale(1);
    cursor:pointer;
  }
  & table tbody tr.partner:hover{
    transform:scale(1.01);
  }
  & table tbody tr:hover{
  
    background:rgb(241, 241, 241);
  
  }
  
  & table thead td{
    color:rgb(120,120,120);
    font-size:11px !important;
    padding-bottom:5px;
  }
`

const ManagerView = styled.div`
& .MuiChip-label p{
    margin:0px;
    text-align:left;
}
& small{
    margin-top: -5px;
    display: block;
}
& .MuiChip-root.MuiChip-filled{
    justify-content:space-between;
}
`



const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (arg) => dispatch(arg),
        getMANAGERS_ROOLES: (arg) => dispatch(getMANAGERS_ROOLES(arg)),
        closePopUp: (arg) => dispatch(closePopUp(arg)),
        openPopUp: (arg) => dispatch(openPopUp(arg)),
        getDB_Data: (operation, tableName, JSONparseKey) => dispatch(getDB_Data(operation, tableName, JSONparseKey)),

        getDB_Data_lazy: (tableName, JSONparseKey) => dispatch(getDB_Data_lazy(tableName, JSONparseKey)),
        getDB_Data_lazy_notID: (tableName, JSONparseKey) => dispatch(getDB_Data_lazy_notID(tableName, JSONparseKey)),
        getUSERS: (arg) => dispatch(getUSERS(arg)),
    };
};


const mapStateToProps = (store) => {

    return {
        PROJECTS: store.page.PROJECTS,
        COMPANYS: store.page.COMPANYS,
        USERS: store.page.USERS,
        user: store.user.data,
        USER_STATUS: store.page.USER_STATUS,
        USER_ROLES: store.page.USER_ROLES,
        MANAGERS_ROOLES: store.page.MANAGERS_ROOLES,
        ORDERS: store.page.ORDERS,
        PARTNER_COMPANYS: store.page.PARTNER_COMPANYS,
        ACCESSORIES_ORDERS: store.page.ACCESSORIES_ORDERS,
        USERS_PERMISSIONS: store.page.USERS_PERMISSIONS,
    };
};

const POP_User = connect(mapStateToProps, mapDispatchToProps)(POPView);

export default POP_User;

