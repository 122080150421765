import { Avatar, Button, ButtonGroup, Chip, Stack, Tab, Tabs } from '@mui/material'
import Immutable from 'immutable'
import React, { memo } from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getManagerInstallersRooles } from '../../../../../actions/InstallSchedule'
import { closePopUp, openPopUp } from '../../../../../actions/PopUpActions'
import { GF } from '../../../../../GlobalFunctions'
import useUsersMNGR from '../../../../../Hooks/Data/USERS/useUsersMNGR'
import POP_User, { UserDataContext } from '../POP_User'
import PopUser_MNGR_installers from './PopUser_MNGR_installers'



export default function PopUser_MNGR({ partnersTab }) {
    const { Self } = useContext(UserDataContext)
    const [tab, setTab] = useState('partners')

    const tabsPages = {
        partners: {
            title: 'Партнеры',
            view: partnersTab()
        },
        installers: {
            title: 'Монтажники',
            view: <PopUser_MNGR_installers />,

        }
    }
    const pages = Object.keys(tabsPages)

    return (<View>
        <Tabs
            value={tab}
            onChange={(e, newValue) => setTab(newValue)}
            textColor="secondary"
            indicatorColor="secondary"
        >
            {pages.map(page =>
                <Tab value={page} label={tabsPages[page].title} />
            )}
        </Tabs>

        {tabsPages[tab].view}

    </View >)
}

const View = styled.div`
margin-top:-47px;
`