
import { Check } from '@mui/icons-material';
import { Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Select, TextField, Tooltip, Typography,Grid } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getDB_Data,getDB_Data_lazy,getFileSustem,getUSERS } from '../../../actions/PageActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import {HexColorPicker} from 'react-colorful'





class POP_FolderReplase extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
            Self:{}
        }
        this.input=[]
    }

    

    componentDidMount() {
        const {props,state} = this
        const {COMPANYS,STATUSES,ID_OBJECT} = this.props

        if(ID_OBJECT!=null){
            var Self_tmp = props.FileSustem.find(p=>p.ID==ID_OBJECT)
            if(Self_tmp==null) return
            
            this.setState({Self:GF.ObjectCopy(Self_tmp)})
        }
    }

    Delete(){
        const {COMPANYS,DEPTS,ID_OBJECT} = this.props

        GF.API_task({
            operation:'deleteSome',
            Table:'ZTR_FOLDERS',
            ID:ID_OBJECT
        }).then(res=>{
            this.props.getDB_Data_lazy('DEPTS')
            setTimeout(()=>this.props.getUSERS(),400)
            
            this.props.closePopUp()
        })
    }


    Save(){
        GF.API_task({
            operation:'changeParent',
            block:'FileSustem',
            Type:'folder',
            ID:this.props.SelectedFolder.ID,
            NewParent_id:this.state.newParentId
        }).then(res=>{
            this.props.getFileSustem()
            this.props.closePopUp()
            /*
                this.props.getFileSustem() //aboutFiles
            */
        })
    }

    render (){
        const {COMPANYS,user} = this.props
        const {state,props} = this
       
    
        return (
            <POP>
                <h4>Перемещение</h4>
                <small>{props.SelectedFolder.PARENT_TITLE} / {props.SelectedFolder.TITLE}</small>
                <Autocomplete
                    className='mt-3 mb-3'
                    value={state.newParentId}
                    onChange={(e,value)=>this.setState({newParentId:value.ID})}
                    options={props.FileSustem.filter(f=>f.ID!=props.SelectedFolder.ID && f.ID != props.SelectedFolder.ID_PARENT)}
                    getOptionLabel={(option) => option.TITLE}
                    renderInput={(params) => <TextField {...params} label="Переместить в:" />}
                />
            
                <Button className='float-end' onClick={()=>this.Save()}>Переместить</Button>

            </POP>
        )

            
      
    }
}

const POP = styled.div`
width:300px;
`





const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
       
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 

        getDB_Data_lazy:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy(tableName,JSONparseKey)), 

        getFileSustem: (arg) => dispatch(getFileSustem(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        user:store.user.data,
        FileSustem:store.page.FileSustem,
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(POP_FolderReplase)

