import { Alert, AlertTitle, Button, CircularProgress, IconButton, TextField } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import POP_Alert from '../../PopUp/Views/POP_Alert'
import { connect, useDispatch } from 'react-redux';
import { closePopUp, openPopUp } from '../../../actions/PopUpActions';
import { useRef } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { POPbottom, POPscroll, POPtop } from '../../PopUp';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import { GlobalFunctions as GF } from '../../../GlobalFunctions';
import POP_AddBasketCount from './POP_AddBasketCount';
import { addToBasket, cleanBasket, getACCESSORIES_ORDERS, removeFromBasket, setCountInBasket, sincBasketDB_reqvest } from '../../../actions/PageActions';
import { LoadingButton } from '@mui/lab';

import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { useToasts } from 'react-toast-notifications';

function POP_Basket(props){
    const toastFuncs = useToasts()
    const dispatch = useDispatch()
    const [confimLoading, setConfimLoading] = useState(false)
    const [isConfimmed, setIsConfimmed] = useState(false)

    let updateTimer = null


    const updateFunction = ()=>{
        clearTimeout(updateTimer)
        updateTimer=setTimeout(()=>{
            GF.API_task({
                operation:'sincBasket',
                ACCESSORIES:props.ACCESSORIES_BASKET.map(a=>{
                    return{
                        COUNT:a.COUNT,
                        ID:a.ID
                    }
                })
            })
        },3000)
    }
    
    let data = {
        weight:0,
        price:0,
        count:0
    }
    let items = []
    props.ACCESSORIES_BASKET.forEach(basket=>{
        let item = props.ACCESSORIES.find(a=>a.ID==basket.ID_ACCESSORY)
        items.push({
            ...basket,
            ...item
        })
        data.weight+=item.WEIGHT==null? 0 : basket.COUNT*item.WEIGHT
        data.count+=basket.COUNT
        data.price+=basket.COUNT*item.PRICE
    })

    return(<POP>
        <POPscroll bottom top>
            <POPtop>
                <h3>Корзина <small><b>{data.count}</b> шт</small></h3>
                <div style={{
                    display:"flex",
                    gap:20,
                    paddingRight:10
                }}>
                    <b>{GF.makeCoast(data.price)} <small>руб</small></b>
                    <b>{GF.makeCoast(data.weight / 1000)} <small>кг</small></b>
                </div>
            </POPtop>
            
            {!isConfimmed?
            items.length>0?null:<small>В корзине пусто</small>:
            <Alert>
                <AlertTitle>Успешно</AlertTitle>    
                <p>Заказ был успешно оформлен. Ваш менеджер вернется с обратной связью в ближайшее время</p>
                <Button color='success' className='float-end' onClick={()=>props.closePopUp()}>Хорошо</Button>
            </Alert>}
            
           
            {items.map(item=>{
                let inBasket = item
                return(<div style={{
                    display:"flex",
                    alignContent:"center",
                    alignItems:'center',
                    gap:10,
                    marginBottom:20,
                }}>
                    {item?.IMAGES_URL?.at(0)==null?
                        <HomeRepairServiceIcon style={{
                            width: 50,
                            height: 50,
                            objectFit: 'contain'
                        }}/>:
                        <img style={{
                            width: 50,
                            height: 50,
                            objectFit: 'contain'
                        }} src={global.URL_host+'img/'+item.IMAGES_URL.at(0)} onClick={(e)=>{
                            e.stopPropagation()
                            props.openPopUp(<img style={{
                                margin: -30,
                                maxHeight:'70vh',
                                maxWidth:'80vw',
                                minHeight:300,
                                minWidth:300,
                                padding:20,
                                objectFit:'contain'
                            }}  src={global.URL_host+'img/'+item.IMAGES_URL.at(0)}/>)
                    }}/>}
                    <div>
                        <small style={{fontSize:10}}>{item.CATEGORY_TITLE} {item.ID_1C}</small>
                        <p className='m-0' style={{
                            lineHeight: '15px',
                            fontSize: 14
                        }}>{item.TITLE}</p>
                        <small className='m-0 d-block'><b>{GF.makeCoast(item.PRICE)}</b> руб {!!item.WEIGHT && <>/ <b>{GF.makeCoast(item.WEIGHT/1000)}</b> кг</>}</small>
                    </div>
                    
                    <div style={{
                        backgroundColor:'rgb(230,230,230)',
                        width:80,
                        display:'flex',
                        alignItems:'center',
                        alignContent:'center',  
                        justifyContent:'space-between',
                        paddingLeft:5,
                        paddingRight:5,
                        borderRadius:8,
                        paddingTop:5,
                        paddingBottom:5,
                        marginLeft:'auto'
                    }}>
                        <IconButton style={{ 
                            
                            textAlign: 'center',
                            width:20,
                            height:20
                        }} size='small' onClick={(e)=>{
                            e.stopPropagation()
                            props.removeFromBasket({
                                ID:item.ID
                            })
                            updateFunction()
                        }}>-</IconButton>
                        <div style={{
                            width:30,
                            fontSize:12,
                            textAlign:'center'
                        }} onClick={()=>{
                            props.openPopUp(<POP_AddBasketCount value={inBasket.COUNT} callBack={(count)=>{
                                props.setCountInBasket({
                                    ID:item.ID,
                                    COUNT:count
                                })
                                updateFunction()
                            }}/>)
                        }}>{inBasket.COUNT}</div>
                        <IconButton style={{ 
                            textAlign: 'center',
                            width:20,
                            height:20
                        }} size='small' onClick={(e)=>{
                            e.stopPropagation()
                            props.addToBasket({
                                ID:item.ID,
                                COUNT:1
                            })
                            updateFunction()
                        }}>+</IconButton>
                    </div>

                </div>)
            })}
            
        </POPscroll>
        <POPbottom style={{
            bottom:items.length==0?-55:0
        }}>
            <Button disabled={items.length==0} color='warning' onClick={()=>{
                props.openPopUp(<POP_Alert text='Убрать из корзины все товары?' buttons={[
                    <Button color='warning' onClick={()=>{
                        props.cleanBasket()
                        props.closePopUp()
                        toastFuncs.addToast('Корзина очищина', { appearance: 'warning',autoDismiss:true })
                    }}>Да, очистить</Button>,
                    <Button color='inherit' onClick={()=>{
                        props.closePopUp()
                    }}>Отмена</Button>
                ]}/>)
            }}>Очистить корзину</Button>
            <LoadingButton disabled={items.length==0}  color='success'
            endIcon={<ShoppingCartCheckoutIcon />}
            loadingPosition="end"
            onClick={()=>{
                props.openPopUp(<POP_Alert text={<>Оформить заказ на <b>{GF.makeCoast(data.price)}</b> руб,<br/> состоящий из <b>{GF.makeCoast(data.count)}</b> {GF.okonchanie(data.count,'товара','товаров','товаров')}?</>} buttons={[
                    <Button color='success' onClick={()=>{
                        if(confimLoading) return

                        setConfimLoading(true)
                        props.closePopUp()
                        
                        sincBasketDB_reqvest().then(()=>{
                            GF.API_task({
                                operation:'confimBasketOrder',
                                block:'Basket'
                            }).then(()=>{
                                dispatch(getACCESSORIES_ORDERS())
                                props.cleanBasket()
                                setIsConfimmed(true)
                            }).finally(()=>{
                                setTimeout(()=>{
                                    setConfimLoading(false)
                                },2000)
                            })
                        }).catch(()=>{
                            setConfimLoading(false)
                        })

                       

                       
                        
                    }} >Да, оформить</Button>,
                    <Button color='inherit' onClick={()=>{
                        props.closePopUp()
                    }}>Отмена</Button>
                ]}/>)
            }} loading={confimLoading}>Оформить заказ</LoadingButton>
        </POPbottom>
    </POP>)
}


const POP = styled.div`
width:400px;
`

const mapDispatchToProps = dispatch => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        addToBasket:(arg)=>dispatch(addToBasket(arg)),
        removeFromBasket:(arg)=>dispatch(removeFromBasket(arg)),
        setCountInBasket:(arg)=>dispatch(setCountInBasket(arg)),
        cleanBasket:(arg)=>dispatch(cleanBasket(arg)),
    }
}
const mapStateToProps = (store) => {
    return {
        ACCESSORIES_BASKET:store.page.ACCESSORIES_BASKET,
        ACCESSORIES:store.page.ACCESSORIES,
        user: store.user.data,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POP_Basket)