
import { Check, Delete } from '@mui/icons-material';
import { Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Select, TextField, Tooltip, Typography,Grid, ListItem, IconButton, ListItemButton,Menu, Switch, FormGroup, FormControlLabel, Alert } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getDB_Data,getDB_Data_lazy,getUSERS,getCHATS } from '../../../actions/PageActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'

import InputMask from 'react-input-mask';
import Question from '../../PopUp/Views/Question'
import { Label } from '../../components/moduls/Input';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SwitchCheck from '../../components/SwitchCheck';
import ComboBox from '../../components/moduls/ComboBox';
import Table from '../../components/Table';


class POP_selectReplacement extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
            replaceManager:null
        }
    }

    

    render (){
        const {state,props} = this
        const {NEW_PARTNERS,ID_OBJECT} = props
        const {Self,anchorEl,selectedRows,filter} = state


        return(<Question
            text='Кто будет замещать?'
            contentObject={<Autocomplete
                color='success'
                value={this.state.replaceManager}
                options={this.props.USERS.filter(u=>u.ROLE=='MNGR' && u.ID!=this.props.CurrentManager.ID)}
                getOptionLabel={(option) => option.FIO}
                onChange={(e,value)=>{
                    this.setState({replaceManager:value})
                }} renderInput={(params) => (
                <TextField
                    {...params}
                    color='success'
                    variant="standard"
                    label="Замещающий"
                    placeholder="ФИО менеджера"
                />
                )}
            />}
            buttons={[
                <Button disabled={this.state.replaceManager==null} color='warning' onClick={()=>{
                    this.props.closePopUp()
                    this.props.callBack(this.state.replaceManager)
                }}>На замещение</Button>,
                <Button onClick={()=>this.props.closePopUp()}>Отмена</Button>
            ]}
        />)

            
      
    }
}





const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 

        getDB_Data_lazy:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy(tableName,JSONparseKey)), 

      
        getUSERS: (arg) => dispatch(getUSERS(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        USERS:store.page.USERS,
        MANAGERS_ROOLES:store.page.MANAGERS_ROOLES,
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(POP_selectReplacement)




