
import { Check, Delete } from '@mui/icons-material';
import { Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Select, TextField, Tooltip, Typography,Grid, ListItem, IconButton, ListItemButton,Menu, Switch, FormGroup, FormControlLabel } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getDB_Data,getDB_Data_lazy,getUSERS,getCHATS } from '../../../actions/PageActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'

import InputMask from 'react-input-mask';
import Question from '../../PopUp/Views/Question'
import { Label } from '../../components/moduls/Input';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SwitchCheck from '../../components/SwitchCheck';
import ComboBox from '../../components/moduls/ComboBox';




const form_fields = [
    {
      label:'Заголовок',
      name:'TITLE'
    },
    {
        label:'Подзаголовок',
        name:'SUB_TITLE',
    },
  ]

  


class POP_News extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
            Self:{
                TYPE:'N'
            },
            anchorEl:null
        }
        this.input=[]
        this.can=true
    }

    

    componentDidMount() {
        const {state,props} = this
        const {ID_OBJECT,NEW_PARTNERS} = props

        if(ID_OBJECT!=null){
            var Self = props.NEWS.find(p=>p.ID==ID_OBJECT)
            if(Self==null) return
            Self = GF.ObjectClone(Self)
            this.setState({Self})
        }
    }

 
    Delete(){
        const {state,props} = this
        const {COMPANYS,DEPTS,ID_OBJECT} = this.props

        new Promise((resolve,reject)=>{
            this.props.openPopUp(<Question text={'Удаление заявки сотрет всю информацию о клиенте в системе'}
            buttons={[
                <Button className='m-2' color='error' onClick={()=>resolve()}>Удалить</Button>,
                <Button className='m-2'  onClick={()=>reject()}>Отмена</Button>
            ]}/>)
        }).then(()=>{

            GF.API_task({
                operation:'deleteSome',
                Table:'NEWS',
                ID:ID_OBJECT
            }).then(res=>{
                this.props.getDB_Data_lazy('NEWS')
                this.props.closePopUp()
            })
            
        }).finally(()=>{
            this.props.closePopUp()
        })

        
    }


    Save(){
        const {state,props} = this
        if(!this.can) return
        this.can=false

        let Self = GF.ObjectCopy(state.Self)
        delete(Self.DATE_CREATE)
        delete(Self.ID)

        let data = Self
        
        GF.API_task({
            operation:'createUpdateSome',
            Table:'NEWS',
            data,
            ID:props.ID_OBJECT
        }).then(res=>{
            this.props.getDB_Data_lazy('NEWS')
            this.props.closePopUp()
        }).finally(()=>{
            this.can=true
        })
    }

    

    render (){
        const {state,props} = this
        const {NEW_PARTNERS,ID_OBJECT} = props
        const {Self,anchorEl} = state
        const open = Boolean(anchorEl)

        
       
        if(ID_OBJECT!=null && Self.ID==null) return <div/>
     
        return (
            <POP>
                {ID_OBJECT==null?
                    <h4>Новая запись</h4>:
                    <h4>Запись #{ID_OBJECT}</h4>
                }

                <div style={{
                    display:'flex',
                    flexDirection:"row",
                    justifyContent:"space-between",
                    alignItems:"center",
                    alignContent:"center"
                }}>

                <FormControl variant="filled" sx={{minWidth: 200}} color='success'>
                    <InputLabel >Тип записи</InputLabel>
                    <Select
                   
                    label='Тип записи'
                    value={Self.TYPE}
                    onChange={e=>
                        this.setState({
                            Self:GF.changeParametr(state.Self,'TYPE',e.target.value)
                        })
                    }>
                        <MenuItem value={'N'}>Новость</MenuItem>
                        <MenuItem value={'A'}>Акция</MenuItem>
                    </Select>
                </FormControl>


                <FormControlLabel className='m-0' control={
                    <SwitchCheck 
                    checked={Self.IS_PRIMARY}
                    onChange={e=>
                        this.setState({
                            Self:GF.changeParametr(state.Self,'IS_PRIMARY',e.target.checked)
                        })
                    }
                />} label="Выводить запись сообщением в приложении?" />
                </div>

                

                
                
               
               {form_fields.map(input => 
                        <TextField
                            className='w-100 mt-3'
                            margin='dense'
                            variant="filled"
                            size='small'
                            color='success'
                            {...input}
                            value={state.Self[input.name]}
                            onChange={(e)=>
                                this.setState({
                                    Self:GF.changeParametr(state.Self,input.name,e.target.value)
                                })
                            }
                            inputRef={ref=>this.input[input.name]=ref}
                        />
                )}

                
                <ReactQuill style={{
                    position:'relative',
                    zIndex:10,
                    
                }} className='mb-3 mt-3' theme="snow" value={Self.TEXT || ""} placeholder='Текст записи' onChange={(value)=>
                    this.setState({Self:{...Self,TEXT:value}})
                }/>

                {this.props.ID_OBJECT==null?null:
                    <Button className='float me-2' color='error' onClick={()=>this.Delete()}>удалить</Button>
                }
                <Button  color='success' className='float-end' onClick={()=>this.Save()}>Сохранить</Button>

                
                
            </POP>
        )

            
      
    }
}

const POP = styled.div`
width:700px;
`


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 

        getDB_Data_lazy:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy(tableName,JSONparseKey)), 

      
        getUSERS: (arg) => dispatch(getUSERS(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        NEWS:store.page.NEWS
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(POP_News)




