import Immutable from "immutable"
import moment from "moment"
import { GlobalFunctions as GF } from "../GlobalFunctions"
import { store } from '../store/configureStore'



export function JSONparse(data, key) {
  data.forEach((d, index) => {
    try {
      data[index][key] = JSON.parse(d[key] || [])
    } catch (e) { data[index][key] = [] }
  })
  return data
}

export function addToBasket(tovar) {
  return (dispatch) => {

    let ACCESSORIES_BASKET = Immutable.fromJS(store.getState().page.ACCESSORIES_BASKET)

    const index = ACCESSORIES_BASKET.findIndex(basket => basket.get('ID_ACCESSORY') == tovar.ID)
    if (index == -1) {
      ACCESSORIES_BASKET = ACCESSORIES_BASKET.push({
        ID_ACCESSORY: tovar.ID,
        COUNT: tovar.COUNT || 1
      })
    } else {
      ACCESSORIES_BASKET = ACCESSORIES_BASKET.update(index,
        tovar => tovar.set('COUNT', tovar.get('COUNT') + 1)
      )
    }

    dispatch({
      type: 'sincBasket',
      payload: ACCESSORIES_BASKET.toJS()
    })
  }
}

export function removeFromBasket(tovar) {
  return (dispatch) => {

    let ACCESSORIES_BASKET = Immutable.fromJS(store.getState().page.ACCESSORIES_BASKET)

    const index = ACCESSORIES_BASKET.findIndex(basket => basket.get('ID_ACCESSORY') == tovar.ID)
    if (index == -1) {

    } else {
      ACCESSORIES_BASKET = ACCESSORIES_BASKET.update(index,
        tovar_basket => tovar_basket.set('COUNT', tovar_basket.get('COUNT') - 1)
      )
      if (ACCESSORIES_BASKET.getIn([index, 'COUNT']) <= 0) {
        ACCESSORIES_BASKET = ACCESSORIES_BASKET.delete(index)
      }
    }

    dispatch({
      type: 'sincBasket',
      payload: ACCESSORIES_BASKET.toJS()
    })
  }
}

export function setCountInBasket(tovar) {
  return (dispatch) => {

    let ACCESSORIES_BASKET = Immutable.fromJS(store.getState().page.ACCESSORIES_BASKET)

    const index = ACCESSORIES_BASKET.findIndex(basket => basket.get('ID_ACCESSORY') == tovar.ID)
    if (index == -1 && tovar.COUNT != 0) {
      ACCESSORIES_BASKET = ACCESSORIES_BASKET.push({
        ID_ACCESSORY: tovar.ID,
        COUNT: tovar.COUNT || 1
      })
    } else {
      if (tovar.COUNT > 0) {
        ACCESSORIES_BASKET = ACCESSORIES_BASKET.update(index,
          tovar_basket => tovar_basket.set('COUNT', tovar.COUNT)
        )
      } else {
        ACCESSORIES_BASKET = ACCESSORIES_BASKET.delete(index)
      }
    }

    dispatch({
      type: 'sincBasket',
      payload: ACCESSORIES_BASKET.toJS()
    })
  }
}

export function setBasket(newBasket) {
  return (dispatch) => {

    dispatch({
      type: 'sincBasket',
      payload: newBasket || []
    })
  }
}

export function cleanBasket(tovar) {
  return (dispatch) => {
    dispatch({
      type: 'sincBasket',
      payload: []
    })

  }
}

export function sincBasketDB_reqvest() {
  return GF.API_task({
    operation: 'sincBasket',
    block: 'Basket',
    ACCESSORIES: store.getState().page.ACCESSORIES_BASKET.map(a => {
      return {
        COUNT: a.COUNT,
        ID_ACCESSORY: a.ID_ACCESSORY
      }
    })
  })
}


export function sincBasketDB() {
  clearTimeout(global.sincBasketDB_timer)
  global.sincBasketDB_timer = setTimeout(() => {
    sincBasketDB_reqvest()
  }, 3000)
}


export function getACCESSORIES() {
  return (dispatch) => {

    GF.API_task({
      operation: 'getACCESSORIES',
      block: 'Basket'
    }).then(data => {
      dispatch({
        type: 'JUST_SET_VALUE',
        key: 'ACCESSORIES',
        payload: data
      })
    })

  }
}


export function getACCESSORIES_CATEGORIS() {
  return (dispatch) => {

    GF.API_task({
      operation: 'getACCESSORIES_CATEGORIS',
      block: 'Basket'
    }).then(data => {

      dispatch({
        type: 'JUST_SET_VALUE',
        key: 'ACCESSORIES_CATEGORIS',
        payload: data
      })
    })

  }
}


export function cancelBasketOrder(idBasketOrder, idUserOwner, callBack) {
  return (dispatch) => {

    GF.API_task({
      operation: 'cancelBasketOrder',
      block: 'Basket',
      ID_ORDER: idBasketOrder,
      ID_USER: idUserOwner
    }).then(data => {
      dispatch({
        type: 'JUST_SET_VALUE',
        key: 'ACCESSORIES_ORDERS',
        payload: data
      })
    }).finally(() => {
      if (callBack != null)
        setTimeout(() => callBack(), 1)
    })



  }
}



export function getACCESSORIES_BASKET() {
  return (dispatch) => {

    GF.API_task({
      operation: 'getACCESSORIES_BASKET',
      block: 'Basket'
    }).then(data => {
      dispatch({
        type: 'JUST_SET_VALUE',
        key: 'ACCESSORIES_BASKET',
        payload: data
      })
    })

  }
}

export function getACCESSORIES_ORDERS() {
  return (dispatch) => {

    GF.API_task({
      operation: 'getACCESSORIES_ORDERS',
      block: 'Basket'
    }).then(data => {

      dispatch({
        type: 'JUST_SET_VALUE',
        key: 'ACCESSORIES_ORDERS',
        payload: data
      })
    })

  }
}


export function getORDERS(callBack) {
  return (dispatch) => {

    GF.API_task({
      operation: 'getORDERS'
    }).then(data => {

      data.forEach((d, di) => {
        d.ITEMS.forEach((itm, i) => {
          try {
            data[di].ITEMS[i].RESULT = JSON.parse(itm.RESULT)
          } catch { }

        })
        //console.log('data[di].IMAGES_URL',data[di].IMAGES_URL)
        data[di].IMAGES_URL = d.IMAGES_URL == null || d.IMAGES_URL == '' ? [] : JSON.parse(d.IMAGES_URL)
      })


      dispatch({
        type: 'SET_ORDERS',
        payload: data
      })
      if (callBack != null) callBack()
    })

  }
}

export function getUsersPermissions(arg) {
  return (dispatch) => {
    GF.API_task({
      operation: "getUsersPermissions",
      block: 'PublicFunctions_Auth'
    }).then(res => {

      let result = {}
      res.forEach(r => {
        let key = r.NAME + ''
        result[key] = {}
        delete (r.NAME)
        result[key] = { ...r }
      })

      dispatch({
        type: 'JUST_SET_VALUE',
        key: 'USERS_PERMISSIONS',
        payload: result
      })
    })
  }
}

export function getTexts(arg) {
  return (dispatch) => {
    GF.API_task({
      operation: "getTexts",
      block: 'OutOfAuth'
    })
      .then(res => {

        dispatch({
          type: "GET_TEXTS",
          payload: res
        })
      })
  }
}

export function getDB_Data(operation, tableName, JSONparseKey) {
  return (dispatch) => {
    GF.API_task({
      operation
    }).then(data => {

      if (Array.isArray(JSONparseKey)) {

        JSONparseKey.forEach(key => {
          if (key != null)
            JSONparse(data, key)
        })

      }
      else
        if (JSONparseKey != null)
          JSONparse(data, JSONparseKey)

      dispatch({
        type: 'JUST_SET_VALUE',
        key: tableName,
        payload: data
      })
    })

  }
}

export function getFileSustem(arg) {
  return (dispatch) => {

    GF.API_task({
      operation: 'getFileSustem',
      block: 'FileSustem'
    }).then(data => {
      setTimeout(() => {
        try {
          arg()
        } catch { }
      }, 100)
      dispatch({
        type: 'JUST_SET_VALUE',
        payload: data,
        key: 'FileSustem'
      })
    })

  }
}


export function getDB_Data_lazy(tableName, JSONparseKey) {
  return (dispatch) => {
    GF.API_task({
      operation: 'getDataFromTable',
      Table: tableName
    }).then(data => {

      if (Array.isArray(JSONparseKey)) {

        JSONparseKey.forEach(key => {
          if (key != null)
            JSONparse(data, key)
        })

      }
      else
        if (JSONparseKey != null)
          JSONparse(data, JSONparseKey)

      dispatch({
        type: 'JUST_SET_VALUE',
        key: tableName,
        payload: data
      })
    })

  }
}

export function getDB_Data_lazy_notID(tableName, JSONparseKey) {
  return (dispatch) => {
    GF.API_task({
      operation: 'getDataFromTable',
      noSorting: true,
      Table: tableName
    }).then(data => {

      if (Array.isArray(JSONparseKey)) {

        JSONparseKey.forEach(key => {
          if (key != null)
            JSONparse(data, key)
        })

      }
      else
        if (JSONparseKey != null)
          JSONparse(data, JSONparseKey)

      dispatch({
        type: 'JUST_SET_VALUE',
        key: tableName,
        payload: data
      })
    })

  }
}




export function getVARS(arg) {
  return (dispatch) => {

    GF.API_task({
      operation: 'getVARS'
    }).then(data => {
      let obj = {}
      data.forEach(d => {
        if (!isNaN(d.VALUE))
          d.VALUE = d.VALUE * 1
        obj[d.NAME] = {}
        obj[d.NAME].value = d.VALUE
        obj[d.NAME].category = d.CATEGORY
      })
      dispatch({
        type: 'JUST_SET_VALUE',
        payload: obj,
        key: 'VARS'
      })
    })

  }
}





export function getMyPartners() {
  return async (dispatch) => {

    const USERS = await GF.WaitData('USERS')
    const MANAGERS_ROOLES = await GF.WaitData('MANAGERS_ROOLES')

    let myPartners = []

    let userData = store.getState().user.data


    MANAGERS_ROOLES.forEach(roole => {
      if (roole.ID_USER == userData.ID || roole.ID_REPLACEMENT == userData.ID) {

        let user = USERS.find(user => user.ID == roole.ID_RESPONS)
        if (user != null)
          myPartners.push({ ...{ ...user } })


      }
    })

    dispatch({
      type: 'JUST_SET_VALUE',
      payload: myPartners,
      key: 'MyPartners'
    })

  }
}

export function getMANAGERS_ROOLES() {
  return (dispatch) => {
    GF.API_task({
      operation: 'getMANAGERS_ROOLES',
    }).then(data => {
      dispatch({
        type: 'JUST_SET_VALUE',
        payload: data,
        key: 'MANAGERS_ROOLES'
      })
    })
  }
}












export function getUSERS(callBack) {
  return (dispatch) => {

    GF.API_task({
      operation: 'getUSERS'
    }).then(async data => {

      JSONparse(data, 'IMAGES_URL')
      JSONparse(data, 'PERMISSIONS')

      let PARTNERS = []

      let MANAGERS_ROOLES = []

      await GF.API_task({
        operation: 'getMANAGERS_ROOLES',
      }).then(data => {
        MANAGERS_ROOLES = data
        dispatch({
          type: 'JUST_SET_VALUE',
          payload: MANAGERS_ROOLES,
          key: 'MANAGERS_ROOLES'
        })
      })


      await GF.API_task({
        operation: 'getDataFromTable',
        Table: 'PARTNERS',
        noSorting: true
      }).then(data => {
        PARTNERS = data
      })




      let PARTNER_COMPANYS = await GF.WaitData('PARTNER_COMPANYS')

      data.forEach((d, i) => {
        if (d.ROLE == 'PRTN' || d.ROLE == 'СPRT') {
          data[i].PARTNER = Object.assign({}, PARTNERS.find(p => p.ID_USER == d.ID))
          if (d.PARTNER.ID_COMPANY == null)
            data[i].COMPANY = {}
          else {

            data[i].COMPANY = Object.assign({}, PARTNER_COMPANYS.find(p => p.ID == data[i].PARTNER.ID_COMPANY))
            delete (data[i].PARTNER.ID_USER)
          }

          delete (data[i].ID_COMPANY)
        }

      })

      data.forEach((d, i) => { //второй раз проходимся по всем юзерам тк в менеджеров лучше запомнинать уже посчитанные значения
        if (d.ROLE == 'MNGR') {
          data[i].MANAGER = {
            RESPONSE: MANAGERS_ROOLES.filter(r => r.ID_USER == d.ID || r.ID_REPLACEMENT == d.ID)
          }
          data[i].MANAGER.RESPONSE.forEach((r, ri) => {

            data[i].MANAGER.RESPONSE[ri] = data.find(user => user.ID == r.ID_RESPONS)

            if (data[i].MANAGER.RESPONSE[ri] != null) {

              data[i].MANAGER.RESPONSE[ri] = GF.ObjectCopy(data[i].MANAGER.RESPONSE[ri])

              if (r.ID_USER != d.ID)//Если это замена - то помечаем
                data[i].MANAGER.RESPONSE[ri].isReplacement = true
              else
                data[i].MANAGER.RESPONSE[ri].isReplacement = false
            }
          })
          data[i].MANAGER.RESPONSE = data[i].MANAGER.RESPONSE.filter(r => r != null)
        }
      })

      if (callBack != null)
        setTimeout(() => callBack(), 1)

      dispatch({
        type: 'JUST_SET_VALUE',
        payload: [...data],
        key: 'USERS'
      })

    })
  }
}




