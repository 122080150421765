

import { Button} from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { openPopUp } from '../../../actions/PopUpActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';


class Index extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.input=[] 

        if(props.files==null){
            this.GETs = GF.urlGETparams()
            this.filesUrls = this.GETs.get('files')
            if(this.filesUrls!=null)
                this.filesUrls = JSON.parse(this.filesUrls)
            else
                this.filesUrls = []
        }else{
            this.filesUrls = props.files
        }
        
    }

  

    render (){
        const {user,STATUSES,USERS} = this.props
       
        return(<div className='p-3'>
            <h3>Файлы для скачивания</h3>
            {this.filesUrls.map(file=>
                <a style={{
                    textDecoration:'none'
                }} href={global.URL_server+'files/'+file.url} download={file.title}><Button id='primaryButton' color='success' className='mb-3 w-100' startIcon={<InsertDriveFileIcon/>} style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingLeft: 20
                }}>{file.title}</Button></a>
            )}
        </div>)
    
      
    }
}



const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
        
        user: store.user.data,
        POPUPS:store.page.POPUPS,
        STATUSES:store.page.STATUSES,
        USERS:store.page.USERS,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(Index)

