
import { Button, TextField } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getDB_Data, getDB_Data_lazy, getUSERS } from '../../../actions/PageActions';
import { closePopUp, openPopUp } from '../../../actions/PopUpActions';
import { GlobalFunctions as GF } from '../../../GlobalFunctions';

import 'react-quill/dist/quill.snow.css';
import { POPbottom, POPscroll } from '../../PopUp';
import POP_Alert from '../../PopUp/Views/POP_Alert';



const form_fields = [

    {
        label: 'Ваше сообщение',
        name: 'COMMENT',
        multiline: true,
        rows: 10
    },
]



class POP_DevComment extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
            message: null
        }
        this.input = []
        this.can = true
    }



    componentDidMount() {
        const { state, props } = this
        const { ID_OBJECT, NEW_PARTNERS } = props

        if (ID_OBJECT != null) {
            var Self = props.NEWS.find(p => p.ID == ID_OBJECT)
            if (Self == null) return
            Self = GF.ObjectClone(Self)
            this.setState({ Self })
        }
    }



    render() {
        const { state, props } = this
        const { NEW_PARTNERS, ID_OBJECT } = props
        const { Self, anchorEl } = state
        const open = Boolean(anchorEl)





        return (
            <POP>
                <h3>Написать разработчикам</h3>
                <p>Вы можете задать любой вопрос о приложении или описать проблему, с которой столкнулись</p>

                <POPscroll bottom>
                    {form_fields.map(input =>
                        <TextField
                            className='w-100 mt-1'
                            margin='dense'
                            variant="filled"
                            size='small'
                            color='success'
                            {...input}
                            value={state.message}
                            onChange={(e) =>
                                this.setState({
                                    message: e.target.value
                                })
                            }
                            inputRef={ref => this.input[input.name] = ref}
                        />
                    )}
                </POPscroll>
                <POPbottom>
                    <Button color='success' onClick={() => {
                        props.closePopUp()
                        GF.API_task({
                            operation: 'sendDevComment',
                            block: 'PublicFunctions_Auth',
                            text: state.message,
                            OS: 'web ' + GF.browserDetection(),
                            AppVersion: null
                        }).then(data => {
                            props.openPopUp(<POP_Alert text='Сообщение отправленно разработчикам' />)
                        })
                    }}>Отправить</Button>
                </POPbottom>


            </POP>
        )



    }
}

const POP = styled.div`
width:500px;
`


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp: (arg) => dispatch(closePopUp(arg)),
        openPopUp: (arg) => dispatch(openPopUp(arg)),
        getDB_Data: (operation, tableName, JSONparseKey) => dispatch(getDB_Data(operation, tableName, JSONparseKey)),

        getDB_Data_lazy: (tableName, JSONparseKey) => dispatch(getDB_Data_lazy(tableName, JSONparseKey)),


        getUSERS: (arg) => dispatch(getUSERS(arg)),
    };
};


const mapStateToProps = (store) => {

    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POP_DevComment)




