import React from 'react';

export default function NewOrderDot (props){
    return(
        <div style={{
            background:'rgb(52,145,255)',
            borderRadius: 50,
            height: 12,
            width: 12,
            textAlign: 'center',
            position: 'absolute',
            fontSize: 8,
            lineHeight:'13px',
            color: 'white',
            fontWeight: 'bold',
            top: 10,
            left:35,
        }}>
            {props.children}
        </div>
    )
}