

import { useTab } from '@mui/base';
import { Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Select, TextField, Tooltip, Typography,Grid, ListItem, IconButton, ListItemButton,Menu, Switch, FormGroup, FormControlLabel, Alert } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { closePopUp } from '../../../actions/PopUpActions';
import Debounce from '../../../Class/Debounce';
import Question from '../../PopUp/Views/Question';

export default function POP_CanselReson({onAccept,onCansel}){

    const dispatch = useDispatch()
    const [text,setText] = useState(null)
    const [error,setError] = useState(false)
    const toasts = useToasts()

    function ChangeText(e){
        setText(e.target.value)
        if(error) setError(false)
    }

    return(<Question
            text='Опишите причину отмены'
            contentObject={<TextField
                color='success'
                variant="filled"
                error={error}
                label="Причина отмены"
                placeholder="Опишите причину отмены данной установки. Это сообщение получит ваш менеджер"
                multiline={true}
                rows={10}
                className='w-100'
                value={text}
                onChange={ChangeText}
            />}
            buttons={[
                <Button color='error' onClick={()=>{
                    Debounce.start(()=>{
                        if(!(text?.length>10)){
                            setError(true)
                            toasts.addToast('Опишите причину подробнее', { appearance: 'error',autoDismiss:true })
                            return
                        }
                        dispatch(closePopUp())
                        !!onAccept && onAccept(text)
                    },1000)
                }}>Отправить и отменить</Button>,
                <Button onClick={()=>{
                    dispatch(closePopUp())
                    !!onCansel && onCansel()
                }}>Назад</Button>
            ]}
        />)
   
      
    
}








