import { Button } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import useUsersINST from '../../../Hooks/Data/USERS/useUsersINST'
import { openPopUp } from '../../../actions/PopUpActions'
import { POPscroll, POPtop } from '../../PopUp'
import POP_User from '../DataBase/pages/POP_User'



export default function POP_InstallersList() {
    const UsersINST = useUsersINST()
    const managerInstallersRooles = useSelector(state => state.page.ManagerInstallersRooles)
    const selfUser = useSelector(state => state.user.data)
    const managerInstallers = UsersINST

    const dispatch = useDispatch()





    return <POP>
        <POPtop>
            <h5 className='m-0'>Список монтажников</h5>
            <Button color='success' size='small' onClick={() => {
                dispatch(openPopUp(<POP_User ROLE='INST' />))
            }}>Создать нового</Button>
        </POPtop>
        <POPscroll top>
            {managerInstallers.length > 0 ? null : <small>Нет доступных монтажников</small>}
            {managerInstallers.map(user => {

                return (<>
                    <Button size='small' color='inherit' onClick={() =>
                        dispatch(openPopUp(<POP_User ID_OBJECT={user.ID} />))
                    } className='w-100' style={{
                        paddingLeft: 5,
                        transition: '.3s'
                    }}> <div className='w-100'>
                            <p className='m-0 text-start'>{user.FIO}</p>
                            <small className='m-0 d-block text-start'>{user.ROLE_TITLE}</small>
                        </div>
                    </Button>
                    <hr className='m-0 p-0' />
                </>)
            })}
        </POPscroll>
    </POP>
}

const POP = styled.div`
    width:400px;
`