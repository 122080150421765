import React from 'react';
import styled from 'styled-components';

import { Button, Chip } from '@mui/material';
import Kalend, { CalendarView } from 'kalend'; // import component
import 'kalend/dist/styles/index.css'; // import styles
import moment from 'moment';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInstallSchedule, setInstallSchedule } from '../../../actions/InstallSchedule';
import { openPopUp } from '../../../actions/PopUpActions';
import UpdateInterval from '../../../Class/UpdateInterval';
import { GF } from '../../../GlobalFunctions';
import useIsManagerOrAdmin from '../../../Hooks/useIsManagerOrAdmin';
import Pallet from '../../../Pallet';
import StackHorizontal from '../../components/StackHorizontal';
import POP_InstallersList from './POP_InstallersList';
import POP_InstallSchedule from './POP_InstallSchedule';




export default function Inst_schedule() {

    const dispatch = useDispatch()
    const [events, setEvents] = useState([])
    const [sumPrice, setSumPrice] = useState(0)
    const [calendView, setCalendView] = useState(null)
    const InstallSchedule = useSelector(state => state.page.InstallSchedule || [])
    const Statuses = useSelector(state => state.page.STATUSES)

    const isManagerOrAdmin = useIsManagerOrAdmin()

    useEffect(() => {
        UpdateInterval.start(() => {
            dispatch(getInstallSchedule())
        }, 30000)
        return (() => {
            UpdateInterval.stop()
        })
    }, [])

    useLayoutEffect(() => {
        if (calendView == null || (events?.length || 0) == 0) return

        const from = moment(calendView.rangeFrom)
        const to = moment(calendView.rangeTo)

        let sum = 0
        events.forEach(e => {
            if (e.schedule.dateTimeStart.isBetween(from, to))
                sum += e.schedule.PRICE
        })

        if (sum != sumPrice)
            setSumPrice(sum)

    }, [calendView, events])

    useLayoutEffect(() => {

        let events = InstallSchedule.map(schedule => {

            return ({
                id: schedule.ID,
                startAt: schedule.dateTimeStart.format('YYYY-MM-DDTHH:mm:ssZ'),
                endAt: schedule.dateTimeEnd.format('YYYY-MM-DDTHH:mm:ssZ'),
                timezoneStartAt: 'Europe/Moscow',
                summary: schedule.GUID == null ?
                    schedule.ADDRESS + (isManagerOrAdmin ? ' | ' + schedule.USER_INSTALL_FIO : '')
                    :
                    schedule.GUID + ' | ' + (isManagerOrAdmin ? schedule.USER_INSTALL_FIO : schedule.ADDRESS),
                color: schedule.STATUS_COLOR,
                calendarID: schedule.ID_USER_INSTALL,
                schedule
            })
        })
        setEvents(events)

    }, [InstallSchedule])





    return (<View isManagerOrAdmin={isManagerOrAdmin} style={{
        display: 'flex',
        margin: -20,
        width: 'calc(100% + 40px)',
        height: 'calc(100% + 40px)',
    }}>
        <Chip size='small' label='UTC+3 MSC' style={{
            position: 'absolute',
            top: 3,
            left: 219,
            transform: 'scale(0.7)'
        }} />

        <Chip size='small' label={`Расходы: ${GF.makeCoast(sumPrice)}руб`} style={{
            position: 'absolute',
            top: 60,
            right: 13,
        }} />

        {!isManagerOrAdmin ? null :
            <StackHorizontal style={{
                position: 'absolute',
                top: 21,
                right: 265,
            }}>
                <Button size='small' color='success' onClick={() => dispatch(openPopUp(<POP_InstallSchedule />))}>Новый монтаж</Button>
                <Button size='small' color='success' onClick={() => dispatch(openPopUp(<POP_InstallersList />))}>Список монтажников</Button>
            </StackHorizontal>
        }
        <div className='legend' style={{
            position: 'absolute',
            top: 60,
            left: 20,
            display: "flex",
            cursor: 'pointer',
            gap: 20,
            paddingLeft: 20
        }}>
            <small className='title'>Легенда</small>
            <StackHorizontal className='legendDescr'>
                {Statuses.map(st =>
                    st.ENTITY == 'INSTALL_SCHEDULE' &&
                    <StackHorizontal alignItems='center'>
                        <div style={{
                            borderRadius: 100,
                            backgroundColor: st.COLOR,
                            width: 5,
                            height: 5,
                        }} />
                        <small className='me-4'>{st.TITLE}</small>
                    </StackHorizontal>
                )}
            </StackHorizontal>
        </div>
        <Kalend
            onPageChange={setCalendView}
            colors={{
                light: {
                    primaryColor: Pallet.Accent,
                },
            }}
            onEventDragFinish={(prevEvent, updatedEvent, events) => {
                UpdateInterval.resset()
                console.warn('onEventDragFinish', updatedEvent)
                if (updatedEvent.schedule.ID_STATUS != 23 && updatedEvent.schedule.ID_STATUS != 19 && updatedEvent.schedule.ID_STATUS != 20) {
                    return
                }


                const schedules = []
                const eventsNew = []

                const newDateTime_start = (moment(updatedEvent.startAt))
                const newDateTime_end = (moment(updatedEvent.endAt))

                const makeTime = (time, canDayAdding = true) => {
                    if (time.m < 15) time.m = time.m < 7 ? 0 : 15
                    else if (time.m < 30) time.m = time.m < 22 ? 15 : 30
                    else if (time.m < 45) time.m = time.m < 37 ? 30 : 45
                    else if (time.m < 52) time.m = 45
                    else {
                        if (time.h < 23) {
                            time.h++
                            time.m = 0
                        } else {
                            if (canDayAdding) {
                                time.h = 0
                                time.m = 0
                                newDateTime_start.add(1, 'day')
                                newDateTime_end.add(1, 'day')
                            } else {
                                time.h = 23
                                time.m = 45
                            }
                        }
                    }

                    return time
                }
                let TIME_START = {
                    h: newDateTime_start.format('H') * 1,
                    m: newDateTime_start.format('m') * 1
                }
                let TIME_END = {
                    h: newDateTime_end.format('H') * 1,
                    m: newDateTime_end.format('m') * 1
                }




                if (TIME_START.h == 23 && TIME_START.m > 0) {
                    TIME_START = {
                        h: 23,
                        m: 0
                    }
                } else {
                    TIME_START = makeTime(TIME_START, true)
                }

                if (TIME_END.h < TIME_START.h) {
                    TIME_END = {
                        h: 23,
                        m: 45
                    }
                } else {
                    TIME_END = makeTime(TIME_END, false)
                }



                updatedEvent.schedule.DATE_INSTALL = newDateTime_start.format('YYYY-MM-DD')
                updatedEvent.schedule.TIME_START = `${TIME_START.h}:${TIME_START.m}:00`
                updatedEvent.schedule.TIME_END = `${TIME_END.h}:${TIME_END.m}:00`



                events.forEach(event => {
                    if (event.id == updatedEvent.id)
                        eventsNew.push(updatedEvent)
                    else
                        eventsNew.push(event)

                    schedules.push(eventsNew.at(-1).schedule)
                })


                dispatch(setInstallSchedule(schedules))

            }}
            autoScroll={true}
            onEventClick={(data, event) => {
                UpdateInterval.resset()
                dispatch(openPopUp(<POP_InstallSchedule ID_OBJECT={data.schedule.ID} />))
            }}
            draggingDisabledConditions={isManagerOrAdmin ? false : true}
            disabledDragging={isManagerOrAdmin ? false : true}
            resizeDisabledConditions={isManagerOrAdmin ? false : true}
            calendarIDsHidden={[]}//ID скрытых монтажников

            events={events}
            initialDate={new Date().toISOString()}
            hourHeight={35}
            initialView={CalendarView.AGENDA}
            disabledViews={[CalendarView.DAY, CalendarView.THREE_DAYS,]}
            timeFormat={'24'}
            weekDayStart={'Monday'}
            language={'ru'}
        />

    </View>)

}

const View = styled.div`
position:relative;

& .Kalend__header_calendar_button-text-selected, 
& .Kalend__header_calendar_button-text-selected-dark{
    color:${Pallet.Accent} !important;
}
& .Kalend__CalendarHeader,
& .Kalend__CalendarHeader-dark,
& .Kalend__CalendarHeader-tall,
& .Kalend__CalendarHeader-tall-dark,
& .Kalend__CalendarHeader-small,
& .Kalend__CalendarHeader-day,
& .Kalend__CalendarHeader-tall-day{
    box-shadow:none !important;
    border-radius:0px;
    border-bottom:solid 1px #e0e0e0;
}


& .Kalend__text.Kalend__Event__summary.Kalend__Event__summary__type-normal.Kalend__text-light{
    font-size:10px !important;
}
& .Kalend__Event__summary,
& .Kalend__Event__summary-dark{
    font-size:10px !important;
}

& p,
& button{
    font-family: var(--bs-font-sans-serif) !important;
}
& .Kalend__NewEvent{
    display:none;
}
& .Kalend__Event-normal{
    cursor:pointer;
}

& .Kalend__CalendarDesktopNavigation__container{
    margin-bottom:20px;
}

& .legend:hover .legendDescr{
    opacity:1;
}

& .legend:hover .title{
    color:black;
}
& .legend .title{
    transition:.3s;
}

& .legendDescr{
    opacity:0;
    transition:.3s;
}
`