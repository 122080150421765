import React from 'react';
import { connect } from 'react-redux';
import { closePopUp } from '../../actions/PopUpActions';
import styled from 'styled-components';
import { GlobalFunctions } from '../../GlobalFunctions'
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Pallet from '../../Pallet';



class PopUp extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
            visible: false
        }
        this.key = GlobalFunctions.randomString()
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ visible: true })

        }, 1)
    }


    render() {
        return (<>
            <div style={{
                position: 'fixed',
                top: 0,
                width: '100vw',
                height: '100vh',
                left: 0,
                background: 'rgba(0,0,0,0)',
                zIndex: this.props.zIndex + 200,

            }} />
            <Pop key={this.key} visible={this.state.visible} style={{
                zIndex: this.props.zIndex + 201,
                transform:
                    this.props.PopUps.length <= this.props.zIndex + 1 && this.state.visible ?
                        `translate(-50%,-50%) scale(1)`
                        :
                        'translate(-50%,-45%) scale(0.95)'
            }}>
                <IconButton className='float-end' size="small" onClick={() => this.props.closePopUp()} style={{
                    position: 'absolute',
                    right: -3,
                    zIndex: 15,
                    top: -3
                }}>
                    <CloseIcon fontSize="small" />
                </IconButton>
                {this.props.children}
                {this.props.PopUps.length <= this.props.zIndex + 1 ? null :
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        height: '100%',
                        left: 0,
                        background: 'rgb(0 0 0 / 8%)',
                        zIndex: 99999999
                    }} />}
            </Pop>
        </>)
    }
}

const Pop = styled.div`
    position: fixed;
    left: 50%;
    transition: .6s cubic-bezier(.58,1.47,.37,.94);
    top: 50%;
    opacity: 1;
    height: auto;
    background: #fff;
    width: max-content;
    max-width:90vw;
    max-height:90vh;
    overflow-y:auto;
    overflow-x:hidden;
    border-radius: 5px;
    border:1px solid ${Pallet.Gray};
    padding:25px;
    > .Content { 
        margin:20px;
     }; 
`
export const Bg = styled.div`
position:fixed;
top:0px;
width:100vw;
height:100vh;
left:0px;
background:rgba(0,0,0,0.15);
z-index:100;
`

const Close = styled.div`
    position: absolute;
    right: 20px;
    top: 18px;
    font-weight: 100;
    cursor: pointer;
    opacity: 0.3;
    transition: .3s;
    height: 20px;
    width: 20px;
    text-align: center;
    line-height: 19px;
    border-radius: 5px;
    box-shadow: 5px 2px 10px rgba(0,0,0,0);
    z-index:20;
    &:hover{
        opacity:1;
        background: rgb(250, 250, 250);
        box-shadow: 5px 2px 10px rgba(0,0,0,0.03);
    }
    &:active{
        font-size:9px
    }

`

const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp: () => dispatch(closePopUp()),
    };
};

const mapStateToProps = (store) => {

    return {
        PopUps: store.popups.windows
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PopUp);

export function POPscroll(props) {
    return (
        <div style={{
            maxHeight: 'calc(90vh - 80px)',
            overflowY: 'auto',
            alignItems: "center",
            overflowX: 'hidden',
            margin: -25,
            padding: 25,
            paddingTop: props.top ? 55 + 20 : 25,
            paddingBottom: props.bottom ? 55 + 20 : 25,
            ...props.style
        }}>{props.children}</div>)
}

export function POPbottom(props) {
    return (
        <div style={{
            position: 'absolute',
            height: 55,
            bottom: 0,
            left: 0,
            zIndex: 9999999,
            width: '100%',
            overflow: 'hidden',
        }}>
            <div style={{
                position: 'absolute',
                height: 55,
                bottom: 0,
                transition: '.3s',
                overflow: 'hidden',
                left: 0,

                background: 'rgba(255,255,255,0.8)',
                backdropFilter: 'blur(10px)',
                WebkitBackdropFilter: 'blur(10px)',
                width: '100%',
                ...props.style
            }}>
                <hr style={{
                    margin: 0,
                    padding: 0,
                    marginLeft: 25,
                    marginRight: 25,
                }} />
                <div style={{
                    padding: 25,
                    paddingTop: 10,
                    paddingBottom: 10,
                    display: 'flex',
                    gap: 20,
                    justifyContent: 'space-between',
                    alignItems: "center",
                }}>
                    {props.children}
                </div>
            </div>
        </div>)
}

export function POPgap(props) {
    return (<div style={{
        display: "flex",
        justifyContent: 'space-between',
        gap: 20,
        ...props.style
    }}>{props.children}</div>)
}

export function POPtop(props) {
    return (
        <div style={{
            position: 'absolute',
            top: 0,
            height: 55,
            overflow: 'hidden',
            left: 0,
            zIndex: 10,
            background: 'rgba(255,255,255,0.8)',
            backdropFilter: 'blur(10px)',
            WebkitBackdropFilter: 'blur(10px)',

            width: '100%',
            ...props.style
        }}>

            <div style={{
                padding: 25,
                paddingTop: 10,
                height: '100%',
                paddingBottom: 10,
                display: 'flex',
                gap: 20,
                justifyContent: 'space-between',
                alignItems: "center",
                alignContent: 'center'
            }}>
                {props.children}
            </div>

            <hr style={{
                margin: 0,
                padding: 0,
                marginLeft: 25,
                marginRight: 25,
                bottom: 0,
                position: 'absolute',
                width: 'calc(100% - 50px)'
            }} />
        </div>)
}