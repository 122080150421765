
import { Avatar, Button, Chip, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getDB_Data, getDB_Data_lazy, getORDERS, getUSERS } from '../../../actions/PageActions';
import { closePopUp, openPopUp } from '../../../actions/PopUpActions';
import { GlobalFunctions as GF } from '../../../GlobalFunctions';
import withToast from '../../components/Toasts';

import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DateRangePicker } from '@mui/lab';
import moment from 'moment';
import 'moment/locale/ru';
import { withRouter } from 'react-router-dom';
import Pallet from '../../../Pallet';
import SwitchCheck from '../../components/SwitchCheck';
import POP_User from '../DataBase/pages/POP_User';
import CalkPage from '../MainPage/CalkPage';
import POP_Order from './POP_Order';





class CalckOrders extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      errorMSG: null,
      succes: false,
      filter: null,
      filterFIO: null,
      Data: [],
      selectedRows: [],
      sortedOrders: null,
      openedUsers: [],
      dateFilter: [null, null],
      showDrafts: props.user.ROLE == 'PRTN' ? true : false
    }

    this.itFerstOpen = true

    this.input = {
      dataWindow: {},
      main: {}
    }
    this.checkBoxs = {}
    this.can = true




  }

  componentDidMount() {
    this.sortOrders()
  }


  componentDidUpdate(prevProps) {
    const { state, props } = this
    if (prevProps.ORDERS_timeUpdate != props.ORDERS_timeUpdate) { //если у нас обновились данные о заказах
      this.sortOrders()
    }
  }

  sortOrders() {
    const { state, props } = this
    /*
      [
        {
          ID_USER:123,
          FIO: ... ,
          ORDERS:[ ... ]
        }
      ]
    */
    let sortedOrders = []
    let myOrders = [{
      ID_USER: props.user.ID,
      FIO: 'Мои заказы',
      ORDERS: []
    }] //for manager orders
    props.ORDERS.forEach(order => {
      if (order.ID_USER == props.user.ID) {
        myOrders[0].ORDERS.push(order)
      } else {
        let index = sortedOrders.findIndex(so => so.ID_USER == order.ID_USER)

        if (index == -1) {
          sortedOrders.push({
            ID_USER: order.ID_USER,
            FIO: order.FIO,
            ORDERS: [order]
          })
        } else {
          sortedOrders[index].ORDERS.push(order)
        }
      }

    })


    sortedOrders = sortedOrders.sort((a, b) => {
      let textA = a.FIO.toUpperCase();
      let textB = b.FIO.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    })

    sortedOrders = myOrders.concat(sortedOrders)

    if (this.itFerstOpen === true) {
      this.setState({ sortedOrders, openedUsers: [this.props.user.ID] })
      this.itFerstOpen = false
    } else {
      this.setState({ sortedOrders })
    }

  }


  checkSelected() {
    const { state, props } = this
    const { filter, Data, selectedRows } = this.state

    const filtered = props.ORDERS.filter(u => filter == null ||
      u.GUID.toUpperCase().indexOf(filter) != -1
    )
    let correctRows = []
    selectedRows.forEach((sr, i) => {
      if (filtered.findIndex(u => u.ID == sr) != -1) {
        correctRows.push(sr)
      }
    })
    if (correctRows.length != selectedRows) {
      this.setState({ selectedRows: correctRows })
    }
  }


  renderTable() {
    const { state, props } = this
    const { Data, selectedRows, sortedOrders, filterFIO, filter, dateFilter, showDrafts } = this.state


    var filtered = [...sortedOrders]
    if (filterFIO != null) {
      filtered = filtered.filter(user => user.FIO.toUpperCase().indexOf(filterFIO) != -1)
    }

    if (filter != null || (dateFilter[0] != null && dateFilter[1] != null) || showDrafts === false) {
      let filteredTMP = []
      filtered.forEach(user => {
        let filteredOrders = user.ORDERS?.filter(order => {
          let DATE_SENDED = moment(order.DATE_SENDED)
          let d1 = dateFilter[0] == null ? null : moment(dateFilter[0])
          let d2 = dateFilter[1] == null ? null : moment(dateFilter[1]).set({ hours: 23, minutes: 59 })

          if (d1 == null && d2 != null) d1 = d2
          if (d2 == null && d1 != null) d2 = d1

          if (
            (showDrafts === true || showDrafts == false && order.ID_STATUS == 13 || order.ID_USER == props.user.ID) &&
            (filter == null || filter != null && order.GUID.indexOf(filter) != -1) &&
            (dateFilter[0] == null || (dateFilter[0] != null && d1.isSameOrBefore(DATE_SENDED) && d2.isSameOrAfter(DATE_SENDED)))
          ) {
            return true
          }
          else return false
        }) || []
        if (filteredOrders.length > 0) {
          filteredTMP.push({
            ...user,
            ORDERS: [...filteredOrders]
          })
        }
      })

      filtered = [...filteredTMP]
    }







    return (<table className='w-100 mt-3 mb-3 ' >
      <thead>
        <td> </td>
        <td style={{
          textAlign: 'left'
        }}>{props.user.ROLE == 'PRTN' ? '' :
          props.user.ROLE == 'СPRT' ? 'Менеджер' : "Партнер"
          }</td>

        <td>Номер</td>
        <td>Монтаж</td>
        <td width='140px'>Дата оформления</td>
        <td width='120px'>Кол-во карнизов</td>
        <td>Стоимость</td>
      </thead>
      <tbody>
        {filtered.map(user => {
          const index = state.openedUsers.findIndex(ou => ou == user.ID_USER)
          const isOpen = index != -1
          let NoReadedCount = 0
          let orderCoast = 0
          let orderItems = 0
          user.ORDERS.forEach(order => {
            if (order.ID_STATUS == 13) {
              order?.ITEMS?.forEach(item => {
                orderItems += item.ORDERS_COUNT
              })
              orderCoast += order.ALL_COAST - order.TAX_COAST
              if (order.MANAGER_READED == 0)
                NoReadedCount++
            }
          })

          const userRow = (
            <tr key={'partner_' + user.ID_USER} className='partner' style={{
              cursor: 'pointer',
            }} onClick={() => {
              let tmp = [...state.openedUsers]
              if (!isOpen) {
                tmp.push(user.ID_USER)
              } else {
                tmp.splice(index, 1)
              }
              this.setState({ openedUsers: tmp })
            }}>
              <td width='30px'><IconButton key={'button_order_' + user.ID_USER}><ExpandMoreIcon style={{
                transform: !isOpen ? 'scale(-1)' : null,
                transition: '.3s'
              }} /></IconButton></td>
              <td style={{
                textAlign: 'left'
              }}>
                <Chip style={{
                  cursor: 'pointer',
                }} label={<div style={{
                  display: "flex",
                  alignItems: 'center',
                }} onClick={(e) => {
                  e.stopPropagation()
                  this.props.openPopUp(<POP_User ID_OBJECT={user.ID_USER} />)
                }}>
                  <p className='m-0'>
                    {user.ID_USER == this.props.user.ID ? ' Мои заказы карнизов' : user.FIO}
                  </p>
                  {(props.user.ROLE != 'MNGR' && props.user.ROLE != 'ADMN') || NoReadedCount == 0 ? null :
                    <div style={{
                      minWidth: 20,
                      background: 'rgb(52, 145, 255)',
                      paddingLeft: 5,
                      paddingRight: 5,
                      marginRight: -5,
                      marginLeft: 10,
                      borderRadius: 100,
                      transition: '.5s',
                      color: 'white',
                      fontSize: 10,
                      fontWeight: '700',
                      paddingBottom: 2,
                      paddingTop: 2,
                      textAlign: 'center'
                    }}>
                      {NoReadedCount}
                    </div>
                  }


                </div>} avatar={<Avatar />} />

              </td>
              <td colSpan={3}></td>
              <td>{orderItems == 0 ? null : <Chip size='small' label={<>{orderItems}<small>шт</small></>} />}</td>
              <td>{orderCoast == 0 ? null : <Chip size='small' label={<>{GF.makeCoast(orderCoast)}<small>руб</small></>} />}</td>
            </tr>)


          let ordersRows = null
          if (isOpen) {
            let lastDate = null
            let lastDateView = null

            let Date_create = null
            let Year_create = null
            ordersRows = user.ORDERS.map(order => {
              lastDateView = null

              if (state.filter != null && order.GUID.indexOf(state.filter) == -1) return null


              var date = null
              var dateYear = null

              if (order.DATE_SENDED != null) {
                date = moment(order.DATE_SENDED).locale('ru').format('DD MMMM YYYY')
                dateYear = moment(order.DATE_SENDED).format('YYYY')


                if (date == moment().locale('ru').format('DD MMMM YYYY')) {
                  date = 'сегодня'
                } else {
                  date = date.replace(' ' + dateYear, '')
                }
              }




              let isToday = false
              date = moment(order.DATE_SENDED || order.DATE_CREATE).locale('ru').format('M YYYY')

              dateYear = moment(order.DATE_SENDED || order.DATE_CREATE).format('YYYY')


              date = date.replace(' ' + dateYear, '')

              Date_create = date
              Year_create = dateYear

              if (lastDate != date) {

                let orderMonthDate_0 = moment(`01.${Date_create}.${Year_create}`, 'DD.M.YYYY')
                let orderMonthDate_1 = moment(`01.${Date_create}.${Year_create}`, 'DD.M.YYYY')
                orderMonthDate_1.add(1, 'M')
                let textMonth = <p className='m-0'>{orderMonthDate_0.format('MMMM')} <small>{orderMonthDate_0.format('YYYY')}</small></p>

                lastDate = date

                let OrdersPriceMonth = 0
                let karnizCount = 0
                console.warn(orderMonthDate_0, orderMonthDate_1)
                user.ORDERS.forEach(order_ => {


                  if (
                    order_.ID_STATUS == 13 &&
                    moment(order_.DATE_SENDED || order_.DATE_CREATE).isSameOrAfter(orderMonthDate_0) &&
                    moment(order_.DATE_SENDED || order_.DATE_CREATE).isBefore(orderMonthDate_1)
                  ) {
                    console.error(order_.GUID, order_.ALL_COAST - order_.TAX_COAST)
                    OrdersPriceMonth += order_.ALL_COAST - order_.TAX_COAST
                    order_.ITEMS.forEach(item => {
                      karnizCount += item.ORDERS_COUNT
                    })
                  }
                })


                lastDateView = <tr style={{
                  cursor: 'default',
                }}><td colSpan={5} style={{
                  textAlign: 'left',
                  paddingLeft: 10,
                  fontWeight: 'bold'
                }}>{textMonth}</td>
                  <td style={{
                    paddingBottom: 10,
                    paddingTop: 10,
                    fontSize: 15
                  }}><b>{GF.makeCoast(karnizCount)}</b><small>шт</small></td>
                  <td style={{
                    paddingBottom: 10,
                    paddingTop: 10,
                    fontSize: 15
                  }}><b>{GF.makeCoast(OrdersPriceMonth)}</b><small>руб</small></td>

                </tr>
              }



              let itemsCount = 0

              order?.ITEMS?.forEach(item => {
                itemsCount += item.ORDERS_COUNT
              })

              let install = props.InstallSchedule?.filter(sch => sch.ID_ORDER == order.ID)?.[0]

              return (<>
                {lastDateView}
                <tr key={'order_' + order.ID} style={{
                  borderBottom: '1px solid rgb(240,240,240)',
                  paddingBottom: 2,
                  cursor: 'pointer',
                }} onClick={() => this.props.openPopUp(<POP_Order ID_OBJECT={order.ID} />)}>
                  <td style={{
                    borderBottom: '1px solid color(srgb 0.9112 0.9258 0.9344)'

                  }}>
                    {order.MANAGER_READED == 1 || order.ID_STATUS != 13 ? null :
                      <div style={{

                        background: 'rgb(52, 145, 255)',
                        height: 8,
                        width: 8,
                        borderRadius: 100,
                        margin: 'auto',
                        transition: '.5s'
                      }} />
                    }
                  </td>
                  <td>
                    {order.ADDRESS != null && order.ADDRESS != '' && order.ADDRESS != ' ' ?
                      <b style={{
                        display: 'block',
                        textAlign: 'left',
                        fontSize: 12,
                        marginBottom: -6,
                        fontWeight: 400,
                      }}>{order.ADDRESS}</b> : null}
                    <small style={{
                      lineHeight: '11px',
                      display: 'block',
                      textAlign: "left",
                      fontSize: 11,
                      marginTop: 5,
                      marginBottom: 5
                    }}>

                      {(order.COMMENT || '').substr(0, 150)}{order.COMMENT?.length >= 150 ? '...' : ''}</small></td>
                  <td style={{
                    textAlign: 'right'
                  }}><Chip style={{
                    marginTop: 2,
                    marginBottom: 2
                  }} color={order.ID_STATUS == 13 ? 'success' : 'default'} label={order.GUID} size='small' /></td>
                  <td>{install == null ? null :
                    <Chip size='small' style={{
                      color: Pallet.White,
                      background: install.STATUS_COLOR
                    }} label={install.STATUS_TITLE} />
                  }</td>
                  <td>
                    {order.DATE_SENDED == null ? ' - ' :
                      !isToday ? moment(order.DATE_SENDED).format('D MMM') :
                        moment(order.DATE_SENDED).format('HH:mm')}</td>

                  <td>{itemsCount}<small>шт</small></td>

                  <td>{GF.makeCoast(order.ALL_COAST - order.TAX_COAST)}<small>руб</small></td>


                </tr>
              </>)
            })
          }

          return <>
            {userRow}
            {ordersRows}
          </>
        }
        )}
      </tbody>
    </table>)


  }


  render() {
    const { state, props } = this
    const { filter, Data, selectedRows, sortedOrders } = this.state



    return (
      <View className='noselect'>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: 10
        }}>
          {(this.props.user.ROLE == 'PRNT' || this.props.user.ROLE == 'CPRT') && this.props.user?.PERMISSIONS?.includes("COMPONENTS") !== true ? null :
            <FormControl key='urlFormer' variant="filled" sx={{ minWidth: 200 }} color='success' size='small'>
              <InputLabel>Раздел заказов</InputLabel>
              <Select
                size='small'
                label='Раздел заказов'
                value='/orders/calk'
                onChange={e => {
                  this.props.history.push(e.target.value)
                }}>
                <MenuItem value={'/orders/calk'}>Расчеты</MenuItem>
                <MenuItem value={'/orders/basket'}>Комплектующие</MenuItem>
              </Select>
            </FormControl>
          }

          <TextField
            style={{ width: 250 }}
            className='m-0'
            label='Поиск по номеру заказа'
            variant='filled'
            size="small"
            color='success'
            value={state.filter}
            onChange={(e) => this.setState({ filter: e.target.value.length > 0 ? e.target.value.toUpperCase() : null })}
          />

          {props.user.ROLE == 'PRTN' ? null :
            <TextField
              style={{ width: 250 }}
              className='m-0'
              label={props.user.ROLE == 'СPRT' ? 'ФИО менеджера' : 'ФИО партнера'}
              variant='filled'
              size="small"
              color='success'
              onKeyUp={(e) => this.setState({ filterFIO: e.target.value.length > 0 ? e.target.value.toUpperCase() : null })}
            />}



          <DateRangePicker
            value={state.dateFilter}
            onChange={(newValue) => this.setState({ dateFilter: newValue })}
            renderInput={(startProps, endProps) => (
              <div style={{
                display: 'flex',
                gap: 10,
                position: 'relative'
              }}>
                <small style={{
                  position: 'absolute',
                  display: 'block',
                  top: -17,
                  left: 0
                }}>Дата оформления заказа</small>
                <TextField style={{ width: 140 }} size='small' variant='filled' {...startProps} label='Дата начала' />
                <TextField style={{ width: 150 }} size='small' variant='filled' {...endProps} label='Дата окончания' />
              </div>
            )}
          />



          <div>
            <IconButton onClick={() => {
              this.setState({
                filter: '',
                filterFIO: null,
                dateFilter: [null, null]
              }, () => this.setState({
                filter: null
              }))
            }}><CancelIcon /></IconButton>
          </div>




        </div>



        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 10,
          marginTop: 10,
        }}>
          {this.props.user.ROLE != 'ADMN' && this.props.user.ROLE != 'MNGR' ? null :
            <div style={{
              borderRadius: 5,
              background: 'white',
              paddingLeft: 5,
              height: 40,
              width: 250,
              paddingTop: 0,
              alignSelf: 'stretch',
              width: 'max-content',
              justifyContent: 'center',
              alignContent: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <SwitchCheck label='Показать черновики'
                checked={state.showDrafts}
                onChange={(e) => {
                  this.setState({ showDrafts: e.target.checked })
                }} />
            </div>}

          {props.user.ROLE == 'ADMN' ? null :
            <Button color='success' className='align-bottom' variant='text' onClick={() => {
              props.openPopUp(<div style={{
                width: 500,
                marginTop: -20,
                marginBottom: -20,
                paddingLeft: 15,
                paddingRight: 15,
                maxHeight: '80vh',
                overflow: 'auto'
              }}>

                <CalkPage callBack={(data) => {
                  props.closePopUp()
                  this.props.getORDERS(() => {
                    this.props.openPopUp(<POP_Order ID_OBJECT={data.ORDER.ID} />)
                    this.componentDidMount()
                  })

                }} />

              </div>)
            }}>Новый расчет</Button>}
        </div>


        {sortedOrders == null ? null :
          this.renderTable()
        }


      </View>
    )
  }
}
const View = styled.div`
height:max-content;
padding-bottom:150px;
& table td{
  padding-top:0px;
  padding-bottom:0px;
  font-size:13px;
  text-align:center;
}
& table tbody tr{
  cursor:pointer;
}
& table tbody tr:hover{

  background:rgb(241, 241, 241);

}

& table thead td{
  color:rgb(120,120,120);
  font-size:11px !important;
  padding-bottom:5px;
}
`

const mapDispatchToProps = (dispatch) => {
  return {
    openPopUp: (arg) => dispatch(openPopUp(arg)),
    closePopUp: (arg) => dispatch(closePopUp(arg)),
    getUSERS: (arg) => dispatch(getUSERS(arg)),
    getDB_Data: (arg, grfm) => dispatch(getDB_Data(arg, grfm)),
    getORDERS: (arg) => dispatch(getORDERS(arg)),
    getDB_Data_lazy: (tableName, JSONparseKey) => dispatch(getDB_Data_lazy(tableName, JSONparseKey)),
  };
};


const mapStateToProps = (store) => {

  return {
    user: store.user.data,
    ORDERS: store.page.ORDERS,
    InstallSchedule: store.page.InstallSchedule,
    ORDERS_timeUpdate: store.page.ORDERS_timeUpdate,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withToast(withRouter(CalckOrders)))

