
import { Check } from '@mui/icons-material';
import { Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Select, TextField, Tooltip, Typography,Grid, List, ListItem, ListItemAvatar, IconButton } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getDB_Data,getDB_Data_lazy,getFileSustem,getUSERS } from '../../../actions/PageActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import {HexColorPicker} from 'react-colorful'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LinearProgressWithLabel from '../../components/moduls/LinearProgressWithLabel';





class POP_Upload extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            fileToUpload:props.files,
            progress:[]
        }
        this.input=[]
        this.canComplite=false
    }

    

    componentDidMount() {
        const {props,state} = this
        const {COMPANYS,STATUSES,ID_OBJECT} = this.props

        if(ID_OBJECT!=null){
            var Self_tmp = props.FileSustem.find(p=>p.ID==ID_OBJECT)
            if(Self_tmp==null) return
            
            this.setState({Self:GF.ObjectCopy(Self_tmp)})
        }
    }

    


    Save(){
        const {Self} = this.state
        const {state,props} = this
        this.setState({nowLoading:true})

        this.animInterval =  setInterval(()=>{
            
                let needDoSomething = false
                let progress= this.state.progress.splice(0)

                this.state.fileToUpload.forEach((f,i)=>{
                    if(progress[i]==null) progress[i]=0

                    if(progress[i]<99){
                        
                        needDoSomething=true
                        let k = 50 - (Math.round(f.size/1048576 * 100) / 100)*10
                        if(k<=0)
                            k=1
                        progress[i]+=k<2?k:Math.floor(Math.random() * k)
                        if(progress[i]>99) progress[i]=99

                         
                        
                    }
                    
                })
                this.setState({progress})
                if(!needDoSomething){
                    this.canComplite = true
                    if(this.realyComplited){
                        let progress= this.state.progress.splice(0)
                        
                        progress.forEach((f,i)=>{
                            progress[i] = 100
                        })
        
                        this.setState({progress})
        
                        setTimeout(()=>{
                            
                            clearInterval(this.animInterval)
                            this.props.closePopUp()
                        },1000)
                    }
                    
                    clearInterval(this.animInterval)
                }
                    
        },100)

        

        if(state.fileToUpload[0].type=='url'){
            let urlObj = state.fileToUpload[0]
            GF.API_task({
                operation:'uploadURL',
                ID_FOLDER:props.PARENT.ID,
                ...urlObj
            }).then(res=>{
                this.props.getFileSustem()
                this.props.closePopUp()
            })
        }else{
            GF.API_files({
                operation:'uploadFile_DB',
                ID_FOLDER:props.PARENT.ID,
            },state.fileToUpload)
            .then(d=>{
                this.realyComplited=true
                if(this.canComplite){
                    let progress= this.state.progress.splice(0)
                    
                    progress.forEach((f,i)=>{
                        progress[i] = 100
                    })
    
                    this.setState({progress})
    
                    setTimeout(()=>{
                        clearInterval(this.animInterval)
                        this.props.closePopUp()
                    },1000)
                   
                }
                
            })
            .finally(()=>{
                this.props.getFileSustem()
            })
        }
        
    }

    componentWillUnmount(){
        clearInterval(this.animInterval)
    }

    render (){
        const {COMPANYS,user} = this.props
        const {state,props} = this
       
    
        return (
            <POP>
                <h4>Загрузка файлов</h4>
                <small>в {props.PARENT.PARENT_TITLE} {!!props.PARENT.PARENT_TITLE && '/ '} {props.PARENT.TITLE} </small>
            
                <List className='mt-3'>
                    {state.fileToUpload.map((file,i)=>
                        <div className='mb-4'>
                            <ListItem disablePadding secondaryAction={
                                <IconButton edge="end" onClick={e=>{
                                    var files = []
                                    state.fileToUpload.forEach((f,fi)=>{
                                        if(fi!=i)
                                            files.push(f)
                                    })
                                    if(files.length==0){
                                        this.props.closePopUp()
                                    }else{
                                        this.setState({fileToUpload:files})
                                    }
                                    

                                }}>
                                <DeleteForeverIcon />
                                </IconButton>
                            }>
                                <ListItemAvatar>
                                    
                                    {file.type!='url' ? <InsertDriveFileIcon/> : <Chip size='small' color='primary' label='URL'/>}
                                </ListItemAvatar>
                                <ListItemText primary={<p style={{
                                    margin:0,
                                    fontSize:12,
                                    width:'100%',
                                    paddingRight:40
                                }}>{file.name} 
                                </p>} secondary={<small style={{paddinRight:40,lineHeight:'13px',display:'block'}}>{file.type=='url'?file.url:Math.round(file.size/1048576 * 100) / 100+' мб'}</small>} />
                            </ListItem>
                            {!!state.nowLoading &&
                            <LinearProgressWithLabel  value={state.progress[i]==null?0:state.progress[i]} />}
                        </div>
                    )}
                </List>

                <div className='mt-3'/>
                {state.nowLoading?null:
                <Button className='float-end' onClick={()=>this.Save()}>Загрузить</Button>}

            </POP>
        )

            
      
    }
}

const POP = styled.div`
width:450px;
`





const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
       
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 

        getDB_Data_lazy:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy(tableName,JSONparseKey)), 

        getFileSustem: (arg) => dispatch(getFileSustem(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        user:store.user.data,
        FileSustem:store.page.FileSustem,
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(POP_Upload)

