import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import { Label } from '../../components/moduls/Input';


import {BrowserRouter as Router,Switch, Route,Link,withRouter,Redirect} from "react-router-dom";
import { Button, ButtonGroup, Tab, Tabs } from '@mui/material';
import Users from './pages/Users';
import Statuses from './pages/Statuses';
import {WorkOutline,LocalOfferOutlined} from '@mui/icons-material'
import Companys from './pages/Companys';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import Costs from './pages/Costs';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';


const pages = {
    ADMN:[
        mkP('Пользователи','/data/users',<Users/>,<PeopleAltOutlinedIcon/>),
        mkP('Компании','/data/сompanys',<Companys/>,<WorkOutline/>),
        mkP('Статусы','/data/statuses',<Statuses/>,<LocalOfferOutlined/>),
        mkP('Цены','/data/costs_all',<Costs category='sustem-coast'/>,<LocalAtmIcon/>),
        mkP('Цены (пульты)','/data/costs_pults',<Costs category='sustem-coast-pults'/>,<LocalAtmIcon/>),
        mkP('Цены (ткани)','/data/costs_tkan',<Costs category='sustem-coast-tkan'/>,<LocalAtmIcon/>),
    
        
    ],
}

function mkP(title,url,view,icon){
    return{title,url,view,icon}
}

class Index extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
           errorMSG:null,
        }
        this.input=[]
    }

    

    componentDidMount() {
    
    }


    render (){
        const {user} = this.props
        let pathname = this.props.location.pathname
        if(pathname=='/data') pathname='/data/users'

        return(
            <View>
                <Tabs
                    value={pathname}
                    onChange={(e,value)=>{
                        this.props.history.push(value)
                    }}>
                    {pages[user.ROLE].map(p=>
                        <Tab  value={p.url} label={[p.icon,p.title]}/>
                    )}
                    
                </Tabs>
               
                <div style={{padding:20,paddingTop:0}}>
                    <Switch>
                        {pages[user.ROLE].map(p=>
                            <Route path={p.url}>{p.view}</Route>
                        )}
                       
                        <Route path='/data'>
                            <Redirect to='/data/users'/>
                        </Route>
                        
                    </Switch>
                </div>
            </View>
        )
    }
}

const View = styled.div`
height:100%;
padding:0px;
margin:-20px;
`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
        user:store.user.data
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Index));



