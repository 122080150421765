
import { Check, Delete } from '@mui/icons-material';
import { Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Select, TextField, Tooltip, Typography,Grid, ListItem, IconButton, ListItemButton,Menu, Checkbox } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../../actions/PopUpActions';
import { getDB_Data,getDB_Data_lazy,getUSERS,getCHATS, getDB_Data_lazy_notID } from '../../../../actions/PageActions';
import { POPbottom, POPscroll, POPtop } from '../../../PopUp';
import CancelIcon from '@mui/icons-material/Cancel';


class POP_UserSearch extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
           
            filter:null,
            selected:props.selected || [],
        }
       
    }

    render(){
        const {state,props} = this
        let filteredData = []
        let filtered = props.USERS.map(user=>{
            if(user.ROLE!='ADMN' && user.ROLE!='TMPU'){
                if((state.filter==null ||
                    user.FIO.toUpperCase().indexOf(state.filter)!=-1 ||
                    user.EMAIL.toUpperCase().indexOf(state.filter)!=-1 ||
                    user.PHONE.indexOf(state.filter)!=-1 
                )){
                    if(props.isHiddenRule==null || (  props.isHiddenRule!=null && props.isHiddenRule(user)==false)){
                        const index = this.state.selected.findIndex(t=>t.ID==user.ID)
                        const isSelected = index!=-1
                        
                        filteredData.push(user)

                        return(<>
                            <Button size='small' color={isSelected?'success':'inherit'} onClick={()=>{
                                let tmp = [...[...this.state.selected]]
                                if(props.multiple){
                                    
                                    if(index==-1)
                                        tmp.push(user)
                                    else
                                        tmp.splice(index,1)
                                }else{
                                    tmp=[user]
                                }
                                this.setState({selected:tmp})
                            }} className='w-100' style={{
                                paddingLeft:isSelected?20:5,
                                transition:'.3s'
                            }}> <div className='w-100'>
                                    <p className='m-0 text-start'>{user.FIO}</p>
                                    <small className='m-0 d-block text-start'>{user.ROLE_TITLE}</small>
                                </div>
                            </Button>
                            <hr className='m-0 p-0'/>
                        </>)
                    }
                }
            }
        })

        filtered = filtered.filter(u=>u!=null)


        

        return(<POP>
            <POPtop style={{height:130}}>
                <div className='w-100'>
                {props.title==null?
                    <h3 className='m-0'>Выбор {props.multiple?'пользователей':'пользователя'}</h3>:
                    <h3 className='m-0'>{props.title}</h3>
                }
                <small className=' d-block m-0 mb-2'>Выберите из списка</small>
                    <div style={{display:'flex',gap:16,width:'100%'}}>
                        <TextField
                            className='m-0'
                            style={{
                                display:'block',
                                flex:1
                            }}
                            inputProps={{style:{
                                width:'100%'
                            }}}
                            label='Поиск'
                            variant='filled'
                            size="small"
                            value={state.filter}
                            color='success'
                            onChange={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}
                        />
                        {!props.multiple?null:
                        <Button size='small' color='success' onClick={()=>this.setState({selected:[...filteredData]})}>Выбрать всех</Button>}
                        <Button size='small' color='inherit' onClick={()=>this.setState({selected:[]})}>Отменить выбор</Button>
                    </div>
                </div>

            </POPtop>
            <POPscroll top bottom style={{
                paddingTop:140
            }}>
                
                {filtered.length==0?<small>{props.placeholder || 'Нет записей'}</small>:filtered}
                
                
            </POPscroll>

            
            <POPbottom style={{
                bottom:state.selected.length==0?-55:0
            }}>
                <Button color='success' size='small' onClick={()=>{
                    props.callBack(state.selected)
                }}>Выбрать</Button>
            </POPbottom>
        </POP>)
    }
    

}

const POP = styled.div`
width:500px;

`

const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 

        getDB_Data_lazy:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy(tableName,JSONparseKey)), 
        getDB_Data_lazy_notID:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy_notID(tableName,JSONparseKey)), 
      
        getUSERS: (arg) => dispatch(getUSERS(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        user:store.user.data,
        USERS:store.page.USERS,
        
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(POP_UserSearch);

