import { useSelector } from "react-redux";


export default function useIsManagerOrAdmin(){
    const reduxUser = useSelector(store=>store.user.data)
    if(reduxUser?.ROLE==null){
        throw 'Не задана роль пользователя'
    }


    if(reduxUser.ROLE=='ADMN' || reduxUser.ROLE=='MNGR')
        return true
    else return false
}