
import { Button } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getDB_Data, getDB_Data_lazy, getDB_Data_lazy_notID, getUSERS } from '../../../actions/PageActions';
import { closePopUp, openPopUp } from '../../../actions/PopUpActions';
import { GlobalFunctions as GF } from '../../../GlobalFunctions';

import { Label } from '../../components/moduls/Input';
import Question from '../../PopUp/Views/Question';
import POP_User from '../DataBase/pages/POP_User';
import POP_NewPartner_Accept from './POP_NewPartner_Accept';
import POP_NewPartner_Forbidden from './POP_NewPartner_Forbidden';




const form_fields = [
    {
        label: 'ФИО',
        name: 'FIO'
    },
    {
        label: 'E-mail',
        name: 'EMAIL',
    },
    {
        label: 'Телефон',
        name: 'PHONE',
    },
    {
        label: 'Компания',
        name: 'COMPANY_TITLE',
    },

    {
        label: 'ИНН',
        name: 'INN',
    },

    {
        label: 'Дата создания',
        name: 'DATE_CREATE',
    },
]




class POP_NewPartner extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
            Self: {
            },
            anchorEl: null
        }
        this.input = []
        this.can = true
    }



    componentDidMount() {
        const { } = this.state
        const { ID_OBJECT, NEW_PARTNERS } = this.props

        if (ID_OBJECT != null) {
            var Self = NEW_PARTNERS.find(p => p.ID == ID_OBJECT)
            if (Self == null) return
            Self = GF.ObjectClone(Self)
            Object.keys(this.input).forEach(key => {
                this.input[key].value = Self[key]
            })
            this.setState({ Self })
        }
    }


    Delete() {
        const { COMPANYS, DEPTS, ID_OBJECT } = this.props

        new Promise((resolve, reject) => {
            this.props.openPopUp(<Question text={'Удаление заявки сотрет всю информацию о клиенте в системе'}
                buttons={[
                    <Button className='m-2' color='error' onClick={() => resolve()}>Удалить</Button>,
                    <Button className='m-2' onClick={() => reject()}>Отмена</Button>
                ]} />)
        }).then(() => {

            GF.API_task({
                operation: 'deleteSome',
                Table: 'NEW_PARTNERS',
                ID: ID_OBJECT
            }).then(res => {
                this.props.getDB_Data('getNewPartners', 'NEW_PARTNERS')
                this.props.closePopUp()
            })

        }).finally(() => {
            this.props.closePopUp()
        })


    }


    Save(NEW_STATUS) {


        const func = (ADDITIONAL) => {
            this.props.closePopUp()
            if (!this.can) return
            this.can = false

            GF.API_task({
                operation: 'setNewPartnerStatus',
                ID: this.props.ID_OBJECT,
                NEW_STATUS,
                ADDITIONAL
            }).then(res => {
                this.props.getDB_Data('getNewPartners', 'NEW_PARTNERS')
                this.props.getDB_Data_lazy_notID('HUMANS', 'IMAGES_URL')
                setTimeout(() => this.props.getUSERS(), 500)
                this.props.closePopUp()
            }).finally(() => {
                this.can = true
            })
        }

        if (NEW_STATUS == 16)
            this.props.openPopUp(<POP_NewPartner_Accept callBack={(args) => func(args)} />)
        else
            this.props.openPopUp(<POP_NewPartner_Forbidden callBack={(args) => func(args)} />)






    }



    render() {
        const { NEW_PARTNERS, ID_OBJECT } = this.props
        const { Self, anchorEl } = this.state
        const open = Boolean(anchorEl)



        if (ID_OBJECT != null && Self.ID == null) return <div />

        return (
            <POP>

                <h4>Запрос на сотрудничество #{ID_OBJECT}</h4>
                <div className='row'>
                    <div className='col-6'>
                        {form_fields.map(f => (
                            <div className='mb-2'>
                                <Label>{f.label}</Label>
                                {f.name == 'DATE_CREATE' ?
                                    <p>{Self[f.name] == null ? '-' : GF.makeNormalDate(Self[f.name], 'dd.mm.yyyy H:M')}</p>
                                    : f.name == 'PHONE' ?
                                        <p>{Self[f.name] == null ? '-' : GF.phoneFormatter(Self[f.name])}</p>
                                        :
                                        <p>{Self[f.name] == null ? '-' : Self[f.name]}</p>
                                }
                            </div>
                        ))}
                    </div>
                    <div className='col-6'>
                        <Label>Дополнительная информация</Label>
                        <p>{Self['COMMENT'] == null ? '-' : Self['COMMENT']}</p>

                        {Self['ADDRESS'] == null ? null :
                            <>
                                <Label>Главный офис (адрес доставки)</Label>
                                <p>{Self['ADDRESS']}</p>
                            </>
                        }
                        {Self['REASON_FORBIDDEN'] == null ? null :
                            <>
                                <Label>Причниа отказа</Label>
                                <p>{Self['REASON_FORBIDDEN']}</p>
                            </>
                        }

                        {Self['CREATED_ID_USER'] == null ? null :
                            <>
                                <Label>Созданный аккаунт в системе</Label>
                                <Button onClick={() => this.props.openPopUp(<POP_User ID_OBJECT={Self['CREATED_ID_USER']} />)}>Открыть</Button>
                            </>
                        }
                    </div>
                </div>

                {this.props.ID_OBJECT == null || Self.ID == 1 ? null :
                    <Button className='float me-2' color='error' onClick={() => this.Delete()}>удалить</Button>
                }
                {this.props.ID_OBJECT == null || Self.ID_STATUS != 14 ? null : <>
                    <Button color='success' className='float-end' onClick={() => this.Save(16)}>Подтвердить</Button>
                    <Button color='error' className='float-end me-2' onClick={() => this.Save(15)}>Отказать</Button>
                </>}



            </POP>
        )



    }
}

const POP = styled.div`
width:800px;
`


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp: (arg) => dispatch(closePopUp(arg)),
        openPopUp: (arg) => dispatch(openPopUp(arg)),
        getDB_Data: (operation, tableName, JSONparseKey) => dispatch(getDB_Data(operation, tableName, JSONparseKey)),

        getDB_Data_lazy: (tableName, JSONparseKey) => dispatch(getDB_Data_lazy(tableName, JSONparseKey)),
        getDB_Data_lazy_notID: (tableName, JSONparseKey) => dispatch(getDB_Data_lazy_notID(tableName, JSONparseKey)),

        getUSERS: (arg) => dispatch(getUSERS(arg)),
    };
};


const mapStateToProps = (store) => {

    return {
        NEW_PARTNERS: store.page.NEW_PARTNERS
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POP_NewPartner)




