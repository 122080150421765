
import { AccessTime, AccessTimeFilled, LibraryAddCheck, PeopleAlt, TaskAlt, Work } from '@mui/icons-material';
import { Button, Card, CardActions, CardHeader, IconButton ,CardContent, FormControl, InputLabel, Select, MenuItem, TextField, Alert, Chip, Avatar} from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import { Label } from '../../components/moduls/Input';
import {down, up} from 'styled-breakpoints'
import lodash from 'lodash'
import {withRouter} from 'react-router-dom'
import { DOLLAR, Pults, Tkani } from './Consts';
import POP_News_user from './POP_News_user';
import POP_DevComment from './POP_DevComment';
import MainPage_INST from './roles/MainPage_INST';



class Index extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
           errorMSG:null,
        }
        this.input=[] 
    }

    addPults(){
        let promises = []
        Object.keys(Pults).forEach(pultFirm=>{
            Pults[pultFirm].forEach(pult=>{
                promises.push(
                    GF.API_task({
                        operation:'createUpdateSome',
                        Table:'TEXTS',
                        data:{
                            NAME:pultFirm+" - "+pult.type+" "+pult.title,
                            TITLE:'sustem-coast-pults',
                            TEXT:pult.coast
                        }
                    })
                )
            })
        })

    }
    

    renderADMN(){
        const {user,STATUSES,USERS} = this.props
        const {state,props} = this
        return (
            <MainView>
                <div className='row'>
                     
                     <div className='col-md-4 mb-2'>
                         <Card>
                             <CardHeader
                                 avatar={<PeopleAlt/>}
                                 title="Партнеры"
                                 subheader="Все аккаунты партнеров"
                             />
                                 <CardContent className='InfoBlock'>
                                     <InfoBlock count={2}>
                                         <h1>{USERS.filter(u=> u.ROLE=='СPRT').length}</h1>
                                         <small>Компании</small>
                                     </InfoBlock>
                                     <InfoBlock count={2}>
                                         <h1>{USERS.filter(u=>u.ROLE=='PRTN').length}</h1>
                                         <small>Партнеры</small>
                                     </InfoBlock>
                                 </CardContent>
                                 <CardActions>
                                     <Button color='success' size="small" onClick={()=>this.props.history.push('/data/users')}>Подробнее</Button>
                                 </CardActions>
                         </Card>
                     </div>
                     <div className='col-md-4 mb-2'>
                         
                         <Card>
                             <CardHeader
                                 avatar={<Work/>}
                                 title="Заказы"
                                 subheader="Все заказы партнеров"
                             />
                                 <CardContent className='InfoBlock'>
                                     <InfoBlock count={3}>
                                         <h1>{props.ORDERS.length}</h1>
                                         <small>Все</small>
                                     </InfoBlock>
                                     <InfoBlock count={3}>
                                         <h1 style={{
                                            
                                         }}>{props.ORDERS.filter(f=>GF.makeNormalDate(f.DATE_CREATE,'mm.yyyy')==GF.makeNormalDate(new Date(),'mm.yyyy')).length}</h1>
                                         <small>В этом месяце</small>
                                     </InfoBlock>
                                     <InfoBlock count={3}>
                                         <h1 style={{
                                            
                                         }}>{props.ORDERS.filter(f=>GF.makeNormalDate(f.DATE_CREATE,'dd.mm.yyyy')==GF.makeNormalDate(new Date(),'dd.mm.yyyy')).length}</h1>
                                         <small>За сегодня</small>
                                     </InfoBlock>
                                 </CardContent>
                                 <CardActions>
                                     <Button  color='success' size="small" onClick={()=>this.props.history.push('/data/projects')}>Подробнее</Button>
                                 </CardActions>
                         </Card>
                     </div>
                     
                </div>
            </MainView>
         )
    }

    renderMNGR(){
        const {user,STATUSES,USERS,MyPartners} = this.props
        const {state,props} = this

        const formedOrders = props.ORDERS.filter(order=>order.ID_STATUS==13)
       

        return (
            <MainView>
                <div className='row'>
                     
                     <div className='col-md-4 mb-2'>
                         <Card>
                             <CardHeader
                                 avatar={<PeopleAlt/>}
                                 title="Мои партнеры"
                                 subheader="Все аккаунты партнеров"
                             />
                                 <CardContent className='InfoBlock'>
                                     <InfoBlock count={2}>
                                         <h1>{MyPartners.filter(u=> u.ROLE=='СPRT').length}</h1>
                                         <small>Компании</small>
                                     </InfoBlock>
                                     <InfoBlock count={2}>
                                         <h1>{MyPartners.filter(u=>u.ROLE=='PRTN').length}</h1>
                                         <small>Партнеры</small>
                                     </InfoBlock>
                                 </CardContent>
                                 <CardActions>
                                     <Button color='success' size="small" onClick={()=>this.props.history.push('/my_partners')}>Подробнее</Button>
                                 </CardActions>
                         </Card>
                     </div>
                     <div className='col-md-4 mb-2'>
                         
                         <Card>
                             <CardHeader
                                 avatar={<Work/>}
                                 title="Заказы"
                                 subheader="Все заказы партнеров"
                             />
                                 <CardContent className='InfoBlock'>
                                     <InfoBlock count={3}>
                                         <h1>{formedOrders.length}</h1>
                                         <small>Все</small>
                                     </InfoBlock>
                                     <InfoBlock count={3}>
                                         <h1 style={{
                                            
                                         }}>{formedOrders.filter(f=>GF.makeNormalDate(f.DATE_CREATE,'mm.yyyy')==GF.makeNormalDate(new Date(),'mm.yyyy')).length}</h1>
                                         <small>В этом месяце</small>
                                     </InfoBlock>
                                     <InfoBlock count={3}>
                                         <h1 style={{
                                            
                                         }}>{formedOrders.filter(f=>GF.makeNormalDate(f.DATE_CREATE,'dd.mm.yyyy')==GF.makeNormalDate(new Date(),'dd.mm.yyyy')).length}</h1>
                                         <small>За сегодня</small>
                                     </InfoBlock>
                                 </CardContent>
                                 <CardActions>
                                     <Button  color='success' size="small" onClick={()=>this.props.history.push('/orders')}>Подробнее</Button>
                                 </CardActions>
                         </Card>
                     </div>
                     
                </div>
            </MainView>
         )
    }

    renderPRNT_CPRT(){
        const {user,STATUSES,USERS} = this.props
        const {state,props} = this
        return (
            <MainView>
                <div className='row'>
                    <div className='col-md-4 mb-2'>
                        
                        <Card>
                             <CardHeader
                                 avatar={
                                 <TaskAlt/>
                                 }
                                 title="Мои заказы"
                                 subheader="Все мои заказы"
                             />
                                 <CardContent className='InfoBlock'>
                                     <InfoBlock count={3}>
                                         <h1>{props.ORDERS.length}</h1>
                                         <small>Все</small>
                                     </InfoBlock>
                                     <InfoBlock count={3}>
                                         <h1 style={{
                                            
                                         }}>{props.ORDERS.filter(f=>GF.makeNormalDate(f.DATE_CREATE,'mm.yyyy')==GF.makeNormalDate(new Date(),'mm.yyyy')).length}</h1>
                                         <small>В этом месяце</small>
                                     </InfoBlock>
                                     <InfoBlock count={3}>
                                         <h1 style={{
                                            
                                         }}>{props.ORDERS.filter(f=>GF.makeNormalDate(f.DATE_CREATE,'dd.mm.yyyy')==GF.makeNormalDate(new Date(),'dd.mm.yyyy')).length}</h1>
                                         <small>За сегодня</small>
                                     </InfoBlock>
                                 </CardContent>
                                 <CardActions>
                                     <Button size="small" color='success' onClick={()=>this.props.history.push('/orders')}>Подробнее</Button>
                                 </CardActions>
                        </Card>
                             
                    </div>

                    {props.MY_MANAGER?.ID ==null?null:
                    <div className='col-md-4 mb-2'>
                        
                        <Card style={{height:"100%"}}>
                             <CardHeader
                                 avatar={
                                 <TaskAlt/>
                                 }
                                 title="Мои менеджер"
                                 subheader="На связи"
                             />
                                 <CardContent>
                                    <Chip avatar={<Avatar/>} label={props.MY_MANAGER.FIO}/>
                                    <a href={'tel:'+props.MY_MANAGER.PHONE}  className='m-0 mt-2 d-block'>{GF.phoneFormatter(props.MY_MANAGER.PHONE)}</a>
                                    <a href={'mailto:'+props.MY_MANAGER.EMAIL} className='m-0 mt-1 d-block'>{props.MY_MANAGER.EMAIL}</a>
                                 </CardContent>
                        </Card>
                             
                    </div>}
                     
                </div>
                <hr/>
                {props.NEWS.map(n=>
                 <Card className='mb-3'>
                     <CardHeader
                         title={<div style={{
                             display:'flex',
                             justifyContent:'space-between'
                         }}>
                             <h3 className='m-0'>{n.TITLE}</h3>
                             <Alert  icon={false}  color={n.TYPE=='N'?'primary':'warning'}>{n.TYPE=='N'?'Новость':'Акция'}</Alert>
                         </div>}
                         subheader={n.SUB_TITLE}
                     />
                     <CardContent>
                     {n.TEXT.replace(/<\/p>/g, ' ').replace(/<\/h1>/g, '. ').replace(/<\/h2>/g, '. ').replace(/<\/li>/g, ' ').replace(/<\/h3>/g, '. ').replace(/<(.|\n)*?>/g, '')}
                     </CardContent>
                     <CardActions>
                         <Button size="small" color='success' onClick={()=>props.openPopUp(<POP_News_user ID_OBJECT={n.ID}/>)}>Подробнее</Button>
                     </CardActions>
                 </Card>
                 
                 )}
            </MainView>
         )
    }
  

    render (){
        const {user,STATUSES,USERS} = this.props
        const {state,props} = this
       
        const content = []

        switch(user.ROLE){
            case 'ADMN':content.push(this.renderADMN()); break;
            case 'MNGR':content.push(this.renderMNGR()); break;
            case 'PRTN':
            case 'СPRT':content.push(this.renderPRNT_CPRT()); break;
            case 'INST':content.push(<MainPage_INST/>); break;
        }
        
      
        return (<div>
            <div style={{
                display:'flex',
                gap:20,
                marginBottom:20
            }}>
                <Button color='success' onClick={()=>{
                    this.props.openPopUp(<POP_DevComment/>)
                }}>Написать разработчикам</Button>
            </div>
            {content}
        </div>)
    
      
    }
}

const MainView = styled.div`
& .InfoBlock{
    display: flex;
    justify-content: center;
}
`

const InfoBlock = styled.div`
z-index: 0;
width:calc(100% / ${props=>props.count});
text-align: center;
border-left: 1px solid rgb(230,230,230);
&:first-child{
    border-left: 1px solid transparent;
}
& small{
    margin-top: -10px;
    display: block;
}

${down('lg')}{
    & h1{
        font-size:20px;
    }
    & small{
        font-size:10px;
    }
}

`



const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
        MyPartners:store.page.MyPartners,
        user: store.user.data,
        POPUPS:store.page.POPUPS,
        ORDERS:store.page.ORDERS,
        STATUSES:store.page.STATUSES,
        USERS:store.page.USERS,
        NEWS:store.page.NEWS,
        MY_MANAGER: store.page.MY_MANAGER,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Index))

