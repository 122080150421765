import { useSelector } from "react-redux"
import { createSelector } from "reselect"

const selectUsersMNGR = createSelector(
    (state) => state.page.USERS,
    (USERS) => USERS.filter((user) => user.ROLE == 'MNGR')
)
export default function useUsersMNGR() {
    const UsersMNGR = useSelector(selectUsersMNGR)
    return UsersMNGR
}