import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from "react-router";
import { Link, Route, Switch } from "react-router-dom";
import { down, up } from 'styled-breakpoints';
import styled from 'styled-components';
import { openPopUp } from '../../actions/PopUpActions';
import DataBase from '../pages/DataBase';
import MainPage from '../pages/MainPage';

import BuildCircleIcon from '@mui/icons-material/BuildCircle';

import { IconButton } from '@mui/material';

import { GlobalFunctions as GF } from '../../GlobalFunctions';

import { ArrowBackIosNewRounded, BallotRounded, DashboardRounded, Work } from '@mui/icons-material';
import FolderIcon from '@mui/icons-material/Folder';
import GamepadIcon from '@mui/icons-material/Gamepad';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import Accessories from '../pages/Accessories';
import FileSustem from '../pages/FileSustem';
import CalkPage from '../pages/MainPage/CalkPage';
import Managers from '../pages/Managers';
import MyPartners from '../pages/MyPartners';
import NewPartner from '../pages/NewPartner/NewPartner';
import News from '../pages/News';
import Orders from '../pages/Orders';
import UserProfile from '../pages/UserProfile';

import ExtensionIcon from '@mui/icons-material/Extension';
import Inst_schedule from '../pages/Inst_schedule';
import ManagerInstallers from '../pages/ManagerInstallers';




function mkP(title, url, view, icon) {
    return { title, url, view, icon }
}

const AllMenu = (props) => {

    return {
        ADMN: [
            mkP('Главная', '/main', <MainPage />, <DashboardRounded fontSize='small' />),
            mkP('База данных', '/data', <DataBase />, <BallotRounded fontSize='small' />),
            mkP('Комплектующие', '/accessories', <Accessories />, <ExtensionIcon fontSize='small' />),
            mkP('Новые партнеры', '/new_partners_data', <NewPartner />, <PeopleAltIcon fontSize='small' />),
            mkP('Новости', '/news', <News />, <NewspaperIcon fontSize='small' />),
            mkP('Файловая система', '/file_manager', <FileSustem />, <FolderIcon fontSize='small' />),
            mkP('Менеджеры', '/managers', <Managers />, <GamepadIcon fontSize='small' />),
            mkP('Заказы', '/orders', <Orders />, <Work fontSize='small' />),
            mkP('Монтажники', '/installers', <ManagerInstallers />, <BuildCircleIcon fontSize='small' />),

            //mkP('Помощь','/help',<Help/>,<HelpCenterRounded fontSize='small'/>),

        ],
        PRTN: [
            mkP('Главная', '/main', <MainPage />, <DashboardRounded fontSize='small' />),
            mkP('Заказы', '/orders', <Orders />, <Work fontSize='small' />),
            mkP('Файлы', '/file_manager', <FileSustem />, <FolderIcon fontSize='small' />),
            ...(props.user.PERMISSIONS?.includes('COMPONENTS') ? [
                mkP('Комплектующие', '/accessories', <Accessories />, <ExtensionIcon fontSize='small' />),
            ] : [])

        ],
        MNGR: [
            mkP('Главная', '/main', <MainPage />, <DashboardRounded fontSize='small' />),
            mkP('Заказы', '/orders', <Orders />, <Work fontSize='small' />),
            mkP('Монтажники', '/installers', <ManagerInstallers />, <BuildCircleIcon fontSize='small' />),
            mkP('Мои партнеры', '/my_partners', <MyPartners />, <PeopleAltIcon fontSize='small' />),
            mkP('Файлы', '/file_manager', <FileSustem />, <FolderIcon fontSize='small' />),
        ],
        INST: [
            mkP('Главная', '/main', <MainPage />, <DashboardRounded fontSize='small' />),
            mkP('Мое расписание', '/schedule', <Inst_schedule />, <CalendarMonthIcon fontSize='small' />),
            mkP('Файлы', '/file_manager', <FileSustem />, <FolderIcon fontSize='small' />),
        ],

    }
}

const SimpleRoutes = [
    /*
    {
        url:'/chats/:ID_CHAT',
        view:<Chats/>
    },
    */
    {
        url: '/user_profile',
        view: <UserProfile />
    },
    {
        url: '/calk/:ID_ORDER/:ID_ORDER_ITEM',
        view: <CalkPage />
    },
    {
        url: '/calk/:ID_ORDER',
        view: <CalkPage />
    },
    {
        url: '/calk',
        view: <CalkPage />
    },
]

class LeftMenu extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
            isOpen: false
        }

        let isOpen = GF.getCookie('isOpenMenu')
        if (isOpen != null)
            this.state.isOpen = isOpen
    }





    render() {
        const { user } = this.props
        const { isOpen } = this.state
        const menu = AllMenu(this.props)[user.ROLE]

        const additionalComponents = {//для индикации в меню
            '/new_partners_data': (() => {
                let count = this.props.NEW_PARTNERS.filter(p => p.ID_STATUS == 14).length
                if (count > 0) {
                    return (
                        <MenuInd isOpen={isOpen} color='rgb(83, 154, 104)'>{count}</MenuInd>
                    )
                }
            })(),
            '/orders': (() => {
                let count = this.props.ORDERS.filter(p => p.ID_STATUS == 13 && p.MANAGER_READED == 0).length

                count += this.props.ACCESSORIES_ORDERS.filter(p => p.MANAGER_READED == 0 && p.ID_STATUS == 17).length
                if (count > 0) {
                    return (
                        <MenuInd isOpen={isOpen} color='rgb(52, 145, 255)'>{count}</MenuInd>
                    )
                }
            })(),
            '/managers': (() => {
                const count = this.props.USERS.filter(user =>
                    (user.ROLE == 'PRTN' || user.ROLE == 'CPRT') && user.ID_MANAGER == null
                ).length
                if (count > 0) {
                    return (
                        <MenuInd isOpen={isOpen} color='#d32f2f'>{count}</MenuInd>
                    )
                }
            })()
        }




        if (menu == null) return <div />
        return ([
            <Burger isOpen={isOpen} onClick={() => this.setState({ isOpen: !isOpen })}>
                <div className='b1' />
                <div className='b2' />
                <div className='b3' />
            </Burger>,
            <MasterView isOpen={isOpen}>
                {window.innerWidth < 1000 ? null : [
                    <IconButton style={{
                        transition: '.3s',
                        transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)'
                    }} size='small' className='m-1' onClick={() => {
                        GF.setCookie('isOpenMenu', !isOpen)
                        this.setState({ isOpen: !isOpen })
                    }}>
                        <ArrowBackIosNewRounded fontSize='small' />
                    </IconButton>,
                    <hr className='mt-1 mb-0 ms-1 me-1' />
                ]}
                {menu.map(m => (
                    <Link to={m.url} onClick={() => {
                        if (window.innerWidth < 1000) {
                            this.setState({ isOpen: false })
                        }
                    }} style={{
                        position: 'relative'
                    }}>
                        <ListElem isSelected={
                            this.props.location.pathname.indexOf(m.url) == 0 ||
                            m.url == '/main' && this.props.location.pathname == '/'
                        }>{[m.icon, m.title]} {additionalComponents[m.url]}</ListElem>
                    </Link>
                ))}


            </MasterView>,

            <ContentView isOpen={isOpen}>
                <Switch>
                    {SimpleRoutes.map(m =>
                        <Route path={m.url}>
                            {m.view}
                        </Route>

                    )}
                    {menu.map(m =>
                        <Route path={m.url}>
                            {m.view}
                        </Route>

                    )}

                    <Redirect path='/' to={'/main'} />

                </Switch>
            </ContentView>
        ])
    }
}

const MenuInd = styled.div`
background:${props => props.color};
border-radius:50px;
height:18px;
width:18px;
text-align:center;
position: absolute;
transition:.0s;
border: 2px solid white;
font-size: 12px;
line-height: 12.5px;
color: white;
font-weight: bold;

${props => props.isOpen ? `
top: 15px;
right: 15px;
`: `
right: unset;
left: 22px;
top: 7px;
`}

}

`

const Burger = styled.div`
${up('md')}{
    display:none;
}
height: 32px;
width: 32px;
position: fixed;
top: 7px;
left: 30px;
z-index: 11;
cursor:pointer;
transition:.3s;
padding-top: 7px;

& div{
    transform-origin-x:0%;
    transition:.3s;
    background:rgba(0,0,0,0.8);
    height:3px;
    width:100%;
    margin-bottom:5px;
    border-radius:2px;
}

& div.b2{
    transition:all .3s, transform.3s ease .1s;
}

${props => props.isOpen ? `
    & div.b1{
        transform:scale(0.8,1) translateY(8px) rotate(-34deg);
    }
    & div.b3{
        transform:scale(0.8,1) translateY(-8px) rotate(34deg);
    }
    & div.b2{
        transform:scale(0,1);
    }
    & div:hover{
        margin-bottom:5px;
        background:rgba(0,0,0,1);
    }
    &:hover{
        transform:scale(1);
        padding-top: 7px;
    }
`: `
    &:hover div{
        margin-bottom:8px;
        background:rgba(0,0,0,1);
    }
    &:hover{
        transform:scale(1.1);
        padding-top: 4px;
    
    }
`}

`

const MasterView = styled.div`
width:230px;
border-right: 1px solid #f0f0f0;
height:calc(100vh - 50px);
background:white;
position:fixed;
top:50px;
overflow-x:hidden;
overflow-y:auto;
left:0px;

& a{
    color:gray;
    color: rgb(151, 158, 167);
    text-decoration: none;
    font-variant: all-petite-caps;
    font-size: 18px;
    padding: 10px 20px;
    width: 230px;
    display: block;
    cursor: pointer;
    transition: .3s;
    padding-left: 30px;

}

& a:hover{
    color:gray;
    background:rgba(0,0,0,0.01);
    
}
& a svg{
    margin-left:-20px;
    margin-right:10px;
}
z-index:5;

${down('md')}{
    transition:.5s ease-in-out;
    width:60vw;
    ${props => props.isOpen ? `
        transform:translateX(0);
        box-shadow: 10px 0px 30px rgba(0,0,0,0.1);
    `: `
        transform:translateX(-60vw);
        box-shadow: 10px 0px 30px rgba(0,0,0,0);
    `}
    
}
${up('md')}{
    transition:.5s ease-in-out;
    
    ${props => props.isOpen ? `
        width:230px;
    `: `
        width:40px;
    `}

    
    
}
`

const ListElem = styled.div`
transition:.3s;
${props => props.isSelected ? `
color:rgb(46, 125, 50);
`: ``}
`

const ContentView = styled.div`

background:white;

height:calc(100vh - 50px);
overflow-y:auto;
margin-top:50px;
overflow-x:hidden;
padding:20px;

${down('md')}{
    margin-left:0px;
    width:100vw;
    padding-bottom:20vh;
    overflow-x:auto;
}
${up('md')}{
    transition:.5s ease-in-out;
    ${props => props.isOpen ? `
    margin-left:230px;
            width:calc(100vw - 230px);
        `: `
        margin-left:40px;
            width:calc(100vw - 40px);
        
    `}
}
`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp: (arg) => dispatch(openPopUp(arg))
    };
};


const mapStateToProps = (store) => {

    return {
        user: store.user.data,
        NEW_PARTNERS: store.page.NEW_PARTNERS,
        ACCESSORIES_ORDERS: store.page.ACCESSORIES_ORDERS,
        ORDERS: store.page.ORDERS,
        USERS: store.page.USERS,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LeftMenu));

