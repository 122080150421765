
import { Check } from '@mui/icons-material';
import { Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Select, TextField, Tooltip, Typography,Grid } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getDB_Data,getDB_Data_lazy,getFileSustem,getUSERS } from '../../../actions/PageActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import {HexColorPicker} from 'react-colorful'





const form_fields = [
    {
        label:'Название ссылки',
        name:'TITLE',
    },
    {
        label:'URL',
        name:'URL',
    },
    
]

class POP_AddURL extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
            Self:{}
        }
        this.input=[]
    }

    

    componentDidMount() {
        const {props,state} = this
        const {COMPANYS,STATUSES,ID_OBJECT} = this.props

        if(ID_OBJECT!=null){
            var Self_tmp = props.FileSustem.find(p=>p.ID==ID_OBJECT)
            if(Self_tmp==null) return
            
            this.setState({Self:GF.ObjectCopy(Self_tmp)})
        }
    }

   


    Save(){
        this.props.closePopUp()
        this.props.callBack({
            name:this.state.Self.TITLE,
            url:this.state.Self.URL
        })
        
    }

    render (){
        const {COMPANYS,user} = this.props
        const {state,props} = this
       
    
        return (
            <POP>
                <h4>Директория</h4>
                <small>{props.PARENT.TITLE} / </small>
                {form_fields.map(input => 
                        <TextField
                            className='w-100 mt-3'
                            margin='dense'
                            variant="filled"
                            size='small'
                            {...input}
                            value={state.Self[input.name]}
                            onChange={(e)=>
                                this.setState({
                                    Self:GF.changeParametr(state.Self,input.name,e.target.value)
                                })
                            }
                            inputRef={ref=>this.input[input.name]=ref}
                        />
                )}

                <div className='mt-3'/>

                <Button className='float-end' onClick={()=>this.Save()}>Сохранить</Button>

            </POP>
        )

            
      
    }
}

const POP = styled.div`
width:300px;
`





const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
       
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 

        getDB_Data_lazy:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy(tableName,JSONparseKey)), 

        getFileSustem: (arg) => dispatch(getFileSustem(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        user:store.user.data,
        FileSustem:store.page.FileSustem,
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(POP_AddURL)

