import { Avatar, Button, Chip, Stack, TextField } from '@mui/material'
import Immutable from 'immutable'
import React, { memo } from 'react'
import { useState } from 'react'
import { useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getManagerInstallersRooles } from '../../../../../actions/InstallSchedule'
import { closePopUp, openPopUp } from '../../../../../actions/PopUpActions'
import { GF } from '../../../../../GlobalFunctions'
import useUsersMNGR from '../../../../../Hooks/Data/USERS/useUsersMNGR'
import POP_User, { UserDataContext } from '../POP_User'
import POP_Alert from '../../../../PopUp/Views/POP_Alert'
import POP_UserSearch from '../POP_UserSearch'
import { useContext } from 'react'
import StackHorizontal from '../../../../components/StackHorizontal'
import { Label } from '../../../../components/moduls/Input'
import Debounce from '../../../../../Class/Debounce'



export default function PopUser_INST() {

    const { Self } = useContext(UserDataContext)
    const [filter, setFilter] = useState(null)
    const managerInstallersRooles = useSelector(state => state.page.ManagerInstallersRooles)
    const usersMNGR = useUsersMNGR()

    const dispatch = useDispatch()

    const [thisUserManagers, setThisUserManagers] = useState([])
    const [thisUserManagers_filtered, setThisUserManagers_filtered] = useState([])
    const [toDeleteManagersID, setToDeleteManagersID] = useState([])

    useLayoutEffect(() => {
        const newUserManagers = []
        managerInstallersRooles.forEach(roole => {
            if (roole.ID_INST == Self.ID) {
                let user = usersMNGR.find(us => us.ID == roole.ID_MNGR)
                if (user != null) {
                    newUserManagers.push(
                        Immutable.fromJS(user).toJS()
                    )
                }
            }
        })
        setThisUserManagers(newUserManagers)
    }, [managerInstallersRooles, usersMNGR, Self])

    useLayoutEffect(() => {
        if (filter == null) {
            setThisUserManagers_filtered(thisUserManagers)
        } else {
            console.error('filter', filter)
            setThisUserManagers_filtered(
                thisUserManagers.filter(user =>
                    user.FIO?.toUpperCase()?.indexOf(filter) >= 0
                )
            )
        }
    }, [thisUserManagers, filter])


    function removeMNGR(userMNGR) {
        let text = 'Вы уверены, что хотите удалить доступ этому менеджеру?'
        if (userMNGR.ID == Self.ID_USER_CREATOR) {
            //Если удаляем того, кто создал этого юзера (менеджера автора)
            text += ' Он сам создал акаунт данного монтажника'
        }

        new Promise((resolve, reject) => {

            dispatch(openPopUp(<POP_Alert text={text}
                buttons={[
                    <Button onClick={resolve} color='error'>Да, удалить</Button>,
                    <Button onClick={reject}>Отмена</Button>
                ]} />
            ))

        }).then(() => {
            setToDeleteManagersID(prev => ([...prev, userMNGR.ID]))
            GF.API_task({
                operation: 'deleteManagerInstRoole',
                ID_MNGR: userMNGR.ID,
                ID_INST: Self.ID,
                block: 'InstallSchedule'
            }).then(() => {
                dispatch(getManagerInstallersRooles())
            })
        }).finally(() => {
            dispatch(closePopUp())
        })
    }

    function addManagerRoole() {
        setToDeleteManagersID([])

        dispatch(openPopUp(<POP_UserSearch title='Выберите менеджеров'
            callBack={selected => {
                dispatch(closePopUp())
                GF.API_task({
                    operation: 'createManagerInstRooles',
                    ID_MNGR_arr: selected.map(usr => usr.ID),
                    ID_INST: Self.ID,
                    block: 'InstallSchedule'
                }).then(() => {
                    dispatch(getManagerInstallersRooles())
                })
            }} isHiddenRule={user => {
                return user.ROLE != 'MNGR' || thisUserManagers.findIndex(usr => usr.ID == user.ID) != -1
            }} multiple placeholder='Нет доступных менеджеров' />))
    }

    return (<View>
        <StackHorizontal>
            <TextField
                style={{ width: '100%', flex: 1 }}
                className='m-0 w-100'
                label='Поиск менеджера'
                variant='filled'
                size="small"
                color='success'
                onChange={(e) => setFilter(e.target.value.length > 0 ? e.target.value.toUpperCase() : null)}
            />

            <Button key='ActionButton' color='success'
                style={{ width: 100 }}
                onClick={addManagerRoole}
                disabled={Self.ID == null}>
                Добавить
            </Button>
        </StackHorizontal>
        <Label>Работает с менеджерами:</Label>
        <Stack direction='row' flexWrap='wrap' sx={{ mb: 2 }}>
            {thisUserManagers.length > 0 ? null : <small>Доступен только Администратору</small>}
            {thisUserManagers_filtered.map(userMNGR => {

                return (<Chip key={`mngr_${userMNGR.ID}`}
                    avatar={<Avatar />}
                    label={userMNGR.FIO}
                    style={{ marginBottom: 2, width: '100%', justifyContent: "space-between" }}
                    color={toDeleteManagersID.includes(userMNGR.ID) ? 'warning' : 'default'}
                    onDelete={() => removeMNGR(userMNGR)}
                    onClick={() => dispatch(openPopUp(<POP_User ID_OBJECT={userMNGR.ID} />))} />)
            })}
        </Stack>
    </View >)
}

const View = styled.div`
& .MuiChip-label.MuiChip-labelMedium{
    flex:1;
}
`