
import { AccessTime, AccessTimeFilled, LibraryAddCheck, PeopleAlt, TaskAlt, Work } from '@mui/icons-material';
import { Button, Card, CardActions, CardHeader, IconButton ,CardContent, FormControl, InputLabel, Select, MenuItem, TextField, Alert, AlertTitle} from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'

import {withRouter} from 'react-router-dom'




class Index extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
           errorMSG:null,
        }
        this.input=[] 
    }

  

    render (){
        const {user,STATUSES,USERS} = this.props
       
        return (<div>
            
            <Alert>
                <AlertTitle>Сохранено</AlertTitle>
                Расчет сохранен в вашем аккаунте
            </Alert>
            
        </div>)

    
      
    }
}




const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
        
        user: store.user.data,
        POPUPS:store.page.POPUPS,
        STATUSES:store.page.STATUSES,
        USERS:store.page.USERS,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Index))

