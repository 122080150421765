
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { closePopUp, openPopUp } from '../../../actions/PopUpActions';
import { getDB_Data, getDB_Data_lazy, getDB_Data_lazy_notID, getUSERS } from '../../../actions/PageActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import Table from '../../components/Table';
import withToast from '../../components/Toasts'
import { Avatar, Button, ButtonGroup, Chip, TextField, Tooltip } from '@mui/material';
import POP_News from './POP_News';
import { Label } from '../../components/moduls/Input';





const Rows = [
  {
    title:'Заголовок',
    key:'TITLE'
  },
  {
    title:'Подзаголовок',
    key:'SUB_TITLE'
  },

  {
    title:'Тип',
    key:'TYPE',
    width:'100px'
  },
  {
    title:'В заголовке',
    key:'IS_PRIMARY',
    width:'150px'
  },
  {
      title:'Дата публикации',
      key:'DATE_CREATE',
      width:'150px'
  },
  
]

class NewPartner extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
          errorMSG:null,
          succes:false,
          filter:null,
          Data:[],
          selectedRows:[]
        }
        this.input={
          dataWindow:{},
          main:{}
        }
        this.checkBoxs={}
        this.can=true
    }


 

    checkSelected(){
      const {state,props} = this
        const {filter,Data,selectedRows} = this.state

        const filtered  = props.NEWS.filter(u=>filter==null ||
          u.TITLE.toUpperCase().indexOf(filter)!=-1 ||
          u.SUB_TITLE.toUpperCase().indexOf(filter)!=-1 ||
          u.TEXT.toUpperCase().indexOf(filter)!=-1
        )
      let correctRows = []
        selectedRows.forEach((sr,i)=>{
          if(filtered.findIndex(u=>u.ID==sr)!=-1){
            correctRows.push(sr)
          }
        })
        if(correctRows.length!=selectedRows){
          this.setState({selectedRows:correctRows})
        }
    }

    setStatus(NEW_STATUS){

      const {filter,Data,selectedRows} = this.state

      const func = (ADDITIONAL)=>{
        this.props.closePopUp()
        if(!this.can) return
        this.can=false

        let promises = []

        selectedRows.forEach(sr=>{
          promises.push(GF.API_task({
            operation:'setNewPartnerStatus',
            ID:sr,
            NEW_STATUS,
            ADDITIONAL
          }))
        })

      

      Promise.all(promises)
        .then(res=>{
            this.props.getDB_Data('getNewPartners','NEW_PARTNERS')
            this.props.getDB_Data_lazy_notID('HUMANS','IMAGES_URL')
            setTimeout(()=>this.props.getUSERS(),500)
           
        }).finally(()=>{
            this.can=true
            this.setState({selectedRows:[]})
        })
        
        
    }

    

  

    }



    render (){
      const {state,props} = this
        const {filter,Data,selectedRows} = this.state

        const filtered  = props.NEWS.filter(u=>filter==null ||
          u.TITLE.toUpperCase().indexOf(filter)!=-1 ||
          u.SUB_TITLE.toUpperCase().indexOf(filter)!=-1 ||
          u.TEXT.toUpperCase().indexOf(filter)!=-1
        )

        return(
          <View>
            <div className='mt-4'>
              <TextField
                className='me-2'
                label='Поиск'
                variant='filled'
                size="small"
                onKeyUp={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null},this.checkSelected)}
              />

              <Button color='success' className='me-2 align-bottom' variant='text' onClick={()=>{
                this.props.openPopUp(<POP_News/>)
              }}>Создать</Button>

              <Button color='success' className='me-2 align-bottom' variant='text' onClick={()=>{
                this.setState({selectedRows:filtered.map(u=>u.ID)})
              }}>Выбрать все</Button>

              <Button disabled={selectedRows.length==0} color='success' className='me-2 align-bottom' variant='text' onClick={()=>{
                 this.setState({selectedRows:[]})
              }}>Cнять выделение</Button>

            

            </div>
              
          
              <Table
                    className='mt-1'
                    rows={Rows}
                    keyExtraktor={item=>item.ID}
                    canCheck={true}
                    selectedRows={selectedRows}
                    setSelectedRows={(rows)=>this.setState({selectedRows:rows})}
                    data={filtered}
                    itemOnClick={({data,index})=>{
                      this.props.openPopUp(<POP_News ID_OBJECT={data.ID}/>)
                    }}
                    rowOnClick={({data,index})=>{
                        debugger
                    }}
                    renderItem={({data,index})=>
                      Rows.map(r=>{
                        switch(r.key){
                          case 'IS_PRIMARY':
                            return(<td>
                              {!!data.IS_PRIMARY==1 &&
                               <Chip size='small' label='Да'/>
                              }
                            </td>)
                           case 'TYPE':
                            return(<td>
                              {data.TYPE=='N' ?
                               <Chip size='small' color='primary' label='Новость'/>:
                               <Chip size='small' color='warning' label='Акция'/>
                              }
                            </td>)
                          case 'DATE_CREATE':
                            return(<td>{GF.makeNormalDate(data.DATE_CREATE,'dd.mm.yyyy H:M')}</td>)
                            
                          default: return(<td>{data[r.key]}</td>)
                        }
                        
                      })
                    }/>

           
          </View>
        )
    }
}
const View = styled.div`
height:100%;
`

const mapDispatchToProps = (dispatch) => {
    return {
      openPopUp:(arg)=>dispatch(openPopUp(arg)),
      closePopUp:(arg)=>dispatch(closePopUp(arg)),
      getUSERS:(arg)=>dispatch(getUSERS(arg)),
      getDB_Data:(arg,grfm)=>dispatch(getDB_Data(arg,grfm)),
      getDB_Data_lazy_notID:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy_notID(tableName,JSONparseKey)), 
      getDB_Data_lazy:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy(tableName,JSONparseKey)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
      NEWS:store.page.NEWS,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(withToast(NewPartner));

