import { store } from "../../../store/configureStore"

export const DOLLAR = 70

function mP(type, title, descr, coast) {
  return { type, title, coast, descr }
}
export const Pults = () => {
  let pults = {
    "NOVO": [
      mP('PULT Пульт ДУ', '5 - канальный белый', null, 1200),
      mP('PULT Пульт ДУ', '5 - канальный черный', null, 1200),
      mP('PULT Пульт ДУ', '9 - канальный черный', null, 1450),
      mP('PULT Пульт ДУ', '99 - канальный черный', null, 1775),

      mP('PULT Радиокнопка', '1 - канальная белая нажимная', null, 1350),
      mP('PULT Радиокнопка', '2 - канальная белая нажимная', null, 1350),
      mP('PULT Радиокнопка', '5 - канальная белая сенсорная', null, 1550),
      mP('PULT Радиокнопка', '5 - канальная черная сенсорная', null, 1550),

      mP('PULT Hub', 'управления изделиями со смартфона', null, 3500),
    ],
    "DAUERHAFT": [
      mP('PULT Пульт ДУ', '1 - канальный черный', null, 2250),
      mP('PULT Пульт ДУ', '2 - канальный черный', null, 2450),
      mP('PULT Пульт ДУ', '6 - канальный черный', null, 2750),
      mP('PULT Пульт ДУ', '16 - канальный черный', null, 3000),

      mP('PULT Радиокнопка', '1 - канальная черная нажимная', null, 2500),
      mP('PULT Радиокнопка', '2 - канальная черная нажимная', null, 2500),
      mP('PULT Радиокнопка', '5 - канальная черная сенсорная', null, 2700),
      mP('PULT Радиокнопка', 'с таймером 16 - канальная серая сенсорная', null, 4500),
      mP('PULT Радиокнопка', 'с таймером 1 - канальная черная нажимная', null, 3700),

      mP('PULT Hub', 'управления изделиями со смартфона', null, 5000),
    ],
    "AQARA": [
      mP('PULT Hub', 'Aqara Hub M1S ', null, 4990),
      mP('PULT Кнопка управления', 'Aqara Wireless Mini Switch WXKG11LM', null, 2290),
      mP('PULT Выключатель', 'Беспроводной двухклавишный WRS-R02  (Zigbee)', null, 3990),
      mP('PULT Куб управления', 'MFKZQ01LM  (Zigbee)', null, 1990),
    ],
    "SOMFY": [
      mP('PULT Пульт ДУ SITUO', '1 RTS PURE, радиопередатчик 1-канальный ', '30, цвет белый, новый изогнутый дизайн', 3700),
      //mP('PULT Пульт ДУ SITUO', '2 RTS PURE, радиопередатчик 2-канальный ', '30, цвет белый, новый изогнутый дизайн', 5990),
      mP('PULT Пульт ДУ SITUO', '5 RTS PURE, радиопередатчик 4+1-канальный ', '30, цвет белый, новый изогнутый дизайн', 6900),
      //mP('PULT Пульт ДУ SITUO', '1 VARIATION RTS PURE, для жалюзи 1-канальный ', '30, цвет белый, новый изогнутый дизайн', 6925),
      //mP('PULT Пульт ДУ SITUO', '5 VARIATION RTS PURE, для жалюзи 5-канальный ', '30, цвет белый, новый изогнутый дизайн', 8650),
      //mP('PULT Пульт ДУ TELIS', '1 RTS PATIO,  1-канальный ', '44, влагозащищенный, цвет белый', 5625),
      //mP('PULT Пульт ДУ TELIS', '4 RTS PATIO, 4+1-канальный ', '44, влагозащищенный', 8200),
      mP('PULT Пульт ДУ TELIS', '16 RTS PURE, 16 канальный', '30, ЖК-дисплей, цвет белый', 16900),
      mP('PULT Пульт ДУ TELIS', '6 CHRONIS RTS PURE, сценарный, 6-канальный ', '30, ЖК-дисплей, цвет белый', 18950),
      mP('PULT Радиокнопка SMOOVE', 'ORIGIN RTS, 1-канальный ', '30, нажимные клавиши, комплект с рамкой, цвет белый', 2651),
      //mP('PULT Радиокнопка SMOOVE', '1 RTS PURE SHINE, 1-канальный, сенсорный', '30, без рамки, цвет белый', 3340),
      //mP('PULT Радиокнопка SMOOVE', '1 RTS BLACK SHINE, 1-канальный, сенсорный', '30, без рамки, цвет черный', 3340),
      //mP('PULT Радиокнопка SMOOVE', '1 RTS SILVER SHINE, 1-канальный, сенсорный', '30, без рамки, цвет серебристый', 4050),
      //mP('PULT Радиокнопка SMOOVE', '1 RTS BLACK SHINE O/C, 1-канальный, сенсорный', '30, без рамки, цвет черный, для шторных карнизов GLYDEA RTS', 3340),
      //mP('PULT Радиокнопка SMOOVE', '1 RTS PURE SHINE O/С, 1-канальный, сенсорный ', '30, без рамки, цвет белый, для шторных карнизов GLYDEA RTS', 3340),
      mP('PULT Радиокнопка SMOOVE', 'ORIGIN 2 RTS, 2 канальный', '30, нажимные клавиши, комплект с рамкой, цвет белый', 4100),
      mP('PULT Радиокнопка SMOOVE', 'ORIGIN 4 RTS, 4 канальный', '30, нажимные клавиши, комплект с рамкой, цвет белый', 5230),
      mP('PULT Радиопередатчик', 'SHUTTER IN-WALL TRANSMITTER RTS, радиопередатчик', '30, установка под выключатель для привода, внутри помещения, батарейка 3 В тип CR 2430', 4280),
      mP('PULT Радиопередатчик', 'DRY CONTACT TRANSMITTER, радиопередатчик с IB входом, 230V', '30, дизайн Jung CD 500, цвет белый', 10456),
      mP('PULT Радиопередатчик', 'SUNIS INDOOR WIREFREE RTS, радиодатчик солнца', '31, установка на стекло внутри помещения, батарейка 3 В тип CR 2430', 16900),
      mP('PULT Радиопередатчик', 'THERMOSUNIS INDOOR WIREFREE RTS, радиодатчик солнца/температуры', '31, установка на стекло внутри помещения, ,батарейка 3 В тип CR 2430', 18250),
      mP('PULT Управление через ИНТЕРНЕТ', 'CONNEXOON WINDOW RTS', 'управление всеми устройствами RTS в доме через интернет со смартфона/планшета, подключение через Ethernet, 24 канала, приложение для iOS и Android', 15000)
    ]
  }
  let TEXTS = store.getState().page?.TEXTS
  let result = {}

  if (TEXTS != null && Object.keys(TEXTS).length > 0) {
    Object.keys(pults).forEach(pultMark => {
      pults[pultMark].forEach((pult, pult_index) => {
        pults[pultMark][pult_index].coast = TEXTS[`${pultMark} - ${pult.type} ${pult.title}`]?.TEXT
        if (pults[pultMark][pult_index].coast <= 0)
          delete (pults[pultMark][pult_index])
      })


    })
  }







  return pults
}

const Tkani = {
  "E категория": {
    "1.1": {
      "0.5": "26.20",
      "0.6": "28.80",
      "0.7": "31.68",
      "0.8": "34.56",
      "0.9": "37.31",
      "1.0": "40.29",
      "1.1": "42.70",
      "1.2": "45.27",
      "1.3": "47.98",
      "1.4": "50.39",
      "1.5": "52.90",
      "1.6": "55.55",
      "1.7": "58.33",
      "1.8": "60.66",
      "1.9": "63.09",
      "2.0": "65.60",
      "2.1": "68.23",
      "2.2": "70.28",
      "2.3": "72.39",
      "2.4": "74.57",
      "2.5": "76.80",
      "2.6": "79.11",
      "2.7": "81.47",
      "2.8": "83.90",
      "2.9": "86.45",
      "3.0": "89.03",
      "3.1": "91.71",
      "3.2": "94.46",
      "3.3": "97.30"
    },
    "1.3": {
      "0.5": "27.76",
      "0.6": "30.55",
      "0.7": "33.59",
      "0.8": "36.62",
      "0.9": "39.54",
      "1.0": "42.70",
      "1.1": "45.27",
      "1.2": "47.98",
      "1.3": "50.87",
      "1.4": "53.41",
      "1.5": "56.09",
      "1.6": "58.89",
      "1.7": "61.83",
      "1.8": "64.29",
      "1.9": "66.88",
      "2.0": "69.54",
      "2.1": "72.34",
      "2.2": "74.49",
      "2.3": "76.73",
      "2.4": "79.03",
      "2.5": "81.40",
      "2.6": "83.85",
      "2.7": "86.36",
      "2.8": "88.96",
      "2.9": "91.64",
      "3.0": "94.39",
      "3.1": "97.22",
      "3.2": "100.13",
      "3.3": "103.13"
    },
    "1.5": {
      "0.5": "29.43",
      "0.6": "32.37",
      "0.7": "35.60",
      "0.8": "38.82",
      "0.9": "41.92",
      "1.0": "45.27",
      "1.1": "47.98",
      "1.2": "50.87",
      "1.3": "53.91",
      "1.4": "56.62",
      "1.5": "59.46",
      "1.6": "62.41",
      "1.7": "65.53",
      "1.8": "68.16",
      "1.9": "70.89",
      "2.0": "73.72",
      "2.1": "76.67",
      "2.2": "78.96",
      "2.3": "81.35",
      "2.4": "83.78",
      "2.5": "86.30",
      "2.6": "88.88",
      "2.7": "91.54",
      "2.8": "94.29",
      "2.9": "97.11",
      "3.0": "100.04",
      "3.1": "103.04",
      "3.2": "106.13",
      "3.3": "109.31"
    },
    "1.7": {
      "0.5": "31.20",
      "0.6": "34.31",
      "0.7": "37.75",
      "0.8": "41.14",
      "0.9": "44.44",
      "1.0": "47.98",
      "1.1": "50.87",
      "1.2": "53.91",
      "1.3": "57.14",
      "1.4": "60.01",
      "1.5": "63.00",
      "1.6": "66.16",
      "1.7": "69.48",
      "1.8": "72.25",
      "1.9": "75.14",
      "2.0": "78.14",
      "2.1": "81.28",
      "2.2": "83.71",
      "2.3": "86.22",
      "2.4": "88.80",
      "2.5": "91.46",
      "2.6": "94.21",
      "2.7": "97.04",
      "2.8": "99.94",
      "2.9": "102.95",
      "3.0": "106.04",
      "3.1": "109.23",
      "3.2": "112.49",
      "3.3": "115.87"
    },
    "1.9": {
      "0.5": "32.74",
      "0.6": "36.04",
      "0.7": "39.63",
      "0.8": "43.20",
      "0.9": "46.65",
      "1.0": "50.39",
      "1.1": "53.41",
      "1.2": "56.62",
      "1.3": "60.01",
      "1.4": "63.00",
      "1.5": "66.16",
      "1.6": "69.48",
      "1.7": "72.94",
      "1.8": "75.87",
      "1.9": "78.89",
      "2.0": "82.05",
      "2.1": "85.34",
      "2.2": "87.90",
      "2.3": "90.53",
      "2.4": "93.25",
      "2.5": "96.05",
      "2.6": "98.93",
      "2.7": "101.88",
      "2.8": "104.95",
      "2.9": "108.09",
      "3.0": "111.33",
      "3.1": "114.69",
      "3.2": "118.12",
      "3.3": "121.67"
    },
    "2.1": {
      "0.5": "34.38",
      "0.6": "37.82",
      "0.7": "41.61",
      "0.8": "45.34",
      "0.9": "48.98",
      "1.0": "52.90",
      "1.1": "56.09",
      "1.2": "59.46",
      "1.3": "63.00",
      "1.4": "66.16",
      "1.5": "69.48",
      "1.6": "72.94",
      "1.7": "76.59",
      "1.8": "79.66",
      "1.9": "82.84",
      "2.0": "86.16",
      "2.1": "89.60",
      "2.2": "92.27",
      "2.3": "95.05",
      "2.4": "97.91",
      "2.5": "100.85",
      "2.6": "103.87",
      "2.7": "106.99",
      "2.8": "110.19",
      "2.9": "113.51",
      "3.0": "116.91",
      "3.1": "120.40",
      "3.2": "124.02",
      "3.3": "127.74"
    },
    "2.3": {
      "0.5": "36.11",
      "0.6": "39.72",
      "0.7": "43.70",
      "0.8": "47.62",
      "0.9": "51.43",
      "1.0": "55.55",
      "1.1": "58.89",
      "1.2": "62.41",
      "1.3": "66.16",
      "1.4": "69.48",
      "1.5": "72.94",
      "1.6": "76.59",
      "1.7": "80.42",
      "1.8": "83.64",
      "1.9": "86.98",
      "2.0": "90.47",
      "2.1": "94.08",
      "2.2": "96.90",
      "2.3": "99.81",
      "2.4": "102.81",
      "2.5": "105.88",
      "2.6": "109.06",
      "2.7": "112.34",
      "2.8": "115.70",
      "2.9": "119.18",
      "3.0": "122.75",
      "3.1": "126.41",
      "3.2": "130.22",
      "3.3": "134.15"
    },
    "2.5": {
      "0.5": "37.91",
      "0.6": "41.71",
      "0.7": "45.89",
      "0.8": "50.01",
      "0.9": "54.00",
      "1.0": "58.33",
      "1.1": "61.83",
      "1.2": "65.53",
      "1.3": "69.48",
      "1.4": "72.94",
      "1.5": "76.59",
      "1.6": "80.42",
      "1.7": "84.45",
      "1.8": "87.82",
      "1.9": "91.33",
      "2.0": "94.98",
      "2.1": "98.79",
      "2.2": "101.76",
      "2.3": "104.80",
      "2.4": "107.95",
      "2.5": "111.18"
    },
    "2.7": {
      "0.5": "39.44",
      "0.6": "43.39",
      "0.7": "47.73",
      "0.8": "52.00",
      "0.9": "56.17",
      "1.0": "60.66",
      "1.1": "64.29",
      "1.2": "68.16",
      "1.3": "72.25",
      "1.4": "75.87",
      "1.5": "79.66",
      "1.6": "83.64",
      "1.7": "87.82",
      "1.8": "91.33",
      "1.9": "94.98",
      "2.0": "98.79",
      "2.1": "102.75",
      "2.2": "105.82",
      "2.3": "108.99",
      "2.4": "112.25",
      "2.5": "115.64"
    },
    "2.9": {
      "0.5": "41.01",
      "0.6": "45.12",
      "0.7": "49.63",
      "0.8": "54.10",
      "0.9": "58.41",
      "1.0": "63.09",
      "1.1": "66.88",
      "1.2": "70.89",
      "1.3": "75.14",
      "1.4": "78.89",
      "1.5": "82.84",
      "1.6": "86.98",
      "1.7": "91.33",
      "1.8": "94.98",
      "1.9": "98.79",
      "2.0": "102.75",
      "2.1": "106.83",
      "2.2": "110.04",
      "2.3": "113.35",
      "2.4": "116.76",
      "2.5": "120.24"
    },
    "3.1": {
      "0.5": "42.66",
      "0.6": "46.91",
      "0.7": "51.61",
      "0.8": "56.26",
      "0.9": "60.75",
      "1.0": "65.60",
      "1.1": "69.54",
      "1.2": "73.72",
      "1.3": "78.14",
      "1.4": "82.05",
      "1.5": "86.16",
      "1.6": "90.47",
      "1.7": "94.98",
      "1.8": "98.79",
      "1.9": "102.75",
      "2.0": "106.83",
      "2.1": "111.13",
      "2.2": "114.45",
      "2.3": "117.88",
      "2.4": "121.42",
      "2.5": "125.07"
    },
    "3.3": {
      "0.5": "44.36",
      "0.6": "48.78",
      "0.7": "53.67",
      "0.8": "58.51",
      "0.9": "63.18",
      "1.0": "68.23",
      "1.1": "72.33",
      "1.2": "76.67",
      "1.3": "81.27",
      "1.4": "85.34",
      "1.5": "89.60",
      "1.6": "94.09",
      "1.7": "98.79",
      "1.8": "102.75",
      "1.9": "106.85",
      "2.0": "111.11",
      "2.1": "115.58",
      "2.2": "119.04",
      "2.3": "122.60",
      "2.4": "126.27",
      "2.5": "130.06"
    },
    "3.5": {
      "0.5": "46.13",
      "0.6": "50.74",
      "0.7": "55.81",
      "0.8": "60.83",
      "0.9": "65.71",
      "1.0": "70.97",
      "1.1": "75.21",
      "1.2": "79.74",
      "1.3": "84.51",
      "1.4": "88.74",
      "1.5": "93.19",
      "1.6": "97.84",
      "1.7": "102.75",
      "1.8": "106.83",
      "1.9": "111.13",
      "2.0": "115.55",
      "2.1": "120.20",
      "2.2": "123.80",
      "2.3": "127.50",
      "2.4": "131.33",
      "2.5": "135.27"
    },
    "3.7": {
      "0.5": "47.98",
      "0.6": "52.76",
      "0.7": "58.04",
      "0.8": "63.28",
      "0.9": "68.33",
      "1.0": "73.81",
      "1.1": "78.22",
      "1.2": "82.92",
      "1.3": "87.90",
      "1.4": "92.31",
      "1.5": "96.90",
      "1.6": "101.77",
      "1.7": "106.85",
      "1.8": "111.13",
      "1.9": "115.58",
      "2.0": "120.18",
      "2.1": "125.00",
      "2.2": "128.75",
      "2.3": "132.59",
      "2.4": "136.59",
      "2.5": "140.69"
    },
    "3.9": {
      "0.5": "49.91",
      "0.6": "54.87",
      "0.7": "60.38",
      "0.8": "65.79",
      "0.9": "71.06",
      "1.0": "76.76",
      "1.1": "81.36",
      "1.2": "86.24",
      "1.3": "91.42",
      "1.4": "95.99",
      "1.5": "100.78",
      "1.6": "105.83",
      "1.7": "111.13",
      "1.8": "115.58",
      "1.9": "120.18",
      "2.0": "124.99",
      "2.1": "130.00",
      "2.2": "133.90",
      "2.3": "137.91",
      "2.4": "142.05",
      "2.5": "146.30"
    },
    "4.1": {
      "0.5": "51.91",
      "0.6": "57.07",
      "0.7": "62.78",
      "0.8": "68.43",
      "0.9": "73.90",
      "1.0": "79.84",
      "1.1": "84.60",
      "1.2": "89.70",
      "1.3": "95.06",
      "1.4": "99.82",
      "1.5": "104.81",
      "1.6": "110.07",
      "1.7": "115.58",
      "1.8": "120.18",
      "1.9": "125.00",
      "2.0": "129.99",
      "2.1": "135.20",
      "2.2": "139.25",
      "2.3": "143.42",
      "2.4": "147.74",
      "2.5": "152.16"
    },
    "4.3": {
      "0.5": "53.97",
      "0.6": "59.35",
      "0.7": "65.30",
      "0.8": "71.17",
      "0.9": "76.87",
      "1.0": "83.01",
      "1.1": "87.99",
      "1.2": "93.28",
      "1.3": "98.87",
      "1.4": "103.83",
      "1.5": "109.00",
      "1.6": "114.47",
      "1.7": "120.20",
      "1.8": "125.00",
      "1.9": "130.00",
      "2.0": "135.18",
      "2.1": "140.61",
      "2.2": "144.83",
      "2.3": "149.15",
      "2.4": "153.65",
      "2.5": "158.25"
    },
    "4.5": {
      "0.5": "56.13",
      "0.6": "61.75",
      "0.7": "67.91",
      "0.8": "74.02",
      "0.9": "79.93",
      "1.0": "86.34",
      "1.1": "91.50",
      "1.2": "97.00",
      "1.3": "102.83",
      "1.4": "107.97",
      "1.5": "113.38",
      "1.6": "119.05",
      "1.7": "125.00",
      "1.8": "130.00",
      "1.9": "135.20",
      "2.0": "140.59",
      "2.1": "146.23",
      "2.2": "150.61",
      "2.3": "155.13",
      "2.4": "159.79",
      "2.5": "164.57"
    }
  },
  "1 категория": {
    "1.1": {
      "0.5": "27.72",
      "0.6": "30.47",
      "0.7": "33.53",
      "0.8": "36.55",
      "0.9": "39.48",
      "1.0": "42.63",
      "1.1": "45.19",
      "1.2": "47.89",
      "1.3": "50.78",
      "1.4": "53.31",
      "1.5": "55.98",
      "1.6": "58.80",
      "1.7": "61.72",
      "1.8": "64.20",
      "1.9": "66.77",
      "2.0": "69.44",
      "2.1": "72.21",
      "2.2": "74.37",
      "2.3": "76.60",
      "2.4": "78.89",
      "2.5": "81.28",
      "2.6": "83.71",
      "2.7": "86.22",
      "2.8": "88.81",
      "2.9": "91.47",
      "3.0": "94.22",
      "3.1": "97.03",
      "3.2": "99.97",
      "3.3": "102.95"
    },
    "1.3": {
      "0.5": "29.37",
      "0.6": "32.33",
      "0.7": "35.56",
      "0.8": "38.74",
      "0.9": "41.85",
      "1.0": "45.19",
      "1.1": "47.89",
      "1.2": "50.78",
      "1.3": "53.82",
      "1.4": "56.51",
      "1.5": "59.34",
      "1.6": "62.31",
      "1.7": "65.43",
      "1.8": "68.05",
      "1.9": "70.77",
      "2.0": "73.60",
      "2.1": "76.52",
      "2.2": "78.84",
      "2.3": "81.20",
      "2.4": "83.64",
      "2.5": "86.15",
      "2.6": "88.73",
      "2.7": "91.40",
      "2.8": "94.13",
      "2.9": "96.96",
      "3.0": "99.86",
      "3.1": "102.86",
      "3.2": "105.95",
      "3.3": "109.10"
    },
    "1.5": {
      "0.5": "31.13",
      "0.6": "34.24",
      "0.7": "37.67",
      "0.8": "41.07",
      "0.9": "44.34",
      "1.0": "47.89",
      "1.1": "50.78",
      "1.2": "53.82",
      "1.3": "57.05",
      "1.4": "59.90",
      "1.5": "62.91",
      "1.6": "66.06",
      "1.7": "69.36",
      "1.8": "72.13",
      "1.9": "75.01",
      "2.0": "78.01",
      "2.1": "81.13",
      "2.2": "83.56",
      "2.3": "86.07",
      "2.4": "88.66",
      "2.5": "91.31",
      "2.6": "94.07",
      "2.7": "96.87",
      "2.8": "99.79",
      "2.9": "102.78",
      "3.0": "105.86",
      "3.1": "109.03",
      "3.2": "112.30",
      "3.3": "115.66"
    },
    "1.7": {
      "0.5": "33.01",
      "0.6": "36.32",
      "0.7": "39.94",
      "0.8": "43.54",
      "0.9": "47.02",
      "1.0": "50.78",
      "1.1": "53.82",
      "1.2": "57.05",
      "1.3": "60.47",
      "1.4": "63.52",
      "1.5": "66.69",
      "1.6": "70.01",
      "1.7": "73.51",
      "1.8": "76.46",
      "1.9": "79.51",
      "2.0": "82.70",
      "2.1": "86.01",
      "2.2": "88.57",
      "2.3": "91.24",
      "2.4": "93.98",
      "2.5": "96.79",
      "2.6": "99.69",
      "2.7": "102.69",
      "2.8": "105.76",
      "2.9": "108.94",
      "3.0": "112.21",
      "3.1": "115.56",
      "3.2": "119.04",
      "3.3": "122.61"
    },
    "1.9": {
      "0.5": "34.66",
      "0.6": "38.12",
      "0.7": "41.93",
      "0.8": "45.71",
      "0.9": "49.36",
      "1.0": "53.31",
      "1.1": "56.51",
      "1.2": "59.90",
      "1.3": "63.52",
      "1.4": "66.69",
      "1.5": "70.01",
      "1.6": "73.51",
      "1.7": "77.19",
      "1.8": "80.27",
      "1.9": "83.49",
      "2.0": "86.83",
      "2.1": "90.31",
      "2.2": "93.01",
      "2.3": "95.80",
      "2.4": "98.67",
      "2.5": "101.64",
      "2.6": "104.69",
      "2.7": "107.82",
      "2.8": "111.05",
      "2.9": "114.38",
      "3.0": "117.82",
      "3.1": "121.35",
      "3.2": "125.00",
      "3.3": "128.74"
    },
    "2.1": {
      "0.5": "36.39",
      "0.6": "40.02",
      "0.7": "44.03",
      "0.8": "48.00",
      "0.9": "51.83",
      "1.0": "55.98",
      "1.1": "59.34",
      "1.2": "62.91",
      "1.3": "66.69",
      "1.4": "70.01",
      "1.5": "73.51",
      "1.6": "77.19",
      "1.7": "81.03",
      "1.8": "84.29",
      "1.9": "87.65",
      "2.0": "91.17",
      "2.1": "94.82",
      "2.2": "97.66",
      "2.3": "100.59",
      "2.4": "103.60",
      "2.5": "106.71",
      "2.6": "109.92",
      "2.7": "113.22",
      "2.8": "116.61",
      "2.9": "120.10",
      "3.0": "123.73",
      "3.1": "127.43",
      "3.2": "131.24",
      "3.3": "135.19"
    },
    "2.3": {
      "0.5": "38.21",
      "0.6": "42.04",
      "0.7": "46.24",
      "0.8": "50.40",
      "0.9": "54.42",
      "1.0": "58.80",
      "1.1": "62.31",
      "1.2": "66.06",
      "1.3": "70.01",
      "1.4": "73.51",
      "1.5": "77.19",
      "1.6": "81.03",
      "1.7": "85.09",
      "1.8": "88.50",
      "1.9": "92.04",
      "2.0": "95.73",
      "2.1": "99.56",
      "2.2": "102.55",
      "2.3": "105.62",
      "2.4": "108.79",
      "2.5": "112.06",
      "2.6": "115.41",
      "2.7": "118.87",
      "2.8": "122.44",
      "2.9": "126.11",
      "3.0": "129.91",
      "3.1": "133.80",
      "3.2": "137.81",
      "3.3": "141.95"
    },
    "2.5": {
      "0.5": "40.11",
      "0.6": "44.13",
      "0.7": "48.54",
      "0.8": "52.92",
      "0.9": "57.15",
      "1.0": "61.72",
      "1.1": "65.43",
      "1.2": "69.36",
      "1.3": "73.51",
      "1.4": "77.19",
      "1.5": "81.03",
      "1.6": "85.09",
      "1.7": "89.37",
      "1.8": "92.93",
      "1.9": "96.66",
      "2.0": "100.53",
      "2.1": "104.54",
      "2.2": "107.66",
      "2.3": "110.89",
      "2.4": "114.22",
      "2.5": "117.66",
      "2.6": "121.19",
      "2.7": "124.81",
      "2.8": "128.56",
      "2.9": "132.42",
      "3.0": "136.39",
      "3.1": "140.49",
      "3.2": "144.69",
      "3.3": "149.04"
    },
    "2.7": {
      "0.5": "41.73",
      "0.6": "45.90",
      "0.7": "50.48",
      "0.8": "55.04",
      "0.9": "59.44",
      "1.0": "64.20",
      "1.1": "68.05",
      "1.2": "72.13",
      "1.3": "76.46",
      "1.4": "80.27",
      "1.5": "84.29",
      "1.6": "88.50",
      "1.7": "92.93",
      "1.8": "96.66",
      "1.9": "100.53",
      "2.0": "104.54",
      "2.1": "108.72",
      "2.2": "111.98",
      "2.3": "115.33",
      "2.4": "118.79",
      "2.5": "122.36",
      "2.6": "126.03",
      "2.7": "129.81",
      "2.8": "133.71",
      "2.9": "137.71",
      "3.0": "141.84",
      "3.1": "146.10",
      "3.2": "150.48",
      "3.3": "154.99"
    },
    "2.9": {
      "0.5": "43.38",
      "0.6": "47.74",
      "0.7": "52.51",
      "0.8": "57.23",
      "0.9": "61.81",
      "1.0": "66.77",
      "1.1": "70.77",
      "1.2": "75.01",
      "1.3": "79.51",
      "1.4": "83.49",
      "1.5": "87.65",
      "1.6": "92.04",
      "1.7": "96.66",
      "1.8": "100.53",
      "1.9": "104.54",
      "2.0": "108.72",
      "2.1": "113.05",
      "2.2": "116.46",
      "2.3": "119.96",
      "2.4": "123.55",
      "2.5": "127.26",
      "2.6": "131.08",
      "2.7": "134.99",
      "2.8": "139.06",
      "2.9": "143.23",
      "3.0": "147.53"
    },
    "3.1": {
      "0.5": "45.14",
      "0.6": "49.65",
      "0.7": "54.62",
      "0.8": "59.54",
      "0.9": "64.29",
      "1.0": "69.44",
      "1.1": "73.60",
      "1.2": "78.01",
      "1.3": "82.70",
      "1.4": "86.83",
      "1.5": "91.17",
      "1.6": "95.73",
      "1.7": "100.53",
      "1.8": "104.54",
      "1.9": "108.72",
      "2.0": "113.05",
      "2.1": "117.57",
      "2.2": "121.11",
      "2.3": "124.75",
      "2.4": "128.50",
      "2.5": "132.33",
      "2.6": "136.31",
      "2.7": "140.40",
      "2.8": "144.61",
      "2.9": "148.96",
      "3.0": "153.42"
    },
    "3.3": {
      "0.5": "46.93",
      "0.6": "51.63",
      "0.7": "56.80",
      "0.8": "61.91",
      "0.9": "66.87",
      "1.0": "72.20",
      "1.1": "76.54",
      "1.2": "81.13",
      "1.3": "86.01",
      "1.4": "90.31",
      "1.5": "94.83",
      "1.6": "99.56",
      "1.7": "104.54",
      "1.8": "108.72",
      "1.9": "113.05",
      "2.0": "117.57",
      "2.1": "122.28",
      "2.2": "125.96",
      "2.3": "129.74",
      "2.4": "133.63",
      "2.5": "137.64",
      "2.6": "141.77",
      "2.7": "146.03",
      "2.8": "150.40",
      "2.9": "154.91",
      "3.0": "159.56"
    },
    "3.5": {
      "0.5": "48.81",
      "0.6": "53.69",
      "0.7": "59.07",
      "0.8": "64.40",
      "0.9": "69.52",
      "1.0": "75.09",
      "1.1": "79.60",
      "1.2": "84.36",
      "1.3": "89.45",
      "1.4": "93.91",
      "1.5": "98.62",
      "1.6": "103.54",
      "1.7": "108.72",
      "1.8": "113.05",
      "1.9": "117.57",
      "2.0": "122.28",
      "2.1": "127.18",
      "2.2": "130.99",
      "2.3": "134.92",
      "2.4": "138.96",
      "2.5": "143.14",
      "2.6": "147.43",
      "2.7": "151.87",
      "2.8": "156.41",
      "2.9": "161.10",
      "3.0": "165.94"
    },
    "3.7": {
      "0.5": "50.77",
      "0.6": "55.83",
      "0.7": "61.43",
      "0.8": "66.95",
      "0.9": "72.31",
      "1.0": "78.10",
      "1.1": "82.80",
      "1.2": "87.75",
      "1.3": "93.02",
      "1.4": "97.67",
      "1.5": "102.57",
      "1.6": "107.67",
      "1.7": "113.05",
      "1.8": "117.57",
      "1.9": "122.29",
      "2.0": "127.18",
      "2.1": "132.27",
      "2.2": "136.24",
      "2.3": "140.34",
      "2.4": "144.54",
      "2.5": "148.86",
      "2.6": "153.32",
      "2.7": "157.94",
      "2.8": "162.67",
      "2.9": "167.56",
      "3.0": "172.57"
    },
    "3.9": {
      "0.5": "52.80",
      "0.6": "58.08",
      "0.7": "63.89",
      "0.8": "69.64",
      "0.9": "75.20",
      "1.0": "81.21",
      "1.1": "86.09",
      "1.2": "91.26",
      "1.3": "96.74",
      "1.4": "101.56",
      "1.5": "106.66",
      "1.6": "111.99",
      "1.7": "117.57",
      "1.8": "122.29",
      "1.9": "127.18",
      "2.0": "132.27",
      "2.1": "137.55",
      "2.2": "141.68",
      "2.3": "145.94",
      "2.4": "150.31",
      "2.5": "154.82",
      "2.6": "159.45",
      "2.7": "164.26",
      "2.8": "169.18",
      "2.9": "174.25",
      "3.0": "179.48"
    },
    "4.1": {
      "0.5": "54.90",
      "0.6": "60.41",
      "0.7": "66.44",
      "0.8": "72.43",
      "0.9": "78.22",
      "1.0": "84.46",
      "1.1": "89.55",
      "1.2": "94.90",
      "1.3": "100.60",
      "1.4": "105.63",
      "1.5": "110.91",
      "1.6": "116.48",
      "1.7": "122.29",
      "1.8": "127.18",
      "1.9": "132.27",
      "2.0": "137.55",
      "2.1": "143.06",
      "2.2": "147.35",
      "2.3": "151.79",
      "2.4": "156.33",
      "2.5": "161.02",
      "2.6": "165.85",
      "2.7": "170.83",
      "2.8": "175.94",
      "2.9": "181.22",
      "3.0": "186.65"
    },
    "4.3": {
      "0.5": "57.11",
      "0.6": "62.82",
      "0.7": "69.10",
      "0.8": "75.33",
      "0.9": "81.35",
      "1.0": "87.84",
      "1.1": "93.13",
      "1.2": "98.71",
      "1.3": "104.63",
      "1.4": "109.88",
      "1.5": "115.35",
      "1.6": "121.12",
      "1.7": "127.18",
      "1.8": "132.27",
      "1.9": "137.55",
      "2.0": "143.07",
      "2.1": "148.78",
      "2.2": "153.25",
      "2.3": "157.84",
      "2.4": "162.60",
      "2.5": "167.46",
      "2.6": "172.49",
      "2.7": "177.67",
      "2.8": "182.98",
      "2.9": "188.45",
      "3.0": "194.14"
    },
    "4.5": {
      "0.5": "59.39",
      "0.6": "65.31",
      "0.7": "71.86",
      "0.8": "78.33",
      "0.9": "84.61",
      "1.0": "91.36",
      "1.1": "96.84",
      "1.2": "102.66",
      "1.3": "108.82",
      "1.4": "114.25",
      "1.5": "119.99",
      "1.6": "125.97",
      "1.7": "132.27",
      "1.8": "137.55",
      "1.9": "143.07",
      "2.0": "148.78",
      "2.1": "154.72",
      "2.2": "159.38",
      "2.3": "164.15",
      "2.4": "169.09",
      "2.5": "174.16",
      "2.6": "179.38",
      "2.7": "184.78",
      "2.8": "190.30",
      "2.9": "196.00",
      "3.0": "201.89"
    }
  },
  "2 категория": {
    "1.1": {
      "0.5": "36.12",
      "0.6": "39.73",
      "0.7": "43.71",
      "0.8": "47.64",
      "0.9": "51.46",
      "1.0": "55.58",
      "1.1": "58.90",
      "1.2": "62.43",
      "1.3": "66.18",
      "1.4": "69.49",
      "1.5": "72.97",
      "1.6": "76.60",
      "1.7": "80.45",
      "1.8": "83.66",
      "1.9": "87.02",
      "2.0": "90.48",
      "2.1": "94.11",
      "2.2": "96.93",
      "2.3": "99.83",
      "2.4": "102.83",
      "2.5": "105.93",
      "2.6": "109.08",
      "2.7": "112.36",
      "2.8": "115.74",
      "2.9": "119.20",
      "3.0": "122.79",
      "3.1": "126.47",
      "3.2": "130.26",
      "3.3": "134.17"
    },
    "1.3": {
      "0.5": "38.30",
      "0.6": "42.12",
      "0.7": "46.33",
      "0.8": "50.51",
      "0.9": "54.54",
      "1.0": "58.90",
      "1.1": "62.43",
      "1.2": "66.18",
      "1.3": "70.15",
      "1.4": "73.66",
      "1.5": "77.35",
      "1.6": "81.20",
      "1.7": "85.28",
      "1.8": "88.69",
      "1.9": "92.24",
      "2.0": "95.92",
      "2.1": "99.75",
      "2.2": "102.74",
      "2.3": "105.84",
      "2.4": "109.00",
      "2.5": "112.28",
      "2.6": "115.64",
      "2.7": "119.11",
      "2.8": "122.69",
      "2.9": "126.36",
      "3.0": "130.15",
      "3.1": "134.05",
      "3.2": "138.07",
      "3.3": "142.21"
    },
    "1.5": {
      "0.5": "40.58",
      "0.6": "44.64",
      "0.7": "49.12",
      "0.8": "53.54",
      "0.9": "57.82",
      "1.0": "62.43",
      "1.1": "66.18",
      "1.2": "70.15",
      "1.3": "74.36",
      "1.4": "78.09",
      "1.5": "81.99",
      "1.6": "86.08",
      "1.7": "90.38",
      "1.8": "94.00",
      "1.9": "97.75",
      "2.0": "101.67",
      "2.1": "105.74",
      "2.2": "108.92",
      "2.3": "112.17",
      "2.4": "115.54",
      "2.5": "118.99",
      "2.6": "122.59",
      "2.7": "126.26",
      "2.8": "130.05",
      "2.9": "133.94",
      "3.0": "137.97",
      "3.1": "142.11",
      "3.2": "146.37",
      "3.3": "150.76"
    },
    "1.7": {
      "0.5": "43.02",
      "0.6": "47.32",
      "0.7": "52.05",
      "0.8": "56.75",
      "0.9": "61.28",
      "1.0": "66.18",
      "1.1": "70.15",
      "1.2": "74.36",
      "1.3": "78.83",
      "1.4": "82.78",
      "1.5": "86.90",
      "1.6": "91.25",
      "1.7": "95.80",
      "1.8": "99.64",
      "1.9": "103.62",
      "2.0": "107.77",
      "2.1": "112.08",
      "2.2": "115.44",
      "2.3": "118.90",
      "2.4": "122.49",
      "2.5": "126.15",
      "2.6": "129.94",
      "2.7": "133.83",
      "2.8": "137.86",
      "2.9": "141.98",
      "3.0": "146.24",
      "3.1": "150.62",
      "3.2": "155.14",
      "3.3": "159.81"
    },
    "1.9": {
      "0.5": "45.17",
      "0.6": "49.69",
      "0.7": "54.65",
      "0.8": "59.58",
      "0.9": "64.34",
      "1.0": "69.49",
      "1.1": "73.66",
      "1.2": "78.09",
      "1.3": "82.78",
      "1.4": "86.90",
      "1.5": "91.25",
      "1.6": "95.80",
      "1.7": "100.60",
      "1.8": "104.62",
      "1.9": "108.81",
      "2.0": "113.17",
      "2.1": "117.68",
      "2.2": "121.23",
      "2.3": "124.85",
      "2.4": "128.61",
      "2.5": "132.47",
      "2.6": "136.45",
      "2.7": "140.52",
      "2.8": "144.74",
      "2.9": "149.09",
      "3.0": "153.55",
      "3.1": "158.16",
      "3.2": "162.91",
      "3.3": "167.79"
    },
    "2.1": {
      "0.5": "47.45",
      "0.6": "52.18",
      "0.7": "57.40",
      "0.8": "62.56",
      "0.9": "67.56",
      "1.0": "72.97",
      "1.1": "77.35",
      "1.2": "81.99",
      "1.3": "86.90",
      "1.4": "91.25",
      "1.5": "95.80",
      "1.6": "100.60",
      "1.7": "105.63",
      "1.8": "109.88",
      "1.9": "114.24",
      "2.0": "118.82",
      "2.1": "123.58",
      "2.2": "127.28",
      "2.3": "131.11",
      "2.4": "135.03",
      "2.5": "139.09",
      "2.6": "143.25",
      "2.7": "147.55",
      "2.8": "151.99",
      "2.9": "156.54",
      "3.0": "161.24",
      "3.1": "166.07",
      "3.2": "171.07",
      "3.3": "176.19"
    },
    "2.3": {
      "0.5": "49.81",
      "0.6": "54.78",
      "0.7": "60.26",
      "0.8": "65.69",
      "0.9": "70.93",
      "1.0": "76.60",
      "1.1": "81.20",
      "1.2": "86.08",
      "1.3": "91.25",
      "1.4": "95.80",
      "1.5": "100.60",
      "1.6": "105.63",
      "1.7": "110.90",
      "1.8": "115.35",
      "1.9": "119.97",
      "2.0": "124.76",
      "2.1": "129.75",
      "2.2": "133.65",
      "2.3": "137.65",
      "2.4": "141.78",
      "2.5": "146.04",
      "2.6": "150.43",
      "2.7": "154.93",
      "2.8": "159.59",
      "2.9": "164.36",
      "3.0": "169.30",
      "3.1": "174.38",
      "3.2": "179.62",
      "3.3": "185.00"
    },
    "2.5": {
      "0.5": "52.29",
      "0.6": "57.53",
      "0.7": "63.27",
      "0.8": "68.96",
      "0.9": "74.47",
      "1.0": "80.45",
      "1.1": "85.28",
      "1.2": "90.38",
      "1.3": "95.80",
      "1.4": "100.60",
      "1.5": "105.63",
      "1.6": "110.90",
      "1.7": "116.47",
      "1.8": "121.12",
      "1.9": "125.96",
      "2.0": "130.99",
      "2.1": "136.24",
      "2.2": "140.34",
      "2.3": "144.54",
      "2.4": "148.86",
      "2.5": "153.34",
      "2.6": "157.94",
      "2.7": "162.67",
      "2.8": "167.56",
      "2.9": "172.59",
      "3.0": "177.77",
      "3.1": "183.10",
      "3.2": "188.58",
      "3.3": "194.26"
    },
    "2.7": {
      "0.5": "54.39",
      "0.6": "59.81",
      "0.7": "65.81",
      "0.8": "71.73",
      "0.9": "77.47",
      "1.0": "83.66",
      "1.1": "88.69",
      "1.2": "94.00",
      "1.3": "99.64",
      "1.4": "104.62",
      "1.5": "109.88",
      "1.6": "115.35",
      "1.7": "121.12",
      "1.8": "125.96",
      "1.9": "130.99",
      "2.0": "136.24",
      "2.1": "141.69",
      "2.2": "145.94",
      "2.3": "150.31",
      "2.4": "154.82",
      "2.5": "159.47",
      "2.6": "164.26",
      "2.7": "169.19",
      "2.8": "174.26",
      "2.9": "179.50",
      "3.0": "184.87",
      "3.1": "190.41",
      "3.2": "196.13",
      "3.3": "202.02"
    },
    "2.9": {
      "0.5": "56.55",
      "0.6": "62.21",
      "0.7": "68.43",
      "0.8": "74.60",
      "0.9": "80.56",
      "1.0": "87.02",
      "1.1": "92.24",
      "1.2": "97.75",
      "1.3": "103.62",
      "1.4": "108.81",
      "1.5": "114.24",
      "1.6": "119.97",
      "1.7": "125.96",
      "1.8": "130.99",
      "1.9": "136.24",
      "2.0": "141.69",
      "2.1": "147.36",
      "2.2": "151.79",
      "2.3": "156.33",
      "2.4": "161.03",
      "2.5": "165.85",
      "2.6": "170.83",
      "2.7": "175.95",
      "2.8": "181.23",
      "2.9": "186.66",
      "3.0": "192.26"
    },
    "3.1": {
      "0.5": "58.83",
      "0.6": "64.72",
      "0.7": "71.18",
      "0.8": "77.58",
      "0.9": "83.79",
      "1.0": "90.48",
      "1.1": "95.92",
      "1.2": "101.67",
      "1.3": "107.77",
      "1.4": "113.17",
      "1.5": "118.82",
      "1.6": "124.76",
      "1.7": "130.99",
      "1.8": "136.24",
      "1.9": "141.69",
      "2.0": "147.36",
      "2.1": "153.26",
      "2.2": "157.84",
      "2.3": "162.60",
      "2.4": "167.46",
      "2.5": "172.50",
      "2.6": "177.67",
      "2.7": "182.99",
      "2.8": "188.47",
      "2.9": "194.14",
      "3.0": "199.95"
    },
    "3.3": {
      "0.5": "61.18",
      "0.6": "67.29",
      "0.7": "74.02",
      "0.8": "80.68",
      "0.9": "87.13",
      "1.0": "94.11",
      "1.1": "99.75",
      "1.2": "105.74",
      "1.3": "112.08",
      "1.4": "117.68",
      "1.5": "123.57",
      "1.6": "129.75",
      "1.7": "136.24",
      "1.8": "141.69",
      "1.9": "147.36",
      "2.0": "153.26",
      "2.1": "159.39",
      "2.2": "164.15",
      "2.3": "169.09",
      "2.4": "174.17",
      "2.5": "179.39",
      "2.6": "184.78",
      "2.7": "190.31",
      "2.8": "196.01",
      "2.9": "201.89",
      "3.0": "207.95"
    },
    "3.5": {
      "0.5": "63.63",
      "0.6": "69.98",
      "0.7": "76.99",
      "0.8": "83.90",
      "0.9": "90.64",
      "1.0": "97.88",
      "1.1": "103.75",
      "1.2": "109.97",
      "1.3": "116.57",
      "1.4": "122.39",
      "1.5": "128.52",
      "1.6": "134.93",
      "1.7": "141.69",
      "1.8": "147.36",
      "1.9": "153.26",
      "2.0": "159.39",
      "2.1": "165.76",
      "2.2": "170.73",
      "2.3": "175.85",
      "2.4": "181.13",
      "2.5": "186.55",
      "2.6": "192.17",
      "2.7": "197.92",
      "2.8": "203.85",
      "2.9": "209.98",
      "3.0": "216.28"
    },
    "3.7": {
      "0.5": "66.17",
      "0.6": "72.78",
      "0.7": "80.07",
      "0.8": "87.27",
      "0.9": "94.25",
      "1.0": "101.78",
      "1.1": "107.90",
      "1.2": "114.37",
      "1.3": "121.23",
      "1.4": "127.29",
      "1.5": "133.66",
      "1.6": "140.35",
      "1.7": "147.36",
      "1.8": "153.26",
      "1.9": "159.39",
      "2.0": "165.76",
      "2.1": "172.39",
      "2.2": "177.57",
      "2.3": "182.89",
      "2.4": "188.37",
      "2.5": "194.02",
      "2.6": "199.85",
      "2.7": "205.84",
      "2.8": "212.00",
      "2.9": "218.37",
      "3.0": "224.92"
    },
    "3.9": {
      "0.5": "68.81",
      "0.6": "75.71",
      "0.7": "83.28",
      "0.8": "90.75",
      "0.9": "98.02",
      "1.0": "105.86",
      "1.1": "112.21",
      "1.2": "118.94",
      "1.3": "126.08",
      "1.4": "132.39",
      "1.5": "138.99",
      "1.6": "145.95",
      "1.7": "153.26",
      "1.8": "159.38",
      "1.9": "165.76",
      "2.0": "172.39",
      "2.1": "179.29",
      "2.2": "184.66",
      "2.3": "190.21",
      "2.4": "195.90",
      "2.5": "201.78",
      "2.6": "207.84",
      "2.7": "214.08",
      "2.8": "220.49",
      "2.9": "227.10",
      "3.0": "233.94"
    },
    "4.1": {
      "0.5": "71.57",
      "0.6": "78.73",
      "0.7": "86.60",
      "0.8": "94.39",
      "0.9": "101.95",
      "1.0": "110.10",
      "1.1": "116.71",
      "1.2": "123.69",
      "1.3": "131.13",
      "1.4": "137.68",
      "1.5": "144.56",
      "1.6": "151.80",
      "1.7": "159.38",
      "1.8": "165.75",
      "1.9": "172.39",
      "2.0": "179.29",
      "2.1": "186.45",
      "2.2": "192.04",
      "2.3": "197.81",
      "2.4": "203.73",
      "2.5": "209.85",
      "2.6": "216.16",
      "2.7": "222.65",
      "2.8": "229.31",
      "2.9": "236.19",
      "3.0": "243.28"
    },
    "4.3": {
      "0.5": "74.43",
      "0.6": "81.87",
      "0.7": "90.07",
      "0.8": "98.17",
      "0.9": "106.02",
      "1.0": "114.49",
      "1.1": "121.35",
      "1.2": "128.65",
      "1.3": "136.37",
      "1.4": "143.19",
      "1.5": "150.36",
      "1.6": "157.87",
      "1.7": "165.75",
      "1.8": "172.39",
      "1.9": "179.29",
      "2.0": "186.45",
      "2.1": "193.92",
      "2.2": "199.73",
      "2.3": "205.73",
      "2.4": "211.89",
      "2.5": "218.25",
      "2.6": "224.80",
      "2.7": "231.56",
      "2.8": "238.47",
      "2.9": "245.63",
      "3.0": "253.01"
    },
    "4.5": {
      "0.5": "77.41",
      "0.6": "85.15",
      "0.7": "93.67",
      "0.8": "102.09",
      "0.9": "110.26",
      "1.0": "119.09",
      "1.1": "126.21",
      "1.2": "133.80",
      "1.3": "141.81",
      "1.4": "148.91",
      "1.5": "156.36",
      "1.6": "164.16",
      "1.7": "172.39",
      "1.8": "179.27",
      "1.9": "186.45",
      "2.0": "193.92",
      "2.1": "201.68",
      "2.2": "207.71",
      "2.3": "213.96",
      "2.4": "220.37",
      "2.5": "226.97",
      "2.6": "233.79",
      "2.7": "240.81",
      "2.8": "248.01",
      "2.9": "255.46",
      "3.0": "263.13"
    }
  },
  "3 категория": {
    "1.1": {
      "0.5": "47.65",
      "0.6": "52.41",
      "0.7": "57.65",
      "0.8": "62.84",
      "0.9": "67.86",
      "1.0": "73.28",
      "1.1": "77.70",
      "1.2": "82.37",
      "1.3": "87.31",
      "1.4": "91.66",
      "1.5": "96.25",
      "1.6": "101.07",
      "1.7": "106.13",
      "1.8": "111.42",
      "1.9": "116.99",
      "2.0": "122.85",
      "2.1": "128.98",
      "2.2": "134.15",
      "2.3": "139.51",
      "2.4": "145.09",
      "2.5": "150.90",
      "2.6": "156.94",
      "2.7": "163.20",
      "2.8": "168.10",
      "2.9": "173.14",
      "3.0": "178.35",
      "3.1": "183.70",
      "3.2": "189.20",
      "3.3": "194.89"
    },
    "1.3": {
      "0.5": "50.99",
      "0.6": "56.08",
      "0.7": "61.70",
      "0.8": "67.24",
      "0.9": "72.63",
      "1.0": "78.43",
      "1.1": "83.13",
      "1.2": "88.12",
      "1.3": "93.41",
      "1.4": "98.09",
      "1.5": "102.97",
      "1.6": "108.13",
      "1.7": "113.54",
      "1.8": "119.22",
      "1.9": "125.19",
      "2.0": "131.43",
      "2.1": "138.01",
      "2.2": "143.53",
      "2.3": "149.28",
      "2.4": "155.24",
      "2.5": "161.45",
      "2.6": "167.91",
      "2.7": "174.62",
      "2.8": "179.87",
      "2.9": "185.28",
      "3.0": "190.84",
      "3.1": "196.55",
      "3.2": "202.45",
      "3.3": "208.53"
    },
    "1.5": {
      "0.5": "54.55",
      "0.6": "60.00",
      "0.7": "66.02",
      "0.8": "71.95",
      "0.9": "77.70",
      "1.0": "83.91",
      "1.1": "88.95",
      "1.2": "94.30",
      "1.3": "99.95",
      "1.4": "104.95",
      "1.5": "110.19",
      "1.6": "115.70",
      "1.7": "121.48",
      "1.8": "127.57",
      "1.9": "133.94",
      "2.0": "140.65",
      "2.1": "147.68",
      "2.2": "153.59",
      "2.3": "159.73",
      "2.4": "166.11",
      "2.5": "172.76",
      "2.6": "179.66",
      "2.7": "186.86",
      "2.8": "192.47",
      "2.9": "198.24",
      "3.0": "204.18",
      "3.1": "210.30",
      "3.2": "216.62",
      "3.3": "223.11"
    },
    "1.7": {
      "0.5": "58.37",
      "0.6": "64.22",
      "0.7": "70.62",
      "0.8": "76.99",
      "0.9": "83.14",
      "1.0": "89.79",
      "1.1": "95.18",
      "1.2": "100.90",
      "1.3": "106.94",
      "1.4": "112.29",
      "1.5": "117.91",
      "1.6": "123.81",
      "1.7": "130.00",
      "1.8": "136.51",
      "1.9": "143.31",
      "2.0": "150.48",
      "2.1": "158.01",
      "2.2": "164.34",
      "2.3": "170.91",
      "2.4": "177.75",
      "2.5": "184.86",
      "2.6": "192.25",
      "2.7": "199.93",
      "2.8": "205.92",
      "2.9": "212.12",
      "3.0": "218.47",
      "3.1": "225.03",
      "3.2": "231.77",
      "3.3": "238.74"
    },
    "1.9": {
      "0.5": "62.46",
      "0.6": "68.71",
      "0.7": "75.58",
      "0.8": "82.38",
      "0.9": "88.96",
      "1.0": "96.08",
      "1.1": "101.86",
      "1.2": "107.97",
      "1.3": "114.43",
      "1.4": "120.15",
      "1.5": "126.16",
      "1.6": "132.48",
      "1.7": "139.10",
      "1.8": "146.05",
      "1.9": "153.35",
      "2.0": "161.03",
      "2.1": "169.08",
      "2.2": "175.84",
      "2.3": "182.87",
      "2.4": "190.19",
      "2.5": "197.79",
      "2.6": "205.70",
      "2.7": "213.93",
      "2.8": "220.36",
      "2.9": "226.96",
      "3.0": "233.76",
      "3.1": "240.79",
      "3.2": "248.00",
      "3.3": "255.45"
    },
    "2.1": {
      "0.5": "66.21",
      "0.6": "72.83",
      "0.7": "80.11",
      "0.8": "87.32",
      "0.9": "94.31",
      "1.0": "101.86",
      "1.1": "107.97",
      "1.2": "114.43",
      "1.3": "121.30",
      "1.4": "127.37",
      "1.5": "133.73",
      "1.6": "140.41",
      "1.7": "147.44",
      "1.8": "154.81",
      "1.9": "162.54",
      "2.0": "170.69",
      "2.1": "179.21",
      "2.2": "186.39",
      "2.3": "193.83",
      "2.4": "201.59",
      "2.5": "209.66",
      "2.6": "218.05",
      "2.7": "226.76",
      "2.8": "233.56",
      "2.9": "240.59",
      "3.0": "247.81",
      "3.1": "255.23",
      "3.2": "262.89",
      "3.3": "270.77"
    },
    "2.3": {
      "0.5": "70.17",
      "0.6": "77.20",
      "0.7": "84.91",
      "0.8": "92.55",
      "0.9": "99.97",
      "1.0": "107.97",
      "1.1": "114.43",
      "1.2": "121.30",
      "1.3": "128.58",
      "1.4": "134.99",
      "1.5": "141.76",
      "1.6": "148.84",
      "1.7": "156.30",
      "1.8": "164.10",
      "1.9": "172.32",
      "2.0": "180.92",
      "2.1": "189.97",
      "2.2": "197.57",
      "2.3": "205.48",
      "2.4": "213.70",
      "2.5": "222.24",
      "2.6": "231.14",
      "2.7": "240.38",
      "2.8": "247.59",
      "2.9": "255.01",
      "3.0": "262.67",
      "3.1": "270.55",
      "3.2": "278.67",
      "3.3": "287.02"
    },
    "2.5": {
      "0.5": "74.39",
      "0.6": "81.83",
      "0.7": "90.01",
      "0.8": "98.11",
      "0.9": "105.96",
      "1.0": "114.43",
      "1.1": "121.30",
      "1.2": "128.58",
      "1.3": "136.29",
      "1.4": "143.12",
      "1.5": "150.27",
      "1.6": "157.78",
      "1.7": "165.67",
      "1.8": "173.95",
      "1.9": "182.65",
      "2.0": "191.79",
      "2.1": "201.37",
      "2.2": "209.43",
      "2.3": "217.79",
      "2.4": "226.51",
      "2.5": "235.57",
      "2.6": "245.00",
      "2.7": "254.80",
      "2.8": "262.44",
      "2.9": "270.30",
      "3.0": "278.43",
      "3.1": "286.77",
      "3.2": "295.38",
      "3.3": "304.25"
    },
    "2.7": {
      "0.5": "78.85",
      "0.6": "86.73",
      "0.7": "95.41",
      "0.8": "104.00",
      "0.9": "112.31",
      "1.0": "121.30",
      "1.1": "128.58",
      "1.2": "136.29",
      "1.3": "144.48",
      "1.4": "151.69",
      "1.5": "159.28",
      "1.6": "167.24",
      "1.7": "175.62",
      "1.8": "184.39",
      "1.9": "193.61",
      "2.0": "203.28",
      "2.1": "213.46",
      "2.2": "222.00",
      "2.3": "230.87",
      "2.4": "240.10",
      "2.5": "249.72",
      "2.6": "259.69",
      "2.7": "270.08",
      "2.8": "278.18",
      "2.9": "286.53",
      "3.0": "295.13",
      "3.1": "303.97",
      "3.2": "313.10",
      "3.3": "322.49"
    },
    "2.9": {
      "0.5": "82.80",
      "0.6": "91.07",
      "0.7": "100.18",
      "0.8": "109.20",
      "0.9": "117.93",
      "1.0": "127.37",
      "1.1": "134.99",
      "1.2": "143.12",
      "1.3": "151.69",
      "1.4": "159.28",
      "1.5": "167.24",
      "1.6": "175.62",
      "1.7": "184.39",
      "1.8": "193.61",
      "1.9": "203.28",
      "2.0": "213.46",
      "2.1": "224.13",
      "2.2": "233.08",
      "2.3": "242.41",
      "2.4": "252.11",
      "2.5": "262.20",
      "2.6": "272.67",
      "2.7": "283.59",
      "2.8": "292.10",
      "2.9": "300.86",
      "3.0": "309.88"
    },
    "3.1": {
      "0.5": "86.93",
      "0.6": "95.62",
      "0.7": "105.20",
      "0.8": "114.66",
      "0.9": "123.83",
      "1.0": "133.73",
      "1.1": "141.76",
      "1.2": "150.27",
      "1.3": "159.28",
      "1.4": "167.24",
      "1.5": "175.62",
      "1.6": "184.39",
      "1.7": "193.61",
      "1.8": "203.28",
      "1.9": "213.46",
      "2.0": "224.13",
      "2.1": "235.34",
      "2.2": "244.74",
      "2.3": "254.55",
      "2.4": "264.71",
      "2.5": "275.31",
      "2.6": "286.32",
      "2.7": "297.77",
      "2.8": "306.69",
      "2.9": "315.91",
      "3.0": "325.37"
    },
    "3.3": {
      "0.5": "90.41",
      "0.6": "99.46",
      "0.7": "109.40",
      "0.8": "119.26",
      "0.9": "128.78",
      "1.0": "139.09",
      "1.1": "147.42",
      "1.2": "156.28",
      "1.3": "165.66",
      "1.4": "173.94",
      "1.5": "182.64",
      "1.6": "191.78",
      "1.7": "201.36",
      "1.8": "211.43",
      "1.9": "222.00",
      "2.0": "233.08",
      "2.1": "244.74",
      "2.2": "254.52",
      "2.3": "264.71",
      "2.4": "275.31",
      "2.5": "286.32",
      "2.6": "297.77",
      "2.7": "309.68",
      "2.8": "318.98",
      "2.9": "328.53",
      "3.0": "338.39"
    },
    "3.5": {
      "0.5": "94.03",
      "0.6": "103.44",
      "0.7": "113.76",
      "0.8": "124.02",
      "0.9": "133.93",
      "1.0": "144.64",
      "1.1": "153.31",
      "1.2": "162.52",
      "1.3": "172.28",
      "1.4": "180.89",
      "1.5": "189.95",
      "1.6": "199.44",
      "1.7": "209.41",
      "1.8": "219.87",
      "1.9": "230.87",
      "2.0": "242.41",
      "2.1": "254.55",
      "2.2": "264.70",
      "2.3": "275.31",
      "2.4": "286.32",
      "2.5": "297.79",
      "2.6": "309.68",
      "2.7": "322.06",
      "2.8": "331.73",
      "2.9": "341.69",
      "3.0": "351.94"
    },
    "3.7": {
      "0.5": "97.78",
      "0.6": "107.57",
      "0.7": "118.33",
      "0.8": "128.98",
      "0.9": "139.30",
      "1.0": "150.44",
      "1.1": "159.44",
      "1.2": "169.04",
      "1.3": "179.17",
      "1.4": "188.14",
      "1.5": "197.55",
      "1.6": "207.42",
      "1.7": "217.78",
      "1.8": "228.67",
      "1.9": "240.10",
      "2.0": "252.11",
      "2.1": "264.71",
      "2.2": "275.29",
      "2.3": "286.32",
      "2.4": "297.77",
      "2.5": "309.68",
      "2.6": "322.06",
      "2.7": "334.95",
      "2.8": "344.98",
      "2.9": "355.36",
      "3.0": "366.01"
    },
    "3.9": {
      "0.5": "101.69",
      "0.6": "111.88",
      "0.7": "123.05",
      "0.8": "134.13",
      "0.9": "144.86",
      "1.0": "156.43",
      "1.1": "165.84",
      "1.2": "175.79",
      "1.3": "186.33",
      "1.4": "195.65",
      "1.5": "205.45",
      "1.6": "215.72",
      "1.7": "226.50",
      "1.8": "237.81",
      "1.9": "249.71",
      "2.0": "262.19",
      "2.1": "275.29",
      "2.2": "286.31",
      "2.3": "297.79",
      "2.4": "309.68",
      "2.5": "322.08",
      "2.6": "334.95",
      "2.7": "348.35",
      "2.8": "358.80",
      "2.9": "369.57",
      "3.0": "380.65"
    },
    "4.1": {
      "0.5": "105.76",
      "0.6": "116.35",
      "0.7": "127.98",
      "0.8": "139.50",
      "0.9": "150.66",
      "1.0": "162.70",
      "1.1": "172.46",
      "1.2": "182.84",
      "1.3": "193.79",
      "1.4": "203.49",
      "1.5": "213.66",
      "1.6": "224.35",
      "1.7": "235.56",
      "1.8": "247.35",
      "1.9": "259.68",
      "2.0": "272.67",
      "2.1": "286.32",
      "2.2": "297.77",
      "2.3": "309.68",
      "2.4": "322.06",
      "2.5": "334.95",
      "2.6": "348.36",
      "2.7": "362.28",
      "2.8": "373.14",
      "2.9": "384.35",
      "3.0": "395.87"
    },
    "4.3": {
      "0.5": "110.00",
      "0.6": "120.99",
      "0.7": "133.10",
      "0.8": "145.08",
      "0.9": "156.69",
      "1.0": "169.21",
      "1.1": "179.37",
      "1.2": "190.15",
      "1.3": "201.54",
      "1.4": "211.62",
      "1.5": "222.21",
      "1.6": "233.32",
      "1.7": "244.98",
      "1.8": "257.22",
      "1.9": "270.08",
      "2.0": "283.59",
      "2.1": "297.77",
      "2.2": "309.67",
      "2.3": "322.08",
      "2.4": "334.95",
      "2.5": "348.36",
      "2.6": "362.28",
      "2.7": "376.77",
      "2.8": "388.07",
      "2.9": "399.72",
      "3.0": "411.72"
    },
    "4.5": {
      "0.5": "114.41",
      "0.6": "125.85",
      "0.7": "138.42",
      "0.8": "150.89",
      "0.9": "162.96",
      "1.0": "175.97",
      "1.1": "186.53",
      "1.2": "197.74",
      "1.3": "209.60",
      "1.4": "220.09",
      "1.5": "231.10",
      "1.6": "242.65",
      "1.7": "254.78",
      "1.8": "267.51",
      "1.9": "280.88",
      "2.0": "294.93",
      "2.1": "309.68",
      "2.2": "322.06",
      "2.3": "334.95",
      "2.4": "348.36",
      "2.5": "362.28",
      "2.6": "376.77",
      "2.7": "391.85",
      "2.8": "403.59",
      "2.9": "415.72",
      "3.0": "428.18"
    }
  },
  "4 категория": {
    "1.1": {
      "0.5": "60.63",
      "0.6": "67.89",
      "0.7": "74.69",
      "0.8": "81.41",
      "0.9": "88.74",
      "1.0": "95.84",
      "1.1": "103.50",
      "1.2": "110.74",
      "1.3": "118.51",
      "1.4": "124.42",
      "1.5": "130.65",
      "1.6": "137.18",
      "1.7": "144.03",
      "1.8": "151.23",
      "1.9": "158.78",
      "2.0": "165.17",
      "2.1": "171.77",
      "2.2": "178.64",
      "2.3": "185.77",
      "2.4": "193.21",
      "2.5": "200.93",
      "2.6": "208.98",
      "2.7": "215.23",
      "2.8": "221.70",
      "2.9": "228.35",
      "3.0": "235.21",
      "3.1": "242.25",
      "3.2": "249.53",
      "3.3": "257.00"
    },
    "1.3": {
      "0.5": "64.88",
      "0.6": "72.67",
      "0.7": "79.91",
      "0.8": "87.11",
      "0.9": "94.95",
      "1.0": "102.55",
      "1.1": "110.74",
      "1.2": "118.51",
      "1.3": "126.80",
      "1.4": "133.15",
      "1.5": "139.80",
      "1.6": "146.78",
      "1.7": "154.13",
      "1.8": "161.82",
      "1.9": "169.92",
      "2.0": "176.72",
      "2.1": "183.79",
      "2.2": "191.13",
      "2.3": "198.78",
      "2.4": "206.74",
      "2.5": "215.00",
      "2.6": "223.61",
      "2.7": "230.30",
      "2.8": "237.22",
      "2.9": "244.34",
      "3.0": "251.67",
      "3.1": "259.22",
      "3.2": "266.99",
      "3.3": "275.01"
    },
    "1.5": {
      "0.5": "69.41",
      "0.6": "77.74",
      "0.7": "85.51",
      "0.8": "93.22",
      "0.9": "101.61",
      "1.0": "109.72",
      "1.1": "118.51",
      "1.2": "126.80",
      "1.3": "135.67",
      "1.4": "142.47",
      "1.5": "149.57",
      "1.6": "157.06",
      "1.7": "164.90",
      "1.8": "173.15",
      "1.9": "181.80",
      "2.0": "189.09",
      "2.1": "196.64",
      "2.2": "204.52",
      "2.3": "212.69",
      "2.4": "221.21",
      "2.5": "230.05",
      "2.6": "239.26",
      "2.7": "246.42",
      "2.8": "253.83",
      "2.9": "261.44",
      "3.0": "269.30",
      "3.1": "277.36",
      "3.2": "285.69",
      "3.3": "294.26"
    },
    "1.7": {
      "0.5": "74.26",
      "0.6": "83.17",
      "0.7": "91.50",
      "0.8": "99.73",
      "0.9": "108.71",
      "1.0": "117.41",
      "1.1": "126.80",
      "1.2": "135.67",
      "1.3": "145.18",
      "1.4": "152.42",
      "1.5": "160.04",
      "1.6": "168.05",
      "1.7": "176.47",
      "1.8": "185.28",
      "1.9": "194.54",
      "2.0": "202.31",
      "2.1": "210.41",
      "2.2": "218.82",
      "2.3": "227.59",
      "2.4": "236.68",
      "2.5": "246.16",
      "2.6": "256.00",
      "2.7": "263.67",
      "2.8": "271.59",
      "2.9": "279.75",
      "3.0": "288.13",
      "3.1": "296.77",
      "3.2": "305.68",
      "3.3": "314.85"
    },
    "1.9": {
      "0.5": "79.48",
      "0.6": "89.00",
      "0.7": "97.90",
      "0.8": "106.71",
      "0.9": "116.30",
      "1.0": "125.62",
      "1.1": "135.67",
      "1.2": "145.18",
      "1.3": "155.33",
      "1.4": "163.09",
      "1.5": "171.25",
      "1.6": "179.82",
      "1.7": "188.80",
      "1.8": "198.25",
      "1.9": "208.16",
      "2.0": "216.49",
      "2.1": "225.13",
      "2.2": "234.15",
      "2.3": "243.51",
      "2.4": "253.26",
      "2.5": "263.39",
      "2.6": "273.92",
      "2.7": "282.13",
      "2.8": "290.60",
      "2.9": "299.31",
      "3.0": "308.29",
      "3.1": "317.55",
      "3.2": "327.08",
      "3.3": "336.89"
    },
    "2.1": {
      "0.5": "84.23",
      "0.6": "94.34",
      "0.7": "103.78",
      "0.8": "113.12",
      "0.9": "123.30",
      "1.0": "133.17",
      "1.1": "143.81",
      "1.2": "153.89",
      "1.3": "164.65",
      "1.4": "172.88",
      "1.5": "181.54",
      "1.6": "190.60",
      "1.7": "200.14",
      "1.8": "210.15",
      "1.9": "220.64",
      "2.0": "229.47",
      "2.1": "238.66",
      "2.2": "248.19",
      "2.3": "258.13",
      "2.4": "268.46",
      "2.5": "279.20",
      "2.6": "290.37",
      "2.7": "299.06",
      "2.8": "308.04",
      "2.9": "317.28",
      "3.0": "326.80",
      "3.1": "336.60",
      "3.2": "346.71",
      "3.3": "357.11"
    },
    "2.3": {
      "0.5": "89.29",
      "0.6": "100.01",
      "0.7": "110.00",
      "0.8": "119.90",
      "0.9": "130.69",
      "1.0": "141.15",
      "1.1": "152.43",
      "1.2": "163.13",
      "1.3": "174.53",
      "1.4": "183.25",
      "1.5": "192.41",
      "1.6": "202.04",
      "1.7": "212.14",
      "1.8": "222.75",
      "1.9": "233.88",
      "2.0": "243.24",
      "2.1": "252.98",
      "2.2": "263.09",
      "2.3": "273.61",
      "2.4": "284.56",
      "2.5": "295.94",
      "2.6": "307.77",
      "2.7": "317.02",
      "2.8": "326.53",
      "2.9": "336.33",
      "3.0": "346.41",
      "3.1": "356.81",
      "3.2": "367.51",
      "3.3": "378.54"
    },
    "2.5": {
      "0.5": "94.65",
      "0.6": "106.01",
      "0.7": "116.60",
      "0.8": "127.10",
      "0.9": "138.53",
      "1.0": "149.61",
      "1.1": "161.59",
      "1.2": "172.91",
      "1.3": "185.00",
      "1.4": "194.26",
      "1.5": "203.96",
      "1.6": "214.17",
      "1.7": "224.88",
      "1.8": "236.11",
      "1.9": "247.93",
      "2.0": "257.84",
      "2.1": "268.15",
      "2.2": "278.88",
      "2.3": "290.03",
      "2.4": "301.63",
      "2.5": "313.69",
      "2.6": "326.25",
      "2.7": "336.03",
      "2.8": "346.11",
      "2.9": "356.49",
      "3.0": "367.21",
      "3.1": "378.21",
      "3.2": "389.56",
      "3.3": "401.24"
    },
    "2.7": {
      "0.5": "100.33",
      "0.6": "112.36",
      "0.7": "123.60",
      "0.8": "134.73",
      "0.9": "146.84",
      "1.0": "158.59",
      "1.1": "171.28",
      "1.2": "183.27",
      "1.3": "196.11",
      "1.4": "205.90",
      "1.5": "216.19",
      "1.6": "227.02",
      "1.7": "238.36",
      "1.8": "250.28",
      "1.9": "262.78",
      "2.0": "273.31",
      "2.1": "284.24",
      "2.2": "295.62",
      "2.3": "307.42",
      "2.4": "319.74",
      "2.5": "332.51",
      "2.6": "345.82",
      "2.7": "356.21",
      "2.8": "366.88",
      "2.9": "377.89",
      "3.0": "389.23",
      "3.1": "400.90",
      "3.2": "412.93",
      "3.3": "425.32"
    },
    "2.9": {
      "0.5": "105.33",
      "0.6": "117.99",
      "0.7": "129.78",
      "0.8": "141.45",
      "0.9": "154.18",
      "1.0": "166.52",
      "1.1": "179.84",
      "1.2": "192.43",
      "1.3": "205.90",
      "1.4": "216.19",
      "1.5": "227.02",
      "1.6": "238.36",
      "1.7": "250.28",
      "1.8": "262.78",
      "1.9": "275.93",
      "2.0": "286.98",
      "2.1": "298.46",
      "2.2": "310.39",
      "2.3": "322.81",
      "2.4": "335.72",
      "2.5": "349.14",
      "2.6": "363.11",
      "2.7": "374.00",
      "2.8": "385.22",
      "2.9": "396.78",
      "3.0": "408.69"
    },
    "3.1": {
      "0.5": "110.61",
      "0.6": "123.88",
      "0.7": "136.26",
      "0.8": "148.53",
      "0.9": "161.90",
      "1.0": "174.87",
      "1.1": "188.84",
      "1.2": "202.07",
      "1.3": "216.19",
      "1.4": "227.02",
      "1.5": "238.36",
      "1.6": "250.28",
      "1.7": "262.78",
      "1.8": "275.93",
      "1.9": "289.74",
      "2.0": "301.32",
      "2.1": "313.37",
      "2.2": "325.90",
      "2.3": "338.95",
      "2.4": "352.49",
      "2.5": "366.60",
      "2.6": "381.27",
      "2.7": "392.70",
      "2.8": "404.49",
      "2.9": "416.62",
      "3.0": "429.13"
    },
    "3.3": {
      "0.5": "116.13",
      "0.6": "130.08",
      "0.7": "143.08",
      "0.8": "155.96",
      "0.9": "169.99",
      "1.0": "183.60",
      "1.1": "198.28",
      "1.2": "212.17",
      "1.3": "227.00",
      "1.4": "238.36",
      "1.5": "250.28",
      "1.6": "262.79",
      "1.7": "275.92",
      "1.8": "289.74",
      "1.9": "304.22",
      "2.0": "316.38",
      "2.1": "329.05",
      "2.2": "342.22",
      "2.3": "355.89",
      "2.4": "370.12",
      "2.5": "384.93",
      "2.6": "400.32",
      "2.7": "412.35",
      "2.8": "424.71",
      "2.9": "437.45",
      "3.0": "450.57"
    },
    "3.5": {
      "0.5": "121.95",
      "0.6": "136.58",
      "0.7": "150.23",
      "0.8": "163.77",
      "0.9": "178.48",
      "1.0": "192.78",
      "1.1": "208.20",
      "1.2": "222.77",
      "1.3": "238.35",
      "1.4": "250.28",
      "1.5": "262.79",
      "1.6": "275.93",
      "1.7": "289.73",
      "1.8": "304.22",
      "1.9": "319.44",
      "2.0": "332.21",
      "2.1": "345.49",
      "2.2": "359.32",
      "2.3": "373.68",
      "2.4": "388.63",
      "2.5": "404.18",
      "2.6": "420.35",
      "2.7": "432.96",
      "2.8": "445.93",
      "2.9": "459.32",
      "3.0": "473.09"
    },
    "3.7": {
      "0.5": "128.04",
      "0.6": "143.40",
      "0.7": "157.75",
      "0.8": "171.95",
      "0.9": "187.43",
      "1.0": "202.42",
      "1.1": "218.61",
      "1.2": "233.93",
      "1.3": "250.27",
      "1.4": "262.79",
      "1.5": "275.93",
      "1.6": "289.74",
      "1.7": "304.22",
      "1.8": "319.44",
      "1.9": "335.41",
      "2.0": "348.83",
      "2.1": "362.78",
      "2.2": "377.28",
      "2.3": "392.38",
      "2.4": "408.06",
      "2.5": "424.41",
      "2.6": "441.36",
      "2.7": "454.59",
      "2.8": "468.24",
      "2.9": "482.30",
      "3.0": "496.76"
    },
    "3.9": {
      "0.5": "134.44",
      "0.6": "150.59",
      "0.7": "165.62",
      "0.8": "180.53",
      "0.9": "196.80",
      "1.0": "212.54",
      "1.1": "229.54",
      "1.2": "245.60",
      "1.3": "262.78",
      "1.4": "275.93",
      "1.5": "289.74",
      "1.6": "304.22",
      "1.7": "319.42",
      "1.8": "335.41",
      "1.9": "352.17",
      "2.0": "366.25",
      "2.1": "380.91",
      "2.2": "396.15",
      "2.3": "411.99",
      "2.4": "428.46",
      "2.5": "445.61",
      "2.6": "463.42",
      "2.7": "477.34",
      "2.8": "491.64",
      "2.9": "506.40",
      "3.0": "521.59"
    },
    "4.1": {
      "0.5": "141.16",
      "0.6": "158.11",
      "0.7": "173.91",
      "0.8": "189.57",
      "0.9": "206.62",
      "1.0": "223.16",
      "1.1": "241.01",
      "1.2": "257.89",
      "1.3": "275.92",
      "1.4": "289.74",
      "1.5": "304.22",
      "1.6": "319.44",
      "1.7": "335.40",
      "1.8": "352.18",
      "1.9": "369.78",
      "2.0": "384.56",
      "2.1": "399.96",
      "2.2": "415.96",
      "2.3": "432.58",
      "2.4": "449.89",
      "2.5": "467.88",
      "2.6": "486.61",
      "2.7": "501.21",
      "2.8": "516.23",
      "2.9": "531.72",
      "3.0": "547.68"
    },
    "4.3": {
      "0.5": "148.22",
      "0.6": "166.01",
      "0.7": "182.61",
      "0.8": "199.03",
      "0.9": "216.96",
      "1.0": "234.31",
      "1.1": "253.06",
      "1.2": "270.79",
      "1.3": "289.73",
      "1.4": "304.23",
      "1.5": "319.44",
      "1.6": "335.41",
      "1.7": "352.16",
      "1.8": "369.78",
      "1.9": "388.27",
      "2.0": "403.80",
      "2.1": "419.95",
      "2.2": "436.74",
      "2.3": "454.22",
      "2.4": "472.38",
      "2.5": "491.29",
      "2.6": "510.92",
      "2.7": "526.26",
      "2.8": "542.03",
      "2.9": "558.32",
      "3.0": "575.06"
    },
    "4.5": {
      "0.5": "155.64",
      "0.6": "174.32",
      "0.7": "191.74",
      "0.8": "209.01",
      "0.9": "227.80",
      "1.0": "246.03",
      "1.1": "265.72",
      "1.2": "284.33",
      "1.3": "304.21",
      "1.4": "319.44",
      "1.5": "335.41",
      "1.6": "352.17",
      "1.7": "369.77",
      "1.8": "388.27",
      "1.9": "407.69",
      "2.0": "423.99",
      "2.1": "440.94",
      "2.2": "458.57",
      "2.3": "476.93",
      "2.4": "496.00",
      "2.5": "515.86",
      "2.6": "536.49",
      "2.7": "552.57",
      "2.8": "569.14",
      "2.9": "586.23",
      "3.0": "603.82"
    }
  },
  "5 категория": {
    "1.1": {
      "0.5": "80.18",
      "0.6": "89.79",
      "0.7": "98.78",
      "0.8": "107.66",
      "0.9": "117.35",
      "1.0": "126.74",
      "1.1": "136.89",
      "1.2": "146.48",
      "1.3": "156.73",
      "1.4": "164.57",
      "1.5": "172.78",
      "1.6": "181.43",
      "1.7": "190.50",
      "1.8": "200.04",
      "1.9": "210.03",
      "2.0": "218.43",
      "2.1": "227.16",
      "2.2": "236.24",
      "2.3": "245.69",
      "2.4": "255.53",
      "2.5": "265.76",
      "2.6": "276.39",
      "2.7": "284.67",
      "2.8": "293.20",
      "2.9": "302.01",
      "3.0": "311.07",
      "3.1": "320.41",
      "3.2": "330.01",
      "3.3": "339.91"
    },
    "1.3": {
      "0.5": "85.79",
      "0.6": "96.08",
      "0.7": "105.69",
      "0.8": "115.20",
      "0.9": "125.57",
      "1.0": "135.62",
      "1.1": "146.48",
      "1.2": "156.73",
      "1.3": "167.69",
      "1.4": "176.08",
      "1.5": "184.88",
      "1.6": "194.14",
      "1.7": "203.84",
      "1.8": "214.03",
      "1.9": "224.73",
      "2.0": "233.72",
      "2.1": "243.06",
      "2.2": "252.79",
      "2.3": "262.90",
      "2.4": "273.41",
      "2.5": "284.36",
      "2.6": "295.72",
      "2.7": "304.60",
      "2.8": "313.75",
      "2.9": "323.15",
      "3.0": "332.86",
      "3.1": "342.83",
      "3.2": "353.11",
      "3.3": "363.70"
    },
    "1.5": {
      "0.5": "91.79",
      "0.6": "102.81",
      "0.7": "113.09",
      "0.8": "123.28",
      "0.9": "134.37",
      "1.0": "145.11",
      "1.1": "156.73",
      "1.2": "167.69",
      "1.3": "179.42",
      "1.4": "188.41",
      "1.5": "197.82",
      "1.6": "207.71",
      "1.7": "218.10",
      "1.8": "229.01",
      "1.9": "240.44",
      "2.0": "250.08",
      "2.1": "260.08",
      "2.2": "270.49",
      "2.3": "281.30",
      "2.4": "292.56",
      "2.5": "304.26",
      "2.6": "316.42",
      "2.7": "325.91",
      "2.8": "335.70",
      "2.9": "345.78",
      "3.0": "356.14",
      "3.1": "366.82",
      "3.2": "377.84",
      "3.3": "389.17"
    },
    "1.7": {
      "0.5": "98.22",
      "0.6": "110.01",
      "0.7": "121.00",
      "0.8": "131.90",
      "0.9": "143.78",
      "1.0": "155.28",
      "1.1": "167.69",
      "1.2": "179.42",
      "1.3": "192.00",
      "1.4": "201.59",
      "1.5": "211.67",
      "1.6": "222.25",
      "1.7": "233.38",
      "1.8": "245.04",
      "1.9": "257.30",
      "2.0": "267.59",
      "2.1": "278.28",
      "2.2": "289.41",
      "2.3": "300.99",
      "2.4": "313.04",
      "2.5": "325.56",
      "2.6": "338.58",
      "2.7": "348.73",
      "2.8": "359.19",
      "2.9": "369.97",
      "3.0": "381.08",
      "3.1": "392.51",
      "3.2": "404.28",
      "3.3": "416.41"
    },
    "1.9": {
      "0.5": "105.09",
      "0.6": "117.70",
      "0.7": "129.49",
      "0.8": "141.13",
      "0.9": "153.83",
      "1.0": "166.14",
      "1.1": "179.42",
      "1.2": "192.00",
      "1.3": "205.45",
      "1.4": "215.70",
      "1.5": "226.49",
      "1.6": "237.81",
      "1.7": "249.71",
      "1.8": "262.20",
      "1.9": "275.29",
      "2.0": "286.32",
      "2.1": "297.77",
      "2.2": "309.68",
      "2.3": "322.06",
      "2.4": "334.95",
      "2.5": "348.35",
      "2.6": "362.28",
      "2.7": "373.14",
      "2.8": "384.33",
      "2.9": "395.87",
      "3.0": "407.75",
      "3.1": "419.98",
      "3.2": "432.57",
      "3.3": "445.55"
    },
    "2.1": {
      "0.5": "111.40",
      "0.6": "124.77",
      "0.7": "137.25",
      "0.8": "149.59",
      "0.9": "163.07",
      "1.0": "176.11",
      "1.1": "190.20",
      "1.2": "203.51",
      "1.3": "217.75",
      "1.4": "228.66",
      "1.5": "240.08",
      "1.6": "252.09",
      "1.7": "264.69",
      "1.8": "277.94",
      "1.9": "291.83",
      "2.0": "303.50",
      "2.1": "315.63",
      "2.2": "328.26",
      "2.3": "341.39",
      "2.4": "355.04",
      "2.5": "369.25",
      "2.6": "384.01",
      "2.7": "395.53",
      "2.8": "407.40",
      "2.9": "419.62",
      "3.0": "432.22",
      "3.1": "445.18",
      "3.2": "458.54",
      "3.3": "472.30"
    },
    "2.3": {
      "0.5": "118.09",
      "0.6": "132.26",
      "0.7": "145.49",
      "0.8": "158.58",
      "0.9": "172.86",
      "1.0": "186.68",
      "1.1": "201.60",
      "1.2": "215.73",
      "1.3": "230.82",
      "1.4": "242.36",
      "1.5": "254.49",
      "1.6": "267.21",
      "1.7": "280.58",
      "1.8": "294.59",
      "1.9": "309.33",
      "2.0": "321.70",
      "2.1": "334.56",
      "2.2": "347.96",
      "2.3": "361.86",
      "2.4": "376.33",
      "2.5": "391.40",
      "2.6": "407.07",
      "2.7": "419.27",
      "2.8": "431.85",
      "2.9": "444.79",
      "3.0": "458.15",
      "3.1": "471.88",
      "3.2": "486.05",
      "3.3": "500.63"
    },
    "2.5": {
      "0.5": "125.19",
      "0.6": "140.18",
      "0.7": "154.21",
      "0.8": "168.09",
      "0.9": "183.21",
      "1.0": "197.89",
      "1.1": "213.72",
      "1.2": "228.67",
      "1.3": "244.68",
      "1.4": "256.90",
      "1.5": "269.76",
      "1.6": "283.24",
      "1.7": "297.40",
      "1.8": "312.27",
      "1.9": "327.88",
      "2.0": "341.00",
      "2.1": "354.65",
      "2.2": "368.84",
      "2.3": "383.58",
      "2.4": "398.92",
      "2.5": "414.88",
      "2.6": "431.47",
      "2.7": "444.43",
      "2.8": "457.76",
      "2.9": "471.50",
      "3.0": "485.64",
      "3.1": "500.22",
      "3.2": "515.21",
      "3.3": "530.67"
    },
    "2.7": {
      "0.5": "132.69",
      "0.6": "148.61",
      "0.7": "163.46",
      "0.8": "178.19",
      "0.9": "194.22",
      "1.0": "209.75",
      "1.1": "226.53",
      "1.2": "242.38",
      "1.3": "259.36",
      "1.4": "272.33",
      "1.5": "285.94",
      "1.6": "300.24",
      "1.7": "315.25",
      "1.8": "331.01",
      "1.9": "347.56",
      "2.0": "361.47",
      "2.1": "375.93",
      "2.2": "390.97",
      "2.3": "406.60",
      "2.4": "422.85",
      "2.5": "439.78",
      "2.6": "457.37",
      "2.7": "471.09",
      "2.8": "485.22",
      "2.9": "499.77",
      "3.0": "514.77",
      "3.1": "530.21",
      "3.2": "546.12",
      "3.3": "562.51"
    },
    "2.9": {
      "0.5": "139.32",
      "0.6": "156.04",
      "0.7": "171.65",
      "0.8": "187.09",
      "0.9": "203.92",
      "1.0": "220.24",
      "1.1": "237.86",
      "1.2": "254.51",
      "1.3": "272.33",
      "1.4": "285.94",
      "1.5": "300.24",
      "1.6": "315.25",
      "1.7": "331.01",
      "1.8": "347.56",
      "1.9": "364.94",
      "2.0": "379.54",
      "2.1": "394.73",
      "2.2": "410.52",
      "2.3": "426.93",
      "2.4": "444.01",
      "2.5": "461.76",
      "2.6": "480.24",
      "2.7": "494.65",
      "2.8": "509.48",
      "2.9": "524.77",
      "3.0": "540.52"
    },
    "3.1": {
      "0.5": "146.29",
      "0.6": "163.83",
      "0.7": "180.24",
      "0.8": "196.44",
      "0.9": "214.12",
      "1.0": "231.25",
      "1.1": "249.75",
      "1.2": "267.23",
      "1.3": "285.94",
      "1.4": "300.24",
      "1.5": "315.25",
      "1.6": "331.01",
      "1.7": "347.56",
      "1.8": "364.94",
      "1.9": "383.21",
      "2.0": "398.52",
      "2.1": "414.45",
      "2.2": "431.03",
      "2.3": "448.28",
      "2.4": "466.20",
      "2.5": "484.86",
      "2.6": "504.25",
      "2.7": "519.38",
      "2.8": "534.96",
      "2.9": "551.01",
      "3.0": "567.54"
    },
    "3.3": {
      "0.5": "153.61",
      "0.6": "172.02",
      "0.7": "189.23",
      "0.8": "206.27",
      "0.9": "224.82",
      "1.0": "242.80",
      "1.1": "262.23",
      "1.2": "280.60",
      "1.3": "300.24",
      "1.4": "315.25",
      "1.5": "331.01",
      "1.6": "347.56",
      "1.7": "364.94",
      "1.8": "383.21",
      "1.9": "402.36",
      "2.0": "418.43",
      "2.1": "435.17",
      "2.2": "452.58",
      "2.3": "470.69",
      "2.4": "489.51",
      "2.5": "509.09",
      "2.6": "529.46",
      "2.7": "545.35",
      "2.8": "561.72",
      "2.9": "578.55",
      "3.0": "595.91"
    },
    "3.5": {
      "0.5": "161.27",
      "0.6": "180.62",
      "0.7": "198.70",
      "0.8": "216.59",
      "0.9": "236.07",
      "1.0": "254.95",
      "1.1": "275.35",
      "1.2": "294.62",
      "1.3": "315.25",
      "1.4": "331.01",
      "1.5": "347.56",
      "1.6": "364.94",
      "1.7": "383.18",
      "1.8": "402.36",
      "1.9": "422.47",
      "2.0": "439.36",
      "2.1": "456.94",
      "2.2": "475.21",
      "2.3": "494.23",
      "2.4": "513.99",
      "2.5": "534.57",
      "2.6": "555.94",
      "2.7": "572.61",
      "2.8": "589.79",
      "2.9": "607.49",
      "3.0": "625.70"
    },
    "3.7": {
      "0.5": "169.34",
      "0.6": "189.66",
      "0.7": "208.62",
      "0.8": "227.41",
      "0.9": "247.86",
      "1.0": "267.71",
      "1.1": "289.12",
      "1.2": "309.35",
      "1.3": "331.01",
      "1.4": "347.56",
      "1.5": "364.94",
      "1.6": "383.18",
      "1.7": "402.34",
      "1.8": "422.47",
      "1.9": "443.59",
      "2.0": "461.32",
      "2.1": "479.77",
      "2.2": "498.97",
      "2.3": "518.94",
      "2.4": "539.68",
      "2.5": "561.27",
      "2.6": "583.73",
      "2.7": "601.25",
      "2.8": "619.29",
      "2.9": "637.86",
      "3.0": "656.99"
    },
    "3.9": {
      "0.5": "177.81",
      "0.6": "199.14",
      "0.7": "219.04",
      "0.8": "238.78",
      "0.9": "260.25",
      "1.0": "281.08",
      "1.1": "303.57",
      "1.2": "324.83",
      "1.3": "347.56",
      "1.4": "364.94",
      "1.5": "383.21",
      "1.6": "402.34",
      "1.7": "422.46",
      "1.8": "443.59",
      "1.9": "465.76",
      "2.0": "484.38",
      "2.1": "503.78",
      "2.2": "523.93",
      "2.3": "544.88",
      "2.4": "566.66",
      "2.5": "589.33",
      "2.6": "612.91",
      "2.7": "631.30",
      "2.8": "650.25",
      "2.9": "669.76",
      "3.0": "689.85"
    },
    "4.1": {
      "0.5": "186.71",
      "0.6": "209.10",
      "0.7": "230.01",
      "0.8": "250.72",
      "0.9": "273.29",
      "1.0": "295.14",
      "1.1": "318.74",
      "1.2": "341.07",
      "1.3": "364.94",
      "1.4": "383.18",
      "1.5": "402.36",
      "1.6": "422.46",
      "1.7": "443.58",
      "1.8": "465.76",
      "1.9": "489.06",
      "2.0": "508.62",
      "2.1": "528.97",
      "2.2": "550.11",
      "2.3": "572.13",
      "2.4": "595.01",
      "2.5": "618.81",
      "2.6": "643.57",
      "2.7": "662.88",
      "2.8": "682.76",
      "2.9": "703.24",
      "3.0": "724.33"
    },
    "4.3": {
      "0.5": "196.05",
      "0.6": "219.56",
      "0.7": "241.51",
      "0.8": "263.25",
      "0.9": "286.95",
      "1.0": "309.89",
      "1.1": "334.70",
      "1.2": "358.11",
      "1.3": "383.21",
      "1.4": "402.36",
      "1.5": "422.47",
      "1.6": "443.58",
      "1.7": "465.75",
      "1.8": "489.06",
      "1.9": "513.51",
      "2.0": "534.04",
      "2.1": "555.42",
      "2.2": "577.62",
      "2.3": "600.75",
      "2.4": "624.76",
      "2.5": "649.75",
      "2.6": "675.74",
      "2.7": "696.02",
      "2.8": "716.90",
      "2.9": "738.40",
      "3.0": "760.55"
    },
    "4.5": {
      "0.5": "205.84",
      "0.6": "230.52",
      "0.7": "253.59",
      "0.8": "276.42",
      "0.9": "301.28",
      "1.0": "325.38",
      "1.1": "351.43",
      "1.2": "376.03",
      "1.3": "402.36",
      "1.4": "422.47",
      "1.5": "443.59",
      "1.6": "465.75",
      "1.7": "489.05",
      "1.8": "513.51",
      "1.9": "539.19",
      "2.0": "560.74",
      "2.1": "583.18",
      "2.2": "606.51",
      "2.3": "630.76",
      "2.4": "656.00",
      "2.5": "682.24",
      "2.6": "709.54",
      "2.7": "730.81",
      "2.8": "752.74",
      "2.9": "775.33",
      "3.0": "798.58"
    }
  }
}
