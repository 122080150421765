import { TextField } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { GlobalFunctions } from '../../../GlobalFunctions';

export default class Input extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
        }
        this.key = GlobalFunctions.randomString()
        this.startValue=null

        this.input = {}
    }

    componentDidMount() {
        this.ready(this.props)
        setTimeout(()=>{
            debugger
        },2000)
    }
    
    componentWillReceiveProps(nextProps) {
        this.ready(nextProps)
    }

    ready(props){
        
    }
    
    render (){
        const {title,name,Ref,type} = this.props
        return ([
           
            type=='textarea'?
            <TextField
            variant="filled"
            size="small"
            label={title}
            ref={this.input[name]}
            itemRef={this.input[name]}
            id={this.key} key={this.key+"I"}
            Ref={this.input[name]}
            inputRef={this.input[name]}
            className="form-control"
            {...this.props}/> :
            <TextField
            variant="filled"
            size="small"
            label={title}
            Ref={this.input[name]}
            ref={this.input[name]}
            id={this.key} key={this.key+"I"}
            itemRef={this.input[name]}
            inputRef={this.input[name]}
            {...this.props}/>
        ])
    }
}

export const Label = styled.label`
font-size:11px;
opacity:0.7;
display:block;
`

const InputView = styled.input`
font-size:13px;
border: 1px #f2f5f5 solid;
width:100%;
margin-bottom:10px;
`
const TextAreaView = styled.textarea`
font-size:13px;
border: 1px #f2f5f5 solid;
width:100%;
margin-bottom:10px;
height:60px;
resize:none;
`
