

import { Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Select, TextField, Tooltip, Typography,Grid, ListItem, IconButton, ListItemButton,Menu, Checkbox } from '@mui/material';
import Immutable from 'immutable';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import { GF } from '../../../GlobalFunctions';
import StackHorizontal from '../../components/StackHorizontal';
import { POPbottom, POPscroll, POPtop } from '../../PopUp';
import POP_Order from './POP_Order'
import POP_User from '../DataBase/pages/POP_User'


class POP_OrdersSearch extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            ORDERS:[],
            filter:null,
            selected:props.selected || [],
        }
       
        this.state.ORDERS = props.ORDERS.sort((a,b)=>{
            let textA = a.FIO.toUpperCase();
            let textB = b.FIO.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;  
        })
    }

    render(){
        const {state,props} = this
        let filteredData = []
        let filtered = []

        let lastUser = null
        
        state.ORDERS.forEach(order=>{
            if(order.ID_STATUS==13){
                if((state.filter==null ||
                    order.GUID.indexOf(state.filter)!=-1 
                )){
                    if(props.isHiddenRule==null || (  props.isHiddenRule!=null && props.isHiddenRule(order)==false)){
                        const index = this.state.selected.findIndex(t=>t.ID==order.ID)
                        const isSelected = index!=-1
                        
                        filteredData.push(order)

                        if(lastUser?.ID!=order.ID_USER){
                            lastUser={
                                ID:order.ID_USER,
                                FIO:order.FIO
                            }

                            filtered.push(
                                <Chip avatar={<Avatar/>} label={lastUser.FIO} onClick={()=>{
                                    props.dispatch(openPopUp(<POP_User ID_OBJECT={lastUser.ID}/>))
                                }}/>
                            )
                        }
                        filtered.push(<>
                            <Button size='small' color={isSelected?'success':'inherit'} onClick={()=>{
                                let tmp = [...[...this.state.selected]]
                                if(props.multiple){
                                    
                                    if(index==-1)
                                        tmp.push(order)
                                    else
                                        tmp.splice(index,1)
                                }else{
                                    tmp=[order]
                                }
                                this.setState({selected:tmp})
                            }} className='w-100' style={{
                                paddingLeft:isSelected?20:5,
                                transition:'.3s'
                            }}> <StackHorizontal className='w-100'>
                                    <Chip color='success' label={order.GUID} sile='small' onClick={()=>
                                        props.dispatch(openPopUp(<POP_Order ID_OBJECT={order.ID}/>))
                                    }/>
                                    
                                    <p className='m-0 d-block text-start'><small className='me-2'>от</small>{moment(order.DATE_SENDED).format('D MMM')}<small className='ms-2'>{moment(order.DATE_SENDED).format('YYYY')}</small></p>
                                </StackHorizontal>
                            </Button>
                            <hr className='m-0 p-0'/>
                        </>)
                    }
                }
            }
        })



        

        return(<POP>
            <POPtop style={{height:130}}>
                <div className='w-100'>
                {props.title==null?
                    <h3 className='m-0'>Выбор {props.multiple?'заказов':'заказа'}</h3>:
                    <h3 className='m-0'>{props.title}</h3>
                }
                <small className=' d-block m-0 mb-2'>Выберите из списка</small>
                    <div style={{display:'flex',gap:16,width:'100%'}}>
                        <TextField
                            className='m-0'
                            style={{
                                display:'block',
                                flex:1
                            }}
                            inputProps={{style:{
                                width:'100%'
                            }}}
                            label='Поиск'
                            variant='filled'
                            size="small"
                            value={state.filter}
                            color='success'
                            onChange={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}
                        />
                        {!props.multiple?null:
                        <Button size='small' color='success' onClick={()=>this.setState({selected:[...filteredData]})}>Выбрать все</Button>}
                        <Button size='small' color='inherit' onClick={()=>this.setState({selected:[]})}>Отменить выбор</Button>
                    </div>
                </div>

            </POPtop>
            <POPscroll top bottom style={{
                paddingTop:140
            }}>
                
                {filtered.length==0?<small>{props.placeholder || 'Нет заказов'}</small>:filtered}
                
                
            </POPscroll>

            
            <POPbottom style={{
                bottom:state.selected.length==0?-55:0
            }}>
                <Button color='success' size='small' onClick={()=>{
                    props.callBack(state.selected)
                }}>Выбрать</Button>
            </POPbottom>
        </POP>)
    }
    

}

const POP = styled.div`
width:500px;

`

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch:(arg)=>dispatch(arg),
    }
}


const mapStateToProps = (store) => {
    return {
        user:store.user.data,
        ORDERS:store.page.ORDERS,
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(POP_OrdersSearch)

