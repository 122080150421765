
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { addToBasket, removeFromBasket, setCountInBasket } from '../../../actions/PageActions';
import { openPopUp } from '../../../actions/PopUpActions';
import { GlobalFunctions as GF } from '../../../GlobalFunctions';
import POP_AccessoryCaterory from './POP_AccessoryCaterory';
import POP_Accessuar from './POP_Accessuar';
import POP_AddBasketCount from './POP_AddBasketCount';
import POP_Basket from './POP_Basket';


class Index extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
            filter: null,
            selectedCategory: 1
        }
        this.input = []
        this.updateTimer = null
    }



    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (prevProps.ACCESSORIES_CATEGORIS != this.props.ACCESSORIES_CATEGORIS) {
            if (this.props.ACCESSORIES_CATEGORIS.findIndex(c => c.ID == this.state.selectedCategory) == -1) {
                //если удалили категорию которая была открыта
                this.setState({ selectedCategory: 1 })
            }
        }
    }




    render() {
        const { state, props } = this
        const { ACCESSORIES, ACCESSORIES_BASKET, ACCESSORIES_CATEGORIS } = props
        const isClient = props.user.ROLE == 'PRTN' || props.user.ROLE == 'CPRT'
        const basketCount = ACCESSORIES_BASKET.reduce((a, b) => a + b.COUNT, 0)

        return (
            <MainView className='noselect'>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 10
                }}>
                    <TextField
                        style={{ width: 250 }}
                        className='m-0'
                        label='Поиск товара'
                        variant='filled'
                        size="small"
                        color='success'

                        onKeyUp={(e) => this.setState({ filter: e.target.value.length > 0 ? e.target.value.toUpperCase() : null })}
                    />

                    <FormControl variant="filled" sx={{ width: 180 }} color='success' size='small'>
                        <InputLabel>Категория</InputLabel>
                        <Select
                            size='small'
                            label='Категория'
                            value={state.selectedCategory}
                            onChange={e =>
                                this.setState({
                                    selectedCategory: e.target.value
                                })
                            }>
                            {ACCESSORIES_CATEGORIS.map(cat =>
                                <MenuItem value={cat.ID}>{cat.TITLE}</MenuItem>
                            )}

                        </Select>
                    </FormControl>

                    {isClient ?
                        <div style={{ position: 'relative' }} onClick={() => this.props.openPopUp(<POP_Basket />)}>
                            <IconButton color='success'><ShoppingCartIcon /></IconButton>
                            {basketCount == 0 ? null :
                                <div style={{
                                    borderRadius: 100,
                                    height: 17,
                                    width: basketCount >= 100 ? 25 : 17,
                                    position: 'absolute',
                                    top: -3,
                                    textAlign: 'center',
                                    right: basketCount >= 100 ? -6 : -4,
                                    transition: '.3s',
                                    fontWeight: 'bold',
                                    fontSize: 8,
                                    color: 'white',
                                    background: 'rgb(46,125,50)',
                                    border: '2px solid white'
                                }}>{basketCount}</div>}
                        </div>
                        : <>
                            <Button color='success' onClick={() => {
                                props.openPopUp(<POP_Accessuar selectedCategory={state.selectedCategory} />)
                            }}>Добавить товар</Button>
                            <Button color='success' onClick={() => {
                                props.openPopUp(<POP_AccessoryCaterory callBack={data => {
                                    this.setState({ selectedCategory: data.ID })
                                }} />)
                            }}>Создать категорию</Button>
                            <Button color='info' onClick={() => {
                                props.openPopUp(<POP_AccessoryCaterory ID_OBJECT={state.selectedCategory} />)
                            }}>Изменить категорию</Button>
                        </>
                    }
                </div>


                <table className='w-100 mt-3 mb-3 ' >
                    <thead>
                        <td width='100px'></td>
                        <td width='120px'>1C идентификатор</td>
                        <td style={{ textAlign: 'left' }}>Название</td>
                        <td>Вес</td>
                        <td>Цена</td>
                        {!isClient ? null : <td></td>}

                    </thead>
                    <tbody>
                        {ACCESSORIES.map(item => {
                            if (item.ID_CATEGORY != state.selectedCategory ||
                                (state.filter != null && item.TITLE.toUpperCase().indexOf(state.filter) == -1)
                            ) return null
                            let inBasket = ACCESSORIES_BASKET.find(b => b.ID_ACCESSORY == item.ID)
                            return (<tr onClick={() => {
                                if (isClient) {
                                    this.props.openPopUp(<POP_AddBasketCount value={inBasket?.COUNT || 0} callBack={(count) => {
                                        this.props.setCountInBasket({
                                            ID: item.ID,
                                            COUNT: count
                                        })
                                    }} />)
                                } else
                                    props.openPopUp(<POP_Accessuar ID_OBJECT={item.ID} />)
                            }}>
                                <td>
                                    {item?.IMAGES_URL?.at(0) == null ?
                                        <HomeRepairServiceIcon style={{
                                            width: 30,
                                            height: 80,
                                            objectFit: 'contain'
                                        }} /> :
                                        <img style={{
                                            width: 100,
                                            height: 80,
                                            objectFit: 'contain'
                                        }} src={global.URL_host + 'img/' + item.IMAGES_URL.at(0)} onClick={(e) => {
                                            e.stopPropagation()
                                            this.props.openPopUp(<img style={{
                                                margin: -30,
                                                maxHeight: '70vh',
                                                maxWidth: '80vw',
                                                minHeight: 300,
                                                minWidth: 300,
                                                padding: 20,
                                                objectFit: 'contain'
                                            }} src={global.URL_host + 'img/' + item.IMAGES_URL.at(0)} />)
                                        }} />}
                                </td>
                                <td>{item.ID_1C || '-'}</td>
                                <td style={{ textAlign: 'left' }}>{item.TITLE}</td>
                                <td>{item.WEIGHT} <small>г</small></td>
                                <td>{GF.makeCoast(item.PRICE)} <small>руб</small></td>
                                {!isClient ? null :
                                    <td>
                                        {inBasket == null ?
                                            <Button style={{
                                                marginLeft: 'auto',
                                                width: 80,
                                                marginRight: 10,
                                                display: 'block'
                                            }} size='small' color='success' onClick={(e) => {
                                                e.stopPropagation()
                                                this.props.addToBasket({
                                                    ID: item.ID,
                                                    COUNT: 1
                                                })
                                            }}>Добавить</Button> :
                                            <div style={{
                                                backgroundColor: 'rgb(230,230,230)',
                                                width: 80,
                                                display: 'flex',
                                                alignItems: 'center',
                                                alignContent: 'center',
                                                alignSelf: 'flex-end',
                                                justifyContent: 'space-between',
                                                paddingLeft: 5,
                                                paddingRight: 5,
                                                borderRadius: 8,
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                marginRight: 10,
                                                marginLeft: 'auto'
                                            }}>
                                                <IconButton style={{

                                                    textAlign: 'center',
                                                    width: 20,
                                                    height: 20
                                                }} size='small' onClick={(e) => {
                                                    e.stopPropagation()
                                                    this.props.removeFromBasket({
                                                        ID: item.ID
                                                    })
                                                }}>-</IconButton>
                                                <div style={{
                                                    width: 30,
                                                    textAlign: 'center'
                                                }} onClick={() => {
                                                    this.props.openPopUp(<POP_AddBasketCount value={inBasket.COUNT} callBack={(count) => {
                                                        this.props.setCountInBasket({
                                                            ID: item.ID,
                                                            COUNT: count
                                                        })
                                                    }} />)
                                                }}>{inBasket.COUNT}</div>
                                                <IconButton style={{
                                                    textAlign: 'center',
                                                    width: 20,
                                                    height: 20
                                                }} size='small' onClick={(e) => {
                                                    e.stopPropagation()
                                                    this.props.addToBasket({
                                                        ID: item.ID,
                                                        COUNT: 1
                                                    })
                                                }}>+</IconButton>
                                            </div>
                                        }
                                    </td>
                                }
                            </tr>)
                        })}
                    </tbody>
                </table>
            </MainView>
        )



    }
}

const MainView = styled.div`
height:max-content;
padding-bottom:150px;
& table td{
    padding-top:0px;
    padding-bottom:0px;
    font-size:13px;
    text-align:center;
  }
  & table tbody tr{
    border-bottom:1px solid #e3e3e3;
    transition:.3s;
    transform:scale(1);
    cursor:pointer;
  }
  & table tbody tr.partner:hover{
    transform:scale(1.01);
  }
  & table tbody tr:hover{
  
    background:rgb(241, 241, 241);
  
  }
  
  & table thead td{
    color:rgb(120,120,120);
    font-size:11px !important;
    padding-bottom:5px;
  }
`



const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp: (arg) => dispatch(openPopUp(arg)),
        addToBasket: (arg) => dispatch(addToBasket(arg)),
        removeFromBasket: (arg) => dispatch(removeFromBasket(arg)),
        setCountInBasket: (arg) => dispatch(setCountInBasket(arg)),
    };
};


const mapStateToProps = (store) => {

    return {
        ACCESSORIES_BASKET: store.page.ACCESSORIES_BASKET,
        ACCESSORIES_CATEGORIS: store.page.ACCESSORIES_CATEGORIS,
        ACCESSORIES: store.page.ACCESSORIES,
        user: store.user.data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Index))

