import React from 'react';

import { Alert, AlertTitle, Button, LinearProgress, TextField } from '@mui/material';
import { withCookies } from 'react-cookie';
import ReactInputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { down, up } from 'styled-breakpoints';
import styled from 'styled-components';
import { GlobalFunctions } from '../../../GlobalFunctions';
import { openPopUp } from "../../../actions/PopUpActions";
import { loadUserData_FromStorage, logOut } from '../../../actions/UserAct';
import BackImg from '../../../design/bg.jpg';
import Logo_png from '../../../design/logo.svg';
import POP_Alert from '../../PopUp/Views/POP_Alert';

const form_fields = {
    step_0: [
        {
            label: 'Email представителя',
            name: 'EMAIL',
        },
        {
            label: 'Задайте пароль',
            name: 'PASSWORD',
            type: 'password'
        },
    ],
    step_1: [
        {
            label: 'ФИО представителя',
            name: 'FIO',
        },
        {
            label: 'Телефон представителя',
            name: 'PHONE',
        },
        {
            label: 'Адрес главного офиса (адрес доставки)',
            name: 'ADDRESS',
        },
        {
            label: 'Название компании',
            name: 'COMPANY_TITLE',
        },
        {
            label: 'ИНН',
            name: 'INN',
        },

    ],
    step_2: [
        {
            label: 'Дополнительная информация (на Ваш выбор)',
            name: 'COMMENT',
            multiline: true,
            rows: 6
        }
    ],
    step_3: [],
    step_4: [],
    step_5: []
}

class NewPartner extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            errorMSG: null,
            isLoaded: false,
            isLoadedALL: false,
            isError: false,

            Self: {},
            step: 0
        }
        this.input = []
        debugger
    }



    componentDidMount() {
        GlobalFunctions.deleteCookie('login')
        GlobalFunctions.deleteCookie('password')
        this.props.logOut()
        this.props.loadUserData_FromStorage()


        setTimeout(() => {
            this.setState({ isLoaded: true })
        }, 500)
    }


    sendData() {
        const { Self } = this.state

        this.setState({ errorMSG: null, step: 3 })

        let data = {}
        Object.keys(Self).forEach(key => {
            data[key] = encodeURIComponent(Self[key])
        })



        setTimeout(() => {
            GlobalFunctions.API_task({
                operation: "createPartnerTiket",
                block: 'OutOfAuth',
                data: Self
            }).then(res => {

                if (res.error != null) {
                    this.setState({ errorMSG: res.error, step: 5 })
                    return
                }

                this.setState({ step: 4 })

            }).catch((error) => {

                this.setState({ errorMSG: error, step: 5 })
            })
        }, 3000)

    }

    showErrors(errors) {
        this.props.openPopUp(<POP_Alert text={errors} buttons />)
    }

    renderView() {
        const { isLoaded, isLoadedALL, isError, Self, step, errorMSG } = this.state
        return ({
            steps: () => {
                const steps = {
                    '0': (<div style={{ marginTop: 20 }}>
                        <Alert color='info' severity={null}>Эти данные понадобятся в дальнейшем для входа в аккаунт</Alert>
                        <div style={{
                            marginTop: 20,
                            display: 'flex',
                            flexDirection: "row",
                            justifyContent: 'space-between'
                        }}>
                            {this.props.CAN_BACK ?
                                <Button key='btn2' size='small' color='success' onClick={() => this.props.history.push('/')}>Назад</Button> :
                                <div />
                            }

                            <Button key='btn1' disabled={Self.PASSWORD == null || Self.EMAIL == null} size='small' color='success' onClick={() => {
                                let errors = []
                                if (Self.PASSWORD == null || Self.PASSWORD.length < 6)
                                    errors.push(<p>Пароль должен содержать не менее 6ти символов</p>)

                                if (Self.EMAIL == null || Self.EMAIL.length < 2
                                    || Self.EMAIL.indexOf('@') == -1
                                    || Self.EMAIL.indexOf('.') == -1
                                )
                                    errors.push(<p>Неверный формат E-mail</p>)

                                if (errors.length == 0)
                                    this.setState({ step: step + 1 })
                                else
                                    this.showErrors(errors)

                            }}>Продолжить</Button>
                        </div>
                    </div>),

                    '1': (<div style={{ marginTop: 20 }}>
                        <Alert color='info' severity={null}>Мы собираем эту информацию для предварительной проверки и дальнейшего сотрудничества с Вами. Информация не передается третьим лицам</Alert>
                        <div style={{
                            marginTop: 20,
                            display: 'flex',
                            flexDirection: "row",
                            justifyContent: 'space-between'
                        }}>

                            <Button key='btn2' size='small' color='success' onClick={() => this.setState({ step: step - 1 })}>Назад</Button>

                            <Button key='btn3' size='small' color='success' onClick={() => {
                                if (!GlobalFunctions.checkReqvireds([this.input.TYPE, this.input.INN])) return
                                this.setState({ step: step + 1 })
                            }}
                                disabled={
                                    Self.FIO == null || Self.FIO.length < 2 ||
                                    Self.PHONE == null || Self.PHONE.length < 11
                                }>Продолжить</Button>
                        </div>
                    </div>),

                    '2': (<div style={{ marginTop: 20 }}>

                        <div style={{
                            display: 'flex',
                            flexDirection: "row",
                            justifyContent: 'space-between'
                        }}>

                            <Button key='btn4' size='small' color='success' onClick={() => this.setState({ step: step - 1 })}>Назад</Button>

                            <Button key='btn5' size='small' color='success' onClick={() => this.sendData()}>Отправить</Button>
                        </div>
                    </div>),

                    '3': (<div style={{
                        marginTop: 40,
                        marginBottom: 40
                    }}>
                        <LinearProgress style={{
                            height: 5
                        }} color='success' />
                    </div>),

                    '4': (<div style={{}}>
                        <Alert color='success' >
                            <AlertTitle>Отлично!</AlertTitle>
                            Мы получили вашу заявку и приняли ее в работу. В ближайшее время с Вами свяжется личный менеджер для уточнения деталей и заключения договора
                        </Alert>
                        <small style={{
                            lineHeight: '18px',
                            marginTop: '15px',
                            display: 'block'
                        }}>Страницу можно закрыть. Все данные успешно отправлены, мы обязательно позвоним Вам</small>
                    </div>),

                    '5': (<div style={{

                    }}>
                        <Alert color='error' style={{
                            marginBottom: 20
                        }} severity='error'>
                            <AlertTitle>Ошибка</AlertTitle>
                            {errorMSG}
                        </Alert>

                        <Button key='btn2' size='small' color='success' onClick={() => this.setState({ step: 0 })}>Назад</Button>
                    </div>),
                }
                return (<>
                    {form_fields['step_' + step].map(input =>
                        input.name == 'PHONE' ?
                            <ReactInputMask mask="+7 (999) 999-99-99" value={Self[input.name]} onChange={e => {
                                let tmp = Object.assign({}, Self)
                                tmp[input.name] = e.target.value.replace(/[ \t(\t,)\t,+\t,_,-]/g, '')
                                this.setState({ Self: tmp })
                            }} {...input}>
                                {(inputProps) =>
                                    <TextField
                                        color='success'
                                        key={input.name}
                                        {...inputProps}
                                        variant='filled'
                                        className='w-100 mt-3'
                                        margin='dense'
                                        inputRef={ref => this.input[input.name] = ref}
                                        size='small'


                                    />}
                            </ReactInputMask> :
                            <TextField
                                color='success'
                                className='w-100 mt-3'
                                margin='dense'
                                variant='filled'
                                size='small'
                                key={input.name}
                                onChange={e => {
                                    let tmp = Object.assign({}, Self)
                                    tmp[input.name] = e.target.value
                                    this.setState({ Self: tmp })
                                }}
                                inputRef={ref => this.input[input.name] = ref}
                                value={Self[input.name]}
                                {...input}
                            />
                    )}

                    {steps[this.state.step]}
                </>)
            }
        })
    }


    render() {
        const { } = this.props.page
        const { isLoaded, isLoadedALL } = this.state

        return (
            <BodyBlack isLoadedALL={isLoadedALL}>
                <Page className="Page Login bg-light">
                    <BGimg isLoaded={isLoaded}>
                        <DarkBg />
                    </BGimg>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: '0px 10px 30px rgba(0,0,0,0.07)',
                        width: 500,
                        margin: 'auto',
                        marginTop: 20,
                        borderRadius: 10,
                        position: 'relative',
                        zIndex: 10,
                        padding: 20,
                        backgroundColor: "white",
                        maxWidth: 'calc(100vw - 40px)'
                    }}>
                        <img style={{
                            height: window.innerWidth < 500 ? 30 : 40
                        }} src={Logo_png} />

                        <div>
                            <h5 className='m-0 text-end'>Регистрация</h5>
                            <p className='m-0 text-end'>заявка на партнерство</p>
                        </div>

                    </div>

                    <LoginView style={{
                        width: 500,
                        margin: 'auto',
                        marginTop: 20,
                        borderRadius: 10,
                        boxShadow: '0px 10px 30px rgba(0,0,0,0.07)',
                        padding: 20,
                        marginBottom: 150,
                        backgroundColor: "white",
                        maxWidth: 'calc(100vw - 40px)'
                    }} isLoaded={isLoaded} isLoadedALL={isLoadedALL}>
                        {this.renderView().steps()}




                    </LoginView>
                </Page>
            </BodyBlack>
        )
    }
}



const LoginView = styled.div`

${props => props.isLoadedALL ? `transition:1s, opacity .3s;` : `transition:1s ease 1s , opacity .5s ease 1s;`}

${props => props.isLoaded ? `
transform:translateY(0px);
opacity:1;
`: `
transform:translateY(-100px);
opacity:0;
`}
`


const BodyBlack = styled.div`
height:100vh;
width:100vw;
${props => props.isLoadedALL ? 'background:rgba(0,0,0,0);' : 'background:rgba(0,0,0,1);'}
`


const Img = styled.img`
display:block;
margin:auto;
width:150px;
margin-top:30px;
z-index:1;
position:relative;

transition:1s;

position: absolute;
width: 80px;
right: 25px;
top: 3px;

`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp: (content) => dispatch(openPopUp(content)),
        loadUserData_FromStorage: (content) => dispatch(loadUserData_FromStorage(content)),

        logOut: (arg) => dispatch(logOut(arg)),
    };
};


const mapStateToProps = (store) => {

    return {
        page: store.page
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(withRouter(NewPartner)))

const BGimg = styled.div`
background:url('${BackImg}');
height:100vh;
    width:100vw;
    transition:1s;
    object-fit: cover;
    background-position: center top;
background-size: cover;
position: fixed;
    top:0;
    left:0;


${down('md')}{
    ${props => props.isLoaded ? `
    transform:scale(1);
    opacity:1;
    `: `
    transform:scale(1.5);
    opacity:0;
    `}
}

${up('md')}{
    ${props => props.isLoaded ? `
    transform:scale(1);
    opacity:1;
    `: `
    transform:scale(1.1);
    opacity:0;
    `}
}
`

const Page = styled.div`
    
   position:relative;
    overflow-y: auto;
    overflow-x:hidden;
    height:100vh;
    width:100vw;
    



`

const DarkBg = styled.div`
position:fixed;
background:rgba(0,0,0,0.);
top:0;
left:0px;
width:100vw;
height:100vh;
`
