
import { Alert, Avatar, Button, Chip, FormControl, InputLabel, Select, Stack, TextField } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getDB_Data, getDB_Data_lazy, getORDERS, getUSERS } from '../../../actions/PageActions';
import { closePopUp, openPopUp } from '../../../actions/PopUpActions';
import { GlobalFunctions as GF } from '../../../GlobalFunctions';

import moment from 'moment';
import 'react-quill/dist/quill.snow.css';
import Debounce from '../../../Class/Debounce';
import FlatCard from '../../components/FlatCard';
import Input_image from '../../components/Input_image';
import StackHorizontal from '../../components/StackHorizontal';
import SwitchCheck from '../../components/SwitchCheck';
import { POPbottom, POPgap, POPscroll, POPtop } from '../../PopUp';
import POP_Alert from '../../PopUp/Views/POP_Alert';
import Question from '../../PopUp/Views/Question';
import POP_User from '../DataBase/pages/POP_User';
import POP_UserSearch from '../DataBase/pages/POP_UserSearch';
import POP_InstallSchedule from '../Inst_schedule/POP_InstallSchedule';
import CalkPage from '../MainPage/CalkPage';
import SaveFiles from '../SaveFiles';




const form_fields = [
    {
        label: 'Адрес',
        name: 'ADDRESS'
    },
    {
        label: 'Комметарий',
        name: 'COMMENT',
        multiline: true,
        rows: 9
    },
]




class POP_Order extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
            Self: {
                IMAGES_URL: []
            },
            installSchedule: [],
            anchorEl: null
        }
        this.input = []
        this.can = true
        this.canSendManager = true

        this.filesToUpload = []
        this.filesToDelete = []
    }



    componentDidMount() {
        const { state, props } = this
        const { ID_OBJECT, NEW_PARTNERS } = props

        if (ID_OBJECT != null) {
            var Self = props.ORDERS.find(p => p.ID == ID_OBJECT)
            if (Self == null) return
            Self = GF.ObjectClone(Self)
            this.setState({ Self })
            console.warn('POP_order', Self)

            if (props.user.ROLE == 'MNGR' || props.user.ROLE == 'ADMN') {
                if (Self.ID_STATUS == 13) {//согласована
                    let installSchedule = props.InstallSchedule.filter(sch => sch.ID_ORDER == Self.ID).sort((a, b) => {
                        let aD = moment(a.DATE_INSTALL)
                        let bD = moment(b.DATE_INSTALL)

                        if (aD.isAfter(bD))
                            return -1

                        if (aD.isBefore(bD))
                            return 1

                        if (aD.isSame(bD))
                            return 0

                    })
                    this.setState({
                        installSchedule: [...installSchedule]
                    })
                }
            }

            if (props.user.ROLE == 'MNGR' && Self.MANAGER_READED == 0) {
                GF.API_task({
                    operation: 'readOrder',
                    ID: Self.ID
                }).then(data => {
                    props.getORDERS()
                })
            }
        }
    }


    Delete() {
        Debounce.start(() => {
            const { state, props } = this
            const { COMPANYS, DEPTS, ID_OBJECT } = this.props

            new Promise((resolve, reject) => {
                this.props.openPopUp(<Question text={'Удаление заказа сотрет всю информацию о расчетах в нем'}
                    buttons={[
                        <Button className='m-2' color='error' onClick={() => resolve()}>Удалить</Button>,
                        <Button className='m-2' onClick={() => reject()}>Отмена</Button>
                    ]} />)
            }).then(() => {
                GF.API_task({
                    operation: 'deleteSome',
                    Table: 'ORDERS',
                    ID: state.Self.ID
                }).then(data => {
                    props.getORDERS()
                    props.closePopUp()

                })

            }).finally(() => {
                this.props.closePopUp()
            })

        })
    }

    OrderBack() {
        Debounce.start(() => {
            GF.API_task({
                operation: 'OrderBack',
                ID_ORDER: this.state.Self.ID
            }).then(data => {
                this.props.getORDERS(() => {
                    setTimeout(() => {
                        this.componentDidMount()
                    })
                })
            })
        })

    }

    SendToInstallers() {
        const { state, props } = this
        const { Self } = state

        const index = props.InstallSchedule.findIndex(schedule => schedule.ID_ORDER == Self.ID)

        const inSchedule = index == -1 ? null : props.InstallSchedule[index]

        const closePop = (func) => {
            props.closePopUp()
            func()
        }

        const CreateNew = () => {
            debugger
            props.openPopUp(<POP_InstallSchedule SelfData={{
                ID_ORDER: Self.ID,
                ADDRESS: Self.ADDRESS,
                COMMENT: Self.COMMENT
            }} callBack={() => this.componentDidMount()} />)
        }

        const OpenOld = () => {
            props.openPopUp(<POP_InstallSchedule ID_OBJECT={inSchedule?.ID} callBack={() => this.componentDidMount()} />)
        }



        if (index != -1) {
            props.openPopUp(<POP_Alert
                text='Этот заказ уже добавлен в расписание на монтаж'
                buttons={[
                    <Button size='small' color='success' onClick={() => closePop(OpenOld)}>Открыть в расписании</Button>,
                    <Button size='small' color='warning' onClick={() => closePop(CreateNew)}>Создать новую запись</Button>
                ]}
            />)
        } else {
            CreateNew()
        }






    }

    Save() {
        Debounce.start(() => {
            const { state, props } = this
            const { Self } = state

            GF.API_task({
                operation: 'updateOrder_simple',
                data: {
                    COMMENT: Self.COMMENT,
                    ADDRESS: Self.ADDRESS,
                    DELIVERY: Self.DELIVERY,
                    NEED_INSTALL: Self.NEED_INSTALL
                },
                ID: Self.ID
            }).then(data => {
                props.getORDERS()
                props.closePopUp()
            })

            if (this.filesToUpload.length > 0)
                GF.API_files({
                    operation: 'setIMAGE',
                    Table: 'ORDERS',
                    ID: this.props.ID_OBJECT
                }, this.filesToUpload).then(() => {
                    props.getORDERS()
                })

            if (this.filesToDelete.length > 0)
                GF.API_task({
                    operation: 'deleteIMAGE',
                    ID: this.props.ID_OBJECT,
                    Table: 'ORDERS',
                    filesToDelete: JSON.stringify(this.filesToDelete)
                }).then(() => {
                    props.getORDERS()
                })


        })
    }

    createDoc() {
        const { state, props } = this
        var { Self } = { ...{ ...state } }


        if ((Self?.ITEMS?.length || 0) == 0) {
            props.openPopUp(<POP_Alert text='Нельзя оформить пустой заказ' />)
            return
        }

        GF.API_task({
            operation: 'createDoc',
            block: 'Calc',
            ID_ORDER: Self.ID,
            ID_USER: this.props.user.ROLE == 'ADMN' || this.props.user.ROLE == 'MNGR' ? Self.ID_USER : null
        }).then(data => {
            this.props.getORDERS(() => {
                this.componentDidMount()
            })
            let files = [
                {
                    title: 'Спецификация ' + Self.GUID + '.xlsx',
                    url: data.clientDoc
                },
                ...(props.user.ROLE == 'ADMN' || props.user.ROLE == 'MNGR' ? [
                    {
                        title: 'Спецификация на производство ' + Self.GUID + '.xlsx',
                        url: data.workDoc
                    },
                    {
                        title: 'Значения калькулятора ' + Self.GUID + '.xlsx',
                        url: data.keyValueDoc
                    }
                ] : [])

            ]

            this.props.openPopUp(<div style={{
                width: 540
            }}>
                <SaveFiles files={files} />
                <hr />
                <Button color='success' onClick={() => {
                    if (!this.canSendManager) {
                        console.error('canSendManager', false)
                        return
                    }

                    this.canSendManager = false
                    GF.API_task({
                        operation: 'confimOrder',
                        ID_ORDER: Self.ID,
                        ID_USER: this.props.user.ROLE == 'ADMN' || this.props.user.ROLE == 'MNGR' ? Self.ID_USER : null
                    }).then(data => {
                        props.closePopUp()
                        props.openPopUp(<POP_Alert text='Письмо отправленно' />)
                    }).catch(() => {
                        this.canSendManager = true
                    })
                }}>{this.props.user.ROLE == 'MNGR' ? 'Продублировать себе на почту' : 'Отправить менеджеру на почту'}</Button>
            </div>)
        })
    }


    OrderItemsCount(ORDER_ITEM, arg) {
        this.canSendManager = true
        const { state, props } = this
        var { Self } = { ...{ ...state } }

        if (ORDER_ITEM.ORDERS_COUNT + arg > 0) {
            let newCount = ORDER_ITEM.ORDERS_COUNT + arg

            ORDER_ITEM.RESULT['Количество карнизов (шт)'] = newCount

            Self.ITEMS.forEach((itm, i) => {
                if (Self.ITEMS[i].ID == ORDER_ITEM.ID) {
                    Self.ITEMS[i].ORDERS_COUNT = newCount
                }
            })



            this.setState({ Self: { ...Self } })

            let PAINTING = ORDER_ITEM.RESULT['_Покраска (базовая стоимость)']

            if (ORDER_ITEM.RESULT['Покраска (базовая стоимость)'] == 'Да') {
                PAINTING = PAINTING + (newCount - 1) * 1000
            }


            GF.API_task({
                operation: 'createUpdateOrderItem',
                block: 'Calc',
                ID_ORDER: Self.ID,
                ID_ORDER_ITEM: ORDER_ITEM.ID,
                PAINTING,
                SUM_PULTS: ORDER_ITEM.SUM_PULTS,
                ORDERS_COUNT: newCount,
                DOP_COAST: ORDER_ITEM.DOP_COAST,
                TAX: ORDER_ITEM.TAX,
                ONE_COAST: ORDER_ITEM.ONE_COAST,
                RESULT: JSON.stringify(ORDER_ITEM.RESULT),
                FIELDS_DATA: ORDER_ITEM.FIELDS_DATA
            }).finally(() => {
                props.getORDERS(() =>
                    setTimeout(() => {
                        this.componentDidMount()
                    }, 300)
                )
            })
        } else {//удаление
            Self.ITEMS.forEach((itm, i) => {
                if (Self.ITEMS[i].ID == ORDER_ITEM.ID) {
                    Self.ITEMS[i].ORDERS_COUNT = 0
                }
            })


            this.setState({ Self: { ...Self } })

            GF.API_task({
                operation: 'deleteSome',
                Table: 'ORDER_ITEMS',
                ID: ORDER_ITEM.ID
            }).then(data => {
                props.getORDERS(() => {
                    setTimeout(() => {
                        this.componentDidMount()
                    }, 300)
                })

            })
        }
    }


    render() {
        const { state, props } = this
        const { NEW_PARTNERS, ID_OBJECT } = props
        const { Self, anchorEl } = state
        const open = Boolean(anchorEl)



        if (ID_OBJECT != null && Self.ID == null) return <div />

        console.error(Self.IMAGES_URL)

        return (
            <POP>
                <POPtop>
                    <div style={{ display: 'flex', gap: 10, justifyContent: 'space-between' }}>
                        <Chip color='success' label={Self.GUID} />
                        <Chip avatar={<Avatar />} label={Self.ID_USER == props.user.ID ? 'Мой заказ' : Self.FIO} onClick={() => this.props.openPopUp(<POP_User ID_OBJECT={Self.ID_USER} />)} />

                        {props.user.ROLE == 'ADMN' || props.user.ROLE == 'MNGR' ?
                            <Button color='info' size='small' onClick={() => {
                                this.props.openPopUp(<POP_UserSearch title='Выберите другого юзера' callBack={async selected_clen => {

                                    if (selected_clen?.[0]?.ID == null) return

                                    let isOk = true

                                    if ((selected_clen[0].ROLE == 'PRTN' || selected_clen[0].ROLE == 'CPRT') && Self.ID_STATUS != 13) {
                                        await new Promise((resolve, reject) => {
                                            this.props.openPopUp(<Question text={'Если этот заказ назначить партнеру, а не менеджеру, то он будет доступен только партнеру. Продолжить?'}
                                                buttons={[
                                                    <Button className='m-2' color='error' onClick={() => resolve()}>Да</Button>,
                                                    <Button className='m-2' onClick={() => reject()}>Отмена</Button>
                                                ]} />)
                                        }).then(() => {

                                        }).finally(() => {
                                            this.props.closePopUp()
                                        }).catch(() => {
                                            isOk = false
                                        })
                                    }

                                    debugger
                                    if (!isOk) return



                                    GF.API_task({
                                        operation: 'orderSetOtherUserOwner',
                                        block: "Calc",
                                        ID_ORDER: state.Self.ID,
                                        ID_USER_NEW: selected_clen[0].ID
                                    }).then(data => {
                                        this.props.closePopUp()

                                        props.getORDERS(() => {
                                            setTimeout(() => {
                                                this.componentDidMount()
                                            }, 10)
                                        })
                                    })

                                }} isHiddenRule={user => {
                                    return !((user.ROLE == 'PRTN' || user.ROLE == 'СPRT') || (user.ID == props.user.ID))
                                }} placeholder='Нет пользователей' />)
                            }}>Другой пользователь</Button>
                            : null}

                        {(props.user.ROLE == 'PRTN' || props.user.ROLE == 'CPRT') && Self.ID_STATUS == 13 && Self.MANAGER_READED == 0 ?
                            <Chip color='info' label='Не просмотрен менеджером' />
                            : null}
                    </div>
                    <div style={{ display: 'flex', gap: 20, justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', gap: 20 }}>
                            {Self.ID_STATUS != 11 ? null :
                                <Button color='success' onClick={() => props.openPopUp(<div style={{
                                    width: 500,
                                    marginTop: -25,
                                    marginBottom: -20,
                                    paddingLeft: 15,
                                    paddingRight: 15,
                                    maxHeight: '80vh',
                                    overflow: 'auto'
                                }}>

                                    <CalkPage callBack={() => {
                                        props.closePopUp()
                                        this.canSendManager = true
                                        setTimeout(() => this.componentDidMount(), 500)
                                    }} ID_ORDER={Self.ID} ID_USER={Self.ID_USER} />

                                </div>)}>Добавить расчет</Button>
                            }
                            <div>
                                <small style={{
                                    display: 'block',
                                    marginBottom: -8,
                                    textAlign: 'center'
                                }}>{moment(Self.DATE_CREATE).format('DD.MM.YYYY')}</small>
                                <b style={{
                                    display: 'block',
                                    textAlign: 'center',
                                    fontWeight: '500'
                                }}>создан</b>
                            </div>
                            {!!Self.DATE_SENDED &&
                                <div>
                                    <small style={{
                                        display: 'block',
                                        marginBottom: -8,
                                        textAlign: 'center'
                                    }}>{moment(Self.DATE_SENDED).format('DD.MM.YYYY')}</small>
                                    <b style={{
                                        display: 'block',
                                        textAlign: 'center',
                                        fontWeight: '500'
                                    }}>оформлен</b>
                                </div>}



                        </div>


                        {this.props.user.ROLE == 'INST' ?
                            <div style={{
                                textAlign: 'right',
                                flexDirection: 'column',
                                alignContent: 'flex-end',
                                alignItems: 'flex-end',
                                marginRight: 5
                            }} /> :

                            <div style={{
                                textAlign: 'right',
                                flexDirection: 'column',
                                alignContent: 'flex-end',
                                alignItems: 'flex-end',
                                marginRight: 30
                            }}>
                                {this.props.user.ROLE == 'PRTN' || this.props.user.ROLE == 'CPRT' ? <>
                                    <p className='m-0'>{GF.makeCoast(Self.ALL_COAST)}₽ </p>
                                    {this.props.user.ROLE == 'MNGR' ? null :
                                        <small style={{ color: 'green', display: 'block', margin: 0 }} >({GF.makeCoast(Self.TAX_AVG)}%) {GF.makeCoast(Self.TAX_COAST)}₽</small>
                                    }
                                </>
                                    : this.props.user.ROLE == 'ADMN' ? <>
                                        <p className='m-0'>{GF.makeCoast(Self.ALL_COAST - Self.TAX_COAST)}₽ </p>
                                        {this.props.user.ROLE == 'MNGR' ? null :
                                            <small style={{ color: 'green', display: 'block', margin: 0 }} >({GF.makeCoast(Self.TAX_AVG)}%) +{GF.makeCoast(Self.TAX_COAST)}₽</small>
                                        }
                                    </>
                                        :
                                        <p className='m-0'>{GF.makeCoast(Self.ALL_COAST - Self.TAX_COAST)}₽ </p>
                                }
                            </div>}
                    </div>
                </POPtop>
                <POPscroll bottom={this.props.user.ROLE != 'INST'} top>

                    <div className='row'>
                        <div className='col-5' >

                            {form_fields.map(input =>
                                <TextField
                                    className='w-100 mt-1'
                                    margin='dense'
                                    disabled={Self.ID_STATUS != 11}
                                    variant="filled"
                                    size='small'
                                    color='success'
                                    {...input}
                                    value={state.Self[input.name]}
                                    onChange={(e) =>
                                        this.setState({
                                            Self: GF.changeParametr(state.Self, input.name, e.target.value)
                                        })
                                    }
                                    inputRef={ref => this.input[input.name] = ref}
                                />
                            )}


                            <FormControl fullWidth className='mt-1' variant="filled" size='small' color='success'>
                                <InputLabel variant="filled" size='small'>Вариант получения</InputLabel>
                                <Select
                                    size='small'
                                    value={Self.DELIVERY}
                                    disabled={Self.ID_STATUS != 11}
                                    label='Вариант получения'
                                    variant="filled"
                                    defaultValue='Самовывоз'
                                    native
                                    onChange={(e) => {
                                        this.setState({
                                            Self: {
                                                ...Self,
                                                DELIVERY: e.target.value
                                            }
                                        })
                                    }}>
                                    <option value='Самовывоз'>Самовывоз</option>
                                    <option value='Доставка'>Доставка</option>
                                    <option value='Доставка ТК'>Доставка ТК</option>
                                </Select>
                            </FormControl>


                            <SwitchCheck label='Нужна установка' className='mt-3'
                                checked={Self.NEED_INSTALL == 1}
                                disabled={Self.ID_STATUS != 11}
                                onChange={(e) => {
                                    this.setState({
                                        Self: {
                                            ...Self,
                                            NEED_INSTALL: e.target.checked ? 1 : 0
                                        }
                                    }, () => {
                                        GF.API_task({
                                            operation: 'updateOrder_simple',
                                            data: {
                                                NEED_INSTALL: e.target.checked ? 1 : 0
                                            },
                                            ID: Self.ID
                                        }).then(data => {
                                            props.getORDERS(() => {
                                                setTimeout(() => {
                                                    this.componentDidMount()
                                                }, 300)
                                            })
                                        })
                                    })


                                }} />

                            {state.installSchedule.length == 0 ? null : <>
                                <hr />
                                <small className='d-block'>Монтаж заказа в расписании</small>
                            </>}
                            {state.installSchedule.map(sch => {
                                return (<FlatCard key={sch.ID + 'Key'} style={{
                                    maxWidth: '100%',
                                    width: '100%',
                                    marginTop: 10
                                }} onClick={() => {
                                    props.openPopUp(<POP_InstallSchedule ID_OBJECT={sch.ID} callBack={() => this.componentDidMount()} />)
                                }}>
                                    <StackHorizontal>
                                        <div style={{
                                            flex: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            gap: 3,
                                            alignItems: 'flex-start'
                                        }}>

                                            {!!sch.USER_INSTALL_FIO && <Chip size='small' label={sch.USER_INSTALL_FIO} avatar={<Avatar />} />}
                                            <Chip size='small' style={{
                                                background: sch.STATUS_COLOR,
                                                color: 'white',
                                            }} label={sch.STATUS_TITLE} />

                                        </div>
                                        <Stack justifyContent='space-between'>
                                            <small className='d-block text-end'>{sch.dateInstall.format('D MMM')}</small>
                                            <small className='d-block text-end'>#{sch.ID}</small>
                                        </Stack>
                                    </StackHorizontal>
                                </FlatCard>)
                            })}

                            {Self.ID_STATUS != 11 ? null :
                                <Alert icon={false} color='info' className='mb-2 mt-3'  >
                                    Для дугового гнутия и загибов угла требуется приложить изображения схем с названием карниза
                                </Alert>
                            }

                            <div className='mb-2' style={{ height: 140 }}>

                                <Input_image multiple={true} value={Self.IMAGES_URL} onChange={(filesToUpload, filesToDelete) => {

                                    this.filesToUpload = [...this.filesToUpload, ...filesToUpload]
                                    this.filesToDelete = [...this.filesToDelete, ...filesToDelete]

                                }} />
                            </div>
                        </div>
                        <div className='col-7'>
                            <div style={{
                                maxHeight: 'calc(90vh - 50px)',
                                overflow: 'auto',
                                paddingTop: 5,
                                paddingRight: 20
                            }}>




                                {Self.ITEMS.map(item => <div className='mb-3' style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    justifyContent: 'space-between',
                                    cursor: 'pointer'
                                }} onClick={() => {


                                    props.openPopUp(<div style={{
                                        width: 500,
                                        marginTop: -20,
                                        marginBottom: -20,
                                        paddingLeft: 15,
                                        paddingRight: 15,
                                        maxHeight: '80vh',
                                        overflow: 'auto'
                                    }}>

                                        <CalkPage callBack={() => {
                                            props.closePopUp()
                                            setTimeout(() => this.componentDidMount(), 500)
                                            this.canSendManager = true
                                        }} ID_ORDER={Self.ID} ID_USER={Self.ID_USER} ID_ORDER_ITEM={item.ID} />

                                    </div>)
                                }}>

                                    <div>
                                        <small>{!!item.RESULT['Вид карниза'] && item.RESULT['Вид карниза']} {item.RESULT['Длинна'] == null ? null : item.RESULT['Длинна'] + 'м'} {item.TITLE == null ? null : item.RESULT['Бренд']}</small>
                                        <p className='m-0'><b>{item.TITLE != null ?
                                            item.TITLE :
                                            `${item.RESULT['Тип карниза']} ${item.RESULT['Бренд'] == null ? '' : item.RESULT['Бренд']}`
                                        }</b></p>
                                        {!!item.DESCR &&
                                            <small>{item.DESCR}</small>}
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <div style={{
                                            backgroundColor: 'rgb(230,230,230)',
                                            width: 80,
                                            display: 'flex',
                                            alignItems: 'center',
                                            alignContent: 'center',
                                            alignSelf: 'flex-end',
                                            justifyContent: 'space-between',
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            borderRadius: 8,
                                            paddingTop: 5,
                                            paddingBottom: 5
                                        }}>
                                            {Self.ID_STATUS != 11 ?
                                                <div style={{ width: '100%', textAlign: 'center' }}>{item.ORDERS_COUNT}</div>
                                                : <>
                                                    <div style={{
                                                        width: 30,
                                                        textAlign: 'center',
                                                        marginLeft: -10,
                                                        paddingLeft: 5
                                                    }} onClick={(e) => {
                                                        e.stopPropagation()
                                                        this.OrderItemsCount(item, -1)
                                                    }}>-</div>
                                                    <div>{item.ORDERS_COUNT}</div>
                                                    <div style={{
                                                        width: 30,
                                                        textAlign: 'center',
                                                        marginRight: -10,
                                                        paddingRight: 5
                                                    }} onClick={(e) => {
                                                        e.stopPropagation()
                                                        this.OrderItemsCount(item, +1)
                                                    }}>+</div>
                                                </>}
                                        </div>
                                        {this.props.user.ROLE == 'INST' ? null : <>
                                            <small style={{ alignSelf: 'flex-end', display: 'block' }} className='m-0 text-right flex-end'>{GF.makeCoast(item.ALL_COAST)}₽</small>
                                            {this.props.user.ROLE == 'MNGR' || item.TAX_COAST == 0 ? null :
                                                <small style={{ alignSelf: 'flex-end', display: 'block', color: 'green' }} >({item.TAX}%) {GF.makeCoast(item.TAX_COAST)}₽</small>
                                            }
                                        </>}
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    </div>

                </POPscroll>

                {this.props.user.ROLE == 'INST' ? null :
                    <POPbottom>

                        {this.props.ID_OBJECT == null ? null :
                            <Button className='float me-2' color='error' onClick={() => this.Delete()}>удалить</Button>
                        }
                        {Self.ID_STATUS != 11 ? <StackHorizontal>
                            <Button color='success' onClick={() => this.SendToInstallers()}>Передать в монтаж</Button>
                            <Button color='error' onClick={() => this.OrderBack()}>Отменить оформление</Button>
                        </StackHorizontal> : <POPgap>
                            <Button color='success' className='float-end' onClick={() => this.Save()}>Сохранить</Button>
                            <Button color='success' className='float-end' onClick={() => this.createDoc()}>Оформить</Button>
                        </POPgap>}

                    </POPbottom>
                }


            </POP>
        )



    }
}

const POP = styled.div`
width:900px;
`


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp: (arg) => dispatch(closePopUp(arg)),
        openPopUp: (arg) => dispatch(openPopUp(arg)),
        getDB_Data: (operation, tableName, JSONparseKey) => dispatch(getDB_Data(operation, tableName, JSONparseKey)),

        getDB_Data_lazy: (tableName, JSONparseKey) => dispatch(getDB_Data_lazy(tableName, JSONparseKey)),

        getORDERS: (arg) => dispatch(getORDERS(arg)),
        getUSERS: (arg) => dispatch(getUSERS(arg)),
    };
};


const mapStateToProps = (store) => {

    return {
        user: store.user.data,
        ORDERS: store.page.ORDERS,
        InstallSchedule: store.page.InstallSchedule,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POP_Order)




